import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";

class CollapsibleTableRow extends CoreComponent {
  state = {
    showRow: false,
    rowDataLoaded: false,
    rowData: {},
  };

  initialLoadStarted = false;

  componentDidMount() {
    const {targetRef} = this.props;

    if (targetRef && targetRef.current) {
      targetRef.current.onclick = (e) => {
        this.toggleExtra(e);
      };
    }
  }

  doLoadData = () => {
    const {loadData} = this.props;

    if (this.defined(loadData)) {
      loadData().then((respData) => {
        this.setState({
          rowData: respData,
          rowDataLoaded: true,
        });
      }).catch((error) => {
        if (error.cancelled) {
          return;
        }

        this.props.setErrors(error.errors);
        this.setState({rowDataLoaded: true});
      });
    } else {
      this.setState({rowDataLoaded: true});
    }
  };

  toggleExtra = (e) => {
    const {targetRef, isOpenable} = this.props;

    // e.target.nodeName -> if other target (non-td) is clicked, table row won't be opened
    if (isOpenable === false || (!targetRef && e.target.nodeName !== "TD")) {
      return;
    }

    let doLoad = false;

    // Data is being loaded once on initial extra data row toggle
    if (!this.initialLoadStarted) {
      this.initialLoadStarted = true;
      doLoad = true;
    }

    this.setState({showRow: !this.state.showRow}, () => {
      if (doLoad) {
        this.doLoadData();
      }
    });
  };

  render() {
    const {rowData, showRow, rowDataLoaded} = this.state;
    const {isOpenable, targetRef} = this.props;
    const classNames = [];

    let {displayData} = this.props;
    if (!displayData) {
      displayData = () => null;
    }

    // targetRef'i olemasolu puhul avame ainult targetRef'i peale klikates - klikk tabelireal ei lähe arvesse
    if (!targetRef && isOpenable) {
      classNames.push("cursor-pointer");
    }
    if (showRow) {
      classNames.push("opened-extra-data-header");
    }

    return (
      <React.Fragment>
        <tr className={classNames.join(" ")} onClick={targetRef ? () => {} : this.toggleExtra}>
          {this.props.children}
        </tr>
        {showRow && (
          <tr className="opened-extra-data">
            <td colSpan={this.props.colSpan || 1}>
              {rowDataLoaded ? displayData(rowData) : `${this.trans("loading")}...`}
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(CollapsibleTableRow);
