import React from "react";
import CoreComponent from "../../core_component";
import {apiFetchCompaniesSummary, apiFetchCompaniesSummaryXLS} from "../../../api/fetch";
import {withTranslation} from "react-i18next";
import {centsToEurosToStr, timestampFmt} from "../../helper/utils";
import AdminCompanyReportFilters from "../../filters/admin_company_report_filters";
import moment from "moment";
import TLink from "../../helper/table_link";
import FileName from "../../helper/fileutil";
import FileSaver from "file-saver";
import {FetchButton} from "../../helper/fetch_button";
import Paginator from "../../helper/paginator";
import {CTable} from "../../helper/ctable";

class AdminCompaniesReport extends CoreComponent {
  state = {
    summaries: [],
  };

  fetchReport = (params) => {
    return this.cancelable(apiFetchCompaniesSummary(params, (respData) => {
      this.setState({
        summaries: respData.list,
      });
    }));
  };

  initXLSExport = (data) => {
    const xlsQuery = Object.assign({
      xls: true,
      xlsLanguage: this.props.i18n.language,
    }, data);

    return this.cancelable(apiFetchCompaniesSummaryXLS(xlsQuery, (xlsData) => {
      const fname = new FileName(this.trans("companies"), "xlsx")
        .withTimestampFormat("MM_YYYY")
        .withTimestamp(xlsQuery.dateIn);
      const opts = {
        "type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      };

      FileSaver.saveAs(new Blob([xlsData], opts), fname.getFullName());
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  render() {
    return (
      <div className="mt-2">
        <Paginator
          next={this.fetchReport}
          initialPageFilters={{dateIn: moment().format(timestampFmt)}}
          additionalComponent={(onFilter, initialPageFilters) => (
            <>
              <AdminCompanyReportFilters
                onFilter={onFilter}
                initialPageFilters={initialPageFilters}
                extraButtons={(data) => (
                  <FetchButton className={"sm-btn"} onClick={() => this.initXLSExport(data)}>
                    XLSX
                  </FetchButton>
                )}
              />
            </>
          )}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("company")}</th>
                <th>{this.trans("noOfCardsCreated")}</th>
                <th>{this.trans("transactionCount")}</th>
                <th>{this.trans("transactionSum")}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.summaries.map((summ, i) => (
                <tr key={`summary-row-${i}`}>
                  <TLink to={`/companies/${summ.companyID}`}>{summ.companyName}</TLink>
                  <td>{summ.cardCount}</td>
                  <td>{summ.transactionCount}</td>
                  <td>{centsToEurosToStr(summ.transactionSum)}</td>
                </tr>
              ))}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withTranslation()(AdminCompaniesReport);
