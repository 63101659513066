import React from "react";
import {Form} from "reactstrap";
import CoreComponent from "../core_component";

class CoreForm extends CoreComponent {
  state = {
    formKey: "form-key-x",
    allowSubmit: true
  };

  afterSubmit = () => {
    // formKey must be changed on every form submit,
    // as if the form data will be emptied on successful submission,
    // we don't want the browser to show empty form fields as being invalid.
    // Changing the key will fix it as the form will be re-mounted by doing this.
    if (this._isMounted) {
      this.setStateValue("formKey", (this.state.formKey === "form-key-x" ? "form-key-y" : "form-key-x"));
      this.addTimeout(setTimeout(() => this.setStateValue("allowSubmit", true), 1000));
    }
  };

  onSubmit = (ev) => {
    ev.preventDefault();

    if (!this.state.allowSubmit) {
      return;
    }

    this.setStateValue("allowSubmit", false, () => {
      // onSubmit promise must resolve only if component is still mounted.
      // If user is being navigated away from the page then onSubmit promise must reject
      this.props.onSubmit(ev).then(() => {
        this.afterSubmit();
      }).catch((error) => {
        if(error.cancelled) {
          return;
        }

        this.afterSubmit();
      });
    });
  };

  render() {
    let cl = this.props.className || "";
    if (!this.state.allowSubmit) {
      cl = cl +" form-deactivated";
    }

    return (
      <div key={this.state.formKey}>
        <Form {...this.props} className={cl} onSubmit={this.onSubmit}>
          {this.props.children}
        </Form>
      </div>
    );
  }
}

export default CoreForm;
