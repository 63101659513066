import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";
import {ic_mode_edit} from "react-icons-kit/md";
import FormGroupWErr from "../../helper/form_group_w_err";
import {Button, FormGroup, NavLink} from "reactstrap";
import Icon from "react-icons-kit";
import LabelWithError from "../../helper/label_with_error";
import DatePicker from "../../helper/date_picker";
import {apiEditCustomerDiscount, apiNewCustomerDiscount} from "../../../api/fetch";
import {discountEditMonthsBack, makeEventObjectFromDate} from "../../helper/utils";
import moment from "moment";
import PropTypes from "prop-types";

class OperCustomerDiscountModal extends CoreComponent {

  body = (data, errors, onChange) => {
    let discountFrom;
    if (!(data.discountFrom === null || data.discountFrom === undefined)) {
      discountFrom = new Date(data.discountFrom);
    }
    let discountTo;
    if (!(data.discountTo === null || data.discountTo === undefined)) {
      discountTo = new Date(data.discountTo);
    }
    const minDate = new Date(moment().subtract(discountEditMonthsBack, "months").startOf("month").format());

    return (
      <>
        <FormGroupWErr
          str={"discountPercentage"}
          type={"number"}
          min={1}
          max={100}
          err={errors.discountPercentage}
          val={data.discountPercentage}
          onChange={onChange}
        />
        <FormGroup>
          <div>
            <LabelWithError required>
              {this.trans("discountFrom")}
            </LabelWithError>
            <DatePicker
              onChange={e => onChange(makeEventObjectFromDate(e, "discountFrom"))}
              value={discountFrom}
              format={"MM.yy"}
              maxDetail={"year"}
              minDetail={"year"}
              minDate={minDate}
            />
          </div>
          <div className="mt-3">
            <LabelWithError>
              {this.trans("discountTo")}
            </LabelWithError>
            <DatePicker
              onChange={e => onChange(makeEventObjectFromDate(e, "discountTo"))}
              value={discountTo}
              format={"MM.yy"}
              maxDetail={"year"}
              minDetail={"year"}
              minDate={discountFrom || minDate}
              required={false}
            />
          </div>
        </FormGroup>
        <FormGroupWErr label={"comment"} str={"comment"} type="textarea" err={errors.comment} val={data.comment} onChange={onChange} required={false}/>
      </>
    );
  };

  caller = (toggleOpen, discount) => {
    if (this.props.isNew) {
      return (
        <Button className={"my-2 btn-add sm-btn"} onClick={toggleOpen}>
          {this.trans("addCustomerDiscount")}
        </Button>
      );
    }
    return (
      <td className="icon-wrapper-width">
        <NavLink className="p-0 d-inline-block" href="#" onClick={(ev) => toggleOpen(ev, discount)}>
          <Icon icon={ic_mode_edit}/>
        </NavLink>
      </td>
    );
  };

  onSubmit = (data) => {
    data["discountFrom"] = moment(data["discountFrom"]).format();
    if (!(data["discountTo"] === null || data["discountTo"] === undefined)) {
      data["discountTo"] = moment(data["discountTo"]).format();
      if (data["discountFrom"] > data["discountTo"]) {
        data["discountTo"] = data["discountFrom"];
      }
    }
    if (this.props.isNew) {
      return this.cancelable(apiNewCustomerDiscount(this.props.customerID, data));
    } else {
      const {customerID, customerDiscountID} = this.props.discount;
      return this.cancelable(apiEditCustomerDiscount(customerID, customerDiscountID, data));
    }
  };

  render() {
    const {isNew} = this.props;

    return (
      <ModalWindow
        headerText={this.trans(isNew ? "addCustomerDiscount" : "editCustomerDiscount")}
        body={this.body}
        caller={(toggleOpen) => this.caller(toggleOpen, this.props.discount)}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default withTranslation()(OperCustomerDiscountModal);

OperCustomerDiscountModal.propTypes = {
  isNew: PropTypes.bool, // controls if add (true) or edit (false/undefined)
  customerID: PropTypes.number, // add
  discount: PropTypes.object, // edit
};
