import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {customerType} from "../../helper/utils";
import {Button, FormGroup, Input, Label} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import TextInputWithCheckbox from "../../helper/text_input_w_checkbox";
import LabelWithError from "../../helper/label_with_error";
import {postRequest} from "../../../api/request";
import ModalWindow from "../../helper/modal_window";
import {withRouter} from "react-router-dom";
import {apiFetchLanguages} from "../../../api/fetch";
import Icon from "react-icons-kit";
import {ic_info_outline} from "react-icons-kit/md";
import Tooltip from "../../helper/tooltip";

class NewCustomerModal extends CoreComponent {

  state = {
    checkedContPerson: false,
    checkedCustomerUnit: false,
    contactEmailRequired: true,
    contactPhoneRequired: true,
    languages: [],
  };

  newCustomerCaller = (toggleOpen) => {
    const initData = {
      customerTypeID: customerType.Company
    };

    return (
      <Button className="ml-2 btn-add sm-btn" onClick={(ev) => toggleOpen(ev, initData)}>
        {this.trans("addNew")}
      </Button>
    );
  };

  newCustomerBody = (data, errors, onChange) => {
    const {customerTypes, user} = this.props;
    const {checkedContPerson, checkedCustomerUnit, contactEmailRequired, contactPhoneRequired} = this.state;

    this.onChangeContactEmail = (ev) => {
      onChange(ev, (_data) => {
        if(_data.contactEmail !== ""){
          this.setState({
            contactPhoneRequired: false
          });
        }else{
          this.setState({
            contactPhoneRequired: true
          });
        }
      });
    };

    this.onChangeContactPhone = (ev) => {
      onChange(ev, (_data) => {
        if(_data.contactPhone !== ""){
          this.setState({
            contactEmailRequired: false
          });
        }else{
          this.setState({
            contactEmailRequired: true
          });
        }
      });
    };

    this.checkboxChangeContPerson = () => {
      this.setState({checkedContPerson: !checkedContPerson});
    };

    this.checkboxChangeCustomerUnit = () => {
      this.setState({checkedCustomerUnit: !checkedCustomerUnit});
    };

    return (
      <>
        <FormGroupWErr
          str="customerTypeID"
          label="customerType"
          type="select"
          err={errors.customerTypeID}
          options={customerTypes.map((ct) => ({value: ct.id, name: this.trans(`customerTypes.${ct.id}`)})).filter(v => v.value !== customerType.Self)}
          onChange={onChange}
          val={data.customerTypeID}
        />
        <FormGroupWErr maxLength={90} str={"name"} err={errors.name} val={data.name} onChange={onChange}/>
        <FormGroupWErr maxLength={64} str={"identification"} err={errors.identification} val={data.identification} onChange={onChange}/>
        <FormGroupWErr maxLength={512} str={"address"} err={errors.address} val={data.address} onChange={onChange}/>
        <FormGroupWErr maxLength={254} str={"email"} err={errors.email} val={data.email} type={"email"} onChange={onChange}/>
        <FormGroupWErr
          maxLength={64} str={"phone"} err={errors.phone} val={data.phone}
          onChange={onChange} optional={customerType.Private !== data.customerTypeID}
        />
        <FormGroupWErr
          str="languageID"
          label="language"
          type="select"
          err={errors.languageID}
          options={this.state.languages.map((lang) => ({value: lang.id, name: this.trans(`languages.${lang.id}`)}))}
          onChange={onChange}
          val={data.languageID}
        />
        <TextInputWithCheckbox
          str={"billingEmail"}
          type="email"
          checkboxID={"billingEmailCB"}
          value={data.billingEmail || ""}
          onChange={onChange}
          maxLength={254}
          error={errors.billingEmail}
        />
        <TextInputWithCheckbox
          str={"customerNo"}
          checkboxID={"customerNoCB"}
          value={data.customerNo || ""}
          onChange={onChange}
          maxLength={64}
          error={errors.customerNo}
        />
        <FormGroup check>
          <Input type="checkbox" checked={checkedContPerson} id={"contactPerson"} onChange={this.checkboxChangeContPerson}/>
          <LabelWithError error={errors.contactPerson} for={"contactPerson"}>{this.trans("contactPerson")}</LabelWithError>
        </FormGroup>
        {checkedContPerson && <FormGroup>
          <FormGroupWErr maxLength={90} label={"name"} str={"contactName"} err={errors.contactName}
            val={data.contactName} required onChange={onChange} />
        </FormGroup>}
        {checkedContPerson && <FormGroup>
          <FormGroupWErr maxLength={254} label={"email"} str={"contactEmail"} type={"email"} err={errors.contactEmail}
            val={data.contactEmail} required={contactEmailRequired} onChange={this.onChangeContactEmail} />
        </FormGroup>}
        {checkedContPerson && <FormGroup>
          <FormGroupWErr maxLength={64} label={"phone"} str={"contactPhone"} err={errors.contactPhone}
            val={data.contactPhone} required={contactPhoneRequired} onChange={this.onChangeContactPhone} />
        </FormGroup>}
        <FormGroup check>
          <Input type="checkbox" checked={checkedCustomerUnit} id={"customerUnit"} onChange={this.checkboxChangeCustomerUnit}/>
          <LabelWithError error={errors.customerUnit} for={"customerUnit"}>
            {this.trans("customerUnit")}{checkedCustomerUnit ? "" : ` (${this.trans("automaticallyGenerated")})`}
          </LabelWithError>
        </FormGroup>
        {checkedCustomerUnit && <FormGroup>
          <FormGroupWErr maxLength={90} label={"name"} str={"customerUnitName"} err={errors.customerUnitName}
            val={data.customerUnitName} required onChange={onChange} />
        </FormGroup>}
        {checkedCustomerUnit && <FormGroup>
          <FormGroupWErr maxLength={500} label={"comment"} str={"customerUnitComment"} err={errors.customerUnitComment}
            val={data.customerUnitComment} required={false} onChange={onChange} />
        </FormGroup>}
        {checkedCustomerUnit &&
          <FormGroup check>
            <Label>
              <Input
                type="checkbox" id="defaultCustomerUnit"
                name="defaultCustomerUnit"
                checked={data.defaultCustomerUnit}
                onChange={onChange}
                disabled={user.companyAsCustomer}
              />
              {this.trans("defaultCustomerUnit")}
            </Label>
            <Tooltip
              trans={(user.companyAsCustomer) ? "toSetThisUnitAsDefaultUncheckCurrentMsg" : "defaultCustomerUnitIsUniqueMsg"}
              showif={true}
            >
              <Icon icon={ic_info_outline} size={20}/>
            </Tooltip>
          </FormGroup>
        }
      </>
    );
  };

  newCustomerOnSubmit = (data) => {
    const reqData = Object.assign({}, data);

    if (!this.state.checkedContPerson) {
      reqData.contactName = "";
      reqData.contactEmail = "";
      reqData.contactPhone = "";
    }
    if (!this.state.checkedCustomerUnit) {
      reqData.customerUnitName = "";
      reqData.customerUnitComment = "";
    }

    return new Promise((resolve, reject) => {
      this.cancelable(postRequest("/api/customers", reqData)).then((resp) => {
        this.navTo(`/customers/${resp.data.customerID}`, "saved");
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  onModalOpen = () => {
    return new Promise((resolve, reject) => {
      const {languages} = this.state;
      const promises = [];

      if (languages.length <= 0) {
        promises.push(this.cancelable(apiFetchLanguages((resp) => {
          this.setStateValue("languages", resp);
        })));
      }

      Promise.all(promises).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  };

  render() {
    return (
      <>
        <ModalWindow
          headerText={this.trans("newCustomer")}
          caller={this.newCustomerCaller}
          body={this.newCustomerBody}
          onSubmit={this.newCustomerOnSubmit}
          onOpen={this.onModalOpen}
        />
      </>
    );
  }

}

export default withRouter(withTranslation()(NewCustomerModal));
