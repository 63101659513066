import React from "react";
import {Link, withRouter} from "react-router-dom";
import {Button, Col, FormGroup, Row} from "reactstrap";
import LabelWithError from "./helper/label_with_error";
import CoreForm from "./helper/core_form";
import {withTranslation} from "react-i18next";
import {withAlert} from "./helper/alert";
import CoreComponent from "./core_component";
import AInput from "./helper/apple_input";
import {apiLogin} from "../api/fetch";

class _Login extends CoreComponent {
  state = {
    email: "",
    password: "",
    role: "",
  };

  onChange = (e) => {
    this.setStateValue(e.target.name, e.target.value);
  };

  onSubmit = () => {
    const {email, password} = this.state;
    const reqData = {email, password};

    return this.cancelable(apiLogin(reqData, resp => {
      this.props.login(resp);
      this.navReplace("/");
    }, error => {
      this.props.setErrors(error.errors);
      this.setState(Object.assign({}, this.state, {
        password: ""
      }));
    }));
  };

  render() {
    const {email} = this.state;
    const {errors, renderAlert} = this.props;

    return (
      <Col className={"panel"}>
        <Row className={"justify-content-center my-3"}>
          <Col xs={9}>
            <div className="kkis-logo lg"/>
            {renderAlert()}
            <CoreForm onSubmit={this.onSubmit}>
              <FormGroup>
                <LabelWithError error={errors.email} for="email">{this.trans("email")}</LabelWithError>
                <AInput maxLength={254} type="email" name="email" id="email" value={email || ""} required onChange={this.onChange}/>
              </FormGroup>
              <FormGroup>
                <LabelWithError error={errors.password} for="password">{this.trans("password")}</LabelWithError>
                <AInput maxLength={256} type="password" name="password" id="password" required onChange={this.onChange}/>
                <div className="d-flex justify-content-end mt-1">
                  <Link to={"/forgot-password"} tabIndex="-1" className="text-underline">
                    {this.trans("forgotPasswordOrResendRegistrationLink")}
                  </Link>
                </div>
              </FormGroup>
              <FormGroup className={"float-right mt-2"}>
                <Button className={"btn-filtr sm-btn"}>{this.trans("login")}</Button>
              </FormGroup>
            </CoreForm>
          </Col>
        </Row>
      </Col>
    );
  }
}

export const Login = withAlert(withTranslation()(withRouter(_Login)));
