import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";

class Tooltip extends CoreComponent {
  state = {
    showContent: false,
  }

  contentDelay = 250;
  contentDelayTimeout = null;

  delayContent = () => {
    clearTimeout(this.contentDelayTimeout);
    this.contentDelayTimeout = setTimeout(() => this.setState({showContent: true}), this.contentDelay);
  }

  render() {
    const {showif, trans, children, getContent, className} = this.props;
    let {title, id} = this.props;

    if (showif === true) {
      if (trans) {
        title = this.trans(`tooltips.${trans}`);
      }

      if (!id) {
        id = `tooltip_${btoa(title)}_${String(parseInt(String(Math.random() * 999999))).padStart(6, "0")}`;
      }

      if (getContent) {
        return (
          this.state.showContent ? (
            <>
              <ReactTooltip id={id} getContent={getContent} className={className}/>
              <span data-tip="content-tooltip" data-for={id}>
                {children}
              </span>
            </>
          ) : (
            <span
              onMouseOver={this.delayContent}
              onMouseLeave={() => clearTimeout(this.contentDelayTimeout)}
            >
              {children}
            </span>
          )
        );
      }

      return (
        <>
          <ReactTooltip id={id} className={className}>
            <span>{title}</span>
          </ReactTooltip>
          <span data-tip="default-tooltip" data-for={id}>
            {children}
          </span>
        </>
      );
    } else {
      return (
        <>
          {children}
        </>
      );
    }
  }
}

export default withTranslation()(Tooltip);
