import Ajv from "ajv";
import {delRequest, getRequest, postRequest, putRequest} from "./request";
import {errorCodes} from "../component/helper/utils";

const handleRequest = (req, onResolve, onReject, jsonSchema = {}, resolveDataKey = null) => {
  return new Promise((resolve, reject) => {
    req.then((resp) => {
      // Validate JSON schema
      const validate = new Ajv().compile(jsonSchema);

      if (!validate(resp.data)) {
        const error = {
          errors: {
            error: "ERROR_SOMETHING_WRONG_CODE_" + errorCodes.schemaValidationFail,
          },
        };

        if (onReject) {
          onReject(error);
        }

        reject(error);
        return;
      }

      // resolveDataKey should be used in case the data that must reach
      // the promise consumer, is not on the top-level of the resp.data
      if (resolveDataKey !== null) {
        resolve(resp.data[resolveDataKey]);
      } else {
        resolve(resp.data);
      }

      if (onResolve) {
        onResolve(resp.data);
      }
    }).catch((error) => {
      if (error.cancelled) {
        reject(error);
        return;
      }

      if (onReject) {
        onReject(error);
      }

      reject(error);
    });
  });
};

const objectSchema = {
  "type": "object",
};

const arraySchema = {
  "type": "array",
  "items": {
    "type": "object",
  },
};

export const paginationSchema = {
  "type": "object",
  "properties": {
    "list": {
      "type": "array",
    },
    "count": {
      "type": ["integer", "null"],
    },
    "sum": {
      "type": ["integer", "null"],
    },
  },
  "required": ["list"],
};

export const apiFetchCards = (filtersData, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/companies/cards", filtersData);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchCardChanges = (cardID, urlQuery, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/cards/${cardID}/changes`, urlQuery);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchCardsWoClient = (filtersData, onResolve, onReject = () => {}) => {
  // see peaks olema GET päring, aga vältimaks võimalike GET browser/server pikkuse limiteeringuid on POST kuna siit võib palju datat tulla
  const {promise, cancel} = postRequest("/api/companies/cards/without-client", filtersData);

  return {
    promise: handleRequest(promise, onResolve, onReject, arraySchema),
    cancel: cancel,
  };
};

export const apiFetchCardWoClient = (cardID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/companies/cards/without-client/${cardID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apiAddClientToCard = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = postRequest("/api/cards/without-client/add-client", data);

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apiDeleteCardsWoClient = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = delRequest("/api/cards/without-client/delete", data);

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apiCreateCardsWithoutClient = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = postRequest("/api/cards/without-client/create", data, {responseType: "blob"});

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apiFetchCustomerCards = (customerID, filtersData, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/customers/${customerID}/cards`, filtersData);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};


export const apiFetchCustomerUnitCards = (customerUnitID, filtersData, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/customer-unit/${customerUnitID}/cards`, filtersData);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchCustomerUnit = (customerID, unitID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/customers/${customerID}/units/${unitID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiFetchCustomerUnits = (customerID, urlQuery, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/customers/${customerID}/units`, urlQuery);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchCard = (cardID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/companies/cards/${cardID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiFetchCardLimitsTotalBalance = (cardID, data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/cards/${cardID}/limits-totals-balance`, data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiFetchCardProducts = (onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/card-products");

  return {
    promise: handleRequest(promise, onResolve, onReject, arraySchema),
    cancel: cancel,
  };
};

export const apiFetchCardProductsAdmin = (companyID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/companies/${companyID}/card-products`);

  return {
    promise: handleRequest(promise, onResolve, onReject, arraySchema),
    cancel: cancel,
  };
};

const transactionsSchema = {
  "type": "object",
  "properties": {
    "totalAmount": {
      "type": "integer",
    },
    "totalCount": {
      "type": "integer",
    },
    "transactions": {
      "type": "array",
      "items": {
        "type": "object",
      },
    },
  },
};

export const apiFetchCardTransactions = (cardID, filtersData, onResolve = () => {}, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/cards/${cardID}/transactions`, filtersData);

  return {
    promise: handleRequest(promise, onResolve, onReject, transactionsSchema),
    cancel: cancel,
  };
};

export const apiExtendCardExpiryTime = (cardID, data, onResolve = () => {}, onReject = () => {}) => {
  const {promise, cancel} = putRequest(`/api/cards/${cardID}/extend-expiry-time`, data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiExtendCardsExpiryTime = (data, onResolve = () => {}, onReject = () => {}) => {
  const {promise, cancel} = putRequest("/api/cards/extend-expiry-time", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiFetchTerminalOwnerTransactions = (terminalOwnerID, filtersData, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/terminal-owners/${terminalOwnerID}/transactions`, filtersData);

  return {
    promise: handleRequest(promise, onResolve, onReject, transactionsSchema),
    cancel: cancel,
  };
};

export const apiFetchTransactionReport = (filters, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/transactions/report", filters);

  const schema = {
    "type": "object",
    "properties": {
      "list": {
        "type": "array",
      },
      "transactionCount": {
        "type": "integer",
      },
      "amountSum": {
        "type": "integer",
      },
    },
  };

  return {
    promise: handleRequest(promise, onResolve, onReject, schema),
    cancel: cancel,
  };
};

export const apiFetchActiveCardsReport = (customerID, filtersData, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/customers/${customerID}/cards`, filtersData, {responseType: "blob"});
  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apiFetchTransactionReportDownload = (filters, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/transactions/report", filters, {responseType: "blob"});

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apiDeleteCard = (cardID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = delRequest(`/api/companies/cards/${cardID}/delete`);

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apiDeleteCards = (reqData, onResolve, onReject = () => {}) => {
  const {promise, cancel} = delRequest("/api/companies/cards/delete", reqData);

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apiDeleteCustomer = (customerID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = delRequest(`/api/customers/${customerID}/delete`);

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apiReopenCard = (cardID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = putRequest(`/api/companies/cards/${cardID}/reopen`);

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apifetchPrinterCSVsInfo = (customerID, query, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/customers/${customerID}/printer-csvs-info`, query);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apifetchPrinterCSV = (csvBatch, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/cards/printer-csv-batch/${csvBatch}`, "", {responseType: "blob"});

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apifetchPrinterCSVForCardsWoClient = (csvBatch, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/cards/without-client/printer-csv-batch/${csvBatch}`, "", {responseType: "blob"});

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apiFetchContactPeople = (customerID, filtersData, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/customers/${customerID}/contact-people`, filtersData);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchTerminals = (terminalOwnerID, filtersData, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/terminal-owners/${terminalOwnerID}/terminals`, filtersData);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchCompanyOperators = (companyID, filtersData, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/companies/${companyID}/operators`, filtersData);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiGetAdminOperators = (filtersData, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/operators", filtersData);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiGetSelfInfo = (onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/self-info");

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiFetchPrimaryContactPerson = (customerID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/customers/${customerID}/primary-contact-person`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiFetchTerminalOwners = (urlQuery, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/terminal-owners", urlQuery);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchTerminalOwnerChanges = (terminalOwnerID, urlQuery, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/terminal-owners/${terminalOwnerID}/changes`, urlQuery);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchCompanies = (urlQuery, onResolve, onReject) => {
  const {promise, cancel} = getRequest("/api/companies", urlQuery);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchCompanyChanges = (companyID, urlQuery, onResolve, onReject) => {
  const {promise, cancel} = getRequest(`/api/companies/${companyID}/changes`, urlQuery);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiGetCompanyWebApis = (companyID, onResolve, onReject) => {
  const {promise, cancel} = getRequest(`/api/companies/${companyID}/web-apis`);

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apiAvailableWebApiPermissions = (webApiID, onResolve, onReject) => {
  const {promise, cancel} = getRequest(`/api/companies/company/web-apis/${webApiID}/permissions`);

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apiFetchTerminal = (terminalOwnerID, terminalID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/terminal-owners/${terminalOwnerID}/terminals/${terminalID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiFetchTerminalChanges = (terminalOwnerID, terminalID, urlQuery, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/terminal-owners/${terminalOwnerID}/terminals/${terminalID}/changes`, urlQuery);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchCustomerUnitChanges = (customerID, customerUnitID, urlQuery, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/customers/${customerID}/units/${customerUnitID}/changes`, urlQuery);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchCustomerChanges = (custID, urlQuery, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/customers/${custID}/changes`, urlQuery);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchTerminalsTransactionsReport = (filters, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/transactions/terminals/report", filters);

  const schema = {
    "type": "object",
    "properties": {
      "list": {
        "type": "array",
      },
      "transactionCount": {
        "type": "integer",
      },
      "amountSum": {
        "type": "integer",
      },
    },
  };

  return {
    promise: handleRequest(promise, onResolve, onReject, schema),
    cancel: cancel,
  };
};

export const apiFetchTerminalsTransactionsXLS = (filters, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/transactions/terminals/report", filters, {responseType: "blob"});

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiFetchWebAPITransactionsReport = (filters, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/transactions/web-api/report", filters);

  const schema = {
    "type": "object",
    "properties": {
      "list": {
        "type": "array",
      },
      "transactionCount": {
        "type": "integer",
      },
      "amountSum": {
        "type": "integer",
      },
    },
  };

  return {
    promise: handleRequest(promise, onResolve, onReject, schema),
    cancel: cancel,
  };
};

export const apiFetchWebAPITransactionsXLS = (filters, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/transactions/web-api/report", filters, {responseType: "blob"});

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

// apiFetchTransactionsTotals fetches transactions totals for current month
export const apiFetchTransactionsTotals = (onResolve, onReject) => {
  const {promise, cancel} = getRequest("/api/transactions-totals");

  const schema = {
    "type": "object",
    "properties": {
      "totalCount": {
        "type": "integer",
      },
      "totalAmount": {
        "type": "integer",
      },
    },
  };

  return {
    promise: handleRequest(promise, onResolve, onReject, schema),
    cancel: cancel,
  };
};

// apiFetchCardsTotals fetches cards totals for current month
export const apiFetchCardsTotals = (onResolve, onReject) => {
  const {promise, cancel} = getRequest("/api/cards-totals");

  const schema = {
    "type": "object",
    "properties": {
      "noOfcardsCreated": {
        "type": "integer",
      },
    },
  };

  return {
    promise: handleRequest(promise, onResolve, onReject, schema),
    cancel: cancel,
  };
};

export const apiFetchCompanyTransactions = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/transactions/company", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchCardsXLSX = (query, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/cards/xlsx", query, {responseType: "blob"});

  return {
    promise: handleRequest(promise, onResolve, onReject),
    cancel: cancel,
  };
};

export const apiReallowLogin = (operatorID, onResolve, onReject) => {
  const {promise, cancel} = postRequest(`/api/operators/${operatorID}/reallow-login`);

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiSendErrors = (module, error, onResolve, onReject) => {
  const {promise, cancel} = postRequest("/api/send-errors", {"module": module, "error": error});

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiForgotPassword = (email, onResolve, onReject) => {
  const {promise, cancel} = postRequest("/api/forgot-password", {"email": email});

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiSearchTerminals = (query, onResolve, onReject) => {
  const {promise, cancel} = getRequest("/api/terminals", query);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchCustomerTypes = (onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/customer-types");

  return {
    promise: handleRequest(promise, onResolve, onReject, arraySchema),
    cancel: cancel,
  };
};

export const apiFetchLanguages = (onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/languages");

  return {
    promise: handleRequest(promise, onResolve, onReject, arraySchema),
    cancel: cancel,
  };
};

export const apiFetchCustomers = (filtersData, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/customers", filtersData);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiCreateReportsForAllCompanies = (params, onResolve, onReject) => {
  const {promise, cancel} = getRequest("/api/customers/all-customers-reports", params, {responseType: "blob"});

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiFetchCompaniesSummary = (params, onResolve, onReject) => {
  const {promise, cancel} = getRequest("/api/companies-summary", params);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchCompaniesSummaryXLS = (params, onResolve, onReject) => {
  const {promise, cancel} = getRequest("/api/companies-summary", params, {responseType: "blob"});

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiGetBatchExpiringCards = (query, cardID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/companies/cards/${cardID}/batch-expiring-cards`, query);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiFetchCustomersActivityReport = (query, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/customers/activity-report", query);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};


export const apiFetchCustomersActivityReportDownload = (query, onResolve, onReject = () => {}) => {
  const _query = Object.assign({downloadXLS: true}, query);
  const {promise, cancel} = getRequest("/api/customers/activity-report", _query, {responseType: "blob"});

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiGetAvailableDistributor = (id, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/distributors/${id}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiFetchDistributorChanges = (id, urlQuery, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/distributors/${id}/changes`, urlQuery);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiFetchAvailableDistributors = (urlQuery, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/distributors", urlQuery);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel,
  };
};

export const apiCreateNewDistributor = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = postRequest("/api/distributors", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiEditDistributor = (id, data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = putRequest(`/api/distributors/${id}`, data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiDeleteDistributor = (id, onResolve, onReject = () => {}) => {
  const {promise, cancel} = delRequest(`/api/distributors/${id}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiSearch = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/search", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, arraySchema),
    cancel: cancel,
  };
};

export const apiFetchCompanyRoles = (onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/company-roles");

  return {
    promise: handleRequest(promise, onResolve, onReject, arraySchema),
    cancel: cancel,
  };
};

export const apiFetchCompanyRole = (roleID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/company-roles/${roleID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiAddCompanyRole = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = postRequest("/api/company-roles", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiEditCompanyRole = (roleID, data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = putRequest(`/api/company-roles/${roleID}`, data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiDeleteCompanyRole = (roleID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = delRequest(`/api/company-roles/${roleID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiActivateCard = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = postRequest("/api/companies/cards/activate", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiGetActivatableCards = (query, onResolve, onReject) => {
  const {promise, cancel} = getRequest("/api/companies/cards/activatable", query);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiGetTransactionsStatsMonthly = (query, onResolve, onReject) => {
  const {promise, cancel} = getRequest("/api/transactions/stats", query);

  return {
    promise: handleRequest(promise, onResolve, onReject, arraySchema),
    cancel: cancel,
  };
};

export const apiGetCreatedCardsStatsMonthly = (query, onResolve, onReject) => {
  const {promise, cancel} = getRequest("/api/cards/created/stats", query);

  return {
    promise: handleRequest(promise, onResolve, onReject, arraySchema),
    cancel: cancel,
  };
};

export const apiGetCustomerDiscounts = (customerID, data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/customers/${customerID}/discount`, data);

  return {
    promise: handleRequest(promise, onResolve, onReject, paginationSchema),
    cancel: cancel
  };
};

export const apiGetCustomerDiscount = (customerID, discountID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/customers/${customerID}/discount/${discountID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel
  };
};

export const apiNewCustomerDiscount = (customerID, data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = postRequest(`/api/customers/${customerID}/discount`, data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel
  };
};

export const apiEditCustomerDiscount = (customerID, discountID, data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = putRequest(`/api/customers/${customerID}/discount/${discountID}`, data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel
  };
};

export const apiCloseCustomerDiscount = (customerID, discountID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = delRequest(`/api/customers/${customerID}/discount/${discountID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel
  };
};

export const apiGetOperator = (accountID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/operators/${accountID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel
  };
};

export const apiGetSelfOperator = (onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/operators/self");

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel
  };
};

export const apiUpdateOperator = (accountID, data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = putRequest(`/api/operators/${accountID}`, data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel
  };
};

export const apiUpdateOperatorSelf = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = putRequest("/api/operators/self", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel
  };
};

export const apiCloseAccount = (accountID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = delRequest(`/api/operators/${accountID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel
  };
};

export const apiCloseUser = (accountID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = delRequest(`/api/operators/${accountID}/all`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel
  };
};

export const apiCreateOperator = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = postRequest("/api/operators/create", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel
  };
};

export const apiChangeSelectedAccount = (accountID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = putRequest(`/api/change-selected-account/${accountID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel
  };
};

export const apiGetOperatorUserOptions = (operatorID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest(`/api/operators/${operatorID}/user-options`);

  return {
    promise: handleRequest(promise, onResolve, onReject, arraySchema),
    cancel: cancel
  };
};

export const apiPwChangeUuid = (uid, data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = putRequest(`/api/pwchange/${uid}`, data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiRegister = (uid, data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = putRequest(`/api/register/${uid}`, data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiLogin = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = postRequest("/api/login", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, objectSchema),
    cancel: cancel,
  };
};

export const apiGetRoles = (reqData, onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/roles", reqData);

  return {
    promise: handleRequest(promise, onResolve, onReject, arraySchema),
    cancel: cancel
  };
};

export const apiEditCompanyWebApiIP = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = putRequest("/api/companies/company/web-apis/ip", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiEditCompanyWebApi = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = putRequest("/api/companies/company/web-apis", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiAddCompanyWebApiIP = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = postRequest("/api/companies/company/web-apis/ip", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiDeleteCompanyWebApi = (webApiID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = delRequest(`/api/companies/company/web-apis/${webApiID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiDeleteCompanyWebApiIP = (webApiIPID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = delRequest(`/api/companies/company/web-apis/ip/${webApiIPID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiDeleteCompanyWebApiPermission = (webApiPermissionID, onResolve, onReject = () => {}) => {
  const {promise, cancel} = delRequest(`/api/companies/company/web-apis/permissions/${webApiPermissionID}`);

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiCreateCompanyWebApi = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = postRequest("/api/companies/company/web-apis", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiAddCompanyWebApiPermission = (data, onResolve, onReject = () => {}) => {
  const {promise, cancel} = postRequest("/api/companies/company/web-apis/permissions", data);

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};

export const apiGetCompanyWebApiAllPermissions = (onResolve, onReject = () => {}) => {
  const {promise, cancel} = getRequest("/api/companies/company/web-apis/all-permissions");

  return {
    promise: handleRequest(promise, onResolve, onReject, {}),
    cancel: cancel,
  };
};
