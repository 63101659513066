import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {apiFetchTerminalOwnerChanges} from "../../../api/fetch";
import Paginator from "../../helper/paginator";
import {mapChangedColumns} from "../../helper/utils";
import {CTable} from "../../helper/ctable";

class TerminalOwnerChangesContainer extends CoreComponent {
  state = {
    terminalOwnerChanges: []
  };

  fetchTerminalOwnerChanges = (urlQuery) => {
    return this.cancelable(apiFetchTerminalOwnerChanges(this.props.terminalOwnerID, urlQuery, (data) => {
      this.setState({terminalOwnerChanges: data.list});
    }));
  };

  render() {
    return (
      <div>
        <Paginator
          next={this.fetchTerminalOwnerChanges}
          pageLimit={10}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("name")}</th>
                <th>{this.trans("identifier")}</th>
                <th>{this.trans("dateOfChange")}</th>
                <th>{this.trans("author")}</th>
              </tr>
            </thead>
            <tbody>
              {mapChangedColumns(this.state.terminalOwnerChanges, (val, i, renderCol) => {
                return (
                  <tr key={`terminal-owner-body-${i}`}>
                    <td>{renderCol("name")}</td>
                    <td>{renderCol("identifier", {defaultValue: "-"})}</td>
                    <td>{val.validFrom}</td>
                    <td>{val.authorEmail}</td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withTranslation()(TerminalOwnerChangesContainer);
