import React from "react";
import {withTranslation} from "react-i18next";
import LabelWithError from "./label_with_error";
import {FormGroup} from "reactstrap";
import CoreComponent from "../core_component";
import SelectWrap from "./select_wrap";
import AInput from "../helper/apple_input";
import Tooltip from "./tooltip";

class FormGroupWErr extends CoreComponent {
  state = {
    optionalChecked: !!this.props.val,
    optionalCheckedOrigValue: this.props.val,
  };

  onOptionalValueChange = () => {
    this.setState({
      optionalChecked: !this.state.optionalChecked,
      optionalCheckedOrigValue: this.state.optionalChecked ? this.props.val : this.state.optionalCheckedOrigValue,
    }, () => {
      this.props.onChange({
        target: {
          name: this.props.str,
          value: this.state.optionalChecked ? this.state.optionalCheckedOrigValue : "",
        },
      });
    });
  };

  render() {
    const {
      err, str, label, type, placeholder, onChange, onInputChange,
      options, disabled, min, max, maxLength, step, className, initData,
      toolTip, row, optional,
    } = this.props;
    let {val, required} = this.props;

    if (!this.defined(required)) {
      required = true;
    }
    if (optional) {
      required = false;
    }

    const labelWrap = (
      <Tooltip trans={toolTip} showif={toolTip !== undefined}>
        <LabelWithError error={err} for={str} required={required}>
          {optional ? (
            <span className="d-flex align-items-center" onClick={this.onOptionalValueChange}>
              <input type="checkbox" className="mr-1" onChange={() => {} /* to prevent react errors */} checked={this.state.optionalChecked}/>
              {this.trans(label || str)}
            </span>
          ) : (
            this.trans(label || str)
          )}
        </LabelWithError>
      </Tooltip>
    );

    if (type === "select") {
      if (!this.defined(val)) {
        val = required ? null : 0;
      }

      return (
        <FormGroup row={row}>
          {labelWrap}
          {(!optional || this.state.optionalChecked) && (
            <SelectWrap
              str={str}
              val={val}
              onChange={onChange}
              onInputChange={onInputChange}
              required={optional ? this.state.optionalChecked : required}
              options={options}
              placeholder={placeholder}
              initData={initData}
            />
          )}
        </FormGroup>
      );
    }

    if (!this.defined(val)) {
      val = "";
    }

    return (
      <FormGroup row={row}>
        {labelWrap}
        {(!optional || this.state.optionalChecked) && (
          <AInput
            type={type || "text"}
            id={str}
            name={str}
            placeholder={placeholder}
            value={val}
            required={optional ? this.state.optionalChecked : required}
            onChange={onChange}
            disabled={disabled}
            min={min}
            max={max}
            maxLength={maxLength}
            step={step}
            className={className}
          />
        )}
      </FormGroup>
    );
  }
}

export default withTranslation()(FormGroupWErr);
