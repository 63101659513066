import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import {PaginationLimit} from "./utils";
import {withAlert} from "./alert";
import Icon from "react-icons-kit";
import {ic_keyboard_arrow_left, ic_keyboard_arrow_right} from "react-icons-kit/md";

class Paginator extends CoreComponent {
  state = {
    page: 0,
    maxPage: 0,
    pages: [],
    pageLimit: this.props.pageLimit || PaginationLimit,
    filtersData: this.props.initialPageFilters || {},
    orderBy: "",
    loading: false,
  };

  componentDidMount() {
    this.getPage(1);
  }

  getPageAsync = (page) => {
    const {pageLimit} = this.state;
    const maxLeadingTrailingPages = 3;

    const data = Object.assign({}, this.state.filtersData, {
      page: page,
      limit: pageLimit,
    });

    if (this.state.orderBy) {
      data["orderBy"] = this.state.orderBy;
    }

    return new Promise((resolve, reject) => {
      this.setState({loading: true}, () => {
        this.props.next(data).then((respData) => {
          let pages = [];
          const maxPage = Math.ceil(respData.count / pageLimit);

          // Leading pages
          for (let p = page - 1; p > 0 && p >= page - maxLeadingTrailingPages; p--) {
            pages.push({page: p, selected: false});
          }

          pages.reverse();
          pages.push({page: page, selected: true});

          // Trailing pages
          for (let p = page + 1; p <= maxPage && p <= page + maxLeadingTrailingPages; p++) {
            pages.push({page: p, selected: false});
          }

          this.setState(Object.assign({}, this.state, {
            page: page,
            maxPage: maxPage,
            pages: pages,
            loading: false,
          }));

          resolve();
        }).catch((err) => {
          reject(err);

          if (err.cancelled) {
            return;
          }
          this.setState(Object.assign({}, this.state, {
            loading: false,
          }));
        });
      });
    });
  }

  getPage = (page) => {
    this.getPageAsync(page).catch((err) => {
      if (err.cancelled) {
        return;
      }

      this.props.setErrors(err.errors);
    });
  };

  prevPageAllowed = () => {
    return this.state.page > 1;
  };

  nextPageAllowed = () => {
    return this.state.pages.filter((p) => p.page > this.state.page).length > 0;
  };

  resetPageAsyncWithData = (dataObj) => {
    return new Promise((resolve, reject) => {
      this.setState(dataObj, () => {
        this.getPageAsync(1).then(resolve).catch((err) => {
          if (err.cancelled) {
            reject(err);
            return;
          }

          this.props.setErrors(err.errors);
          reject(err);
        });
      });
    });
  }

  onFilter = (filtersData) => {
    return this.resetPageAsyncWithData({filtersData: filtersData});
  };

  onOrderByChange = (orderBy) => {
    return this.resetPageAsyncWithData({orderBy: orderBy});
  };

  renderAdditionalComponent = () => {
    // renderAdditionalComponent can be any function that accepts function as a parameter
    // and returns renderable content
    const {additionalComponent} = this.props;

    if (this.defined(additionalComponent)) {
      return additionalComponent(this.onFilter, this.props.initialPageFilters);
    }

    return null;
  };

  render() {
    const firstPageInList = this.state.pages.length > 0 ? this.state.pages.map((v) => v.page)[0] : null;
    const lastPageInList = this.state.pages.length > 0 ? this.state.pages.map((v) => v.page)[this.state.pages.length - 1] : null;

    let containerClassNames = ["paginator-btns"];

    if (this.props.light) {
      containerClassNames.push("light");
    }

    return (
      <div className="my-2">
        {this.props.renderAlert()}
        {this.renderAdditionalComponent()}

        {!this.props.paginationDisabled && this.state.pages.length > 1 && (
          <div className={containerClassNames.join(" ")}>
            <button disabled={!this.prevPageAllowed()} onClick={() => this.getPage(this.state.page - 1)}>
              <Icon icon={ic_keyboard_arrow_left}/>
            </button>

            <div className="d-flex">
              {firstPageInList && firstPageInList > 1 && (
                <>
                  <button disabled={this.state.loading} onClick={() => this.getPage(1)}>1</button>
                  {firstPageInList > 2 && (
                    <span className="mx-1 align-self-end">..</span>
                  )}
                </>
              )}

              {this.state.pages.map((v, i) => {
                let classNames = [];
                const isDisabled = v.selected || this.state.loading;

                if (v.selected) {
                  classNames.push("selected");
                }

                return (
                  <button disabled={isDisabled} className={classNames.join(" ")} key={`page-btn-${i}`} onClick={() => this.getPage(v.page)}>
                    {v.page}
                  </button>
                );
              })}

              {lastPageInList && lastPageInList < this.state.maxPage && (
                <>
                  {lastPageInList < (this.state.maxPage - 1) && (
                    <span className="mx-1 align-self-end">..</span>
                  )}
                  <button disabled={this.state.loading} onClick={() => this.getPage(this.state.maxPage)}>{this.state.maxPage}</button>
                </>
              )}
            </div>

            <button disabled={!this.nextPageAllowed()} onClick={() => this.getPage(this.state.page + 1)}>
              <Icon icon={ic_keyboard_arrow_right}/>
            </button>
          </div>
        )}

        {typeof this.props.children === "function"
          ? this.props.children({onOrderByChange: this.onOrderByChange})
          : this.props.children
        }
      </div>
    );
  }
}

export default withAlert(withTranslation()(Paginator));
