import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {PageTitle} from "../../helper/page_title";
import EditCustomerInfoModal from "../../modals/customer/edit_customer_info_modal";
import OverviewRow from "../../helper/overview_row";
import {Button} from "reactstrap";
import {apiDeleteCustomer, apiFetchPrimaryContactPerson} from "../../../api/fetch";
import {withRouter} from "react-router-dom";
import {ConfirmationModal} from "../../helper/modal_window";
import Tooltip from "../../helper/tooltip";
import {CAN_EDIT_CUSTOMERS} from "../../helper/role_permissions";

class CustomerInfoContainer extends CoreComponent {

  state = {
    primaryContactPerson: {},
  };

  componentDidMount() {
    this.fetchPrimaryContactPerson();
  }

  fetchPrimaryContactPerson = () => {
    return this.cancelable(apiFetchPrimaryContactPerson(this.props.customerID, (data) => {
      this.setState({
        primaryContactPerson: data,
      });
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  renderPrimaryContactPerson = () => {
    const {primaryContactPerson} = this.state;

    //kontrollime kas on tühi object {}
    if (Object.keys(primaryContactPerson).length === 0 && primaryContactPerson.constructor === Object) {
      return null;
    }

    return (
      <>
        <h5 className="dark-col">{this.trans("contactPerson")}</h5>
        <OverviewRow name={"name"} value={primaryContactPerson.contactName}/>
        <OverviewRow name={"email"} value={primaryContactPerson.contactEmail || "-"}/>
        <OverviewRow name={"phone"} value={primaryContactPerson.contactPhone || "-"}/>
      </>
    );
  };

  deleteCustomerRequest = () => {
    this.cancelable(apiDeleteCustomer(this.props.customerID, () => {
      this.navTo("/customers", "closed");
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  render() {
    const {customer, customerID, user} = this.props;

    if (customer.customerTypeID !== undefined) {
      this.cType = this.trans(`customerTypes.${customer.customerTypeID}`);
    } else {
      this.cType = "";
    }

    return (
      <>
        <div className="mt-3 d-flex flex-row justify-content-between">
          <div style={{width: "90%"}}>
            <div className="overflow-hidden mb-3">
              <PageTitle isGrouped={true} title={customer.name}/>
            </div>
            <OverviewRow name={"name"} value={customer.name}/>
            <OverviewRow name={"phone"} value={customer.phone} hidden={!customer.phone}/>
            <OverviewRow name={"email"} value={customer.email}/>
            <OverviewRow name={"billingEmail"} value={customer.billingEmail} hidden={!customer.billingEmail}/>
            <OverviewRow name={"customerNo"} value={customer.customerNo} hidden={!customer.customerNo}/>
            <OverviewRow name={"identification"} value={customer.identification}/>
            <OverviewRow name={"address"} value={customer.address}/>
            <OverviewRow name={"language"} value={this.trans(`languages.${customer.languageID}`)}/>
            <OverviewRow name={"contactPerson"} value={customer.contactPerson} hidden={!customer.contactPerson}/>
            <OverviewRow name={"customerType"} value={this.cType}/>
            <OverviewRow name={"activeDiscount"} value={`${customer.discountPercentage}%`} hidden={!customer.discountPercentage}/>
            {this.renderPrimaryContactPerson()}
          </div>
          <div>
            {user.hasPermission(CAN_EDIT_CUSTOMERS) && (
              <>
                <div className="mb-2 text-right">
                  <EditCustomerInfoModal
                    customer={customer}
                    customerID={customerID}
                  />
                </div>
                <div className="mb-2 text-right">
                  <ConfirmationModal
                    title={customer.name}
                    subtitle={this.trans("areYouSureToCloseThis.customer")}
                    onSubmit={this.deleteCustomerRequest}
                    opener={(open) => {
                      const classes = ["btn-del", "sm-btn"];

                      if (customer.cardsExist || user.companyAsCustomer) {
                        classes.push("btn-disabled");
                      }

                      return (
                        <Tooltip trans="toCloseThisCustomerFirstCloseAllCustomersCards" showif={customer.cardsExist}>
                          <Tooltip trans="toCloseThisCustomerFirstUncheckDefaultCustomerUnit" showif={!customer.cardsExist && user.companyAsCustomer}>
                            <Button onMouseUp={open} className={classes.join(" ")}>
                              {this.trans("close")}
                            </Button>
                          </Tooltip>
                        </Tooltip>
                      );
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withTranslation()(CustomerInfoContainer));
