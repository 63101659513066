import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";
import {Button} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import {customerType, isObjDataMemberDefined} from "../../helper/utils";
import {putRequest} from "../../../api/request";
import TextInputWithCheckbox from "../../helper/text_input_w_checkbox";
import {apiFetchCustomerTypes, apiFetchLanguages} from "../../../api/fetch";

class EditCustomerInfoModal extends CoreComponent {

  state = {
    customerTypes: [],
    languages: [],
  };

  editCustomerCaller = (toggleOpen) => {
    return (
      <Button className="btn-edit sm-btn" onClick={(ev) => toggleOpen(ev, this.props.customer)}>
        {this.trans("edit")}
      </Button>
    );
  };

  editCustomerBody = (data, errors, onChange) => {
    const {customerTypes, languages} = this.state;
    const {customer} = this.props;

    return (
      <>
        <FormGroupWErr maxLength={90} str={"name"} err={errors.name} val={data.name} onChange={onChange}/>
        <FormGroupWErr maxLength={64} str={"identification"} err={errors.identification} val={data.identification} onChange={onChange}/>
        <FormGroupWErr maxLength={512} str={"address"} err={errors.address} val={data.address} onChange={onChange}/>
        <FormGroupWErr
          maxLength={64} str={"phone"} err={errors.phone} val={data.phone}
          onChange={onChange} optional={customerType.Private !== data.customerTypeID}
        />
        <FormGroupWErr maxLength={254} str={"email"} err={errors.email} val={data.email} type={"email"} onChange={onChange}/>

        <TextInputWithCheckbox
          str={"billingEmail"}
          checked={isObjDataMemberDefined(customer, "billingEmail")}
          type="email"
          checkboxID={"billingEmailCB"}
          value={data.billingEmail || ""}
          onChange={onChange}
          maxLength={254}
          error={errors.billingEmail}
        />
        <TextInputWithCheckbox
          str={"customerNo"}
          checked={isObjDataMemberDefined(customer, "customerNo")}
          checkboxID={"customerNoCB"}
          value={data.customerNo || ""}
          onChange={onChange}
          maxLength={64}
          error={errors.customerNo}
        />
        <FormGroupWErr
          str="languageID"
          label="language"
          type="select"
          err={errors.languageID}
          options={languages.map((lang) => ({value: lang.id, name: this.trans(`languages.${lang.id}`)}))}
          onChange={onChange}
          val={data.languageID}
        />
        <FormGroupWErr
          str="customerTypeID"
          label="customerType"
          type="select"
          err={errors.customerTypeID}
          options={customerTypes.map((ct) => ({value: ct.id, name: this.trans(`customerTypes.${ct.id}`)}))}
          onChange={onChange}
          val={data.customerTypeID}
        />
      </>
    );
  };

  editCustomerOnSubmit = (data) => {
    return new Promise((resolve, reject) => {
      this.cancelable(putRequest(`/api/customers/${this.props.customerID}`, data)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  editCustomerOnOpen = () => {
    return new Promise((resolve, reject) => {
      const {customerTypes, languages} = this.state;
      const promises = [];

      if (customerTypes.length <= 0) {
        promises.push(this.cancelable(apiFetchCustomerTypes((resp) => {
          this.setStateValue("customerTypes", resp);
        })));
      }

      if (languages.length <= 0) {
        promises.push(this.cancelable(apiFetchLanguages((resp) => {
          this.setStateValue("languages", resp);
        })));
      }

      Promise.all(promises).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  };

  render() {
    return (
      <ModalWindow
        headerText={this.trans("editCustomer")}
        caller={this.editCustomerCaller}
        body={this.editCustomerBody}
        onSubmit={this.editCustomerOnSubmit}
        onOpen={this.editCustomerOnOpen}
      />
    );
  }

}

export default withTranslation()(EditCustomerInfoModal);
