import React from "react";
import CoreComponent from "../core_component";
import {apiGetCreatedCardsStatsMonthly, apiGetTransactionsStatsMonthly} from "../../api/fetch";
import {withAlert} from "../helper/alert";
import moment from "moment";
import {centsToEurosToStr, timestampFmt} from "../helper/utils";
import {PageTitle} from "../helper/page_title";
import {withTranslation} from "react-i18next";
import SelectWrap from "../helper/select_wrap";
import LoadingPage from "./loading_page";
import {CTable} from "../helper/ctable";

const STATS_CONCLUSIVE = 0;
const STATS_GROUP_BY_CARD_PRODUCT = 1;

class _StatsPage extends CoreComponent {
  state = {
    transStats: [],
    createdCardsStats: [],
    groupBy: STATS_CONCLUSIVE,
    currentTime: moment(),
    loading: false,
  };

  componentDidMount() {
    this.getStats();
  }

  getStats = () => {
    this.setState({loading: true}, () => {
      const reqData = {
        "startMonth": this.state.currentTime.startOf("year").format(timestampFmt),
        "endMonth": this.state.currentTime.endOf("year").format(timestampFmt),
        "groupBy": this.state.groupBy,
      };

      const transProm = this.cancelable(apiGetTransactionsStatsMonthly(reqData, (respData) => {
        this.setState({transStats: respData});
      }));

      const cardsProm = this.cancelable(apiGetCreatedCardsStatsMonthly(reqData, (respData) => {
        this.setState({createdCardsStats: respData});
      }));

      Promise.all([transProm, cardsProm]).catch((err) => {
        if (err.cancelled) {
          return;
        }

        this.props.setErrors(err.errors);
        this.setState({transStats: [], createdCardsStats: [], loading: false});
      }).then(() => {
        this.setState({loading: false});
      });
    });
  };

  renderTable(elemsArray, columnRenderer) {
    let mappedStats = {};
    let hasCardProduct = false;
    let titleCols = [];

    elemsArray.forEach((v, i) => {
      const cardProductName = v.cardProductName || null;
      const monthFormatted = this.trans(v.month ? moment(v.month, timestampFmt).format("MMMM").toLowerCase() : "total");

      if (cardProductName && i === 0) {
        hasCardProduct = true;
        titleCols.push(this.trans("cardProduct"));
      }

      if (titleCols.indexOf(monthFormatted) === -1) {
        titleCols.push(monthFormatted);
      }

      if (Object.prototype.hasOwnProperty.call(mappedStats, cardProductName)) {
        mappedStats[cardProductName].push(v);
      } else {
        mappedStats[cardProductName] = [v];
      }
    });

    return (
      <CTable className="m-0">
        <thead>
          <tr>
            {titleCols.map((v, i) => {
              return (
                <th className={hasCardProduct && i === 0 ? "" : "text-right"} key={`trans-stats-title-${v}-${i}`}>{v}</th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {Object.keys(mappedStats).map((cardProductName, i) => {
            return (
              <tr key={`trans-stats-${cardProductName || "conclusive"}-${i}`}>
                {mappedStats[cardProductName].map((v, j) => {
                  return (
                    <React.Fragment key={`trans-month-${v.month}-${j}`}>
                      {hasCardProduct && j === 0 && (
                        <td>
                          {cardProductName}
                        </td>
                      )}
                      <td className="text-right">
                        {columnRenderer(v)}
                      </td>
                    </React.Fragment>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </CTable>
    );
  }

  render() {
    const years = [];

    for (let year = 2019 /* KKIS läks live'sse */; year <= moment().year(); year++) {
      years.push(year);
    }

    return (
      <div className="my-2 p-2">
        {this.props.renderAlert()}

        {this.state.loading && (
          <div className="overlay m-0">
            <LoadingPage/>
          </div>
        )}

        <div className="mb-2">
          <PageTitle title={this.props.title} isGrouped={true}/>
        </div>

        <div className="d-flex flex-wrap justify-content-end sec-panel mb-3">
          <div className="col-md-5 col-xl-3 p-0 m-2">
            <SelectWrap
              isClearable={false}
              onChange={(ev) => {
                if (this.defined(ev.target.value)) {
                  this.setState({groupBy: ev.target.value}, this.getStats);
                }
              }}
              options={[
                {value: STATS_CONCLUSIVE, name: this.trans("conclusive")},
                {value: STATS_GROUP_BY_CARD_PRODUCT, name: this.trans("groupedByCardProduct")},
              ]}
              val={this.state.groupBy}
            />
          </div>
          <div className="col-md-4 col-xl-2 p-0 m-2">
            <SelectWrap
              isClearable={false}
              onChange={(ev) => {
                if (this.defined(ev.target.value)) {
                  this.setState({currentTime: this.state.currentTime.set("year", ev.target.value)}, this.getStats);
                }
              }}
              options={years.map((year) => {
                return {value: year, name: year};
              })}
              val={this.state.currentTime.year()}
            />
          </div>
        </div>

        {this.state.transStats.length > 0 && (
          <>
            <h5>{this.trans("transactionSum")}</h5>
            <div className="overflow-auto mb-3">
              {this.renderTable(this.state.transStats, (v) => centsToEurosToStr(v.amount))}
            </div>

            <h5>{this.trans("transactionCount")}</h5>
            <div className="overflow-auto mb-3">
              {this.renderTable(this.state.transStats, (v) => v.count)}
            </div>
          </>
        )}

        {this.state.createdCardsStats.length > 0 && (
          <>
            <h5>{this.trans("noOfCardsCreated")}</h5>
            <div className="overflow-auto mb-3">
              {this.renderTable(this.state.createdCardsStats, (v) => v.count)}
            </div>
          </>
        )}
      </div>
    );
  }
}

export const StatsPage = withAlert(withTranslation()(_StatsPage));
