import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {TabPaneOnOpen} from "../../helper/nav_tabs";
import Paginator from "../../helper/paginator";
import {mapChangedColumns} from "../../helper/utils";
import {CTable} from "../../helper/ctable";

class CustomerUnitChangesContainer extends CoreComponent {
  render() {
    const {changes, fetchUnitChanges} = this.props;
    return (
      <>
        <TabPaneOnOpen tabId="changes">
          <Paginator
            next={fetchUnitChanges}
            pageLimit={10}
          >
            <CTable>
              <thead>
                <tr>
                  <th>{this.trans("name")}</th>
                  <th>{this.trans("comment")}</th>
                  <th>{this.trans("author")}</th>
                  <th>{this.trans("timeOfChange")}</th>
                </tr>
              </thead>
              <tbody>
                {mapChangedColumns(changes, (val, i, renderCol) => {
                  return (
                    <tr key={`customer-unit-changes-${i}`}>
                      <td>{renderCol("name")}</td>
                      <td>{renderCol("comment", {defaultValue: "-"})}</td>
                      <td>{val.email}</td>
                      <td>{val.time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </CTable>
          </Paginator>
        </TabPaneOnOpen>
      </>
    );
  }
}

export default withTranslation()(CustomerUnitChangesContainer);
