import React, {useEffect, useRef, useState} from "react";
import {ic_error} from "react-icons-kit/md/ic_error";
import {ic_check_circle} from "react-icons-kit/md/ic_check_circle";
import {ic_warning} from "react-icons-kit/md/ic_warning";
import {Alert} from "reactstrap";
import Icon from "react-icons-kit";
import {withTranslation} from "react-i18next";

const ALERT_ERROR = "ERROR";
const ALERT_WARNING = "WARNING";
const ALERT_INFO = "INFO";

const ALERT_ICONS = {
  [ALERT_ERROR]: ic_error,
  [ALERT_WARNING]: ic_warning,
  [ALERT_INFO]: ic_check_circle,
};

const ALERT_COLORS = {
  [ALERT_ERROR]: "danger",
  [ALERT_WARNING]: "warning",
  [ALERT_INFO]: "success",
};

const I18N_IDENTIFIER = {
  [ALERT_ERROR]: "errors",
  [ALERT_WARNING]: "notifications",
  [ALERT_INFO]: "notifications",
};

export const withAlert = (WrappedComponent) => {
  const _hoc = (props) => {
    const [errs, setErrs] = useState({});
    const [alert, setAlert] = useState(null);

    const timeout = useRef(0);
    const showAlertForMS = 6000;

    useEffect(() => {
      return () => {
        window.clearTimeout(timeout.current);
      };
    }, []);

    const setAlertValue = (v, type) => {
      if (v) {
        window.clearTimeout(timeout.current);
        setAlert({value: v, type: type});
      }
    };

    const clearAlertValue = (clearErrors) => {
      window.clearTimeout(timeout.current);
      setAlert(null);

      if (clearErrors) {
        setErrs({});
      }
    };

    const clearAlertAfterTimeout = () => {
      timeout.current = window.setTimeout(() => setAlert(null), showAlertForMS);
    };

    const setErrors = (_errs) => {
      if (!_errs || (Object.prototype.hasOwnProperty.call(_errs, "cancelled") && _errs.cancelled)) {
        window.clearTimeout(timeout.current);
        return;
      }

      setErrs(_errs);

      if (Object.prototype.hasOwnProperty.call(_errs, "error")) {
        setErrorMsg(_errs.error);
      }
    };

    const setErrorMsg = (v) => {
      setAlertValue(v, ALERT_ERROR);
    };

    const setInfoMsg = (v) => {
      setAlertValue(v, ALERT_INFO);
      clearAlertAfterTimeout();
    };

    const setWarningMsg = (v) => {
      setAlertValue(v, ALERT_WARNING);
    };

    // eslint-disable-next-line no-unused-vars
    const {t: trans, i18n, tReady, ...restProps} = props;

    const renderSubErrorsIfAny = () => {
      const {subErrors} = errs;

      if (subErrors) {
        return (
          <ul className="mt-2" style={{fontSize: "0.9rem"}}>
            {Object.keys(subErrors).map((subError) => {
              let val = subErrors[subError];

              if (Array.isArray(val)) {
                val = val.join(", ");
              }

              return val ? <li>{trans(subError)}: <strong>{val}</strong></li> : null;
            })}
          </ul>
        );
      }

      return null;
    };

    const renderAlert = () => {
      if (!alert) {
        return null;
      }

      return (
        <Alert color={ALERT_COLORS[alert.type]} toggle={() => clearAlertValue(false)}>
          <div className="d-flex align-items-center">
            {Object.prototype.hasOwnProperty.call(ALERT_ICONS, alert.type) && (
              <Icon icon={ALERT_ICONS[alert.type]} size={18} className="d-inline-flex mr-1"/>
            )}
            <span style={{lineHeight: 1}}>
              {trans(
                Object.prototype.hasOwnProperty.call(I18N_IDENTIFIER, alert.type) ? `${I18N_IDENTIFIER[alert.type]}.${alert.value}` : alert.value,
                {defaultValue: trans(alert.value)},
              )}
            </span>
          </div>
          {alert.type === ALERT_ERROR && renderSubErrorsIfAny()}
        </Alert>
      );
    };

    return (
      <WrappedComponent
        errors={errs}
        setErrors={setErrors}
        setErrorMsg={setErrorMsg}
        setInfoMsg={setInfoMsg}
        setWarningMsg={setWarningMsg}
        clearAlert={() => clearAlertValue(true)}
        renderAlert={renderAlert}
        {...restProps}
      />
    );
  };

  return withTranslation()(_hoc);
};
