import React from "react";
import {Nav, NavItem, NavLink, TabPane} from "reactstrap";
import {withTranslation} from "react-i18next";
import CoreComponent from "../core_component";

// TabPaneOnOpen defines a small wrapper of TabPane
// that does not render TabPane before the tab has been opened
export class TabPaneOnOpen extends React.Component {
  renderInner = (attr) => {
    if (attr.className && attr.className.includes("active")) {
      return this.props.children;
    }

    return null;
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const {children, ...attr} = this.props;

    return (
      <TabPane {...attr} tag={this.renderInner}/>
    );
  }
}

class NavTabs extends CoreComponent {
  componentDidMount() {
    const {tabs, activeTab, toggleTab} = this.props;

    // Kui ei leia, siis määrame esimese tab'i aktiivseks
    if (tabs.length > 0 && tabs.map((v) => v.id).indexOf(activeTab) === -1) {
      toggleTab(tabs[0].id);
    }
  }

  render() {
    const {tabs, activeTab, toggleTab} = this.props;

    return (
      <Nav tabs>
        {tabs.map((tab, i) => (
          <NavItem key={`tab-${tab.title}-${i}`} className="tab-link">
            <NavLink active={activeTab === tab.id} onClick={() => toggleTab(tab.id)}>
              {this.trans(tab.title)}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    );
  }
}

export default withTranslation()(NavTabs);
