import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import InfiniteScroller from "../../helper/infinite_scroller";
import {apiFetchCardProducts, apiFetchCardProductsAdmin} from "../../../api/fetch";
import {PageTitle} from "../../helper/page_title";
import NewCardProductModal from "../../modals/company/new_cardproduct_modal";
import UploadFileModal from "../../modals/file/upload_file_modal";
import {CAN_GENERATE_CARDS} from "../../helper/role_permissions";
import {yesNo} from "../../helper/utils";
import {CTable} from "../../helper/ctable";

class CardProductsOverview extends CoreComponent {
  state = {
    cardProducts: [],
  };

  fetchCardProducts = () => {
    const {user} = this.props;

    if (user.isAdmin() || user.isDistributor()) {
      return this.cancelable(apiFetchCardProductsAdmin(this.props.companyID, (data) => {
        this.setState({
          cardProducts: this.state.cardProducts.concat(data),
        });
      }, (err) => {
        this.props.setErrors(err.errors);
      }));
    } else {
      return this.cancelable(apiFetchCardProducts((data) => {
        this.setState({
          cardProducts: this.state.cardProducts.concat(data),
        });
      }, (err) => {
        this.props.setErrors(err.errors);
      }));
    }
  };

  render() {
    const {user, companyID, setErrors} = this.props;
    return (
      <>
        {(user.isAdmin() || user.isDistributor()) && (
          <div className="my-3 d-flex flex-row justify-content-start">
            <PageTitle isGrouped={true} title={this.trans("cardProducts")}/>
            <NewCardProductModal
              companyID={companyID}
            />
          </div>
        )}
        <InfiniteScroller
          id={"card-products-table"}
          next={this.fetchCardProducts}
          additionalComponent={() => {
            if (user.hasPermission(CAN_GENERATE_CARDS) && user.isImportCards()) {
              return (
                <UploadFileModal
                  allowedExtensions={["csv"]}
                  setErrors={setErrors}
                  url={"/api/customers/import-cards"}
                  btnName={this.trans("importCardCSV")}
                />
              );
            }

            return "";
          }}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("name")}</th>
                <th>{this.trans("noOfActiveCards")}</th>
                <th>{this.trans("issuerIDNo")}</th>
                <th colSpan="3" className="text-nowrap">{this.trans("accountIDNoRange")}</th>
                <th className="text-right">{this.trans("canBeUsedForWebActivities")}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.cardProducts.map((cardProd, i) => (
                <tr key={`card-product-${i}`}>
                  <td>{cardProd.name}</td>
                  <td>{cardProd.noOfActiveCards}</td>
                  <td>{cardProd.issuerIDNo}</td>
                  <td className="min-width">{cardProd.minAccountIDNo}</td>
                  <td className="text-center">-</td>
                  <td className="min-width">{cardProd.maxAccountIDNo}</td>
                  <td className="text-right">{yesNo(cardProd.webEnabled)}</td>
                </tr>
              ))}
            </tbody>
          </CTable>
        </InfiniteScroller>
      </>);
  }
}

export default withTranslation()(CardProductsOverview);
