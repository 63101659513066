import React from "react";
import {Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import CoreForm from "./core_form";
import {withAlert} from "./alert";
import LoadingPage from "../pages/loading_page";
import {withRouter} from "react-router-dom";

//closing modal from X doesn't refresh page, only successful submit does

class ModalWindow extends CoreComponent {
  state = {
    isOpen: false,
    data: {},
    saved: false,
    confirmed: false,
    isModalReady: false,
  };

  onChange = (ev, callBack) => {
    let outVal = ev.target.value;

    if (ev.target.type === "checkbox") {
      outVal = ev.target.checked;
    } else if (ev.target.type === "number") {
      outVal = parseFloat(outVal);
    }

    this.setStateValue("data", Object.assign({}, this.state.data, {
      [ev.target.name]: outVal
    }), callBack ? () => callBack(this.state.data) : null);
  };

  postOpen = () => {
    if (!this.defined(this.props.onOpen)) {
      this.setStateValue("isModalReady", true);
      return;
    }

    this.props.onOpen().then((respData) => {
      let modalData = this.state.data;
      if (this.defined(respData)) {
        modalData = Object.assign({}, this.state.data, respData);
      }

      this.setState(Object.assign({}, this.state, {
        "data": modalData,
        "isModalReady": true,
      }));
    }).catch((error) => {
      if (error.cancelled) {
        return;
      }

      this.props.setErrors(error.errors);
      this.setStateValue("isModalReady", true);
    });
  };

  toggleOpen = (ev, initData = {}) => {
    this.setState({
      isOpen: true,
      data: Object.assign({}, initData),
      saved: false,
      confirmed: false,
      isModalReady: false,
    }, this.postOpen);
  };

  toggleClose = () => {
    this.setStateValue("isOpen", false);
  };

  onSubmit = () => {
    if (this.props.forbidSubmit) {
      return Promise.reject("form submission is forbidden");
    }

    return new Promise((resolve, reject) => {
      if (this.props.doubleConfirm && !this.state.saved) {
        this.props.clearAlert();
        this.setState(Object.assign({}, this.state, {
          saved: true,
        }));

        resolve();
        return;
      }

      this.props.onSubmit(this.state.data).then(() => {
        if (this._isMounted) {
          this.navReload("saved");
        }
        reject(this.cancelled());
      }).catch((error) => {
        if (error.cancelled) {
          reject(error);
          return;
        }

        this.props.setErrors(error.errors);
        this.setState(Object.assign({}, this.state, {
          saved: false,
          confirmed: false
        }), () => {
          if (this.props.clearOnError) {
            this.setStateValue("data", {});
          }
        });

        reject(error);
      });
    });
  };

  renderButtons = () => {
    const {saved, confirmed} = this.state;
    const {doubleConfirm, forbidSubmit} = this.props;

    return (
      <>
        {(doubleConfirm && saved && !confirmed) &&
        <Button onClick={() => this.setStateValue("saved", false)} className="ml-2 btn-edit sm-btn">
          {this.trans("edit")}
        </Button>
        }
        <Button disabled={forbidSubmit} className="ml-2 sm-btn">
          {this.trans(saved ? "confirm" : "save")}
        </Button>
      </>
    );
  };

  renderModalBody = () => {
    const {data, saved, isModalReady} = this.state;
    const {body, errors, renderAlert} = this.props;

    if (!isModalReady) {
      return (
        <div style={{"height": "300px"}}>
          <LoadingPage/>
        </div>
      );
    }

    return (
      <>
        {renderAlert()}
        <CoreForm onSubmit={this.onSubmit}>
          {body(data, errors, this.onChange, saved)}
          <FormGroup className="d-flex flex-row justify-content-end">
            {this.renderButtons()}
          </FormGroup>
        </CoreForm>
      </>
    );
  };

  render() {
    const {caller, headerText} = this.props;

    return (
      <>
        {caller(this.toggleOpen)}
        <Modal isOpen={this.state.isOpen} className={this.props.className} centered={true} toggle={this.toggleClose} backdrop={"static"}>
          <ModalHeader toggle={this.toggleClose}>{headerText}</ModalHeader>
          <ModalBody>
            {this.renderModalBody()}
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default withRouter(withAlert(withTranslation()(ModalWindow)));

// ConfirmationModal defines modal for event confirmation
export const ConfirmationModal = withTranslation()(class extends CoreComponent {
  state = {
    isOpen: false,
  };

  open = () => {
    this.setState({isOpen: true});
  };

  close = () => {
    this.setState({isOpen: false});
  };

  onSubmit = () => {
    // TODO onSubmit should ALWAYS be a promise -> this.props.onSubmit().finally(() => this.close());
    this.props.onSubmit();
    this.close();
  };

  render() {
    return (
      <>
        {this.props.opener(this.open)}
        <Modal isOpen={this.state.isOpen} toggle={this.close} centered={true}>
          <ModalHeader toggle={this.close}>
            <span className="font-weight-bold">{this.props.title}</span>
          </ModalHeader>
          {this.props.subtitle && (
            <ModalBody>
              <h5>{this.props.subtitle}</h5>
            </ModalBody>
          )}
          <ModalFooter>
            <div className="d-flex justify-content-end">
              <Button onClick={this.onSubmit} className="btn sm-btn">
                {this.trans("confirm")}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
});
