import React from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {getRequest} from "../../api/request";
import {PageTitle} from "../helper/page_title";
import LoadingPage from "./loading_page";
import {withAlert} from "../helper/alert";
import CoreComponent from "../core_component";
import TerminalOwnerInfoContainer from "../containers/terminal/terminal_owner_info_container";
import TerminalContainer from "../containers/terminal/terminal_container";
import NavTabs, {TabPaneOnOpen} from "../helper/nav_tabs";
import {TabContent} from "reactstrap";
import {TerminalOwnerTabConsumer} from "../helper/context";
import TerminalOwnerChangesContainer from "../containers/terminal/terminal_owner_changes_container";
import CardTransactionsContainer from "../containers/card/card_transactions_container";
import {apiFetchTerminalOwnerTransactions} from "../../api/fetch";
import {CAN_EDIT_TRANSACTIONS} from "../helper/role_permissions";

class TerminalOwnerOverviewPage extends CoreComponent {
  state = {
    terminalOwner: {},
  };

  terminalOwnerID = this.props.match.params.id;

  tabs = {
    TERMINALS: "terminals",
    CHANGES: "changes",
    TRANSACTIONS: "transactions",
  };

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);
    this.fetchInitial(this.fetchTerminalOwner());
  }

  fetchTerminalOwner = () => {
    return new Promise((resolve, reject) => {
      this.cancelable(getRequest(`/api/terminal-owners/${this.terminalOwnerID}`)).then((resp) => {
        this.setStateValue("terminalOwner", resp.data);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  render() {
    const {terminalOwner} = this.state;
    const {setErrors, user} = this.props;

    if (!this.state.isPageReady) {
      return <LoadingPage/>;
    }

    const navTabs = [
      {id: this.tabs.TERMINALS, title: "terminals"},
      {id: this.tabs.CHANGES, title: "changes"},
    ];

    if (user.hasPermission(CAN_EDIT_TRANSACTIONS)) {
      navTabs.push({id: this.tabs.TRANSACTIONS, title: "transactions"});
    }

    return (
      <div>
        {this.props.renderAlert()}
        <PageTitle title={this.props.title}/>
        <TerminalOwnerInfoContainer
          terminalOwnerID={this.terminalOwnerID}
          terminalOwner={terminalOwner}
          setErrors={setErrors}
          user={user}
        />
        <TerminalOwnerTabConsumer>
          {({activeTab, toggleTab}) => (
            <div className={"mt-2"}>
              <NavTabs
                tabs={navTabs}
                activeTab={activeTab}
                toggleTab={toggleTab}
              />
              <TabContent activeTab={activeTab}>
                <TabPaneOnOpen tabId={this.tabs.TERMINALS}>
                  <TerminalContainer
                    terminalOwnerID={this.terminalOwnerID}
                    setErrors={setErrors}
                    user={user}
                  />
                </TabPaneOnOpen>
                <TabPaneOnOpen tabId={this.tabs.CHANGES}>
                  <TerminalOwnerChangesContainer
                    terminalOwnerID={this.terminalOwnerID}
                    setErrors={setErrors}
                  />
                </TabPaneOnOpen>
                {user.hasPermission(CAN_EDIT_TRANSACTIONS) && (
                  <TabPaneOnOpen tabId={this.tabs.TRANSACTIONS}>
                    <CardTransactionsContainer
                      setErrors={setErrors}
                      canAddAndEditTrans={false}
                      fetchFunc={(...args) => apiFetchTerminalOwnerTransactions(this.terminalOwnerID, ...args)}
                    />
                  </TabPaneOnOpen>
                )}
              </TabContent>
            </div>
          )}
        </TerminalOwnerTabConsumer>
      </div>
    );
  }
}

export default withAlert(withRouter(withTranslation()(TerminalOwnerOverviewPage)));
