import React from "react";
import {Col, Row} from "reactstrap";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Tooltip from "./tooltip";

class OverviewRow extends CoreComponent {
  render() {
    //roles ehk lubatud kasutaja rollid. Vaikimisi lubab kõiki
    //rolesi defineerides tuleb passida sisse ka user prop
    const {hidden, nWidth, vWidth, name, link, disableLink, value, user, roles,
      tooltip, // internally translated
    } = this.props;

    let doLink = false;

    if (link && !disableLink) {
      if (roles !== undefined) {
        doLink = user.isRole(roles);
      } else {
        doLink = true;
      }
    }

    if (hidden) {
      return null;
    } else {
      return (
        <Row className="overview-row">
          <Col xs={nWidth || 5} className="overview-row-title">
            {this.trans(name)}:
          </Col>
          <Col xs={vWidth || 7} className="overview-row-value">
            <Tooltip id={`link-tooltip-${tooltip}`} trans={tooltip} showif={tooltip !== undefined}>
              {doLink ? <Link to={link}>{value}</Link> : value}
            </Tooltip>
          </Col>
        </Row>
      );
    }
  }
}

export default withTranslation()(OverviewRow);
