import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import {withAlert} from "./alert";
import {apiSendErrors} from "../../api/fetch";
import retrace from "retrace";


class ErrorBoundary extends CoreComponent {
  state = {
    isError: false
  };

  static parseErrorMessage(stacktrace, frameCount) {
    const errorMsg = stacktrace.split("\n")[0];
    let matchedComponents = [];
    let match;
    const regex = RegExp("at.*[\\s(]([^\\s]+(:\\d+)+)\\)?(\\n|$)", "g");

    while ((match = regex.exec(stacktrace)) !== null) {
      matchedComponents.push(match[1]);
    }

    const errorModule = matchedComponents.slice(0,frameCount).join(" < ");

    return {errorModule, errorMsg};
  }

  reportError(stacktrace) {
    retrace
      .map(stacktrace)
      .then((mappedStackTrace) => {

        const err = ErrorBoundary.parseErrorMessage(mappedStackTrace,2);

        apiSendErrors(err.errorModule, err.errorMsg,()=>{
        },() => {
        });
      });

  }

  componentDidCatch(error) {
    if (this._isMounted) {
      this.props.setErrors({
        error: "ERROR_SOMETHING_WRONG",
      });

      this.setStateValue("isError", true);
      this.reportError(error.stack);
    }
  }

  render() {
    if (this.state.isError) {
      return (
        <div>
          {this.props.renderAlert()}
        </div>
      );
    }

    return this.props.children;
  }
}

export default withAlert(withTranslation()(ErrorBoundary));
