import React from "react";
import {Col} from "reactstrap";
import {withTranslation} from "react-i18next";
import FormGroupWErr from "../helper/form_group_w_err";
import FiltersContainer from "../helper/filters_container";
import CoreComponent from "../core_component";
import {cardState} from "../helper/utils";
import FormGroupCheckbox from "../helper/form_group_checkbox";
import Icon from "react-icons-kit";
import {ic_info_outline} from "react-icons-kit/md";
import Tooltip from "../helper/tooltip";

class CardsFilters extends CoreComponent {
  renderFilters = (filtersData, onChange, onChangeInt) => {
    return (
      <>
        <Col md={4} xl={3}>
          <FormGroupWErr
            str={"pan"} val={filtersData.pan}
            onChange={onChange} required={false}
          />
        </Col>
        <Col md={4} xl={3}>
          <FormGroupWErr
            str={"customerName"} val={filtersData.customerName}
            onChange={onChange} required={false}
          />
        </Col>
        <Col md={4} xl={3}>
          <FormGroupWErr
            str={"customerUnitName"} label={"customerUnit"} val={filtersData.customerUnitName}
            onChange={onChange} required={false}
          />
        </Col>
        <Col md={4} xl={3}>
          <FormGroupWErr
            str={"customerNo"} val={filtersData.customerNo}
            onChange={onChange} required={false}
          />
        </Col>
        <Col md={4} xl={3}>
          <FormGroupWErr
            str={"cardProductID"} val={filtersData.cardProductID}
            onChange={onChangeInt} required={false}
            label={"cardProductName"} type={"select"}
            options={this.props.cardProducts.map((cprod) => ({value: cprod.id, name: cprod.name}))}
          />
        </Col>
        <Col md={4} xl={3}>
          <FormGroupWErr
            str="cardState"
            type="select"
            options={[{value: cardState.name.active, name: this.trans("cardStateTypes.active")},
              {value: cardState.name.expired, name: this.trans("cardStateTypes.expired")},
              {value: cardState.name.closed, name: this.trans("cardStateTypes.closed")},
              {value: cardState.name.future, name: this.trans("cardStateTypes.future")},
              {value: cardState.name.all, name: this.trans("cardStateTypes.all")},
            ]}
            onChange={onChangeInt}
            val={filtersData.cardState}
            required={false}
          />
        </Col>
        <Col xl={3}>
          <div className="d-flex">
            <div className="mr-3">
              <FormGroupCheckbox
                str={"expiringSoon"}
                label={
                  <>
                    <span className="pr-1">{this.trans("onlyExpiringSoonCards")}</span>
                    <Tooltip trans={"expiringSoonCardsHelpMsg"} showif={true}>
                      <Icon icon={ic_info_outline} size={16}/>
                    </Tooltip>
                  </>
                }
                onChange={onChange}
                val={filtersData.expiringSoon || false}
                vertical={true}
                required={false}
              />
            </div>
            <div className="mr-3">
              <FormGroupCheckbox
                str={"onlyExtendable"}
                label={"onlyExtendableCards"}
                onChange={onChange}
                val={filtersData.onlyExtendable || false}
                vertical={true}
                required={false}
              />
            </div>
          </div>
        </Col>
      </>
    );
  };

  render() {
    return (
      <FiltersContainer
        renderFilters={this.renderFilters}
        onFilter={this.props.onFilter}
        initData={this.props.initData}
        extraButtons={this.props.extraButtons}
      />
    );
  }
}

export default withTranslation()(CardsFilters);
