import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";
import {NavLink} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import {ic_mode_edit} from "react-icons-kit/md";
import Icon from "react-icons-kit";
import {withRouter} from "react-router-dom";
import {apiEditCompanyWebApi} from "../../../api/fetch";

class EditWebApiModal extends CoreComponent {

  editWebApiCaller = (toggleOpen, data) => {
    return (
      <NavLink className="p-0 d-inline-block" href="#" onClick={(ev) => toggleOpen(ev, data)}>
        <Icon icon={ic_mode_edit}/>
      </NavLink>
    );
  };
  editWebApi = (data, errors, onChange) => {
    return (
      <>
        <FormGroupWErr str="name" maxLength={128} err={errors.name} val={data.name} onChange={onChange}/>
      </>
    );
  };

  editWebApiOnSubmit = (data) => {
    const postData = {};
    postData.name = data.name;
    postData.webApiId = this.props.webApiId;

    return this.cancelable(apiEditCompanyWebApi(postData));
  };


  render() {
    return (
      <>
        <ModalWindow
          headerText={this.trans("editWebApi")}
          caller={(toggleOpen) => this.editWebApiCaller(toggleOpen, this.props)}
          body={this.editWebApi}
          onSubmit={this.editWebApiOnSubmit}
        />
      </>
    );
  }
}

export default withRouter(withTranslation()(EditWebApiModal));
