import React from "react";

class CoreContainer extends React.Component {
  render() {
    if (this.props.loading) {
      return (
        <div className="core-container">
          <div className="container-loader"/>
        </div>
      );
    }

    return (
      <div className="core-container">
        {this.props.children}
      </div>
    );
  }
}

export default CoreContainer;
