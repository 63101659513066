import React from "react";
import CoreComponent from "../../core_component";
import {apiAddCompanyRole, apiDeleteCompanyRole, apiEditCompanyRole, apiFetchCompanyRole, apiFetchCompanyRoles} from "../../../api/fetch";
import {withAlert} from "../../helper/alert";
import {Button, FormGroup, Input} from "reactstrap";
import {withTranslation} from "react-i18next";
import CollapsibleTableRow from "../../helper/collapsible_table_row";
import Tooltip from "../../helper/tooltip";
import Icon from "react-icons-kit";
import {ic_add_circle, ic_remove_circle} from "react-icons-kit/md";
import {PERMISSION_REQUIREMENT_MAP, PERMISSIONS_MAP} from "../../helper/role_permissions";
import CoreForm from "../../helper/core_form";
import {withRouter} from "react-router-dom";
import {PageTitle} from "../../helper/page_title";
import ModalWindow, {ConfirmationModal} from "../../helper/modal_window";
import FormGroupWErr from "../../helper/form_group_w_err";
import {CTable} from "../../helper/ctable";


class _NewCompanyRoleModel extends CoreComponent {
  renderOpener = (toggleOpen) => {
    return (
      <Button className="ml-3 btn-add sm-btn btn btn-secondary" onClick={toggleOpen}>
        {this.trans("addNew")}
      </Button>
    );
  };

  renderBody = (data, errors, onChange) => {
    return (
      <>
        <FormGroupWErr
          maxLength={64} str="name"
          err={errors.name} val={data.name}
          onChange={onChange}
          required
        />
      </>
    );
  };

  onSubmit = (data) => {
    return this.cancelable(apiAddCompanyRole(data, () => {
      this.navReload("saved");
    }));
  };

  render() {
    return (
      <ModalWindow
        headerText={this.trans("addNew")}
        caller={this.renderOpener}
        body={this.renderBody}
        onSubmit={this.onSubmit}
      />
    );
  }
}


const NewCompanyRoleModel = withRouter(withTranslation()(_NewCompanyRoleModel));


class _CompanyRolePermissionsContainer extends CoreComponent {
  state = {
    permissions: [],
    permissionsInitial: [],
    name: null,
  };

  targetRef = React.createRef();

  fetchCompanyRole = (roleID) => {
    return new Promise((resolve, reject) => {
      apiFetchCompanyRole(roleID, (respData) => {
        this.setState({
          permissions: respData.permissions,
          permissionsInitial: respData.permissions,
        });
        resolve(respData);
      }, (err) => {
        reject(err);
      });
    });
  };

  editCompanyRole = () => {
    const {role} = this.props;
    const data = {
      name: this.state.name || role.name,
      permissions: this.state.permissions,
    };

    return this.cancelable(apiEditCompanyRole(role.id, data, () => {
      this.navReload("saved");
    }, (err) => {
      if (err.cancelled) {
        return;
      }

      this.props.setErrors(err.errors);
    }));
  };

  canEdit = () => {
    let ok = false;

    this.state.permissions.forEach((v) => {
      if (this.state.permissionsInitial.indexOf(v) === -1) {
        ok = true;
      }
    });

    if (!ok) {
      this.state.permissionsInitial.forEach((v) => {
        if (this.state.permissions.indexOf(v) === -1) {
          ok = true;
        }
      });
    }

    return ok || (this.state.name && this.state.name !== this.props.role.name);
  }

  onChangePermission = (ev, permID = null /* all if null */) => {
    const {permissions} = this.state;
    const perms = permID !== null ? permissions.filter((v) => v !== permID) : [];

    if (ev.target.checked) {
      if (permID !== null) {
        perms.push(permID);
      } else {
        perms.push(...Object.keys(PERMISSIONS_MAP).map((v) => parseInt(v)));
      }
    }

    // Õigus(ed), millest checkitud õigus sõltub
    if (ev.target.checked) {
      Object.keys(PERMISSION_REQUIREMENT_MAP).forEach((k) => {
        if (PERMISSION_REQUIREMENT_MAP[k] && PERMISSION_REQUIREMENT_MAP[k].indexOf(permID) !== -1) {
          if (perms.indexOf(parseInt(k)) === -1) {
            perms.push(parseInt(k));
          }
        }
      });
    }

    this.setState({permissions: perms});
  }

  deleteCompanyRole = (roleID) => {
    return this.cancelable(apiDeleteCompanyRole(roleID)).then(() => {
      this.navReload("deleted");
    }).catch((err) => {
      if (err.cancelled) {
        return;
      }

      this.props.setErrors(err.errors);
    });
  };

  renderCompanyRole = () => {
    const {permissions} = this.state;
    const {role} = this.props;

    return (
      <div className="m-3">
        <CoreForm onSubmit={this.editCompanyRole}>
          <FormGroupWErr
            maxLength={64} str="name"
            val={this.state.name !== null ? this.state.name : role.name}
            err={this.props.errors ? this.props.errors.name : null}
            onChange={(ev) => this.setState({name: ev.target.value})}
            required row
          />

          <FormGroup row>
            <CTable>
              <thead>
                <tr>
                  <th>{this.trans("permission")}</th>
                  <th>
                    <Input
                      type="checkbox"
                      className="position-relative"
                      checked={permissions.length === Object.keys(PERMISSIONS_MAP).length}
                      onChange={(ev) => this.onChangePermission(ev)}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(PERMISSIONS_MAP).map((k, i) => {
                  const permID = parseInt(k);
                  const isChecked = permissions.indexOf(permID) !== -1;

                  let isDisabled = false;
                  const requiredFor = PERMISSION_REQUIREMENT_MAP[k];

                  if (isChecked && requiredFor) {
                    requiredFor.forEach((v) => {
                      if (this.state.permissions.indexOf(v) !== -1) {
                        isDisabled = true;
                      }
                    });
                  }

                  return (
                    <tr key={`permission-${i}`}>
                      <td>{this.trans(`rolePermissions.${PERMISSIONS_MAP[k]}`)}</td>
                      <td>
                        <Input
                          type="checkbox"
                          className="position-relative"
                          checked={isChecked}
                          disabled={isDisabled}
                          onChange={(ev) => this.onChangePermission(ev, permID)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </CTable>
          </FormGroup>

          <FormGroup row className="justify-content-end">
            <Button className="btn-add sm-btn" disabled={!this.canEdit()}>
              {this.trans("edit")}
            </Button>
          </FormGroup>
        </CoreForm>
      </div>
    );
  };

  render() {
    const {role, setErrors} = this.props;

    return (
      <CollapsibleTableRow
        colSpan={3}
        displayData={this.renderCompanyRole}
        loadData={() => this.fetchCompanyRole(role.id)}
        setErrors={setErrors}
        targetRef={this.targetRef}
      >
        <td>{role.name}</td>
        <td>
          <div ref={this.targetRef} className="d-inline-block cursor-pointer">
            <Tooltip title={this.trans("showPermissions")} showif={true}>
              <Icon icon={ic_add_circle}/>
            </Tooltip>
          </div>
        </td>
        <td>
          <ConfirmationModal
            title={`${this.trans("delete")} ${role.name}`}
            onSubmit={() => this.deleteCompanyRole(role.id)}
            opener={(openModal) => {
              const className = ["d-inline-block"];

              if (role.canDelete) {
                className.push("cursor-pointer");
              }

              return (
                <div className={className.join(" ")}>
                  <Tooltip title={role.canDelete ? this.trans("delete") : this.trans("roleCanNotBeDeletedMsg")} showif={true}>
                    <Icon
                      onMouseUp={role.canDelete ? openModal : () => {}}
                      icon={ic_remove_circle}
                      className={role.canDelete ? "" : "semi-transparent"}
                      style={{color: "var(--col-danger)"}}
                    />
                  </Tooltip>
                </div>
              );
            }}
          />
        </td>
      </CollapsibleTableRow>
    );
  }
}


const CompanyRolePermissionsContainer = withRouter(withTranslation()(_CompanyRolePermissionsContainer));


class CompanyRolesContainer extends CoreComponent {
  state = {
    roles: [],
  };

  componentDidMount() {
    this.fetchInitial(this.fetchCompanyRoles());
  }

  fetchCompanyRoles = () => {
    return this.cancelable(apiFetchCompanyRoles((respData) => {
      this.setState({roles: respData});
    }, (err) => {
      if (err.cancelled) {
        return;
      }

      this.props.setErrors(err.errors);
    }));
  };

  render() {
    return (
      <div className="my-2">
        {this.props.renderAlert()}

        <div className="my-3 d-flex flex-row justify-content-start">
          <PageTitle title={this.trans("userRoles")} isGrouped={true}/>
          <NewCompanyRoleModel/>
        </div>

        <CTable>
          <thead>
            <tr>
              <th>{this.trans("role")}</th>
              <th colSpan={2}/>
            </tr>
          </thead>
          <tbody>
            {this.state.roles.map((role, i) => {
              return (
                <CompanyRolePermissionsContainer
                  key={`permission-${role.id}-${i}`}
                  role={role}
                  setErrors={this.props.setErrors}
                />
              );
            })}
          </tbody>
        </CTable>
      </div>
    );
  }
}


export default withAlert(withTranslation()(CompanyRolesContainer));
