import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import FormGroupWErr from "../../helper/form_group_w_err";
import {postRequest} from "../../../api/request";
import {Button} from "reactstrap";
import ModalWindow from "../../helper/modal_window";

class NewTerminalModal extends CoreComponent {

  newTerminalBody = (data, errors, onChange, setDisabled) => {
    return (
      <>
        <FormGroupWErr str="terminalID" err={errors.terminalID} val={data.terminalID} maxLength={16} onChange={onChange} disabled={setDisabled}/>
        <FormGroupWErr str="merchantID" err={errors.merchantID} val={data.merchantID} maxLength={16} onChange={onChange} disabled={setDisabled}/>
        <FormGroupWErr str="payDesk" err={errors.payDesk} val={data.payDesk} maxLength={16} onChange={onChange} disabled={setDisabled} required={false}/>
        <FormGroupWErr str="merchantRegNo" err={errors.merchantRegNo} val={data.merchantRegNo} maxLength={16} onChange={onChange} disabled={setDisabled} required={false}/>
      </>
    );
  };

  newTerminalOnSubmit = (data) => {
    return new Promise((resolve, reject) => {
      this.cancelable(postRequest(`/api/terminal-owners/${this.props.terminalOwnerID}/terminals`, data)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  newTerminalCaller = (toggleOpen) => {
    return(
      <Button className={"ml-3 btn-add sm-btn"} onClick={toggleOpen}>
        {this.trans("addNew")}
      </Button>
    );
  };

  render() {
    return (
      <>
        <ModalWindow
          headerText={this.trans("newTerminal")}
          body={this.newTerminalBody}
          caller={this.newTerminalCaller}
          onSubmit={(data) => this.newTerminalOnSubmit(data)}
        />
      </>
    );
  }

}

export default withTranslation()(NewTerminalModal);
