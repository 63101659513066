import React from "react";
import currency from "currency.js";
import moment from "moment";
import i18next from "i18next";

// Kui seda muuta tuleb ka Go pool muuta "paginationLimit" konstanti
export const PaginationLimit = 30;

// Veakoodid ebamäärastele vigadele. Lisada teatele ERROR_SOMETHING_WRONG_CODE_
export const errorCodes = Object.freeze(
  {"schemaValidationFail": "1"}
);

//see on nats halb kuna on pmt db kirje frontendis
export const customerType = Object.freeze({"Company": 1, "Private": 2});

// kaarti staatusi muutes tuleb muuta ka andmebaasi ja go poolt
export const cardState = Object.freeze({
  name: {"active": 0, "future": 1, "closed": 2, "expired": 3, "all": 4},
  value: {0: "active", 1: "future", 2: "closed", 3: "expired", 4: "all"}
});

// kliendi staatusi muutes tuleb muuta ka andmebaasi ja go poolt
export const customerState = Object.freeze({
  name: {"all": 0, "activeWithCards": 1, "activeWithoutCards": 2, "closed": 3},
  value: {0: "all", 1: "activeWithCards", 2: "activeWithoutCards", 3: "closed"}
});

// cardCreationType staatusi muutes tuleb muuta ka andmebaasi ja go poolt
export const cardCreationType = Object.freeze({
  name: {"generate": 1, "import": 2},
  value: {1: "generate", 2: "import"}
});

// operatorStatus staatusi muutes tuleb muuta ka andmebaasi ja go poolt
export const operatorStatus = Object.freeze({
  name: {"registering": 1, "active": 2},
  value: {1: "registering", 2: "active"}
});

// timestampFmt kasutatav aja formaat
export const timestampFmt = "DD.MM.YYYY HH:mm:ss";
export const dateFmt = "DD.MM.YYYY";
export const monthYearFormat = "MM/YY";

// 992px is the main (but not only) break point between desktop and mobile view
export const responsiveBreakpoint = 992;

// defineerib otsingu väljade miinimum tähiste arvu mil tehakse otsing
export const minSearchStrLength = 3;

// kui mitu kuud tagasi saab veel allahindlust muuta
export const discountEditMonthsBack = 2;

/**
 * @param {string, number} amount
 * @returns {number}
 */
export function eurosToCents(amount) {
  return currency(amount).multiply(100).value;
}

/**
 * @param {string, number} amount
 * @returns {number}
 */
export function centsToEuros(amount) {
  return currency(amount).divide(100).value;
}

/**
 * @param {string, number} amount
 * @param {string} separator
 * @returns {string}
 * Adds € sign to the end of returned string. Also checks if amount is defined
 */
export function centsToEurosToStr(amount, separator = ",") {
  if (typeof amount !== "undefined" && amount !== null) {
    return currency(amount, {precision: 2, separator: separator, symbol: "€", pattern: "#!"}).divide(100).format();
  } else {
    return "-";
  }
}

/**
 * @param {string} time
 * @returns {string}
 */
export function ignoreTimezoneAndToISOString(time) {
  const timezoneShift = new Date(new Date(time).getTime() - (new Date(time).getTimezoneOffset() * 60 * 1000));
  return timezoneShift.toISOString();
}

/**
 * @returns {object}
 * Returns a dateRange object with this month's start date and end date
 */
export function getCurrentMonthDateRange() {
  const firstDayFmt = moment().startOf("month").format(timestampFmt);
  const lastDayFmt = moment().endOf("month").format(timestampFmt);
  return {dateRange: {startDate: firstDayFmt, endDate: lastDayFmt}};
}

/**
 * @param {object} balances
 * @returns {boolean}
 */
export function isCardUsed(balances) {
  if (balances) {
    if (balances.amountBalance === 0 || balances.countBalance === 0) {
      return true;
    }
  }
  return false;
}

/**
 * Checks if data member is defined or not using a key.
 * @param {object} object
 * @param {string} key
 * @returns {boolean}
 */
export function isObjDataMemberDefined(object, key) {
  if (object !== undefined) {
    return object[key] !== undefined;
  }
  return false;
}

/**
 * Standard way of implementing type object on react side for select options, avoids making extra server-side requests
 * @param {object} typeObject
 * @param {string} transRoot
 * @param {function} transFunc
 * @returns {object}
 */
export function getTypesOptions(typeObject, transRoot, transFunc) {
  const dict = [];
  for (const [key, value] of Object.entries(typeObject.value)) {
    dict.push({value: parseInt(key), name: transFunc(`${transRoot}.${value}`)});
  }

  return dict;
}

export function yesNo(val) {
  return val ? i18next.t("yes") : i18next.t("no");
}

export const mapChangedColumns = function (arr = [], callback) {
  return arr.map((row, i) => {
    const renderInnerValue = (col, {formatter, defaultValue = null} = {}) => {
      let retVal = row[col];

      if (defaultValue !== null && (typeof retVal === "undefined" || retVal === null || retVal === "")) {
        return defaultValue;
      }

      return formatter ? formatter(retVal) : retVal;
    };

    let columnRenderers = Object.assign({}, row);

    // Set'ime igale column'ile default renderdaja
    Object.keys(columnRenderers).forEach((col) => {
      columnRenderers[col] = (args) => renderInnerValue(col, args);
    });

    if (arr.length > i + 1) {
      const nextRow = arr[i + 1];
      const scannableCols = Object.keys(row).concat(Object.keys(nextRow)).filter((v, i, self) => self.indexOf(v) === i);

      scannableCols.forEach((col) => {
        let hasChanged = !Object.keys(nextRow).includes(col);

        if (!hasChanged) {
          Object.keys(nextRow).forEach((nextCol) => {
            if (col === nextCol && row[col] !== nextRow[nextCol]) { // Sama column, aga erinev väärtus
              hasChanged = true;
            }
          });
        }

        if (hasChanged) {
          columnRenderers[col] = (args) => (
            <div className="changed-col" title={i18next.t("valueHasChanged")}>
              {renderInnerValue(col, args)}
            </div>
          );
        }
      });
    }

    const renderFunc = (col, args) => {
      const renderer = columnRenderers[col];
      return renderer ? renderer(args) : "-";
    };

    return callback(row, i, renderFunc);
  });
};


export function displayAsMonths(date) {
  return moment(date).format(monthYearFormat);
}

export function makeEventObjectFromDate(dateObject, name) {
  return {
    target: {
      type: Date,
      name: name,
      value: dateObject,
    }
  };
}
