import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";
import {Button} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import {getRequest, postRequest} from "../../../api/request";
import FormGroupCheckbox from "../../helper/form_group_checkbox";

class NewCardProductModal extends CoreComponent {
  state = {
    cardTypes: [],
  };

  newCardProductCaller = (toggleOpen) => {
    return (
      <Button className={"ml-3 btn-add sm-btn"} onClick={toggleOpen}>
        {this.trans("addNew")}
      </Button>
    );
  };

  newCardProduct = (data, errors, onChange) => {
    const {cardTypes} = this.state;

    let panLength = null;
    if (data.issuerIDNo && data.maxAccountIDNo) {
      panLength = data.issuerIDNo.length + data.maxAccountIDNo.length + 1;
    }
    return (
      <>
        <FormGroupWErr str="name" maxLength={64} err={errors.name} val={data.name} onChange={onChange}/>
        <FormGroupWErr str="issuerIDNo" maxLength={8} err={errors.issuerIDNo} val={data.issuerIDNo} onChange={onChange}/>
        <FormGroupWErr str="minAccountIDNo" maxLength={10} err={errors.minAccountIDNo} val={data.minAccountIDNo}
          onChange={onChange}/>
        <FormGroupWErr str="maxAccountIDNo" maxLength={10} err={errors.maxAccountIDNo} val={data.maxAccountIDNo}
          onChange={onChange}/>
        <FormGroupWErr str="panLength" type="number" err={errors.panLength} val={panLength} disabled
          min="16" max="19" placeholder={this.trans("errors.ERROR_PAN_LENGTH_16_TO_19")}/>
        <FormGroupWErr str="duration" label="validityPeriodInMonths" type="number" err={errors.duration}
          val={data.duration} onChange={onChange} min="1"/>
        <FormGroupWErr
          str="cardType"
          label="cardProduct"
          type={"select"}
          err={errors.cardType}
          options={cardTypes.map((cardType) => ({value: cardType.id, name: cardType.name}))}
          onChange={onChange}
          val={data.cardType}
        />
        <FormGroupCheckbox str="webEnabled" label="canBeUsedForWebActivities" onChange={onChange}/>
      </>
    );
  };

  newCardProductOnSubmit = (data) => {
    const postData = {};
    Object.assign(postData, data);
    postData.panLength = data.issuerIDNo.length + data.maxAccountIDNo.length + 1;
    postData.duration = parseInt(data.duration);
    postData.cardTypeID = parseInt(data.cardType);

    return new Promise((resolve, reject) => {
      this.cancelable(postRequest(`/api/companies/${this.props.companyID}/card-product`, postData)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  newCardProductOnOpen = () => {
    return new Promise((resolve, reject) => {
      this.cancelable(getRequest("/api/cards/types")).then((resp) => {
        if (Array.isArray(resp.data)) {
          this.setStateValue("cardTypes", resp.data);
        }
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  render() {
    return (
      <>
        <ModalWindow
          headerText={this.trans("newCardProduct")}
          caller={this.newCardProductCaller}
          body={this.newCardProduct}
          onSubmit={this.newCardProductOnSubmit}
          onOpen={this.newCardProductOnOpen}
        />
      </>
    );
  }
}

export default withTranslation()(NewCardProductModal);
