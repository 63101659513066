import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {apiFetchTerminalChanges} from "../../../api/fetch";
import Paginator from "../../helper/paginator";
import {mapChangedColumns} from "../../helper/utils";
import {CTable} from "../../helper/ctable";

class TerminalChangesContainer extends CoreComponent {
  state = {
    terminalChanges: [],
  };

  fetchTerminalChanges = (urlQuery) => {
    return this.cancelable(apiFetchTerminalChanges(this.props.terminalOwnerID, this.props.terminalID, urlQuery, (data) => {
      this.setState({terminalChanges: data.list});
    }));
  };

  render() {
    return (
      <>
        <Paginator
          next={this.fetchTerminalChanges}
          pageLimit={10}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("terminalID")}</th>
                <th>{this.trans("merchantID")}</th>
                <th>{this.trans("payDesk")}</th>
                <th>{this.trans("merchantRegNo")}</th>
                <th>{this.trans("dateOfChange")}</th>
                <th>{this.trans("author")}</th>
              </tr>
            </thead>
            <tbody>
              {mapChangedColumns(this.state.terminalChanges, (val, i, renderCol) => {
                return (
                  <tr key={`terminal-change-body-${i}`}>
                    <td>{renderCol("terminalID")}</td>
                    <td>{renderCol("merchantID")}</td>
                    <td>{renderCol("payDesk", {defaultValue: "-"})}</td>
                    <td>{renderCol("merchantRegNo", {defaultValue: "-"})}</td>
                    <td>{val.validFrom}</td>
                    <td>{val.authorEmail}</td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </>
    );
  }
}

export default withTranslation()(TerminalChangesContainer);
