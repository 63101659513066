import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {centsToEurosToStr, getCurrentMonthDateRange} from "../../helper/utils";
import TerminalTransactionFilters from "../../filters/terminal_transaction_filters";
import {FetchButtonDropdown} from "../../helper/fetch_button";
import OverviewContainer from "../../helper/overview_container";
import {apiFetchTerminalsTransactionsReport, apiFetchTerminalsTransactionsXLS} from "../../../api/fetch";
import {parseDateRange} from "../../helper/date_range_picker";
import TLink from "../../helper/table_link";
import FileSaver from "file-saver";
import FileName from "../../helper/fileutil";
import Paginator from "../../helper/paginator";
import {CAN_GET_CARDS, CAN_GET_CUSTOMERS, CAN_GET_TERMINALS} from "../../helper/role_permissions";
import {CTable} from "../../helper/ctable";

class TerminalTransactionsContainer extends CoreComponent {
  state = {
    reportEntries: [],
    transactionCount: 0,
    amountSum: 0,
  };

  fetchReport = (query) => {
    return this.cancelable(apiFetchTerminalsTransactionsReport(query, (data) => {
      this.setState({
        reportEntries: data.list,
        transactionCount: data.count,
        amountSum: data.sum,
      });
    }));
  };

  initDetailedXLSExport = (data) => {
    return this.initXLSExport(data, {xlsDetailed: true});
  };

  initSummaryXLSExport = (data) => {
    return this.initXLSExport(data, {xlsSummary: true});
  };

  initXLSExport = (data, options) => {
    const xlsQuery = Object.assign({xlsLanguage: this.props.i18n.language}, data, options);

    return this.cancelable(apiFetchTerminalsTransactionsXLS(xlsQuery, (xlsData) => {
      const fileName = new FileName(this.trans("terminalOwners"), "xlsx").withTimestamp(...parseDateRange(xlsQuery.dateRange));
      const opts = {
        "type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      };

      FileSaver.saveAs(new Blob([xlsData], opts), fileName.getFullName());
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  render() {
    const {transactionCount, amountSum} = this.state;
    const {user} = this.props;

    return (
      <div className={"mt-2"}>
        <Paginator
          next={this.fetchReport}
          initialPageFilters={getCurrentMonthDateRange()}
          additionalComponent={(onFilter, initialPageFilters) => (
            <>
              <TerminalTransactionFilters
                initialPageFilters={initialPageFilters}
                onFilter={onFilter}
                extraButtons={(data) => (
                  <FetchButtonDropdown
                    title="XLSX"
                    className={"sm-btn"}
                    options={[
                      {title: this.trans("detailed"), onClick: () => this.initDetailedXLSExport(data)},
                      {title: this.trans("summarized"), onClick: () => this.initSummaryXLSExport(data)},
                    ]}
                  />
                )}
              />
              <OverviewContainer
                columns={[
                  {title: this.trans("transactionSum"), value: centsToEurosToStr(amountSum)},
                  {title: this.trans("transactionCount"), value: transactionCount},
                ]}
              />
            </>
          )}
        >

          <CTable>
            <thead>
              <tr>
                <th>{this.trans("terminalOwner")}</th>
                <th>{this.trans("terminal")}</th>
                <th className="currency-col">{this.trans("amount")}</th>
                <th>{this.trans("date")}</th>
                <th>{this.trans("pan")}</th>
                <th>{this.trans("customer")}</th>
                <th>{this.trans("unit")}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.reportEntries.map((v, i) => (
                <tr key={`report-terminals-${i}`}>
                  <TLink
                    to={`/terminal-owners/${v.terminalOwnerID}`}
                    disableLink={!user.hasPermission(CAN_GET_TERMINALS)}
                  >
                    {v.terminalOwnerName}
                  </TLink>
                  <td>{v.terminal}</td>
                  <td className="currency-col">{centsToEurosToStr(v.amount)}</td>
                  <td>{v.dateIn}</td>
                  <TLink
                    to={`/cards/${v.cardID}`}
                    disableLink={!user.hasPermission(CAN_GET_CARDS)}
                  >
                    {v.cardPan}
                  </TLink>
                  <TLink
                    to={`/customers/${v.customerID}`}
                    disableLink={v.customerClosed || !user.hasPermission(CAN_GET_CUSTOMERS)}
                  >
                    {v.customerName}
                  </TLink>
                  <TLink
                    to={`/customers/${v.customerID}/unit-history/${v.customerUnitID}`}
                    disableLink={v.customerUnitClosed || !user.hasPermission(CAN_GET_CUSTOMERS)}
                  >
                    {v.customerUnitName}
                  </TLink>
                </tr>
              ))}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withTranslation()(TerminalTransactionsContainer);
