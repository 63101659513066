import React from "react";
import {withAlert} from "../helper/alert";
import {withTranslation} from "react-i18next";
import NavTabs, {TabPaneOnOpen} from "../helper/nav_tabs";
import {AdminReportsTabConsumer} from "../helper/context";
import {TabContent} from "reactstrap";
import {PageTitle} from "../helper/page_title";
import AdminCompaniesReport from "../containers/report/admin_companies_report";

class AdminReportsPage extends React.Component {
  tabs = {
    COMPANIES: "companies",
  };

  render() {
    let navTabs = [
      {
        id: this.tabs.COMPANIES,
        title: "companies",
      },
    ];

    return (
      <div>
        {this.props.renderAlert()}
        <PageTitle title={this.props.title}/>
        <AdminReportsTabConsumer>
          {({activeTab, toggleTab}) => (
            <div>
              <NavTabs
                tabs={navTabs}
                activeTab={activeTab}
                toggleTab={toggleTab}
              />
              <TabContent activeTab={activeTab}>
                <TabPaneOnOpen tabId={this.tabs.COMPANIES}>
                  <AdminCompaniesReport
                    setErrors={this.props.setErrors}
                  />
                </TabPaneOnOpen>
              </TabContent>
            </div>
          )}
        </AdminReportsTabConsumer>
      </div>
    );
  }
}

export default withAlert(withTranslation()(AdminReportsPage));
