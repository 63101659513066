import React from "react";
import {withRouter} from "react-router-dom";
import {Button, Col, FormGroup, Row} from "reactstrap";
import LabelWithError from "../helper/label_with_error";
import CoreForm from "../helper/core_form";
import {withTranslation} from "react-i18next";
import {PageTitle} from "../helper/page_title";
import {withAlert} from "../helper/alert";
import CoreComponent from "../core_component";
import AInput from "../helper/apple_input";
import {apiFetchLanguages, apiRegister} from "../../api/fetch";
import FormGroupWErr from "../helper/form_group_w_err";
import PropTypes from "prop-types";

class _Register extends CoreComponent {
  state = {
    password: "",
    repPassword: "",
    name: "",
    languageID: null,
    languages: [],
  };

  uuid = this.props.match.params.id;

  componentDidMount() {
    this.fetchInitial(this.localGetLanguages());
  }

  localGetLanguages = () => {
    return this.cancelable(apiFetchLanguages(data => {
      this.setState({
        languages: data,
      });
    }));
  };

  onChange = (e) => {
    this.setStateValue(e.target.name, e.target.value);
  };

  onSubmit = () => {
    const {repPassword, password, name, languageID} = this.state;
    const reqData = {repPassword, password, name, languageID};

    return this.cancelable(apiRegister(this.uuid, reqData, resp => {
      this.props.login(resp);
      this.navReplace("/");
    }, error => {
      this.props.setErrors(error.errors);
      this.setState(Object.assign({}, this.state, {
        password: "",
        repPassword: ""
      }));
    }));
  };

  debugAutoRegister = () => {
    this.setState({
      name: "xxx",
      password: "Pass1234",
      repPassword: "Pass1234",
      languageID: 1,
    }, this.onSubmit);
  };

  render() {
    const {name, languageID} = this.state;
    const {errors, renderAlert} = this.props;

    return (
      <Col className={"panel"}>
        <Row className={"justify-content-center"}>
          <Col md={5}>
            <PageTitle title={this.trans("register")}/>
            {renderAlert()}
            <CoreForm onSubmit={this.onSubmit}>
              <FormGroupWErr
                maxLength={90}
                str={"name"}
                err={errors.name}
                val={name}
                onChange={this.onChange}
              />
              <FormGroupWErr
                str="languageID"
                label="language"
                type="select"
                err={errors.languageID}
                options={this.state.languages.map((lang) => ({value: lang.id, name: this.trans(`languages.${lang.id}`)}))}
                onChange={this.onChange}
                val={languageID}
              />
              <FormGroup>
                <LabelWithError error={errors.password} for="password">{this.trans("password")}</LabelWithError>
                <AInput maxLength={256} type="password" name="password" id="password" required onChange={this.onChange}/>
              </FormGroup>
              <FormGroup>
                <LabelWithError error={errors.repPassword} for="repPassword">{this.trans("repeatPassword")}</LabelWithError>
                <AInput maxLength={256} type="password" name="repPassword" id="repPassword" required onChange={this.onChange}/>
              </FormGroup>
              <FormGroup className={"d-flex justify-content-end"}>
                {(process.env.REACT_APP_MODE === "dev") && (
                  <Button onClick={this.debugAutoRegister} className="sm-btn btn-save mr-2">DEBUG: Auto register</Button>
                )}
                <Button className="sm-btn btn-save">{this.trans("register")}</Button>
              </FormGroup>
            </CoreForm>
          </Col>
        </Row>
      </Col>
    );
  }
}

export const Register = withAlert(withTranslation()(withRouter(_Register)));

Register.propTypes = {
  login: PropTypes.func.isRequired,
};
