import React from "react";
import {withAlert} from "../helper/alert";
import {withTranslation} from "react-i18next";
import NavTabs, {TabPaneOnOpen} from "../helper/nav_tabs";
import {ReportsTabConsumer} from "../helper/context";
import {TabContent} from "reactstrap";
import {PageTitle} from "../helper/page_title";
import CardTransactionsReport from "../containers/card/card_transactions_report";
import TerminalTransactionsContainer from "../containers/report/terminal_transactions_container";
import AllReportsContainer from "../containers/report/all_reports_container";
import CustomersReport from "../containers/report/customers_report";
import WebApiTransactionsContainer from "../containers/report/web_api_transactions_container";

class ReportsPage extends React.Component {
  tabs = {
    CARDS: "cards",
    TERMINALS: "terminals",
    CUSTOMERSMONTHSTATEMENT: "customersMonthStatement",
    CUSTOMERS: "customers",
    WEBAPI: "webAPI",
  };

  render() {
    const {setErrors, user} = this.props;

    const navTabs = [
      {
        id: this.tabs.CARDS,
        title: "cards",
      },
      {
        id: this.tabs.TERMINALS,
        title: "terminals",
      },
      {
        id: this.tabs.CUSTOMERSMONTHSTATEMENT,
        title: "customersMonthStatement",
      },
      {
        id: this.tabs.CUSTOMERS,
        title: "customers",
      },
      {
        id: this.tabs.WEBAPI,
        title: "webApi",
      },
    ];

    return (
      <div>
        {this.props.renderAlert()}
        <PageTitle title={this.props.title}/>
        <ReportsTabConsumer>
          {({activeTab, toggleTab}) => (
            <div>
              <NavTabs
                tabs={navTabs}
                activeTab={activeTab}
                toggleTab={toggleTab}
              />
              <TabContent activeTab={activeTab}>
                <TabPaneOnOpen tabId={this.tabs.CARDS}>
                  <CardTransactionsReport
                    setErrors={setErrors}
                    user={user}
                  />
                </TabPaneOnOpen>
                <TabPaneOnOpen tabId={this.tabs.TERMINALS}>
                  <TerminalTransactionsContainer
                    setErrors={setErrors}
                    user={user}
                  />
                </TabPaneOnOpen>
                <TabPaneOnOpen tabId={this.tabs.CUSTOMERSMONTHSTATEMENT}>
                  <AllReportsContainer
                    setErrors={setErrors}
                    user={user}
                  />
                </TabPaneOnOpen>
                <TabPaneOnOpen tabId={this.tabs.CUSTOMERS}>
                  <CustomersReport
                    setErrors={setErrors}
                    user={user}
                  />
                </TabPaneOnOpen>
                <TabPaneOnOpen tabId={this.tabs.WEBAPI}>
                  <WebApiTransactionsContainer
                    setErrors={setErrors}
                    user={user}
                  />
                </TabPaneOnOpen>
              </TabContent>
            </div>
          )}
        </ReportsTabConsumer>
      </div>
    );
  }
}

export default withAlert(withTranslation()(ReportsPage));
