import React from "react";
import Ajv from "ajv";
import FormGroupWErr from "./form_group_w_err";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import {paginationSchema} from "../../api/fetch";
import SelectWrap from "./select_wrap";
import Icon from "react-icons-kit";
import {ic_search} from "react-icons-kit/md";

class SelectInput extends CoreComponent {
  state = {
    options: [],
    error: null,
  };

  searchTimeout = null;

  componentDidMount() {
    if (this.props.fetchOnMount) {
      this.doFetch("", this.props.val);
    }
  }

  doFetch = (searchVal, id = null) => {
    const minSearchValLength = this.props.minInputLength || 1;
    let queryData = {
      limit: 5,
    };

    if (id) {
      queryData["id"] = id;
    } else if (searchVal && searchVal.length >= minSearchValLength) {
      queryData["search"] = searchVal;
    } else {
      return;
    }

    this.cancelOnUnmount(this.props.onInputChange(queryData, (respData) => {
      const {mapper} = this.props;
      let opts;
      let arr = respData;

      const ajv = new Ajv();
      const checkIfIsPaginated = ajv.compile(paginationSchema);

      // Kui on pagineeritud result, siis istub array 'list' välja küljes
      if (checkIfIsPaginated(respData)) {
        arr = arr["list"];
      }

      if (mapper) {
        opts = mapper(arr);
      } else {
        opts = arr.map((v) => {
          return {
            value: v.id,
            name: v.name,
          };
        });
      }

      this.setState({
        options: opts,
        error: null,
      });
    }, (err) => {
      this.setState({
        error: err.errors.error,
      });
    }));
  };

  onInputChange = (searchVal) => {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      this.doFetch(searchVal);
    }, 500);
  };

  render() {
    const defaultPlaceholder = (
      <div>
        <Icon icon={ic_search}/>
        <span>{this.trans("search")}...</span>
      </div>
    );

    if (this.props.standalone) {
      return (
        <>
          {(this.props.err || this.state.error) && (
            <i className="select-input-alert">
              {this.trans(`errors.${this.props.err || this.state.error}`)}
            </i>
          )}
          <SelectWrap
            placeholder={defaultPlaceholder}
            {...this.props}
            options={this.state.options}
            onInputChange={this.onInputChange}
          />
        </>
      );
    }

    return (
      <FormGroupWErr
        placeholder={defaultPlaceholder}
        {...this.props}
        type="select"
        options={this.state.options}
        onInputChange={this.onInputChange}
        err={this.props.err || this.state.error}
      />
    );
  }
}

export default withTranslation()(SelectInput);
