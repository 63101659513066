import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import DeleteCardsAlert from "../../alerts/delete_cards_alert";
import {Input, Label} from "reactstrap";
import Icon from "react-icons-kit";
import {ic_radio_button_checked, ic_radio_button_unchecked, ic_search} from "react-icons-kit/md";
import {cardState, isCardUsed, minSearchStrLength} from "../../helper/utils";
import {apiFetchCustomerUnitCards} from "../../../api/fetch";
import EditCustomerUnitModal from "../../modals/customer_unit/edit_customer_unit_modal";
import OverviewContainer from "../../helper/overview_container";
import TLink from "../../helper/table_link";
import Paginator from "../../helper/paginator";
import CardBalancesContainer, {CardBalancesTableColumn} from "../card/card_balances_container";
import Tooltip from "../../helper/tooltip";
import {CAN_EDIT_CARDS, CAN_EDIT_CUSTOMERS} from "../../helper/role_permissions";
import {CTable} from "../../helper/ctable";

class CustomerUnitCardsContainer extends CoreComponent {
  state = {
    cards: [],
    cardCount: 0,

    showAlsoNotActiveCards: false,
    searchCards: "",
  };

  fetchCards = (query) => {
    return this.cancelable(apiFetchCustomerUnitCards(this.props.customerUnitID, query, (data) => {
      this.setState({
        cards: data.list,
        cardCount: data.count,
      });
    }));
  };

  searchComponent = (onChange) => {
    return (
      <input className="apple-input-min" onChange={onChange} placeholder={this.trans("cardNo") + ", " + this.trans("product")}/>
    );
  };

  cardsTableCheckAllOnChange = (e) => {
    this.setState({
      cards: this.state.cards.map((card) => {
        return {...card, selected: this.canEditCard(card) ? e.target.checked : false};
      })
    });
  };

  cardsTableCheckOneOnChange = (e, cardID) => {
    this.setState({
      cards: this.state.cards.map((card) => {
        return {...card, selected: card.id === cardID ? e.target.checked : card.selected};
      })
    });
  };

  onChangeShowNotActiveCards = (onFilter) => {
    const {searchCards, showAlsoNotActiveCards} = this.state;
    this.setState(
      {
        showAlsoNotActiveCards: !showAlsoNotActiveCards,
        cards: this.state.cards.map((card) => ({...card, selected: false})),
      }, () => {
        onFilter({
          search: searchCards,
          showAlsoNotActiveCards: !showAlsoNotActiveCards,
        });
      });
  };

  searchCards = (e, onFilter) => {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    const v = e.target.value;

    const setTheState = (searchCards = "") => {
      this.setState({
        searchCards: searchCards,
        cards: this.state.cards.map((card) => ({...card, selected: false})),
      }, () => onFilter({
        search: this.state.searchCards,
        showAlsoNotActiveCards: this.state.showAlsoNotActiveCards,
      }));
    };

    this.searchTimeout = setTimeout(() => {
      if (v.length >= minSearchStrLength) {
        setTheState(v);
      } else if (v.length === 0) {
        setTheState();
      }
    }, 500);
    this.addTimeout(this.searchTimeout);
  };

  canEditCard = (card) => {
    return [cardState.name.active, cardState.name.future].includes(card.cardState);
  };

  render() {
    const {
      cards,
      showAlsoNotActiveCards,
      cardCount,
    } = this.state;
    const {setErrors, customerID, user} = this.props;

    const selectedCardsIDs = cards.filter((card) => card.selected).map((card) => card.id);
    const allSelected = selectedCardsIDs.length === cards.filter((card) => this.canEditCard(card)).length && selectedCardsIDs.length > 0;

    return (
      <Paginator
        next={this.fetchCards}
        additionalComponent={(onFilter) => (
          <>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="d-flex">
                {user.hasPermission(CAN_EDIT_CUSTOMERS) && (
                  <EditCustomerUnitModal
                    setErrors={setErrors}
                    customerID={customerID}
                    selectedCardIDs={selectedCardsIDs}
                  />
                )}
                {user.hasPermission(CAN_EDIT_CARDS) && (
                  <DeleteCardsAlert
                    setErrors={setErrors}
                    selectedCardIDs={selectedCardsIDs}
                  />
                )}
              </div>
              <div className="d-flex align-items-center">
                <span className="mr-3 form-check-inline">
                  <Input
                    type="checkbox" checked={showAlsoNotActiveCards}
                    id={"showAlsoNotActiveCards"} onChange={() => this.onChangeShowNotActiveCards(onFilter)}
                  />
                  <Label className="m-0" for="showAlsoNotActiveCards">{this.trans("showAlsoNotActiveCards")}</Label>
                </span>
                <span className="icon-input">
                  <Icon icon={ic_search}/>
                  {this.searchComponent((e) => this.searchCards(e, onFilter))}
                </span>
              </div>
            </div>
            <OverviewContainer
              columns={[
                {title: this.trans("cardCount"), value: cardCount},
              ]}
            />
          </>
        )}
      >
        <CTable>
          <thead>
            <tr>
              <th className="z-index-one">
                <Input
                  checked={allSelected} type="checkbox" id={"cards-cb-all"}
                  className="table-checkbox-margin-left" onChange={this.cardsTableCheckAllOnChange}
                />
              </th>
              <th>{this.trans("pan")}</th>
              <th className="text-center">{this.trans("balanceSlashLimit")}</th>
              <th>{this.trans("customerName")}</th>
              <th>{this.trans("nameOnCard")}</th>
              <th>{this.trans("infoFieldOnCard")}</th>
              <th>{this.trans("cardProductName")}</th>
              <th>{this.trans("validTo")}</th>
              <th>{this.trans("cardState")}</th>
              <th>{this.trans("usedUp")}</th>
            </tr>
          </thead>
          <tbody>
            {cards.map((card, i) => {
              return (
                <tr key={`cards-${card.id}-${i}`}>
                  <td>
                    {this.canEditCard(card) &&
                    <Input
                      checked={selectedCardsIDs.includes(card.id)} type="checkbox" id={`cards-cb-${i}`}
                      className="table-checkbox-margin-left" onChange={(e) => this.cardsTableCheckOneOnChange(e, card.id)}
                    />
                    }
                  </td>
                  <TLink to={`/cards/${card.id}`}>
                    <Tooltip
                      showif={true}
                      className={"card-balance-tooltip-content"}
                      getContent={() => <CardBalancesContainer cardID={card.id}/>}
                    >
                      {card.pan}
                    </Tooltip>
                  </TLink>
                  <CardBalancesTableColumn totals={card.balanceAndTotals}/>
                  <TLink to={`/customers/${card.customerID}`}>
                    {card.customerName}
                  </TLink>
                  <td>{card.name || "-"}</td>
                  <td>{card.info || "-"}</td>
                  <td>{card.cardProductName}</td>
                  <td>{card.validTo}</td>
                  <td>{this.trans(`cardStateTypes.${cardState.value[card.cardState]}`)}</td>
                  <td><Icon icon={isCardUsed(card.balanceAndTotals) ? ic_radio_button_checked : ic_radio_button_unchecked}/></td>
                </tr>
              );
            })}
          </tbody>
        </CTable>
      </Paginator>
    );
  }

}

export default withTranslation()(CustomerUnitCardsContainer);
