import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";
import {ic_mode_edit} from "react-icons-kit/md";
import FormGroupWErr from "../../helper/form_group_w_err";
import FormGroupCheckbox from "../../helper/form_group_checkbox";
import {putRequest} from "../../../api/request";
import {NavLink} from "reactstrap";
import Icon from "react-icons-kit";

class EditContactPersonModal extends CoreComponent {

  state = {
    contactEmailRequired: true,
    contactPhoneRequired: true,
  };

  editContPersonBody = (data, errors, onChange, ignoredArg, currentlyIsPrimary) => {
    return (
      <>
        <FormGroupWErr maxLength={90} label={"name"} str={"contactName"} err={errors.contactName} val={data.contactName} onChange={onChange}/>
        <FormGroupWErr maxLength={254} label={"email"} type={"email"} str={"contactEmail"} err={errors.contactEmail} val={data.contactEmail}
          onChange={(ev) => this.onChangeContactEmail(ev, onChange)} required={this.state.contactEmailRequired}/>
        <FormGroupWErr maxLength={64} label={"phone"} str={"contactPhone"} err={errors.contactPhone} val={data.contactPhone}
          onChange={(ev) => this.onChangeContactPhone(ev, onChange)} required={this.state.contactPhoneRequired} />
        <FormGroupCheckbox label={"setAsPrimaryContactPerson"} str={"isPrimary"} val={data.isPrimary} disabled={currentlyIsPrimary} onChange={onChange}/>
      </>
    );
  };

  editContPersonOnSubmit = (data) => {
    return new Promise((resolve, reject) => {
      this.cancelable(putRequest("/api/edit-contact-person", data)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  editContPersonCaller = (toggleOpen, contactPerson, iconType) => {
    return(
      <td className="icon-wrapper-width">
        <NavLink className="p-0 d-inline-block" href="#" onClick={(ev) => toggleOpen(ev, contactPerson)}>
          <Icon icon={iconType}/>
        </NavLink>
      </td>
    );
  };

  contPersonOnOpen = (contactPerson) => {
    const {contactEmail, contactPhone} = contactPerson;

    let ss = (email, phone) => {
      return new Promise((resolve) => {
        this.setState(Object.assign({}, this.state, {
          contactEmailRequired: email,
          contactPhoneRequired: phone,
        }), resolve);
      });
    };

    if (contactEmail && contactPhone) {
      return ss(false, false);
    } else if (contactEmail) {
      return ss(true, false);
    } else if (contactPhone) {
      return ss(false, true);
    }

    return ss(true, true);
  };


  onChangeContactEmail = (ev, onChange) => {
    onChange(ev);
    if(ev.target.value !== ""){
      this.setState({
        contactPhoneRequired: false
      });
    }else{
      this.setState({
        contactPhoneRequired: true
      });
    }
  };

  onChangeContactPhone = (ev, onChange) => {
    onChange(ev);
    if(ev.target.value !== ""){
      this.setState({
        contactEmailRequired: false
      });
    }else{
      this.setState({
        contactEmailRequired: true
      });
    }
  };

  render() {
    const {val} = this.props;
    return (
      <>
        <ModalWindow
          headerText={this.trans("editContactPerson")}
          body={(data, errors, onChange, ignoredArg) =>
            this.editContPersonBody(data, errors, onChange, ignoredArg, val.isPrimary)}
          caller={(toggleOpen) => this.editContPersonCaller(toggleOpen, val, ic_mode_edit)}
          onSubmit={this.editContPersonOnSubmit}
          onOpen={() => this.contPersonOnOpen(val)}
        />
      </>
    );
  }

}

export default withTranslation()(EditContactPersonModal);
