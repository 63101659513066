import React from "react";
import {withRouter} from "react-router-dom";
import {Button} from "reactstrap";
import {withTranslation} from "react-i18next";
import {postRequest} from "../../api/request";
import {PageTitle} from "../helper/page_title";
import FormGroupWErr from "../helper/form_group_w_err";
import ModalWindow from "../helper/modal_window";
import {withAlert} from "../helper/alert";
import CoreComponent from "../core_component";
import TLink from "../helper/table_link";
import TerminalOwnerFilters from "../filters/terminal_owner_filters";
import Paginator from "../helper/paginator";
import {apiFetchTerminalOwners} from "../../api/fetch";
import {CAN_EDIT_TERMINALS} from "../helper/role_permissions";
import {CTable} from "../helper/ctable";

// TerminalOwnersPage defines a page for displaying overview of
// all terminal owners of the company
class TerminalOwnersPage extends CoreComponent {
  state = {
    terminalOwners: [],
  };

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);
  }

  fetchTerminalOwners = (urlQuery) => {
    return this.cancelable(apiFetchTerminalOwners(urlQuery, (respData) => {
      if (this._isMounted) {
        this.setState({terminalOwners: respData.list});
      }
    }));
  };

  /*** add new terminal owner modal ***/

  addNewTerminalOwnerCaller = (toggleOpen) => {
    return (
      <Button className="ml-2 btn-add sm-btn" onClick={toggleOpen}>
        {this.trans("addNew")}
      </Button>

    );
  };

  addNewTerminalOwnerBody = (data, errors, onChange) => {
    return (
      <>
        <FormGroupWErr maxLength={90} str={"name"} err={errors.name} val={data.name} onChange={onChange}/>
        <FormGroupWErr maxLength={16} str={"identifier"} err={errors.identifier} val={data.identifier} onChange={onChange} required={false}/>
      </>
    );
  };

  addNewTerminalOwnerOnSubmit = (data) => {
    return new Promise((resolve, reject) => {
      this.cancelable(postRequest("/api/terminal-owners", data)).then((resp) => {
        this.navTo(`/terminal-owners/${resp.data.terminalOwnerID}`, "saved");
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  /*** ***/

  render() {
    const {terminalOwners} = this.state;
    const {user} = this.props;

    return (
      <div>
        {this.props.renderAlert()}
        <div className={"my-3 d-flex flex-row justify-content-start"}>
          <PageTitle isGrouped={true} title={this.props.title}/>
          {user.hasPermission(CAN_EDIT_TERMINALS) && (
            <ModalWindow
              headerText={this.trans("newTerminalOwner")}
              caller={this.addNewTerminalOwnerCaller}
              body={this.addNewTerminalOwnerBody}
              onSubmit={this.addNewTerminalOwnerOnSubmit}
            />
          )}
        </div>
        <Paginator
          next={this.fetchTerminalOwners}
          additionalComponent={(onFilter) => (
            <TerminalOwnerFilters
              onFilter={onFilter}
            />
          )}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("name")}</th>
                <th>{this.trans("identifier")}</th>
              </tr>
            </thead>
            <tbody>
              {terminalOwners.map((towner, i) => {
                return (
                  <tr key={`terminal-owner-${i}`}>
                    <TLink to={`/terminal-owners/${towner.id}`}>
                      {towner.name}
                    </TLink>
                    <td>{towner.identifier || "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withAlert(withTranslation()(withRouter(TerminalOwnersPage)));
