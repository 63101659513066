import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {apiFetchCompanyTransactions} from "../../../api/fetch";
import {PageTitle} from "../../helper/page_title";
import {centsToEurosToStr} from "../../helper/utils";
import TLink from "../../helper/table_link";
import Paginator from "../../helper/paginator";
import {CAN_GET_CARDS, CAN_GET_CUSTOMERS} from "../../helper/role_permissions";
import {CTable} from "../../helper/ctable";

class HomeRecentTransactions extends CoreComponent {
  state = {
    recentTransactions: [],
  };

  fetchRecentTransactions = (query) => {
    return this.cancelable(apiFetchCompanyTransactions(query, (data) => {
      this.setState({recentTransactions: data.list});
    }));
  };

  render() {
    const {user} = this.props;

    return (
      <div className="p-3">
        <PageTitle isGrouped={true} title={this.trans("recentTransactions")}/>
        <Paginator
          next={this.fetchRecentTransactions}
          pageLimit={10}
          light
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("cardNo")}</th>
                <th className="currency-col">{this.trans("amount")}</th>
                <th>{this.trans("date")}</th>
                <th>{this.trans("customer")}</th>
                <th>{this.trans("customerUnit")}</th>
                <th>{this.trans("payee")}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.recentTransactions.map((v, i) => {
                return (
                  <tr key={`recent-trans-${i}`}>
                    <TLink
                      to={`/cards/${v.cardID}`}
                      disableLink={!user.hasPermission(CAN_GET_CARDS)}
                    >
                      {v.cardPan}
                    </TLink>
                    <td className="currency-col">{centsToEurosToStr(v.amount)}</td>
                    <td>{v.dateIn}</td>
                    <TLink
                      to={`/customers/${v.customerID}`}
                      disableLink={v.customerClosed || !user.hasPermission(CAN_GET_CUSTOMERS)}
                    >
                      {v.customerName}
                    </TLink>
                    <TLink
                      to={`/customers/${v.customerID}/unit-history/${v.customerUnitID}`}
                      disableLink={v.customerUnitClosed || !user.hasPermission(CAN_GET_CUSTOMERS)}
                    >
                      {v.customerUnitName}
                    </TLink>
                    <td>{v.payee}</td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withTranslation()(HomeRecentTransactions);
