import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";
import {NavLink} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import {ic_mode_edit} from "react-icons-kit/md";
import Icon from "react-icons-kit";
import {withRouter} from "react-router-dom";
import {apiEditCompanyWebApiIP} from "../../../api/fetch";

class EditWebApiIpModal extends CoreComponent {

  editWebApiIpCaller = (toggleOpen, data) => {
    return (
      <NavLink className="p-0 d-inline-block" href="#" onClick={(ev) => toggleOpen(ev, data)}>
        <Icon icon={ic_mode_edit}/>
      </NavLink>
    );
  };
  editWebApiIP = (data, errors, onChange) => {
    return (
      <>
        <FormGroupWErr str="ipAddress" maxLength={64} val={this.props.webApiIpAddress} disabled={true}/>
        <FormGroupWErr str="comment" maxLength={4096} err={errors.comment} val={data.comment} onChange={onChange} required={false}/>
      </>
    );
  };

  editWebApiIpOnSubmit = (data) => {
    const postData = {};
    postData.comment = data.comment;
    postData.webApiIpID = this.props.webApiIpID;

    return this.cancelable(apiEditCompanyWebApiIP(postData));
  };


  render() {
    return (
      <>
        <ModalWindow
          headerText={this.trans("editWebApiIP")}
          caller={(toggleOpen) => this.editWebApiIpCaller(toggleOpen, this.props)}
          body={this.editWebApiIP}
          onSubmit={this.editWebApiIpOnSubmit}
        />
      </>
    );
  }
}

export default withRouter(withTranslation()(EditWebApiIpModal));
