import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Row, TabContent} from "reactstrap";
import {withRouter} from "react-router-dom";
import {CardTransactionsTabConsumer} from "../helper/context";
import LoadingPage from "./loading_page";
import {withAlert} from "../helper/alert";
import CardTransactionsContainer from "../containers/card/card_transactions_container";
import CardBalancesContainer from "../containers/card/card_balances_container";
import CardInfoContainer from "../containers/card/card_info_container";
import CardChangesContainer from "../containers/card/card_changes_container";
import {apiFetchCard, apiFetchCardTransactions} from "../../api/fetch";
import NavTabs, {TabPaneOnOpen} from "../helper/nav_tabs";
import {cardState} from "../helper/utils";
import CoreComponent from "../core_component";
import {CAN_EDIT_CARD_LIMITS, CAN_EDIT_TRANSACTIONS, CAN_GET_TRANSACTIONS} from "../helper/role_permissions";

class CardOverviewPage extends CoreComponent {
  state = {
    card: {},
    maxNewTransactionAmount: 0,
  };

  tabs = {
    TRANSACTIONS: "transactions",
    CHANGES: "changes",
  };

  cardID = this.props.match.params.id;

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);
    this.fetchInitial(this.fetchCard());
  }

  fetchCard = () => {
    return this.cancelable(apiFetchCard(this.cardID, (data) => {
      this.setState({
        card: data,
      });
    }));
  };

  render() {
    const {renderAlert, user, setErrors} = this.props;
    const {card} = this.state;
    const {active, future} = cardState.name;

    if (!this.state.isPageReady) {
      return <LoadingPage/>;
    }

    const navTabs = [];

    if (user.hasPermission(CAN_GET_TRANSACTIONS)) {
      navTabs.push({
        id: this.tabs.TRANSACTIONS,
        title: "transactions",
      });
    }

    navTabs.push({
      id: this.tabs.CHANGES,
      title: "changes",
    });

    return (
      <div>
        {renderAlert()}
        <Row>
          <Col md={7}>
            <CardInfoContainer
              card={this.state.card}
              cardID={parseInt(this.cardID)}
              user={user}
              setErrors={setErrors}
            />
          </Col>
          <Col md={5}>
            <CardBalancesContainer
              cardID={this.cardID}
              canEdit={user.hasPermission(CAN_EDIT_CARD_LIMITS) && [active, future].includes(card.cardState)}
              fetchCallback={(data) => {
                this.setState({
                  maxNewTransactionAmount: data.countBalance === null || data.countBalance > 0 ? data.amountBalance : 0,
                });
              }}
            />
          </Col>
        </Row>
        <CardTransactionsTabConsumer>
          {({activeTab, toggleTab}) => (
            <div className={"mt-2"}>
              <NavTabs
                tabs={navTabs}
                activeTab={activeTab}
                toggleTab={toggleTab}
              />
              <TabContent activeTab={activeTab}>
                {card.cardState !== cardState.name.future && user.hasPermission(CAN_GET_TRANSACTIONS) && (
                  <TabPaneOnOpen tabId={this.tabs.TRANSACTIONS}>
                    <CardTransactionsContainer
                      setErrors={setErrors}
                      cardID={this.cardID}
                      maxNewTransactionAmount={this.state.maxNewTransactionAmount}
                      canAddAndEditTrans={user.hasPermission(CAN_EDIT_TRANSACTIONS)}
                      fetchFunc={(...args) => apiFetchCardTransactions(this.cardID, ...args)}
                    />
                  </TabPaneOnOpen>
                )}
                <TabPaneOnOpen tabId={this.tabs.CHANGES}>
                  <CardChangesContainer
                    setErrors={setErrors}
                    cardID={this.cardID}
                    user={user}
                  />
                </TabPaneOnOpen>
              </TabContent>
            </div>
          )}
        </CardTransactionsTabConsumer>
      </div>
    );
  }
}

export default withAlert(withRouter(withTranslation()(CardOverviewPage)));
