import React from "react";
import {Button} from "reactstrap";
import CoreComponent from "../core_component";

export class PageTitle extends CoreComponent {
  render() {
    const {isGrouped, title} = this.props;

    if (isGrouped) {
      return (
        <div className="dark-col">
          <h3 className="m-0">{title || ""}</h3>
        </div>
      );
    }

    return (
      <div className="my-3">
        <div className="dark-col">
          <h3 className="m-0">{title || ""}</h3>
        </div>
      </div>
    );
  }
}

export class TitledButton extends CoreComponent {
  render() {
    const {title, children, position, ...rest} = this.props;

    let justifyContent = "justify-content-start";
    if (position === "right") {
      justifyContent = "justify-content-between";
    }

    return (
      <div className={`my-3 d-flex flex-row ${justifyContent}`}>
        <PageTitle isGrouped={true} title={title}/>
        <Button {...rest}>{children}</Button>
      </div>
    );
  }
}
