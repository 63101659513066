import React from "react";
import {Button, Input, NavLink} from "reactstrap";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {PageTitle} from "../helper/page_title";
import InfiniteScroller from "../helper/infinite_scroller";
import {withAlert} from "../helper/alert";
import {apiFetchCardsWoClient, apifetchPrinterCSVForCardsWoClient} from "../../api/fetch";
import CoreComponent from "../core_component";
import Icon from "react-icons-kit";
import {ic_file_download} from "react-icons-kit/md";
import TLink from "../helper/table_link";
import CardsWithoutClientFilters from "../filters/cards_without_client_filters";
import FileName from "../helper/fileutil";
import Tooltip from "../helper/tooltip";
import DeleteCardsWoClientAlert from "../containers/card_wo_client/delete_cards_wo_client_alert";
import {CAN_EDIT_CARDS, CAN_GENERATE_CARDS} from "../helper/role_permissions";
import {saveAs} from "file-saver";
import {CTable} from "../helper/ctable";

class CardsWithoutClient extends CoreComponent {

  state = {
    cards: [],

    //  checked state
    checkedBatchID: null, // kuna ainult sama batch on sarnane, saab opereerida vaid batchi kaupa
    cardsTableCheckedAll: false,
    cardsTableCheckedDict: {},
  };

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);
  }

  fetchCardsWoClient = (filtersData, filter = false) => {
    return this.cancelable(apiFetchCardsWoClient(filtersData, (data) => {
      const {cardsTableCheckedDict, cards, cardsTableCheckedAll, checkedBatchID} = this.state;
      const checkedDict = {};
      for (let i = 0; i < data.length; i++) {
        const v = data[i];

        let checked = false;
        if (cardsTableCheckedDict[v.cardID] !== undefined) {
          checked = cardsTableCheckedDict[v.cardID].checked;
        } else if (cardsTableCheckedDict[v.cardID] === undefined
          && cardsTableCheckedAll && v.cardBatchID === checkedBatchID) {
          checked = true;
        }
        checkedDict[v.cardID] = {checked: checked, cardBatchID: v.cardBatchID, pan: v.pan};
      }
      const mergedDict = Object.assign({}, cardsTableCheckedDict, checkedDict);

      this.setState({
        cards: filter ? data : cards.concat(data),
        cardsTableCheckedDict: mergedDict,
      });
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  cardLink = (card) => {
    if (card.activated === true) {
      return (
        <TLink to={`/cards/${card.cardID}`}>
          {card.pan}
        </TLink>
      );
    }
    return <td>{card.pan}</td>;
  };

  downloadPrinterCSV = (cardBatchID) => {
    this.cancelable(apifetchPrinterCSVForCardsWoClient(cardBatchID, (resp) => {
      const blob = new Blob([resp], {type: "text/csv; charset=utf-8;"});
      const fileName = new FileName(cardBatchID.toString(), "csv").withTimestamp(new Date());
      saveAs(blob, fileName.getFullName());
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  cardsTableCheckOnChange = (e, cardID) => {
    const cardsDict = this.state.cardsTableCheckedDict;
    const checkedTypes = Object.freeze({"none": 1, "one": 2, "moreThanOne": 3});

    let checkedState = checkedTypes.none;

    // eslint-disable-next-line no-unused-vars
    Object.entries(cardsDict).forEach(([key, val]) => {
      if (val.checked === true) {
        if (checkedState === checkedTypes.none) {
          checkedState = checkedTypes.one;
        } else if (checkedState === checkedTypes.one) {
          checkedState = checkedTypes.moreThanOne;
        }
      }
    });

    let checkedBatchID = this.state.checkedBatchID;
    let cardsTableCheckedAll = this.state.cardsTableCheckedAll;
    if (checkedState !== checkedTypes.moreThanOne) {
      // kui hetkel pole ükski checked ning esimene checkitakse, siis tuleb seada cardBatchID
      if (checkedState === checkedTypes.none && e.target.checked === true) {
        checkedBatchID = cardsDict[cardID].cardBatchID;
      }
      // kui hetkel checked vaid üks box ja see ka uncheckitakse, siis tuleb checkedBatchID resetida
      else if (checkedState === checkedTypes.one && e.target.checked === false) {
        checkedBatchID = null;
        cardsTableCheckedAll = false;
      }
    }

    const checkedDict = Object.assign(cardsDict);
    checkedDict[cardID].checked = e.target.checked;

    this.setStateWithObject({
      cardsTableCheckedDict: checkedDict,
      checkedBatchID: checkedBatchID,
      cardsTableCheckedAll: cardsTableCheckedAll,
    });
  };

  cardsTableCheckAllOnChange = (e) => {
    const cardsDict = Object.assign(this.state.cardsTableCheckedDict);

    Object.entries(this.state.cardsTableCheckedDict).forEach(([key, val]) => {
      if (val.cardBatchID === this.state.checkedBatchID) {
        cardsDict[key].checked = e.target.checked;
      }
    });

    this.setStateWithObject({
      cardsTableCheckedAll: e.target.checked,
      checkedBatchID: e.target.checked === false ? null : this.state.checkedBatchID,
      cardsTableCheckedDict: cardsDict,
    });
  };

  isCheckboxDisabled = (cardBatchID) => {
    if (this.state.checkedBatchID === null) {
      return false;
    } else if (this.state.checkedBatchID !== cardBatchID) {
      return true;
    }
  };

  focusTheseRows = (cardBatchID) => {
    if (this.state.checkedBatchID === cardBatchID) {
      return "bold-dark";
    }
    return "";
  };

  getAllCardIDsDict = (operation) => {
    const obj = {};
    if (this.state.cardsTableCheckedAll) {
      const filtersData = this.state.filtersData;
      filtersData.noPagination = true;
      filtersData.ignoredCardIDs = [];
      filtersData.cardBatchID = this.state.checkedBatchID;
      Object.entries(this.state.cardsTableCheckedDict).forEach(([key, val]) => {
        if (val.checked === false && this.state.checkedBatchID === val.cardBatchID) {
          filtersData.ignoredCardIDs.push(parseInt(key));
        }
      });

      return this.cancelable(apiFetchCardsWoClient(filtersData, (data) => {
        for (let i = 0; i < data.length; i++) {
          const v = data[i];
          obj[v.cardID] = {pan: v.pan};
        }
        operation(obj);

      }, (error) => {
        this.props.setErrors(error.errors);
      }));
    } else {
      Object.entries(this.state.cardsTableCheckedDict).forEach(([key, val]) => {
        if (val.checked === !this.state.cardsTableCheckedAll && this.state.checkedBatchID === val.cardBatchID) {
          obj[key] = {pan: val.pan};
        }
      });
      operation(obj);
    }
  }

  navToAddClient = (obj) => {
    this.navTo("/cards/without-client/add-client", undefined, obj);
  }

  addClientsToCards = () => {
    const show = this.state.checkedBatchID !== null;
    const classes = ["btn-edit", "sm-btn", "mr-2"];
    if (!show) {
      classes.push("btn-disabled");
    }

    return (
      <Tooltip id={"selectCardsToAddClientToCards"} trans={"selectCards"} showif={!show}>
        <Button className={classes.join(" ")} onClick={() => this.getAllCardIDsDict(this.navToAddClient)}>
          {this.trans("addClientToCard")}
        </Button>
      </Tooltip>
    );
  };

  getFilterDataState = (state) => {
    this.setStateWithObject({
      filtersData: state,
    });
  }

  render() {
    const {cards, cardsTableCheckedAll, cardsTableCheckedDict} = this.state;
    const {renderAlert, user, title, setErrors} = this.props;

    return (
      <div>
        {renderAlert()}
        <div className={"my-3 d-flex flex-row justify-content-start"}>
          <PageTitle title={title} isGrouped={true}/>
          {user.hasPermission(CAN_GENERATE_CARDS) && user.isGenerateCards() && (
            <Button className="ml-2 btn-add sm-btn" onClick={() => this.navTo("/cards/without-client/new")}>
              {this.trans("addNew")}
            </Button>
          )}
        </div>
        <InfiniteScroller
          id={"inf-cards-without-client"}
          next={this.fetchCardsWoClient}
          getState={this.getFilterDataState}
          additionalComponent={(onFilter) => (
            <>
              <CardsWithoutClientFilters
                onFilter={onFilter}
              />
              {user.hasPermission(CAN_GENERATE_CARDS) && (
                this.addClientsToCards()
              )}
              {user.hasPermission(CAN_EDIT_CARDS) && (
                <DeleteCardsWoClientAlert
                  setErrors={setErrors}
                  getAllCardIDsDict={this.getAllCardIDsDict}
                  disabled={this.state.checkedBatchID === null}
                />
              )}
            </>
          )}
        >
          <CTable>
            <thead>
              <tr>
                <th className="z-index-one">
                  <Input
                    checked={cardsTableCheckedAll} type="checkbox" id={"cards-wo-cli-all"}
                    disabled={this.state.checkedBatchID === null}
                    className="table-checkbox-margin-left" onChange={this.cardsTableCheckAllOnChange}
                  />
                </th>
                <th>{`${this.trans("pan")}`}</th>
                <th>{this.trans("nameOnCard")}</th>
                <th>{this.trans("infoFieldOnCard")}</th>
                <th>{this.trans("batch")}</th>
                <th>{this.trans("cardProductName")}</th>
                <th>{this.trans("validFrom")}</th>
                <th colSpan={2}>{this.trans("validTo")}</th>
              </tr>
            </thead>
            <tbody>
              {cards.map((card, i) => {

                const chooseCheckedForEachRow = () => {
                  if (cardsTableCheckedDict[card.cardID] !== undefined) {
                    return cardsTableCheckedDict[card.cardID].checked;
                  }
                  return false;
                };

                return (
                  <tr key={`cards-without-client-${i}`} className={this.focusTheseRows(card.cardBatchID)}>
                    <td>
                      {!card.activated && (
                        <Input
                          checked={chooseCheckedForEachRow()} type="checkbox" id={`card-wo-cli-${i}`}
                          disabled={this.isCheckboxDisabled(card.cardBatchID)}
                          className="table-checkbox-margin-left"
                          onChange={(e) => this.cardsTableCheckOnChange(e, card.cardID)}
                        />
                      )}
                    </td>
                    {this.cardLink(card)}
                    <td>{card.name || "-"}</td>
                    <td>{card.info || "-"}</td>
                    <td>{card.cardBatchID}</td>
                    <td>{card.cardProductName}</td>
                    <td>{card.validFrom || "-"}</td>
                    <td>{card.validTo || "-"}</td>
                    <td className="icon-wrapper-width">
                      {!card.webEnabled &&
                      <NavLink className="p-0 d-inline-block" href="#" onMouseUp={() => this.downloadPrinterCSV(card.cardBatchID)}>
                        <Icon icon={ic_file_download}/>
                      </NavLink>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </InfiniteScroller>
      </div>
    );
  }
}

export default withAlert(withRouter(withTranslation()(CardsWithoutClient)));
