import React from "react";
import {withTranslation} from "react-i18next";
import OverviewRow from "../../helper/overview_row";
import {PageTitle} from "../../helper/page_title";
import EditCardInfoModal from "../../modals/card/edit_card_info_modal";
import {cardState} from "../../helper/utils";
import {Button, Col, Row} from "reactstrap";
import {withRouter} from "react-router-dom";
import {apiDeleteCard, apiReopenCard} from "../../../api/fetch";
import CoreComponent from "../../core_component";
import {ConfirmationModal} from "../../helper/modal_window";
import {SUSER} from "../../helper/user_roles";
import ExtendCardExpiryTimeModal from "../../modals/card/extend_card_expiry_time_modal";
import {CAN_EDIT_CARDS, CAN_GENERATE_CARDS, CAN_GET_CUSTOMERS} from "../../helper/role_permissions";

class CardInfoContainer extends CoreComponent {
  state = {};

  deleteCardRequest = () => {
    this.cancelable(apiDeleteCard(this.props.card.id, () => {
      this.navTo("/cards", "closed");
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  reopenCardRequest = () => {
    this.cancelable(apiReopenCard(this.props.card.id, () => {
      this.navReload("reopened");
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  render() {
    const {card, cardID, user} = this.props;

    return (
      <Row className="mt-3">
        <Col xs={12} lg={9}>
          <div className="mb-3">
            <PageTitle isGrouped={true} title={card.pan}/>
          </div>
          <OverviewRow
            name={"pan"} value={card.pan}
            nWidth={4} vWidth={8}
          />
          <OverviewRow
            name={"nameOnCard"} value={card.name}
            nWidth={4} vWidth={8}
          />
          <OverviewRow
            name={"infoFieldOnCard"} value={card.info || "-"}
            nWidth={4} vWidth={8}
          />
          <OverviewRow
            name={"hiddenName"} value={card.hiddenName || "-"}
            nWidth={4} vWidth={8}
          />
          <OverviewRow
            user={user} roles={[SUSER]} link={`/customers/${card.customerID}/unit-history/${card.customerUnitID}`}
            disableLink={card.customerUnitClosed || !user.hasPermission(CAN_GET_CUSTOMERS)}
            name={"customerUnit"} value={card.customerUnitName}
            nWidth={4} vWidth={8}
          />
          <OverviewRow
            user={user} roles={[SUSER]} link={`/customers/${card.customerID}`}
            disableLink={card.customerClosed || !user.hasPermission(CAN_GET_CUSTOMERS)}
            name={"customerName"} value={card.customerName}
            nWidth={4} vWidth={8}
          />
          <OverviewRow
            name={"cardProductName"} value={card.cardProductName}
            nWidth={4} vWidth={8}
          />
          <OverviewRow
            name={"validTo"} value={card.validTo}
            nWidth={4} vWidth={8}
          />
          <OverviewRow
            name={"expiryDateOnCard"} value={card.expiryDateOnCard}
            nWidth={4} vWidth={8}
          />
          <OverviewRow
            name={"cardState"} value={this.trans(`cardStateTypes.${cardState.value[card.cardState]}`)}
            nWidth={4} vWidth={8}
          />
        </Col>
        <Col xs={12} lg={3}>
          {(card.cardState === cardState.name.active || card.cardState === cardState.name.future) && (
            <>
              {user.hasPermission(CAN_EDIT_CARDS) && (
                <div className="mb-2 text-right">
                  <EditCardInfoModal
                    card={card}
                    cardID={cardID}
                  />
                </div>
              )}
              {user.hasPermission(CAN_EDIT_CARDS) && (
                <div className="mb-2 text-right">
                  <ConfirmationModal
                    title={card.pan}
                    subtitle={this.trans("areYouSureToCloseThis.card")}
                    onSubmit={this.deleteCardRequest}
                    opener={(open) => (
                      <Button onMouseUp={open} className="btn-del sm-btn">
                        {this.trans("close")}
                      </Button>
                    )}
                  />
                </div>
              )}
            </>
          )}
          {(card.cardState === cardState.name.closed) && card.customerUnitExists && user.hasPermission(CAN_EDIT_CARDS) && (
            <div className="mb-2 text-right">
              <ConfirmationModal
                title={card.pan}
                subtitle={this.trans("areYouSureToReopenThis.card")}
                onSubmit={this.reopenCardRequest}
                opener={(open) => (
                  <Button onMouseUp={open} className="btn-edit sm-btn">
                    {this.trans("reopen")}
                  </Button>
                )}
              />
            </div>
          )}
          {card.canExtendExpiryDate && user.hasPermission(CAN_GENERATE_CARDS) && (
            <div className="mb-2 text-right">
              <ExtendCardExpiryTimeModal
                cardID={cardID}
                maxExtendByMonths={card.maxMonthsToExtend}
              />
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default withRouter(withTranslation()(CardInfoContainer));
