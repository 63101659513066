import React from "react";
import {Redirect, Route} from "react-router-dom";
import {withTranslation} from "react-i18next";
import CoreComponent from "../core_component";
import ErrorBoundary from "./error_boundary";
import {WithProviders} from "./context";
import {Col} from "reactstrap";
import Menu from "../helper/menu";

class _PrivateRoute extends CoreComponent {
  render() {
    const {user, logout, roles, permission, title, providers, menus, component: WrappedComponent, ...rest} = this.props;

    return WithProviders(() => (
      <Route {...rest} render={(prop) => {
        if (!user.isLoggedIn()) {
          return <Redirect to="/login"/>;
        }

        // Empty allowedRoles array means that route is accessible by all user roles
        if (user.isAllowedToAccess(roles, permission)) {
          return (
            <>
              <Col lg={2} className="menu-padding">
                <Menu menus={menus} user={user}/>
              </Col>
              <Col lg={10} className="panel py-3 page-padding">
                <ErrorBoundary>
                  <WrappedComponent logout={logout} user={user} title={this.trans(title || "")} {...prop}/>
                </ErrorBoundary>
              </Col>
            </>
          );
        }

        // If user is logged in, but does not have required role to access WrappedComponent
        // then redirect to root path - root path must be accessible by all user roles
        return <Redirect to="/"/>;
      }}/>
    ), providers);
  }
}

export const PrivateRoute = withTranslation()(_PrivateRoute);
