import React from "react";
import CoreComponent from "../../core_component";
import {apiFetchCustomerChanges} from "../../../api/fetch";
import {withTranslation} from "react-i18next";
import Paginator from "../../helper/paginator";
import {mapChangedColumns} from "../../helper/utils";
import {CTable} from "../../helper/ctable";

class CustomerChangesContainer extends CoreComponent {
  state = {
    customerChanges: [],
  };


  fetchCustomerChanges = (urlQuery) => {
    return this.cancelable(apiFetchCustomerChanges(this.props.customerID, urlQuery, (respData) => {
      this.setState({customerChanges: respData.list});
    }));
  };

  render() {
    return (
      <div>
        <Paginator
          next={this.fetchCustomerChanges}
          pageLimit={10}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("name")}</th>
                <th>{this.trans("identification")}</th>
                <th>{this.trans("address")}</th>
                <th>{this.trans("phone")}</th>
                <th>{this.trans("email")}</th>
                <th>{this.trans("billingEmail")}</th>
                <th>{this.trans("customerNo")}</th>
                <th>{this.trans("dateOfChange")}</th>
                <th>{this.trans("author")}</th>
              </tr>
            </thead>
            <tbody>
              {mapChangedColumns(this.state.customerChanges, (v, i, renderCol) => {
                return (
                  <tr key={`customer-${i}`}>
                    <td>{renderCol("name")}</td>
                    <td>{renderCol("identification")}</td>
                    <td>{renderCol("address")}</td>
                    <td>{renderCol("phone", {defaultValue: "-"})}</td>
                    <td>{renderCol("email")}</td>
                    <td>{renderCol("billingEmail", {defaultValue: "-"})}</td>
                    <td>{renderCol("customerNo", {defaultValue: "-"})}</td>
                    <td>{v.validFrom || "-"}</td>
                    <td>{v.authorEmail}</td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withTranslation()(CustomerChangesContainer);
