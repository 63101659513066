import {saveAs} from "file-saver";
import stringify from "csv-stringify";

export const exportCSV = (fileName, header, rows) => {
  return new Promise((resolve, reject) => {
    let content = [header];

    for (let i = 0; i < rows.length; i++) {
      content.push(rows[i]);
    }

    stringify(content, {delimiter: ";"}, (error, records) => {
      if (error) {
        reject(error);
        return;
      }

      saveAs(new Blob([records], {"type": "text/csv; charset=utf-8;"}), fileName);
      resolve();
    });
  });
};


/**
 * @param {array} rows
 * As JS takes objects (arrays) by reference, they are modified where defined
 * @param {function} trans
 * @param {array.<array>} filters
 * filters is an array of arrays, second array must always contain two elements
 */
export function printFilterDataToCSV(rows, trans, filters) {

  const filterTitle = trans("chosenFilters");
  const filterHeaders = [];
  const filterContent = [];

  for (let i = 0; i < filters.length; i++) {
    const value = filters[i][1];
    if (value !== undefined && value !== null && value !== "") {
      const header = filters[i][0];
      filterHeaders.push(trans(header));
      filterContent.push(value);
    }
  }

  if (filterHeaders.length !== 0) {
    rows.push([]);
    rows.push([filterTitle]);
    rows.push([...filterHeaders]);
    rows.push([...filterContent]);
  }
}
