import React from "react";
import {ADMIN, DISTRIBUTOR, SUSER} from "./component/helper/user_roles";
import {cardCreationType, operatorStatus} from "./component/helper/utils";
import CoreComponent from "./component/core_component";
import {apiGetOperatorUserOptions, apiGetSelfInfo} from "./api/fetch";
import {postRequest} from "./api/request";
import i18next from "i18next";
import {withRouter} from "react-router-dom";

export class User {
  constructor(id, email, role, previousLogin, cardCreType, permissions, companyAsCustomer, selectedAccountID, statusID) {
    this.id = id || 0;
    this.name = email || "";
    this.role = parseInt(role) || 0;
    this.previousLogin = previousLogin;
    this.cardCreationType = parseInt(cardCreType);
    this.permissions = permissions || [];
    this.companyAsCustomer = companyAsCustomer || false;
    this.selectedAccountID = selectedAccountID || 0;
    this.statusID = statusID || 0; // pole hetkel veel kasutuses
  }

  isLoggedIn = () => {
    return this.id !== 0 && this.name !== "" && this.role !== 0;
  };

  isActive = () => {
    return this.statusID === operatorStatus.name.active;
  };

  isAllowedToAccess = (roles, permission) => {
    // Kui pole vajaliku rolli/õiguseid defineeritud,
    // siis on kõigile lubatud
    if (!roles && !permission) {
      return true;
    }

    return this.hasPermission(permission) || this.isRole(roles);
  };

  isAdmin = () => {
    return this.role === ADMIN;
  };

  isDistributor = () => {
    return this.role === DISTRIBUTOR;
  };

  // is any system admin role, not company role.
  isAdminLike = () => {
    const r = this.role;
    return r === ADMIN || r === DISTRIBUTOR;
  };

  isAdminLikeOrSuper = () => {
    const r = this.role;
    return r === ADMIN || r === DISTRIBUTOR || r === SUSER;
  };

  isSuperuser = () => {
    return this.role === SUSER;
  };

  isGenerateCards = () => {
    return this.cardCreationType === cardCreationType.name.generate;
  };

  isImportCards = () => {
    return this.cardCreationType === cardCreationType.name.import;
  };

  hasAnyPermission = (...permissions) => {
    return permissions.filter((permission) => this.hasPermission(permission)).length > 0;
  };

  hasPermission = (permission) => {
    return (this.permissions || []).indexOf(permission) !== -1;
  };

  isRole = (roles) => {
    return (roles || []).indexOf(this.role) !== -1;
  };

  setName = (name = "") => {
    this.name = name;
  };

  setCardCreationType = (cardCreationType) => {
    this.cardCreationType = parseInt(cardCreationType);
  };
}

export function withUserData(WrappedComponent) {
  const UserDataWrappedComponent = class extends CoreComponent {
    state = {
      user: new User(),
      ready: false,
      accOptions: [],
    }

    userOptionsQueryd = false;

    unlistenPageChange = () => {};

    componentDidMount() {
      this.reloadUserData(window.localStorage.getItem("language"));

      this.unlistenPageChange = this.props.history.listen((() => {
        this.reloadUserData();
      }));
    }

    componentDidUpdate() {
      this.localGetOperatorUserOptions();
    }

    componentWillUnmount() {
      this.unlistenPageChange();
    }

    localGetOperatorUserOptions = () => {
      // ei küsi seda data pidevalt välja, vaid korra algul
      if (this.state.user.id !== 0 && !this.userOptionsQueryd) {
        this.userOptionsQueryd = true;
        this.cancelable(apiGetOperatorUserOptions(this.state.user.id, (data) => {
          this.state.user.setAccountOptions(data);
        }, err => {
          this.userOptionsQueryd = false;
          this.props.setErrors(err.errors);
        }));
      }
    };

    reloadUserData = (lang = null) => {
      this.setState({ready: false}, () => {
        const defer = (queryUserOpts) => {
          if (queryUserOpts) {
            this.userOptionsQueryd = false;
          }
          if (lang) {
            this.changeLanguage(lang, false, () => {
              this.setState({ready: true});
            });
          } else {
            this.setState({ready: true});
          }
        };

        // Kasutaja on sisselogitud
        if (window.localStorage.getItem("id")) {
          this.cancelable(apiGetSelfInfo((data) => {
            const user = new User(
              data.operatorID,
              data.email,
              data.role,
              window.localStorage.getItem("previousLogin"),
              data.cardCreationType,
              data.permissions,
              data.companyAsCustomer,
              data.selectedAccountID,
              data.statusID,
            );
            this.setState({user: user}, () => defer(data.dataChange));
          }, () => {
            this.logout();
          }));
        } else {
          defer();
        }
      });
    };

    changeLanguage = (lang, updateLocalStorage, callback) => {
      if (i18next.languages.includes(lang)) {
        i18next.changeLanguage(lang, callback).finally(() => {
          if (updateLocalStorage) {
            window.localStorage.setItem("language", lang);
          }
        });

        return;
      }

      if (callback) {
        callback();
      }
    };

    login = (data) => {
      const {id, email, role, previousLogin, cardCreationTypeID, permissions, selectedAccountID, statusID, language} = data;

      this.setState({
        user: new User(id, email, role, previousLogin, cardCreationTypeID, permissions, selectedAccountID, statusID),
      });

      window.localStorage.setItem("id", id);
      window.localStorage.setItem("name", email); // TODO miks email -> name
      if (i18next.languages.includes(language)) {
        i18next.changeLanguage(language).finally(() => {
          window.localStorage.setItem("language", language);
        });
      }

      if (previousLogin) {
        window.localStorage.setItem("previousLogin", previousLogin);
      }
    };

    logout = () => {
      window.localStorage.clear();

      postRequest("/api/logout").promise.finally(() => {
        window.location.pathname = "/login";
      });
    };

    setAccountOptions = (accOpts) => {
      this.setState({accOptions: accOpts});
    };

    getOperatorAccountOptions = () => {
      return this.state.accOptions.map((v) => {
        return {
          name: v.userName,
          value: v.userID,
        };
      });
    };

    hasMultipleAccounts = () => {
      return this.state.accOptions.length > 1;
    };

    render() {
      if (!this.state.ready) {
        return null;
      }

      this.state.user.setAccountOptions = this.setAccountOptions;
      this.state.user.getOperatorAccountOptions = this.getOperatorAccountOptions;
      this.state.user.hasMultipleAccounts = this.hasMultipleAccounts;

      return (
        <WrappedComponent
          user={this.state.user}
          login={(user) => this.login(user)}
          logout={() => this.logout()}
          changeLanguage={(lang) => this.changeLanguage(lang, true, this.reloadUserData)}
        />
      );
    }
  };

  return withRouter(UserDataWrappedComponent);
}
