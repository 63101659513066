import React from "react";

export class Orderable extends React.Component {
  state = {
    orderBy: {column: "", dir: ""},
    canOrder: true,
  };

  orderTimeout = null;

  componentWillUnmount() {
    clearTimeout(this.orderTimeout);
  }

  // setOrder sets the order of a column (ASC -> DESC -> no order)
  setOrder = (col) => {
    if (!this.state.canOrder) {
      return;
    }

    this.setState((prevState) => {
      if (col === prevState.orderBy.column) {
        // ASC -> DESC
        if (prevState.orderBy.dir === "ASC") {
          return {
            orderBy: {column: col, dir: "DESC"},
            canOrder: false,
          };
        }

        // DESC -> no order
        return {
          orderBy: {column: "", dir: ""},
          canOrder: false,
        };
      }

      // No order -> ASC
      return {
        orderBy: {column: col, dir: "ASC"},
        canOrder: false,
      };
    }, () => {
      const {orderBy} = this.state;
      const {setOrderCallback: cb} = this.props;

      if (cb && orderBy.column) {
        cb(`${orderBy.column} ${orderBy.dir}`).finally(this.assignTimeout);
      } else if (cb) {
        cb("").finally(this.assignTimeout);
      } else {
        this.assignTimeout();
      }
    });
  };

  assignTimeout = () => {
    this.orderTimeout = setTimeout(() => this.setState({canOrder: true}), 1000);
  };

  renderButtons = (asc, desc, col) => {
    const {canOrder} = this.state;

    return (
      <span className={`order-buttons ${canOrder ? "" : "in-progress"}`} onClick={() => this.setOrder(col)}>
        <i className={`button-asc ${asc ? "active" : ""}`}/>
        <i className={`button-desc ${desc ? "active" : ""}`}/>
      </span>
    );
  };

  renderOrderCol = (elem, col) => {
    const {orderBy} = this.state;

    if (orderBy.column === col) {
      return (
        <>{elem} {this.renderButtons(orderBy.dir === "ASC", orderBy.dir === "DESC", col)}</>
      );
    }

    return (
      <>{elem} {this.renderButtons(false, false, col)}</>
    );
  };

  render() {
    return this.props.children({
      renderOrderCol: this.renderOrderCol,
    });
  }
}
