import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import Select from "react-select";
import LabelWithError from "./label_with_error";
import {FormGroup} from "reactstrap";


class MultiSelectWrap extends CoreComponent {

  render() {
    let {options, required, onInputChange, placeholder, onChange, currentOption, label} = this.props;
    return (
      <FormGroup>
        {label && (
          <LabelWithError required={required}>
            {label}
          </LabelWithError>
        )}
        <Select
          onChange={onChange}
          onInputChange={onInputChange}
          options={options}
          placeholder={placeholder || this.trans("selectAndDots")}
          noOptionsMessage={() => this.trans("noResultsFound")}
          isMulti
        >
        </Select>

        {/*input field on ainult et simuleerida required välja kuna Select ei tundu seda toetavat*/}
        <input
          tabIndex={-1}
          value={currentOption.length > 0 ? currentOption[0] : ""}
          required={required}
          className="select-wrap-required-invisible-input"
          onChange={() => {}} // vältimaks warningu
        >
        </input>
      </FormGroup>
    )
    ;
  }
}

export default withTranslation()(MultiSelectWrap);
