import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";
import {Button} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import {withRouter} from "react-router-dom";
import {apiCreateOperator, apiGetOperatorUserOptions, apiGetRoles} from "../../../api/fetch";

class _NewUserModal extends CoreComponent {
  state = {
    roles: [],
  };

  companyID = this.props.match.params.id;

  newUserCaller = (toggleOpen) => {
    return (
      <Button className={"ml-3 btn-add sm-btn"} onClick={toggleOpen}>
        {this.trans("addNew")}
      </Button>
    );
  };

  newUserBody = (data, errors, onChange) => {
    const {roles} = this.state;

    return (
      <>
        <FormGroupWErr maxLength={254} str="email" type="email" err={errors.email} val={data.email} onChange={onChange}/>
        <FormGroupWErr
          str="roleNr"
          label="role"
          type="select"
          err={errors.roleNr}
          options={roles.map(role => ({value: role.id, name: role.name}))}
          onChange={onChange}
          val={data.roleNr}
        />
      </>
    );
  };

  newUserOnSubmit = (data) => {
    const reqData = {};
    Object.assign(reqData, data);
    if (this.props.companyID !== "company") {
      reqData.company = parseInt(this.props.companyID);
    }
    return this.cancelable(apiCreateOperator(reqData, resp => {
      if (this.props.user.name === reqData.email) {
        apiGetOperatorUserOptions(this.props.user.id, (data) => {
          this.props.user.setAccountOptions(data);
        }, err => {
          this.props.setErrors(err.errors);
        });
      }
      if (process.env.REACT_APP_MODE === "dev") {
        this.props.setMailLink(resp.link);
      }
    }));
  };

  newUserOnOpen = () => {
    if (this.state.roles.length === 0) {
      return new Promise((resolve, reject) => {
        const reqData = {};

        if (this.companyID && parseInt(this.companyID)) {
          reqData["companyID"] = parseInt(this.companyID);
        }

        this.cancelable(apiGetRoles(reqData, resp => {
          this.setState({roles: resp});
          resolve();
        }, err => {
          reject(err);
        }));
      });
    }
  };

  render() {
    return (
      <>
        <ModalWindow
          headerText={this.trans("newUser")}
          caller={this.newUserCaller}
          body={this.newUserBody}
          onSubmit={this.newUserOnSubmit}
          onOpen={this.newUserOnOpen}
        />
      </>
    );
  }
}

export const NewUserModal = withRouter(withTranslation()(_NewUserModal));
