import React from "react";
import CoreComponent from "../../core_component";
import {Button} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import Icon from "react-icons-kit";
import {ic_delete} from "react-icons-kit/md/ic_delete";
import MultiSelectWrap from "../../helper/multi_select_wrap";
import {apiCreateCompanyWebApi, apiGetCompanyWebApiAllPermissions} from "../../../api/fetch";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";

class NewWebApiModal extends CoreComponent {
  state = {
    formIPAddress: [{ipAddress: "", comment: ""}],
    availablePermissions: [],
    permission: [],
  };

  newWebApiCaller = (toggleOpen) => {
    return (
      <Button className={"ml-3 btn-add sm-btn"} onClick={toggleOpen}>
        {this.trans("addNew")}
      </Button>
    );
  };

  handleIPChange(i, e) {
    const formIPAddress = this.state.formIPAddress;
    formIPAddress[i][e.target.name] = e.target.value;
    this.setState({formIPAddress});
  }

  removeFormFields(i) {
    const formIPAddress = this.state.formIPAddress;
    formIPAddress.splice(i, 1);
    this.setState({formIPAddress});
  }

  addFormFields() {
    this.setState(({
      formIPAddress: [...this.state.formIPAddress, {ipAddress: "", comment: ""}]
    }));
  }

  handlePermChange(e) {
    const ids = [];
    if (e !== null) {
      for (const perm of e) {
        ids.push(perm.value);
      }
    }
    this.setState({permission: ids});
  }

  newWebApi = (data, errors, onChange) => {
    return (
      <>
        <FormGroupWErr str="name" maxLength={128} err={errors.name} val={data.name} onChange={onChange}/>

        <MultiSelectWrap
          options={this.state.availablePermissions.map((ct) => (
            {value: ct.id, label: this.trans(`webApiPermission.${ct.name}`)}
          ))}
          placeholder={this.trans("selectAndDots")}
          onChange={e => this.handlePermChange(e)}
          currentOption={this.state.permission}
          required={true}
          label={this.trans("permissions")}
        />
        {this.state.formIPAddress.map((element, ix) => {
          return (
            <div key={`api-ip-addr-${ix}`} className="panel py-2 px-1 my-2 position-relative">
              {this.state.formIPAddress.length > 1 && (
                <Icon
                  icon={ic_delete} size={20}
                  onClick={() => this.removeFormFields(ix)}
                  className="position-absolute cursor-pointer" style={{right: "5px", top: "5px"}}
                />
              )}
              <div className="px-1">
                <FormGroupWErr
                  str="ipAddress" maxLength={64} err={errors.ipAddress}
                  val={element.ipAddress || ""} onChange={e => this.handleIPChange(ix, e)}
                />
              </div>
              <div className="px-1">
                <FormGroupWErr
                  str="comment" maxLength={4096} err={errors.comment}
                  val={element.comment || ""} onChange={e => this.handleIPChange(ix, e)} required={false}
                />
              </div>
            </div>
          );
        })}

        <Button className="btn-edit sm-btn" onClick={() => this.addFormFields()}>{this.trans("addNewIP")}</Button>
      </>
    )
    ;
  };

  newWebApiOnSubmit = (data) => {
    const postData = {};
    Object.assign(postData, data);
    postData.ipAddresses = this.state.formIPAddress;
    postData.permissions = this.state.permission;

    return this.cancelable(apiCreateCompanyWebApi(postData));
  };

  fetchPermissions = () => {
    return new Promise((resolve, reject) => {
      this.cancelOnUnmount(apiGetCompanyWebApiAllPermissions((respData) => {
        this.setState({availablePermissions: respData});
        resolve();
      }, (err) => {
        reject(err);
      }));
    });
  }

  render() {
    return (
      <>
        <ModalWindow
          headerText={this.trans("newWebApi")}
          caller={this.newWebApiCaller}
          body={this.newWebApi}
          onSubmit={this.newWebApiOnSubmit}
          onOpen={this.fetchPermissions}
        />
      </>
    );
  }
}

export default withRouter(withTranslation()(NewWebApiModal));
