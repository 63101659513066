import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import FormGroupWErr from "../../helper/form_group_w_err";
import {putRequest} from "../../../api/request";
import {NavLink} from "reactstrap";
import Icon from "react-icons-kit";
import {ic_mode_edit} from "react-icons-kit/md";
import ModalWindow from "../../helper/modal_window";

class EditTerminalModal extends CoreComponent {

  editTerminalBody = (data, errors, onChange, setDisabled) => {
    return (
      <>
        <FormGroupWErr str="terminalID" err={errors.terminalID} val={data.terminalID} maxLength={16} onChange={onChange} disabled={setDisabled}/>
        <FormGroupWErr str="merchantID" err={errors.merchantID} val={data.merchantID} maxLength={16} onChange={onChange} disabled={setDisabled}/>
        <FormGroupWErr str="payDesk" err={errors.payDesk} val={data.payDesk} maxLength={16} onChange={onChange} disabled={setDisabled} required={false}/>
        <FormGroupWErr str="merchantRegNo" err={errors.merchantRegNo} val={data.merchantRegNo} maxLength={16} onChange={onChange} disabled={setDisabled} required={false}/>
      </>
    );
  };

  editTerminalOnSubmit = (data, terminalID) => {
    return new Promise((resolve, reject) => {
      this.cancelable(putRequest(`/api/terminal-owners/${this.props.terminalOwnerID}/terminals/${terminalID}`, data)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  editTerminalCaller = (toggleOpen, terminal, iconType) => {
    return(
      <NavLink className="p-0 d-inline-block" href="#" onClick={(ev) => toggleOpen(ev, terminal)}>
        <Icon icon={iconType}/>
      </NavLink>
    );
  };

  render() {
    const {terminal} = this.props;
    return (
      <>
        <ModalWindow
          headerText={this.trans("editTerminal")}
          body={this.editTerminalBody}
          caller={(toggleOpen) => this.editTerminalCaller(toggleOpen, terminal, ic_mode_edit)}
          onSubmit={(data) => this.editTerminalOnSubmit(data, terminal.id)}
        />
      </>
    );
  }

}

export default withTranslation()(EditTerminalModal);
