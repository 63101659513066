import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";
import {ic_info_outline, ic_mode_edit} from "react-icons-kit/md";
import FormGroupWErr from "../../helper/form_group_w_err";
import {putRequest} from "../../../api/request";
import {FormGroup, Input, Label, NavLink} from "reactstrap";
import Icon from "react-icons-kit";
import Tooltip from "../../helper/tooltip";

class EditCustomerUnitModal extends CoreComponent {

  unitsOperBodyEdit = (data, errors, onChange, setDisabled) => {
    const {val, user} = this.props;

    return (
      <>
        <FormGroupWErr maxLength={90} str={"name"} err={errors.name} val={data.name} onChange={onChange} disabled={setDisabled}/>
        <FormGroupWErr maxLength={500} str={"comment"} err={errors.comment} val={data.comment} onChange={onChange} required={false} disabled={setDisabled}/>
        <FormGroup check>
          <Label>
            <Input
              type="checkbox" id="defaultCustomerUnit"
              name="defaultCustomerUnit"
              checked={data.defaultCustomerUnit}
              onChange={onChange}
              disabled={user.companyAsCustomer && !val.defaultCustomerUnit}
            />
            {this.trans("defaultCustomerUnit")}
          </Label>
          <Tooltip
            trans={(user.companyAsCustomer && !val.defaultCustomerUnit) ? "toSetThisUnitAsDefaultUncheckCurrentMsg" : "defaultCustomerUnitIsUniqueMsg"}
            showif={true}
          >
            <Icon icon={ic_info_outline} size={20}/>
          </Tooltip>
        </FormGroup>
      </>
    );
  };

  unitsOperOnSubmit = (data) => {
    let optimizedReq = {};
    optimizedReq["name"] = data.name;
    optimizedReq["comment"] = data.comment;
    optimizedReq["defaultCustomerUnit"] = data.defaultCustomerUnit;

    return new Promise((resolve, reject) => {
      this.cancelable(putRequest(`/api/customers/${this.props.customerID}/units/${data.customerUnitID}`, optimizedReq)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  unitsOperCaller = (toggleOpen, customerUnit, iconType) => {
    return(
      <td className="icon-wrapper-width">
        <NavLink className="p-0 d-inline-block" href="#" onClick={(ev) => toggleOpen(ev, customerUnit)}>
          <Icon icon={iconType}/>
        </NavLink>
      </td>
    );
  };


  render() {
    const {val} = this.props;
    return (
      <>
        <ModalWindow
          headerText={this.trans("editCustomerUnit")}
          body={this.unitsOperBodyEdit}
          caller={(toggleOpen) => this.unitsOperCaller(toggleOpen, val, ic_mode_edit)}
          onSubmit={this.unitsOperOnSubmit}
        />
      </>
    );
  }

}

export default withTranslation()(EditCustomerUnitModal);
