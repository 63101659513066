import React from "react";
import CoreComponent from "../core_component";
import CoreForm from "./core_form";
import {withTranslation} from "react-i18next";
import {Button, Col, Collapse, FormGroup, Row} from "reactstrap";
import {responsiveBreakpoint} from "./utils";

class FiltersContainer extends CoreComponent {
  state = {
    data: this.props.initData || {},
    show: window.innerWidth >= responsiveBreakpoint
  };

  formRef = React.createRef();

  clearFilters = () => {
    this.setStateValue("data", this.props.initData || {}, () => this.formRef.current.dispatchEvent(new Event("submit")));
  };

  onChange = (ev) => {
    let v = ev.target.value;

    if (typeof ev.target.value === "number" || ev.target.type === "number") {
      v = parseInt(ev.target.value, 10);
    } else if (ev.target.type === "checkbox") {
      v = ev.target.checked;
    }

    this.setStateValue("data", Object.assign({}, this.state.data, {
      [ev.target.name]: v,
    }));
  };

  onChangeInt = (ev) => { // DEPRECATED, use onChange ; TODO remove onChangeInt
    this.setStateValue("data", Object.assign({}, this.state.data, {
      [ev.target.name]: parseInt(ev.target.value, 10)
    }));
  };

  renderButtons = (data, extraButtons) => {
    return (
      <div className="ml-2-children my-1-children">
        <Button className="sm-btn" onClick={(ev) => this.clearFilters(ev)}>
          {this.trans("clear")}
        </Button>
        {extraButtons && extraButtons(data)}
        <Button className="btn-filtr sm-btn">
          {this.trans("filter")}
        </Button>
      </div>
    );
  };

  renderFullContainer = (data, renderFilters, extraButtons) => {
    return (
      <div className="filter-margin">
        <Row className="p-3 sec-panel">
          {renderFilters(data, this.onChange, this.onChangeInt)}
          <Col className="d-flex justify-content-end align-items-end">
            <FormGroup className="ml-2-children">
              {this.renderButtons(data, extraButtons)}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  renderMinifiedContainer = (data, renderFilters, extraButtons) => {
    return (
      <div className="d-flex justify-content-end align-items-center ml-2-children">
        {renderFilters(data, this.onChange, this.onChangeInt)}
        {this.renderButtons(data, extraButtons)}
      </div>
    );
  };

  render = () => {
    const {data, show} = this.state;
    const {renderFilters, onFilter, extraButtons, canClose, minified} = this.props;

    return (
      <div className="mb-3">
        {canClose && (
          <div className="pb-3">
            <Button color="link" className="px-0" onClick={() => this.setStateValue("show", !this.state.show)}>
              <span className="filters-toggler mr-2">
                {this.trans(show ? "hideFilters" : "showFilters")}
              </span>
              <span>
                <i className={`arrow ${show ? "up" : "down"}`}/>
              </span>
            </Button>
          </div>
        )}
        <Collapse isOpen={show || !canClose}>
          <CoreForm onSubmit={() => onFilter(data)} innerRef={this.formRef}>
            {minified ?
              this.renderMinifiedContainer(data, renderFilters, extraButtons) :
              this.renderFullContainer(data, renderFilters, extraButtons)
            }
          </CoreForm>
        </Collapse>
      </div>
    );
  };
}

FiltersContainer.defaultProps = {
  canClose: true,
  minified: false,
};

export default withTranslation()(FiltersContainer);
