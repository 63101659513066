import {Table} from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

export const CTable = (props) => {
  const {children, size, ...restProps} = props;

  return (
    <div className="overflow-auto">
      <Table size={size || "sm"} {...restProps}>
        {children}
      </Table>
    </div>
  );
};

CTable.propTypes = {
  children: PropTypes.element.isRequired,
  size: PropTypes.string,
};
