import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {NavLink} from "reactstrap";
import {apifetchPrinterCSV, apifetchPrinterCSVsInfo} from "../../../api/fetch";
import {ic_file_download} from "react-icons-kit/md";
import Icon from "react-icons-kit";
import FileName from "../../helper/fileutil";
import Paginator from "../../helper/paginator";
import {saveAs} from "file-saver";
import {CTable} from "../../helper/ctable";

class PrinterFileContainer extends CoreComponent {
  state = {
    csvInfo: []
  };

  getPrinterCSVInfo = (query) => {
    return this.cancelable(apifetchPrinterCSVsInfo(this.props.customerID, query, (data) => {
      this.setState({
        csvInfo: data.list,
      });
    }));
  };

  downloadPrinterCSV = (cardBatchID) => {
    this.cancelable(apifetchPrinterCSV(cardBatchID, (resp) => {
      const blob = new Blob([resp], {type: "text/csv; charset=utf-8;"});
      const fileName = new FileName(cardBatchID.toString(), "csv").withTimestamp(new Date());
      saveAs(blob, fileName.getFullName());
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  render() {
    const {csvInfo} = this.state;

    return (
      <div className="mt-2">
        <Paginator
          next={this.getPrinterCSVInfo}
          pageLimit={10}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("batch")}</th>
                <th>{this.trans("date")}</th>
                <th>{this.trans("noOfCards")}</th>
                <th colSpan="2">{this.trans("author")}</th>
              </tr>
            </thead>
            <tbody>
              {csvInfo.map((val, i) => {
                return (
                  <tr key={`cPrintFile-${i}`}>
                    <td>{val.cardBatchID}</td>
                    <td>{val.dateIn}</td>
                    <td>{val.cardCount}</td>
                    <td>{val.authorName}</td>
                    <td className="icon-wrapper-width">
                      {!val.webEnabled &&
                      <NavLink className="p-0 d-inline-block" href="#" onMouseUp={() => this.downloadPrinterCSV(val.cardBatchID)}>
                        <Icon icon={ic_file_download}/>
                      </NavLink>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withTranslation()(PrinterFileContainer);
