import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {Button} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import {DISTRIBUTOR} from "../../helper/user_roles";
import {putRequest} from "../../../api/request";
import ModalWindow from "../../helper/modal_window";
import * as utils from "../../helper/utils";
import {cardCreationType, isObjDataMemberDefined} from "../../helper/utils";
import TextInputWithCheckbox from "../../helper/text_input_w_checkbox";
import SelectInput from "../../helper/select_input";
import {apiFetchAvailableDistributors} from "../../../api/fetch";
import FormGroupCheckbox from "../../helper/form_group_checkbox";

class EditCompanyModal extends CoreComponent {

  editCompanyCaller = (toggleOpen) => {
    return (
      <Button className={"ml-3 btn-edit sm-btn"} onClick={(ev) => toggleOpen(ev, this.props.company)}>
        {this.trans("edit")}
      </Button>
    );
  };

  editCompanyBody = (data, errors, onChange) => {
    const {company, user} = this.props;

    return (
      <>
        <FormGroupWErr str={"name"} err={errors.name} val={data.name} onChange={onChange} disabled={this.props.user.role < DISTRIBUTOR}/>
        <FormGroupWErr str={"identification"} err={errors.identification} val={data.identification} onChange={onChange}
          disabled={this.props.user.role < DISTRIBUTOR}/>
        <FormGroupWErr maxLength={512} str={"address"} err={errors.address} val={data.address} onChange={onChange}/>
        <FormGroupWErr maxLength={64} str={"phone"} err={errors.phone} val={data.phone} onChange={onChange}/>
        <FormGroupWErr maxLength={254} str={"email"} type={"email"} err={errors.email} val={data.email} onChange={onChange}/>
        <TextInputWithCheckbox
          str={"billingEmail"}
          checked={isObjDataMemberDefined(company, "billingEmail")}
          type="email"
          checkboxID={"billingEmailCB"}
          value={data.billingEmail || ""}
          onChange={onChange}
          maxLength={254}
          error={errors.billingEmail}
        />
        {(user.isAdmin() || user.isDistributor()) &&
        <FormGroupWErr
          label="cardCreationType"
          str="cardCreationTypeID"
          type="select"
          options={utils.getTypesOptions(cardCreationType, "cardCreationTypeOptions", this.trans)}
          onChange={onChange}
          val={data.cardCreationTypeID}
          required={true}
        />
        }
        {user.isAdmin() && (
          <SelectInput
            str="distributorID"
            label="distributor"
            err={errors.distributorID}
            val={data.distributorID}
            onChange={onChange}
            onInputChange={apiFetchAvailableDistributors}
            fetchOnMount={true}
            required={false}
          />
        )}
        <FormGroupCheckbox label={"allowPartialPayments"} str={"allowPartialPayments"} val={data.allowPartialPayments} onChange={onChange}/>
      </>
    );
  };

  editCompanyOnSubmit = (data) => {
    const reqData = Object.assign({}, data);
    return new Promise((resolve, reject) => {
      this.cancelable(putRequest(`/api/companies/${this.props.companyID}`, reqData)).then(() => {
        resolve();
        if (window.localStorage.getItem("cardCreationType") !== null) {
          this.props.user.setCardCreationType(data.cardCreationTypeID);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  };

  render() {
    return (
      <>
        <ModalWindow
          headerText={this.trans("editCompany")}
          caller={this.editCompanyCaller}
          body={this.editCompanyBody}
          onSubmit={this.editCompanyOnSubmit}
        />
      </>
    );
  }
}

export default withTranslation()(EditCompanyModal);
