import React from "react";
import {withTranslation} from "react-i18next";
import CoreComponent from "../../core_component";
import {putRequest} from "../../../api/request";
import {centsToEuros, centsToEurosToStr, eurosToCents} from "../../helper/utils";
import {ic_delete, ic_mode_edit} from "react-icons-kit/md";
import {FormGroup, Input, NavLink} from "reactstrap";
import Icon from "react-icons-kit/index";
import FormGroupWErr from "../../helper/form_group_w_err";
import ModalWindow from "../../helper/modal_window";
import LabelWithError from "../../helper/label_with_error";
import {apiFetchCardLimitsTotalBalance} from "../../../api/fetch";

class EditCardTransactionModal extends CoreComponent {
  state = {};

  fetchMaxTransactionEditAmount = (trans) => {
    return new Promise((resolve, reject) => {
      this.cancelable(apiFetchCardLimitsTotalBalance(this.props.cardID, {id: trans.id}, (data) => {
        const {countBalance, amountBalance} = data;

        if (countBalance === null || countBalance > 0 || (countBalance === 0 && trans.amount > 0)) {
          if (amountBalance === null) {
            resolve({maxAmount: null});
            return;
          }

          const amount = amountBalance + trans.amount;
          if (amount >= 0) {
            resolve({maxAmount: amount});
            return;
          }
        }

        resolve({maxAmount: 0});
      }, (error) => {
        reject(error);
      }));
    });
  };

  editTransaction = (trans) => {
    const reqData = {
      amount: eurosToCents(trans.amount),
      comment: trans.comment,
      showCommentInReports: trans.showCommentInReports,
    };

    return new Promise((resolve, reject) => {
      this.cancelable(putRequest(`/api/transactions/${trans.id}`, reqData)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  opener = (trans, isCancel, toggleOpen) => {
    const onModalOpen = (ev) => {
      const initData = Object.assign({}, trans, {
        currentAmount: trans.amount,
        amount: null,
        comment: "",
      });

      toggleOpen(ev, initData);
    };

    return (
      <NavLink className="p-0 d-inline-block" href="#" onClick={onModalOpen}>
        <Icon icon={isCancel ? ic_delete : ic_mode_edit}/>
      </NavLink>
    );
  };

  body = (isCancel, trans, errors, onChange, setDisabled) => {
    let amtDisabled = setDisabled;
    let amtPlaceHolder = centsToEurosToStr(trans.currentAmount);
    let amtMaxValue = this.maxTransactionAmount;

    if (isCancel) {
      amtDisabled = true;
      amtPlaceHolder = `${amtPlaceHolder} (${this.trans("thisAmountWillBeNulled")})`;
    } else if (trans.maxAmount !== null) {
      amtPlaceHolder = `${amtPlaceHolder} (${this.trans("maxAmount")}: ${centsToEurosToStr(trans.maxAmount)})`;
      amtMaxValue = centsToEuros(trans.maxAmount);
    }

    return (
      <>
        <FormGroupWErr
          str={"amount"} err={errors.amount}
          val={trans.amount} type={"number"}
          min={"0"} max={amtMaxValue} step={"0.01"}
          placeholder={`${amtPlaceHolder}`}
          onChange={onChange} disabled={amtDisabled}
        />
        <FormGroupWErr
          str={"transactionDate"} err={errors.dateIn}
          val={trans.dateIn} disabled={true}
        />
        <FormGroupWErr
          str={"payee"} err={errors.terminal}
          val={trans.payee} disabled={true}
        />
        <FormGroupWErr
          str={"comment"} err={errors.comment} maxLength={500}
          val={trans.comment} onChange={onChange} disabled={setDisabled}
        />
        <FormGroup check>
          <Input
            type={"checkbox"} id={"showCommentInReports"} name={"showCommentInReports"}
            checked={trans.showCommentInReports} onChange={onChange}
          />
          <LabelWithError for={"showCommentInReports"}>
            {this.trans("showCommentInReports")}
          </LabelWithError>
        </FormGroup>
        <FormGroupWErr
          str={"operator"} err={errors.operator}
          val={trans.operator} disabled={true}
        />
      </>
    );
  };

  render() {
    const {transaction, isCancel} = this.props;

    return (
      <ModalWindow
        headerText={this.trans(isCancel ? "cancelTransaction" : "editTransaction")}
        caller={(...args) => this.opener(transaction, isCancel, ...args)}
        body={(...args) => this.body(isCancel, ...args)}
        onSubmit={this.editTransaction}
        onOpen={() => this.fetchMaxTransactionEditAmount(transaction)}
      />
    );
  }
}

export default withTranslation()(EditCardTransactionModal);
