import CoreComponent from "../../core_component";
import {apiCloseCustomerDiscount, apiGetCustomerDiscounts} from "../../../api/fetch";
import React from "react";
import {Input, Label, NavLink} from "reactstrap";
import {withTranslation} from "react-i18next";
import Paginator from "../../helper/paginator";
import {displayAsMonths} from "../../helper/utils";
import {ConfirmationModal} from "../../helper/modal_window";
import Icon from "react-icons-kit";
import {ic_delete} from "react-icons-kit/md";
import {withRouter} from "react-router-dom/cjs/react-router-dom";
import OperCustomerDiscountModal from "../../modals/customer/oper_customer_discount_modal";
import {CTable} from "../../helper/ctable";


class CustomerDiscountContainer extends CoreComponent {

  state = {
    customerDiscounts: [],
  }

  localGetCustomerDiscount = (data) => {
    return this.cancelable(apiGetCustomerDiscounts(this.props.customerID, data, (data) => {
      this.setState({
        customerDiscounts: data.list,
      });
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  }

  onChangeShowOldDiscounts = (e) => {
    if (e.target.checked) {
      this.localGetCustomerDiscount({showOldDiscounts: true});
    } else {
      this.localGetCustomerDiscount({showOldDiscounts: false});
    }
  };

  localCloseCustomerDiscount = (customerID, discountID) => {
    return this.cancelable(apiCloseCustomerDiscount(customerID, discountID, () => {
      this.navReload("closed");
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  render() {
    const {customerID} = this.props;
    const {customerDiscounts} = this.state;

    return (
      <>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <OperCustomerDiscountModal customerID={customerID} isNew={true}/>
          <span className="mr-3">
            <Input
              type="checkbox"
              id={"showOldDiscounts"}
              onChange={this.onChangeShowOldDiscounts}
            />
            <Label className="m-0" for="showOldDiscounts">{this.trans("showOldDiscounts")}</Label>
          </span>
        </div>
        <Paginator
          next={this.localGetCustomerDiscount}
          pageLimit={10}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("discount")}</th>
                <th>{this.trans("discountFrom")}</th>
                <th>{this.trans("discountTo")}</th>
                <th>{this.trans("author")}</th>
                <th>{this.trans("comment")}</th>
                <th colSpan={2}></th>
              </tr>
            </thead>
            <tbody>
              {customerDiscounts.map((v, i) => {
                return (
                  <tr key={`cDiscounts-${i}`}>
                    <td>{v.discountPercentage}%</td>
                    <td>{displayAsMonths(v.discountFrom)}</td>
                    <td>{displayAsMonths(v.discountTo)}</td>
                    <td>{v.author.Email}</td>
                    <td>{v.comment}</td>
                    <OperCustomerDiscountModal
                      discount={v}
                    />
                    <ConfirmationModal
                      title={`${v.discountPercentage}% (${displayAsMonths(v.discountFrom)} - ${displayAsMonths(v.discountTo)})`}
                      subtitle={this.trans("areYouSureToCloseThis.discount")}
                      onSubmit={() => this.localCloseCustomerDiscount(v.customerID, v.customerDiscountID)}
                      opener={(open) => (
                        <td className="icon-wrapper-width">
                          <NavLink className="p-0 d-inline-block" href="#" onClick={open}>
                            <Icon icon={ic_delete}/>
                          </NavLink>
                        </td>
                      )}
                    />
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </>
    );
  }
}

export default withRouter(withTranslation()(CustomerDiscountContainer));
