import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import {Input} from "reactstrap";

class AInput extends CoreComponent {

  render() {
    const copiedProps = Object.assign({}, this.props);
    delete copiedProps.tReady; // kaotab warningud ära console-ist
    delete copiedProps.t;
    return (
      <Input
        {...copiedProps}
        className="apple-input"
      />
    );
  }

}

export default withTranslation()(AInput);
