// Mirrored in model/permission.go

export const CAN_GET_CARDS = 300;
export const CAN_EDIT_CARDS = 301;
export const CAN_GENERATE_CARDS = 302;
export const CAN_EDIT_CARD_LIMITS = 303;
export const CAN_ACTIVATE_CARDS = 304;

export const CAN_GET_CUSTOMERS = 400;
export const CAN_EDIT_CUSTOMERS = 401;

export const CAN_GET_TERMINALS = 500;
export const CAN_EDIT_TERMINALS = 501;

export const CAN_GET_TRANSACTIONS = 800;
export const CAN_EDIT_TRANSACTIONS = 801;

export const CAN_GET_WEB_API = 900;
export const CAN_EDIT_WEB_API = 901;

export const CAN_GET_STATISTICS = 3000;

export const CAN_GET_REPORTS = 5000;

// Kui tekib ülesse poole õiguseid juurde, siis lisa siia kah :)
export const PERMISSIONS_MAP = {
  [CAN_GET_CARDS]: "CAN_GET_CARDS",
  [CAN_EDIT_CARDS]: "CAN_EDIT_CARDS",
  [CAN_GENERATE_CARDS]: "CAN_GENERATE_CARDS",
  [CAN_EDIT_CARD_LIMITS]: "CAN_EDIT_CARD_LIMITS",
  [CAN_ACTIVATE_CARDS]: "CAN_ACTIVATE_CARDS",
  [CAN_GET_CUSTOMERS]: "CAN_GET_CUSTOMERS",
  [CAN_EDIT_CUSTOMERS]: "CAN_EDIT_CUSTOMERS",
  [CAN_GET_TERMINALS]: "CAN_GET_TERMINALS",
  [CAN_EDIT_TERMINALS]: "CAN_EDIT_TERMINALS",
  [CAN_GET_TRANSACTIONS]: "CAN_GET_TRANSACTIONS",
  [CAN_EDIT_TRANSACTIONS]: "CAN_EDIT_TRANSACTIONS",
  [CAN_GET_STATISTICS]: "CAN_GET_STATISTICS",
  [CAN_GET_REPORTS]: "CAN_GET_REPORTS",
  [CAN_GET_WEB_API]: "CAN_GET_WEB_API",
  [CAN_EDIT_WEB_API]: "CAN_EDIT_WEB_API",
};

// [Õigus mida on vaja]: [õigus(ed), milleks eespool mainitud õigust on vaja]
export const PERMISSION_REQUIREMENT_MAP = {
  [CAN_GET_CARDS]: [CAN_EDIT_CARDS, CAN_GENERATE_CARDS, CAN_EDIT_CARD_LIMITS, CAN_EDIT_TRANSACTIONS],
  [CAN_GET_TERMINALS]: [CAN_EDIT_TERMINALS],
  [CAN_GET_CUSTOMERS]: [CAN_EDIT_CUSTOMERS],
  [CAN_GET_TRANSACTIONS]: [CAN_EDIT_TRANSACTIONS],
  [CAN_GET_WEB_API]: [CAN_EDIT_WEB_API],
};
