import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button, Input, Label} from "reactstrap";
import Icon from "react-icons-kit";
import {ic_format_list_numbered, ic_radio_button_checked, ic_radio_button_unchecked, ic_search} from "react-icons-kit/md";
import CollapsibleTableRow from "../../helper/collapsible_table_row";
import {cardState, centsToEurosToStr, isCardUsed, minSearchStrLength} from "../../helper/utils";
import {apiFetchActiveCardsReport, apiFetchCardTransactions, apiFetchCustomerCards,} from "../../../api/fetch";
import OverviewContainer from "../../helper/overview_container";
import TLink from "../../helper/table_link";
import {FetchButton} from "../../helper/fetch_button";
import moment from "moment";
import {saveAs} from "file-saver";
import {Orderable} from "../../helper/orderable";
import Paginator from "../../helper/paginator";
import CardBalancesContainer, {CardBalancesTableColumn} from "../card/card_balances_container";
import Tooltip from "../../helper/tooltip";
import {CAN_GENERATE_CARDS, CAN_GET_CUSTOMERS, CAN_GET_TRANSACTIONS} from "../../helper/role_permissions";
import {CTable} from "../../helper/ctable";

class CustomerCardsContainer extends CoreComponent {
  state = {
    cards: [],
    showAlsoNotActiveCards: false,
    searchCards: "",
    cardCount: 0,
  };

  searchTimeout = 0;

  fetchCards = (filtersData) => {
    return this.cancelable(apiFetchCustomerCards(this.props.customerID, filtersData, (data) => {
      this.setState({
        cards: data.list,
        cardCount: data.count,
      });
    }));
  };

  onChangeShowNotActiveCards = (onFilter) => {
    const {searchCards, showAlsoNotActiveCards} = this.state;
    this.setState({
      showAlsoNotActiveCards: !showAlsoNotActiveCards,
    }, () => {
      onFilter({
        search: searchCards,
        showAlsoNotActiveCards: !showAlsoNotActiveCards,
      });
    });
  };

  searchCards = (e, onFilter) => {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    const v = e.target.value;

    const setTheState = (searchCards = "") => {
      this.setState({
        searchCards: searchCards,
      }, () => onFilter({
        search: this.state.searchCards,
        showAlsoNotActiveCards: this.state.showAlsoNotActiveCards,
      }));
    };

    this.searchTimeout = setTimeout(() => {
      if (v.length >= minSearchStrLength) {
        setTheState(v);
      } else if (v.length === 0) {
        setTheState();
      }
    }, 500);
    this.addTimeout(this.searchTimeout);
  };

  fetchCardTransactions = (cardId) => {
    return this.cancelable(apiFetchCardTransactions(cardId, {limit: 5}));
  };

  searchComponent = (onChange) => {
    return (
      <input className="apple-input-min" onChange={onChange} placeholder={this.trans("cardNo") + ", " + this.trans("unit") +
      ", " + this.trans("product")}/>
    );
  };

  renderCardTransactions = (transactions) => {
    if (transactions && Array.isArray(transactions.list)) {
      return (
        <div className="p-2">
          <h5>{this.trans("recentTransactions")}</h5>
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("date")}</th>
                <th className="currency-col">{this.trans("amount")}</th>
                <th>{this.trans("payee")}</th>
                <th>{this.trans("cardNo")}</th>
                <th>{this.trans("comment")}</th>
              </tr>
            </thead>
            <tbody>
              {transactions.list.map((transaction, i) => {
                return (
                  <tr key={`transaction-${i}`}>
                    <td>{transaction.dateIn}</td>
                    <td className="currency-col">{centsToEurosToStr(transaction.amount)}</td>
                    <td>{transaction.payee}</td>
                    <td>{transaction.cardPan}</td>
                    <td>{transaction.comment || "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </div>
      );
    }

    return "";
  };


  activeCardsExport = (customerName) => {
    const query = Object.assign({}, {
      ShowAlsoNotActiveCards: false,
      XlsxReport: true,
      XlsxLanguage: this.props.i18n.language,
    });
    return this.cancelable(apiFetchActiveCardsReport(this.props.customerID, query, (data) => {
      const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      const fileName = customerName.replace(" ", "_") + "_" + this.trans("activeCards") + moment(new Date()).format("_DD_MM_YYYY") + ".xlsx";

      saveAs(blob, fileName);
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  render() {
    const {customerID, customer, user} = this.props;
    const {cards, showAlsoNotActiveCards, cardCount} = this.state;

    return (
      <>
        <Paginator
          next={this.fetchCards}
          pageLimit={10}
          additionalComponent={(onFilter) => (
            <>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="d-flex">
                  {user.isGenerateCards() && user.hasPermission(CAN_GENERATE_CARDS) && (
                    <Link to={{
                      pathname: "/cards/new",
                      state: {customerID: customerID}
                    }}>
                      <Button className="mr-2 btn-add sm-btn">
                        {this.trans("addCard")}
                      </Button>
                    </Link>
                  )}

                  <FetchButton className={"mr-2 btn-add sm-btn"} onClick={() => this.activeCardsExport(customer.name)}>
                    {this.trans("activeCards")}
                  </FetchButton>
                </div>

                <div className="d-flex align-items-center">
                  <span className="mr-3 form-check-inline">
                    <Input
                      type="checkbox"
                      checked={showAlsoNotActiveCards}
                      id={"showAlsoNotActiveCards"}
                      onChange={() => this.onChangeShowNotActiveCards(onFilter)}
                    />
                    <Label className="m-0" for="showAlsoNotActiveCards">{this.trans("showAlsoNotActiveCards")}</Label>
                  </span>
                  <span className="icon-input">
                    <Icon icon={ic_search}/>
                    {this.searchComponent((e) => this.searchCards(e, onFilter))}
                  </span>
                </div>
              </div>
              <OverviewContainer
                columns={[
                  {title: this.trans("cardCount"), value: cardCount},
                ]}
              />
            </>
          )}
        >
          {({onOrderByChange}) => {
            return (
              <CTable>
                <thead>
                  <Orderable
                    setOrderCallback={onOrderByChange}
                  >
                    {({renderOrderCol}) => (
                      <tr>
                        <th colSpan={user.hasPermission(CAN_GET_TRANSACTIONS) ? 2 : 1} className="text-nowrap">
                          {renderOrderCol(this.trans("pan"), "pan")}
                        </th>
                        <th className="text-nowrap text-center">{this.trans("balanceSlashLimit")}</th>
                        <th className="text-nowrap">
                          {renderOrderCol(this.trans("customerUnit"), "customer_unit_name")}
                        </th>
                        <th>{this.trans("nameOnCard")}</th>
                        <th>{this.trans("cardProductName")}</th>
                        <th className="text-nowrap">
                          {renderOrderCol(this.trans("validTo"), "valid_to_real")}
                        </th>
                        <th>{this.trans("cardState")}</th>
                        <th>{this.trans("usedUp")}</th>
                      </tr>
                    )}
                  </Orderable>
                </thead>
                <tbody>
                  {cards.map((v, i) => {
                    const targetRef = React.createRef();

                    return (
                      <CollapsibleTableRow
                        key={`cards-${v.id}-${i}`}
                        colSpan={9}
                        displayData={this.renderCardTransactions}
                        loadData={() => this.fetchCardTransactions(v.id)}
                        setErrors={this.props.setErrors}
                        targetRef={targetRef}
                      >
                        <TLink to={`/cards/${v.id}`}>
                          <Tooltip
                            showif={true}
                            className={"card-balance-tooltip-content"}
                            getContent={() => <CardBalancesContainer cardID={v.id}/>}
                          >
                            {v.pan}
                          </Tooltip>
                        </TLink>
                        {user.hasPermission(CAN_GET_TRANSACTIONS) && (
                          <td>
                            <div ref={targetRef} className="d-inline-block cursor-pointer">
                              <Tooltip title={`${this.trans("show")}: ${this.trans("recentTransactions").toLowerCase()}`} showif={true}>
                                <Icon icon={ic_format_list_numbered}/>
                              </Tooltip>
                            </div>
                          </td>
                        )}
                        <CardBalancesTableColumn totals={v.balanceAndTotals}/>
                        <TLink
                          to={`/customers/${v.customerID}/unit-history/${v.customerUnitID}`}
                          disableLink={v.customerUnitClosed || !user.hasPermission(CAN_GET_CUSTOMERS)}
                        >
                          {v.customerUnitName}
                        </TLink>
                        <td>{v.name || "-"}</td>
                        <td>{v.cardProductName}</td>
                        <td>{v.validTo}</td>
                        <td>{this.trans(`cardStateTypes.${cardState.value[v.cardState]}`)}</td>
                        <td><Icon icon={isCardUsed(v.balanceAndTotals) ? ic_radio_button_checked : ic_radio_button_unchecked}/></td>
                      </CollapsibleTableRow>
                    );
                  })}
                </tbody>
              </CTable>
            );
          }}
        </Paginator>
      </>
    );
  }
}

export default withTranslation()(CustomerCardsContainer);
