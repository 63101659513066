import React from "react";
import OverviewRow from "../../helper/overview_row";
import {centsToEurosToStr} from "../../helper/utils";
import CoreComponent from "../../core_component";
import EditCardLimitsModal from "../../modals/card/edit_card_limits_modal";
import {withTranslation} from "react-i18next";
import {apiFetchCardLimitsTotalBalance} from "../../../api/fetch";
import {withAlert} from "../../helper/alert";

class CardBalancesContainer extends CoreComponent {
  state = {
    card: {},
  };

  componentDidMount() {
    const {fetchCallback} = this.props;

    this.fetchInitial(this.cancelable(apiFetchCardLimitsTotalBalance(this.props.cardID, {}, (data) => {
      this.setState({card: data}, () => {
        if (fetchCallback) {
          fetchCallback(data);
        }
      });
    }, (err) => {
      if (err.cancelled) {
        return;
      }

      this.props.setErrors(err.errors);
    })));
  }

  render = () => {
    const {cardID, canEdit} = this.props;
    const {card} = this.state;

    return (
      <div className="sec-panel p-3 h-100">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <h4 className="dark-col">
            {this.trans("balanceAndLimits")}
          </h4>
          {canEdit && (
            <EditCardLimitsModal
              card={card}
              cardID={cardID}
            />
          )}
        </div>

        {this.props.renderAlert()}

        <div className="my-3">
          <OverviewRow
            name="amountLimit" value={centsToEurosToStr(card.amountLimit)}
            nWidth={6} vWidth={6}
          />
          <OverviewRow
            name="used" value={centsToEurosToStr(card.amountUsed)}
            nWidth={6} vWidth={6}
          />
          <OverviewRow
            name="netBalance" value={centsToEurosToStr(card.amountBalance)}
            nWidth={6} vWidth={6}
          />
        </div>

        <div className="my-3">
          <OverviewRow
            name="countLimit" value={card.countLimit !== null ? card.countLimit : "-"}
            nWidth={6} vWidth={6}
          />
          <OverviewRow
            name="used" value={card.timesUsed}
            nWidth={6} vWidth={6}
          />
          <OverviewRow
            name="netBalance"
            value={(card.countBalance !== null) ? card.countBalance : "-"}
            nWidth={6} vWidth={6}
          />
        </div>
      </div>
    );
  };
}

class _CardBalancesTableColumn extends CoreComponent {
  render() {
    const {totals} = this.props;

    if (!totals) {
      return null;
    }

    return (
      <td className="card-balances-table-col">
        <div>
          {this.defined(totals.amountLimit) ? (
            <>
              <span>{centsToEurosToStr(totals.amountBalance)}</span>
              <span className="mx-1">/</span>
              <span>{centsToEurosToStr(totals.amountLimit)}</span>
            </>
          ) : (
            <span>{this.trans("noAmountLimit")}</span>
          )}
        </div>
        <div>
          {this.defined(totals.countLimit) ? (
            <>
              <span>{totals.countBalance}x</span>
              <span className="mx-1">/</span>
              <span>{totals.countLimit}x</span>
            </>
          ) : (
            <span>{this.trans("noCountLimit")}</span>
          )}
        </div>
      </td>
    );
  }
}

export const CardBalancesTableColumn = withTranslation()(_CardBalancesTableColumn);
export default withAlert(withTranslation()(CardBalancesContainer));
