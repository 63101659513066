import React from "react";
import {withTranslation} from "react-i18next";
import {Button} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import ModalWindow from "../../helper/modal_window";
import CoreComponent from "../../core_component";
import {apiExtendCardExpiryTime, apiExtendCardsExpiryTime} from "../../../api/fetch";


class ExtendCardExpiryTimeModal extends CoreComponent {
  onSubmit = (reqData) => {
    const {cardID, cardIDs} = this.props;

    if (cardID) {
      return this.cancelable(apiExtendCardExpiryTime(cardID, reqData));
    } else {
      reqData = reqData || {};
      reqData["cardIDs"] = cardIDs;
      return this.cancelable(apiExtendCardsExpiryTime(reqData));
    }
  };

  opener = (toggleOpen) => {
    return (
      <Button className="btn-edit sm-btn" disabled={this.props.disabled} onClick={(ev) => toggleOpen(ev, this.props.card)}>
        {this.trans("extendExpiryTime")}
      </Button>
    );
  };

  body = (data, errors, onChange) => {
    const maxMonths = this.props.maxExtendByMonths;
    return (
      <>
        <FormGroupWErr
          type="number"
          str="extendByMonths"
          min={1}
          max={maxMonths}
          placeholder={this.trans("maxCount", undefined, {count: maxMonths})}
          step="1"
          err={errors.extendByMonths}
          val={data.extendByMonths}
          onChange={onChange}
        />
      </>
    );
  };

  render() {
    return (
      <ModalWindow
        headerText={this.trans("extendExpiryTime")}
        caller={this.opener}
        body={this.body}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default withTranslation()(ExtendCardExpiryTimeModal);
