import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";
import {Button, FormGroup, Input, Label} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import {postRequest} from "../../../api/request";
import Tooltip from "../../helper/tooltip";
import Icon from "react-icons-kit";
import {ic_info_outline} from "react-icons-kit/md";

class NewCustomerUnitModal extends CoreComponent {

  addNewUnitCaller = (toggleOpen) => {
    return (
      <Button className="my-2 btn-add sm-btn" onClick={toggleOpen}>
        {this.trans("addUnit")}
      </Button>
    );
  };

  addNewUnitBody = (data, errors, onChange) => {
    const {user} = this.props;

    return (
      <>
        <FormGroupWErr maxLength={90} str={"name"} err={errors.name} val={data.name} onChange={onChange}/>
        <FormGroupWErr maxLength={500} str={"comment"} err={errors.comment} val={data.comment} required={false} onChange={onChange}/>
        <FormGroup check>
          <Label>
            <Input
              type="checkbox" id="defaultCustomerUnit"
              name="defaultCustomerUnit"
              checked={data.defaultCustomerUnit}
              onChange={onChange}
              disabled={user.companyAsCustomer}
            />
            {this.trans("defaultCustomerUnit")}
          </Label>
          <Tooltip
            trans={(user.companyAsCustomer) ? "toSetThisUnitAsDefaultUncheckCurrentMsg" : "defaultCustomerUnitIsUniqueMsg"}
            showif={true}
          >
            <Icon icon={ic_info_outline} size={20}/>
          </Tooltip>
        </FormGroup>
      </>
    );
  };

  addNewUnitOnSubmit = (data) => {
    return new Promise((resolve, reject) => {
      this.cancelable(postRequest(`/api/customers/${this.props.customerID}/units`, data)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  render() {
    return (
      <>
        <ModalWindow
          headerText={this.trans("newCustomerUnit")}
          body={this.addNewUnitBody}
          caller={this.addNewUnitCaller}
          onSubmit={this.addNewUnitOnSubmit}
        />
      </>
    );
  }
}

export default withTranslation()(NewCustomerUnitModal);
