import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";
import {Button} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import FormGroupCheckbox from "../../helper/form_group_checkbox";
import {postRequest} from "../../../api/request";

class NewContactPersonModal extends CoreComponent {

  state = {
    contactEmailRequired: true,
    contactPhoneRequired: true,
  };

  addContPersCaller = (toggleOpen) => {
    return (
      <Button className={"my-2 btn-add sm-btn"} onClick={toggleOpen}>
        {this.trans("addContactPerson")}
      </Button>
    );
  };

  addContPersBody = (data, errors, onChange) => {
    const contPeople = this.props.contactPeople;
    let mutableIsPrimary = true;
    if (!Array.isArray(contPeople) || !contPeople.length) {
      mutableIsPrimary = false;
    }
    return (
      <>
        <FormGroupWErr maxLength={90} label={"name"} str={"contactName"} err={errors.contactName} val={data.contactName} onChange={onChange}/>
        <FormGroupWErr maxLength={254} label={"email"} type={"email"} str={"contactEmail"} err={errors.contactEmail} val={data.contactEmail}
          onChange={(ev) => this.onChangeContactEmail(ev, onChange)} required={this.state.contactEmailRequired}/>
        <FormGroupWErr maxLength={64} label={"phone"} str={"contactPhone"} err={errors.contactPhone} val={data.contactPhone}
          onChange={(ev) => this.onChangeContactPhone(ev, onChange)} required={this.state.contactPhoneRequired}/>
        <FormGroupCheckbox label={"setAsPrimaryContactPerson"} str={"isPrimary"} disabled={!mutableIsPrimary}
          val={mutableIsPrimary ? data.isPrimary : true} onChange={onChange}/>
      </>
    );
  };

  addContPersOnSubmit = (data) => {
    const reqData = {};
    Object.assign(reqData, data);
    const contPeople = this.props.contactPeople;
    if (!Array.isArray(contPeople) || !contPeople.length) {
      reqData.isPrimary = true;
    }
    return new Promise((resolve, reject) => {
      this.cancelable(postRequest(`/api/customers/${this.props.customerID}/new-contact-person`, reqData)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  addContPersOnOpen = () => {
    return new Promise((resolve) => {
      this.setState(Object.assign({}, this.state, {
        contactEmailRequired: true,
        contactPhoneRequired: true,
      }), resolve);
    });
  };

  onChangeContactEmail = (ev, onChange) => {
    onChange(ev);
    if(ev.target.value !== ""){
      this.setState({
        contactPhoneRequired: false
      });
    }else{
      this.setState({
        contactPhoneRequired: true
      });
    }
  };

  onChangeContactPhone = (ev, onChange) => {
    onChange(ev);
    if(ev.target.value !== ""){
      this.setState({
        contactEmailRequired: false
      });
    }else{
      this.setState({
        contactEmailRequired: true
      });
    }
  };

  render() {
    return (
      <>
        <ModalWindow
          headerText={this.trans("addContactPerson")}
          body={this.addContPersBody}
          caller={(toggleOpen) => this.addContPersCaller(toggleOpen)}
          onSubmit={this.addContPersOnSubmit}
          onOpen={this.addContPersOnOpen}
        />
      </>
    );
  }
}

export default withTranslation()(NewContactPersonModal);
