import i18next from "i18next";
import en from "../locales/en";
import et from "../locales/et";

i18next.init({
  lng: window.localStorage.getItem("language") || "et",
  fallbackLng: ["en", "et"],
  resources: {
    en: {translation: en},
    et: {translation: et}
  }
});

export default i18next;
