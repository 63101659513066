import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import {Col, FormGroup} from "reactstrap";
import FormGroupWErr from "../helper/form_group_w_err";
import FiltersContainer from "../helper/filters_container";
import LabelWithError from "../helper/label_with_error";
import DatePicker from "../helper/date_picker";
import moment from "moment";
import {timestampFmt} from "../helper/utils";

class AdminCompanyReportFilters extends CoreComponent {
  renderFilters = (filtersData, onChange) => {
    return (
      <>
        <Col md={3}>
          <LabelWithError>
            {this.trans("date")}
          </LabelWithError>
          <FormGroup>
            <DatePicker
              onChange={(data) => {
                onChange({
                  target: {
                    name: "dateIn",
                    value: moment(data).add(10, "days").format(timestampFmt),
                  },
                });
              }}
              value={moment(filtersData.dateIn, timestampFmt).toDate()}
              format={"MM/yy"}
              minDetail={"year"}
              maxDetail={"year"}
              maxDate={new Date()}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroupWErr
            str={"companyName"} label={"company"}
            val={filtersData.companyName} onChange={onChange}
            required={false}
          />
        </Col>
      </>
    );
  };

  render() {
    return (
      <FiltersContainer
        renderFilters={this.renderFilters}
        onFilter={this.props.onFilter}
        initData={this.props.initialPageFilters}
        extraButtons={this.props.extraButtons}
      />
    );
  }

}

export default withTranslation()(AdminCompanyReportFilters);
