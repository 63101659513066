import React from "react";
import CoreComponent from "../../core_component";
import {apiFetchTransactionReport, apiFetchTransactionReportDownload} from "../../../api/fetch";
import {withTranslation} from "react-i18next";
import {centsToEurosToStr, getCurrentMonthDateRange} from "../../helper/utils";
import {exportCSV, printFilterDataToCSV} from "../../helper/csv";
import TransactionFilters from "../../filters/transaction_filters";
import {FetchButton} from "../../helper/fetch_button";
import {parseDateRange} from "../../helper/date_range_picker";
import OverviewContainer from "../../helper/overview_container";
import TLink from "../../helper/table_link";
import FileName from "../../helper/fileutil";
import moment from "moment";
import {saveAs} from "file-saver";
import Paginator from "../../helper/paginator";
import {CAN_GET_CARDS, CAN_GET_CUSTOMERS} from "../../helper/role_permissions";
import {CTable} from "../../helper/ctable";

class CardTransactionsReport extends CoreComponent {
  state = {
    reportEntries: [],
    transactionCount: 0,
    amountSum: 0,
  };

  fetchReport = (query) => {
    return this.cancelable(apiFetchTransactionReport(query, (data) => {
      this.setState({
        reportEntries: data.list,
        transactionCount: data.count,
        amountSum: data.sum,
      });
    }));
  };

  generateAndExportCSV = (fileName, csvData, filters) => {
    const {list, count, sum} = csvData;

    let header = [
      this.trans("pan"),
      this.trans("amount"),
      this.trans("date"),
    ];
    let rows = [];

    for (let i = 0; i < list.length; i++) {
      rows.push([
        list[i].cardPan,
        centsToEurosToStr(list[i].amount, ""),
        list[i].dateIn,
      ]);
    }

    rows.push([]);
    rows.push([this.trans("transactionCount"), this.trans("transactionSum")]);
    rows.push([count, centsToEurosToStr(sum)]);

    const dateRange = parseDateRange(filters.dateRange);

    printFilterDataToCSV(rows, this.trans,
      [
        ["startDate", dateRange[0]],
        ["endDate", dateRange[1]],
        ["pan", filters.pan],
        ["customer", filters.customerName],
        ["unit", filters.customerUnitName],
      ]
    );

    return exportCSV(fileName, header, rows);
  };

  initCSVExport = (query) => {
    const csvQuery = Object.assign({}, query, {
      csv: true,
    });

    return this.cancelable(apiFetchTransactionReport(csvQuery, (data) => {
      const fileName = new FileName(this.trans("transactions"), "csv").withTimestamp(...parseDateRange(csvQuery.dateRange));
      this.generateAndExportCSV(fileName.getFullName(), data, csvQuery);
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  initReport = (query) => {
    const csvQuery = Object.assign({}, query, {
      fullReport: true,
      xlsLanguage: this.props.i18n.language,
    });

    return this.cancelable(apiFetchTransactionReportDownload(csvQuery, (data) => {
      const blob = new Blob([data], {type: "application/zip"});
      const fileName = this.trans("notCapitalized.statement") + moment(new Date()).format("_DD_MM_YYYY") + ".zip";
      saveAs(blob, fileName);
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  render() {
    const {transactionCount, amountSum} = this.state;
    const {user} = this.props;

    return (
      <div className={"mt-2"}>
        <Paginator
          next={this.fetchReport}
          initialPageFilters={getCurrentMonthDateRange()}
          additionalComponent={(onFilter, initialPageFilters) => (
            <>
              <TransactionFilters
                initialPageFilters={initialPageFilters}
                onFilter={onFilter}
                extraButtons={(data) => (
                  <>
                    <FetchButton className={"sm-btn"} onClick={() => this.initCSVExport(data)}>
                      CSV
                    </FetchButton>
                    <FetchButton className={"sm-btn"} onClick={() => this.initReport(data)}>
                      {this.trans("createReports")}
                    </FetchButton>
                  </>
                )}
              />
              <OverviewContainer
                columns={[
                  {title: this.trans("transactionSum"), value: centsToEurosToStr(amountSum)},
                  {title: this.trans("transactionCount"), value: transactionCount},
                ]}
              />
            </>
          )}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("pan")}</th>
                <th className="currency-col">{this.trans("amount")}</th>
                <th>{this.trans("date")}</th>
                <th>{this.trans("customerName")}</th>
                <th>{this.trans("customerUnit")}</th>
                <th>{this.trans("payee")}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.reportEntries.map((v, i) => (
                <tr key={`report-entry-${i}`}>
                  <TLink
                    to={`/cards/${v.cardID}`}
                    disableLink={!user.hasPermission(CAN_GET_CARDS)}
                  >
                    {v.cardPan}
                  </TLink>
                  <td className="currency-col">{centsToEurosToStr(v.amount)}</td>
                  <td>{v.dateIn}</td>
                  <TLink
                    to={`/customers/${v.customerID}`}
                    disableLink={v.customerClosed || !user.hasPermission(CAN_GET_CUSTOMERS)}
                  >
                    {v.customerName}
                  </TLink>
                  <TLink
                    to={`/customers/${v.customerID}/unit-history/${v.customerUnitID}`}
                    disableLink={v.customerUnitClosed || !user.hasPermission(CAN_GET_CUSTOMERS)}
                  >
                    {v.customerUnitName}
                  </TLink>
                  <td>{v.payee}</td>
                </tr>
              ))}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withTranslation()(CardTransactionsReport);
