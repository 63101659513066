import React from "react";
import Icon from "react-icons-kit/index";
import {centsToEurosToStr, getCurrentMonthDateRange} from "../../helper/utils";
import {Col, Row} from "reactstrap";
import {getRequest} from "../../../api/request";
import {DateRangePick} from "../../helper/date_range_picker";
import OverviewContainer from "../../helper/overview_container";
import CollapsibleTableRow from "../../helper/collapsible_table_row";
import {ic_format_list_numbered} from "react-icons-kit/md";
import EditCardTransactionModal from "../../modals/card/edit_card_transaction_modal";
import NewCardTransactionModal from "../../modals/card/new_card_transaction_modal";
import {withTranslation} from "react-i18next";
import CoreComponent from "../../core_component";
import FiltersContainer from "../../helper/filters_container";
import Tooltip from "../../helper/tooltip";
import Paginator from "../../helper/paginator";
import {CTable} from "../../helper/ctable";

class CardTransactionsContainer extends CoreComponent {
  state = {
    transactions: [],
    transactionCount: 0,
    transactionSum: 0,
  };

  fetchTransactions = (filtersData) => {
    return this.cancelable(this.props.fetchFunc(filtersData, (data) => {
      this.setState(Object.assign({}, this.state, {
        transactions: data.list,
        transactionCount: data.count,
        transactionSum: data.sum,
      }));
    }));
  };

  fetchCardTransHistory = (transId) => {
    return new Promise((resolve, reject) => {
      this.cancelable(getRequest(`/api/transactions/${transId}/changes`, {limit: 5})).then((resp) => {
        resolve(resp.data);
      }).catch((error) => {
        reject(error);
      });
    });
  };

  renderCardTransHistory = (transHistory) => {
    if (Array.isArray(transHistory)) {
      return (
        <div className="p-2">
          <h5>{this.trans("transactionChangeHistory")}</h5>

          <CTable>
            <thead>
              <tr>
                <th>{this.trans("dateOfChange")}</th>
                <th className="currency-col">{this.trans("amount")}</th>
                <th>{this.trans("payee")}</th>
                <th>{this.trans("comment")}</th>
                <th>{this.trans("operator")}</th>
              </tr>
            </thead>
            <tbody>
              {transHistory.map((transHist, i) => {
                return (
                  <tr key={`trans-hist-val-${i}`}>
                    <td>{transHist.validFrom}</td>
                    <td className="currency-col">{centsToEurosToStr(transHist.amount)}</td>
                    <td>{transHist.payee}</td>
                    <td>{transHist.comment || "-"}</td>
                    <td>{transHist.operator}</td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </div>
      );
    }

    return "";
  };

  render() {
    const {transactions, transactionCount, transactionSum} = this.state;
    const {maxNewTransactionAmount, cardID, canAddAndEditTrans} = this.props;

    const overviewColumns = [
      {title: this.trans("transactionSum"), value: centsToEurosToStr(transactionSum)},
      {title: this.trans("transactionCount"), value: transactionCount},
    ];

    return (
      <div className="mt-2">
        <Paginator
          next={this.fetchTransactions}
          initialPageFilters={getCurrentMonthDateRange()}
          additionalComponent={(onFilter, initialPageFilters) => (
            <>
              <Row>
                {canAddAndEditTrans && (
                  <Col>
                    <NewCardTransactionModal
                      cardID={cardID}
                      maxAmount={maxNewTransactionAmount}
                    />
                  </Col>
                )}
                <Col>
                  <FiltersContainer
                    minified={true}
                    canClose={false}
                    onFilter={onFilter}
                    initData={initialPageFilters}
                    renderFilters={(data, onChange) => (
                      <DateRangePick
                        name={"dateRange"}
                        right={true}
                        onChange={onChange}
                        value={data.dateRange}
                        placeholder={this.trans("transactionDate")}
                      />
                    )}
                  />
                </Col>
              </Row>
              <OverviewContainer columns={overviewColumns}/>
            </>
          )}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("transactionDate")}</th>
                <th className="currency-col">{this.trans("amount")}</th>
                <th>{this.trans("payee")}</th>
                <th colSpan="2">{this.trans("comment")}</th>
                <th>{this.trans("operator")}</th>
                {canAddAndEditTrans && <th colSpan="2"/>}
              </tr>
            </thead>
            <tbody>
              {transactions.map((trans, i) => {
                const targetRef = React.createRef();

                return (
                  <CollapsibleTableRow
                    isOpenable={trans.hasChanged}
                    key={`trans-hist-row-${trans.id}-${i}`}
                    colSpan={8}
                    displayData={this.renderCardTransHistory}
                    loadData={() => this.fetchCardTransHistory(trans.id)}
                    setErrors={this.props.setErrors}
                    targetRef={targetRef}
                  >
                    <td>{trans.dateIn}</td>
                    <td className="currency-col">{centsToEurosToStr(trans.amount)}</td>
                    <td>{trans.payee}</td>
                    <td>{trans.comment || "-"}</td>
                    <td>
                      {trans.hasChanged && (
                        <div ref={targetRef} className="d-inline-block cursor-pointer">
                          <Tooltip title={`${this.trans("show")}: ${this.trans("transactionChangeHistory").toLowerCase()}`} showif={true}>
                            <Icon icon={ic_format_list_numbered}/>
                          </Tooltip>
                        </div>
                      )}
                    </td>
                    <td>{trans.operator || "-"}</td>
                    {canAddAndEditTrans && (
                      <>
                        <td className="icon-wrapper-width">
                          <EditCardTransactionModal
                            cardID={cardID}
                            isCancel={false}
                            transaction={trans}
                          />
                        </td>
                        <td className="icon-wrapper-width">
                          <EditCardTransactionModal
                            cardID={cardID}
                            isCancel={true}
                            transaction={trans}
                          />
                        </td>
                      </>
                    )}
                  </CollapsibleTableRow>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withTranslation()(CardTransactionsContainer);
