import axios from "axios";
import qs from "qs";
import moment from "moment";

const notAvailableError = "NOT_AVAILABLE_ERROR";
const tout = 60000;

function request(method, url, data, params, customOpts) {
  const CancelToken = axios.CancelToken;
  const startTime = moment();
  let cancel = null;

  const opts = {
    method: method,
    url: url,
    data: data,
    params: params,
    paramsSerializer: qs.stringify,
    timeout: tout,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  };

  if (process.env.REACT_APP_MODE === "dev") {
    opts.url = `http://${window.location.hostname}:${process.env.REACT_APP_SRV_PORT}${url}`;
    opts.withCredentials = true;
  }

  // Add (or overwrite with) custom user-defined options
  const reqOptions = Object.assign(opts, customOpts || {});

  // Ei saa panna finally callback'i, kuna axios teeb veel mingeid lisatoiminguid, mistõttu finally call viibib natukene
  const logDuration = () => {
    if (process.env.REACT_APP_MODE === "dev") {
      console.info(`%crequest to '${url}' took ${moment() - startTime}ms`, "color: blue; font-size: 1.1rem");
    }
  };

  const req = new Promise((resolve, reject) => {
    axios(reqOptions).then((result) => {
      logDuration();

      if ([200, 201, 204].indexOf(result.status) !== -1) {
        resolve({
          data: result.data || {},
          errors: {},
        });
      } else {
        reject({
          data: result.data || {},
          errors: {
            error: notAvailableError
          }
        });
      }
    }).catch((error) => {
      logDuration();

      if (axios.isCancel(error)) {
        reject({
          data: {},
          errors: {},
          cancelled: true
        });

        return;
      }

      if (error.response) {
        if (error.response.status === 401) {
          window.localStorage.clear();
          window.location = "/login";
        }
        reject({
          data: {},
          errors: Object.assign({error: `http.${error.response.status}`}, error.response.data)
        });

        return;
      }

      reject({
        data: {},
        errors: {
          error: notAvailableError
        }
      });
    });
  });

  return {
    promise: req,
    cancel: cancel,
  };
}

export function postRequest(url, data, options) {
  return request("POST", url, data, {}, options);
}

export function getRequest(url, params, options) {
  return request("GET", url, {}, params, options);
}

export function putRequest(url, data, options) {
  return request("PUT", url, data, {}, options);
}

export function delRequest(url, data, options) {
  return request("DELETE", url, data, {}, options);
}
