import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";
import OverviewRow from "../../helper/overview_row";

class TerminalInfoContainer extends CoreComponent {

  render() {
    const {terminal} = this.props;

    return (
      <>
        <Row>
          <Col md={10}>
            <OverviewRow name={"terminalID"} value={terminal.terminalID} nWidth={4} vWidth={8}/>
            <OverviewRow name={"merchantID"} value={terminal.merchantID} nWidth={4} vWidth={8}/>
            <OverviewRow name={"payDesk"} value={terminal.payDesk || "-"} nWidth={4} vWidth={8}/>
            <OverviewRow name={"merchantRegNo"} value={terminal.merchantRegNo || "-"} nWidth={4} vWidth={8}/>
          </Col>
        </Row>
      </>
    );
  }

}

export default withTranslation()(TerminalInfoContainer);
