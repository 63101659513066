import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import {apiFetchTerminal} from "../../api/fetch";
import {withRouter} from "react-router-dom";
import {withAlert} from "../helper/alert";
import LoadingPage from "./loading_page";
import {PageTitle} from "../helper/page_title";
import TerminalInfoContainer from "../containers/terminal/terminal_info_container";
import TerminalChangesContainer from "../containers/terminal/terminal_changes_container";
import NavTabs, {TabPaneOnOpen} from "../helper/nav_tabs";
import {TabContent} from "reactstrap";
import {TerminalTabConsumer} from "../helper/context";

class TerminalOverviewPage extends CoreComponent {

  state = {
    terminal: {}
  };

  terminalOwnerID = this.props.match.params.id;
  terminalID = this.props.match.params.tid;

  tabs = {
    CHANGES: "changes",
  };

  componentDidMount() {
    this.fetchInitial(this.fetchTerminal());
  }

  fetchTerminal = () => {
    return this.cancelable(apiFetchTerminal(this.terminalOwnerID, this.terminalID, (data) => {
      this.setState({
        terminal: data,
      });
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  render() {
    const {terminal} = this.state;
    const {setErrors} = this.props;

    if (!this.state.isPageReady) {
      return <LoadingPage/>;
    }

    const navTabs = [
      {id: this.tabs.CHANGES, title: "changes"},
    ];

    return (
      <>
        {this.props.renderAlert()}
        <PageTitle title={this.props.title}/>
        <TerminalInfoContainer
          terminal={terminal}
        />
        <TerminalTabConsumer>
          {({activeTab, toggleTab}) => (
            <div className={"mt-2"}>
              <NavTabs
                tabs={navTabs}
                activeTab={activeTab}
                toggleTab={toggleTab}
              />
              <TabContent activeTab={activeTab}>
                <TabPaneOnOpen tabId={this.tabs.CHANGES}>
                  <TerminalChangesContainer
                    terminalID={this.terminalID}
                    terminalOwnerID={this.terminalOwnerID}
                    setErrors={setErrors}
                  />
                </TabPaneOnOpen>
              </TabContent>
            </div>
          )}
        </TerminalTabConsumer>
      </>
    );
  }

}

export default withAlert(withRouter(withTranslation()(TerminalOverviewPage)));
