import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import {Button} from "reactstrap";
import {withRouter} from "react-router-dom";
import {apiDeleteCards} from "../../api/fetch";
import {ConfirmationModal} from "../helper/modal_window";
import Tooltip from "../helper/tooltip";

class DeleteCardsAlert extends CoreComponent {

  deleteCardsRequest = () => {
    const reqData = {
      selectedCardIDs: this.props.selectedCardIDs,
    };

    this.cancelable(apiDeleteCards(reqData, () => {
      this.navReload("closed");
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  render() {
    const hasChecked = this.props.selectedCardIDs.length > 0;

    return (
      <div className="mr-2">
        <ConfirmationModal
          subtitle={this.trans("areYouSureToCloseThis.selectedCards")}
          onSubmit={this.deleteCardsRequest}
          opener={(open) => {
            let classes = ["btn-del", "sm-btn"];

            if (!hasChecked) {
              classes.push("btn-disabled");
            }

            return (
              <Tooltip id={"selectCardsForCloseCards"} trans={"selectCards"} showif={!hasChecked}>
                <Button onMouseUp={open} className={classes.join(" ")}>
                  {this.trans("closeCards")}
                </Button>
              </Tooltip>
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation()(DeleteCardsAlert));
