import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {apiFetchCardsTotals, apiFetchTransactionsTotals} from "../../../api/fetch";
import {centsToEurosToStr} from "../../helper/utils";
import CoreContainer from "../../helper/core_container";

class CompanyTotalsContainer extends CoreComponent {
  state = {
    transTotalCount: 0,
    transTotalAmount: 0,
    noOfcardsCreated: 0,
  };

  componentDidMount() {
    this.fetchInitial(
      this.fetchCardsTotals(),
      this.fetchTransactionsTotals(),
    );
  }

  fetchCardsTotals = () => {
    return this.cancelable(apiFetchCardsTotals((data) => {
      this.setState({
        noOfcardsCreated: data.noOfcardsCreated,
      });
    }, (err) => {
      this.props.setErrors(err.errors);
    }));
  };

  fetchTransactionsTotals = () => {
    return this.cancelable(apiFetchTransactionsTotals((data) => {
      this.setState({
        transTotalCount: data.totalCount,
        transTotalAmount: data.totalAmount,
      });
    }, (err) => {
      this.props.setErrors(err.errors);
    }));
  };

  render() {
    const {transTotalCount, transTotalAmount, noOfcardsCreated, isPageReady} = this.state;

    return (
      <div className="p-3">
        <CoreContainer loading={!isPageReady}>
          <h4 className="dark-col font-weight-bold">{this.trans("inThisMonth")}</h4>
          <table className="transparent-table w-100">
            <thead>
              <tr>
                <th className="text-center">{this.trans("transactions")}</th>
                <th className="text-center">{this.trans("sum")}</th>
                <th className="text-center">{this.trans("noOfCardsCreated")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                  <h3 className="font-weight-bold">{transTotalCount}</h3>
                </td>
                <td className="text-center">
                  <h3 className="font-weight-bold">{centsToEurosToStr(transTotalAmount)}</h3>
                </td>
                <td className="text-center">
                  <h3 className="font-weight-bold">{noOfcardsCreated}</h3>
                </td>
              </tr>
            </tbody>
          </table>
        </CoreContainer>
      </div>
    );
  }
}

export default withTranslation()(CompanyTotalsContainer);
