import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";
import {NavLink} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import {ic_add_circle} from "react-icons-kit/md";
import Icon from "react-icons-kit";
import {withRouter} from "react-router-dom";
import {apiAddCompanyWebApiIP} from "../../../api/fetch";

class AddWebApiIpModal extends CoreComponent {

  addWebApiIpCaller = (toggleOpen, data) => {
    return (
      <NavLink className="p-0 d-inline-block" href="#" onClick={(ev) => toggleOpen(ev, data)}>
        <Icon icon={ic_add_circle}/>
      </NavLink>
    );
  };
  addWebApiIP = (data, errors, onChange) => {
    return (
      <>
        <FormGroupWErr str="ipAddress" maxLength={64} err={errors.ipAddress} val={data.ipAddress} onChange={onChange}/>
        <FormGroupWErr str="comment" maxLength={4096} err={errors.comment} val={data.comment} onChange={onChange} required={false}/>
      </>
    );
  };

  addWebApiIpOnSubmit = (data) => {
    const postData = {};
    postData.comment = data.comment;
    postData.ipAddress = data.ipAddress;
    postData.webApiId = this.props.webApiId;

    this.cancelable(apiAddCompanyWebApiIP(postData, () => {
      this.navReload("saved");
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };


  render() {
    return (
      <>
        <ModalWindow
          headerText={this.trans("addNewIP")}
          caller={(toggleOpen) => this.addWebApiIpCaller(toggleOpen, this.props)}
          body={this.addWebApiIP}
          onSubmit={this.addWebApiIpOnSubmit}
        />
      </>
    );
  }
}

export default withRouter(withTranslation()(AddWebApiIpModal));
