import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import {delRequest} from "../../api/request";
import {NavLink} from "reactstrap";
import Icon from "react-icons-kit";
import {ic_delete} from "react-icons-kit/md";
import {withRouter} from "react-router-dom";
import {ConfirmationModal} from "../helper/modal_window";
import Tooltip from "../helper/tooltip";

class RemoveContactPersonAlert extends CoreComponent {
  removeContactPersonRequest = (contactPersonID) => {
    this.cancelable(delRequest("/api/delete-contact-person",{contactPersonID})).then(() => {
      this.navReload("closed");
    }).catch((error) => {
      if (error.cancelled) {
        return;
      }
      this.props.setErrors(error.errors);
    });
  };

  render() {
    const {val, contactPeople} = this.props;
    let disabled = false;
    if(contactPeople.length > 1){
      disabled = val.isPrimary;
    }
    return (
      <td className="icon-wrapper-width">
        <ConfirmationModal
          title={val.contactName}
          subtitle={this.trans("areYouSureToCloseThis.contactPerson")}
          onSubmit={() => this.removeContactPersonRequest(val.contactPersonID)}
          opener={(open) => (
            <Tooltip trans={"cantClosePrimaryContactPersonEtc"} showif={disabled}>
              <NavLink className="p-0 d-inline-block" href="#" disabled={disabled} onMouseUp={open}>
                <Icon icon={ic_delete}/>
              </NavLink>
            </Tooltip>
          )}
        />
      </td>
    );
  }
}

export default withRouter(withTranslation()(RemoveContactPersonAlert));
