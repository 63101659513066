import {Component} from "react";

class CoreComponent extends Component {
  // commonTimeout - timeout that might be overwritten multiple times
  // during lifetime of the component
  commonTimeout = 0;

  unmountFuncs = [];
  unmountTimeouts = [];

  // Just set a _isMounted property to true in componentDidMount and set it to false in componentWillUnmount,
  // and use this variable to check your component’s status. [https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html]
  _isMounted = true;

  componentWillUnmount() {
    this._isMounted = false;
    this.unmountFuncs.forEach((fn) => {
      fn();
    });

    this.unmountTimeouts.forEach((timeout) => {
      clearTimeout(timeout);
    });

    clearTimeout(this.commonTimeout);
  }

  addTimeout = (timeout) => {
    this.unmountTimeouts.push(timeout);
  };

  defined = (...vals) => {
    for (let i = 0; i < vals.length; i++) {
      if (typeof vals[i] === "undefined" || vals[i] === null) {
        return false;
      }
    }

    return true;
  };

  trans = (message, escape = true, vars = {}) => {
    const {t} = this.props;

    if (!this.defined(t)) {
      return message;
    }
    return t(message, {...vars, defaultValue: t("translationError") + `: ${message}`, interpolation: {escapeValue: escape}});
  };

  setStateValue = (key, value, cb) => {
    if(typeof key !== "string") {throw new Error("setStateValue key is not a string!");}
    this.setState(Object.assign({}, this.state, {[key]: value}), cb);
  };

  setStateWithObject = (object, cb) => {
    this.setState(Object.assign({}, this.state, object), cb);
  };

  cancelOnUnmount = (cancelablePromise) => {
    this.unmountFuncs.push(cancelablePromise.cancel);
  };

  cancelable = (cancelablePromise) => {
    this.cancelOnUnmount(cancelablePromise);
    return cancelablePromise.promise;
  };

  cancelled = (data = {}) => {
    return Object.assign({}, data, {
      cancelled: true,
    });
  };

  arrayResponse = (data) => {
    if (Array.isArray(data)) {
      return {
        data: data,
        length: data.length
      };
    }

    return {
      data: [],
      length: 0
    };
  };

  fetchInitial = (...promises) => {
    if (promises.length <= 0) {
      return;
    }

    this.setStateValue("isPageReady", false, () => {
      Promise.all(promises).then(() => {
        this.setStateValue("isPageReady", true);
      }).catch((error) => {
        if (error.cancelled) {
          return;
        }

        this.setStateValue("isPageReady", true);
        this.props.setErrors(error.errors);
      });
    });
  };

  navTo = (path, notification, navData) => {
    this.props.history.push({
      pathname: path,
      notification: notification,
      navData: navData,
    });
  };

  navReplace = (path) => {
    this.props.history.replace({
      pathname: path,
    });
  };

  navReload = (notification, forceRemount = false) => {
    const pathname = window.location.pathname;

    // Replace path with some unknown path for a moment
    // so component will be remounted
    if (forceRemount) {
      this.props.history.replace({
        pathname: "/forceremount",
      });
    }

    this.props.history.replace({
      pathname: pathname,
      notification: notification,
    });
  };
}

export default CoreComponent;
