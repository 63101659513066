import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import NewContactPersonModal from "../../modals/customer/new_contact_person_modal";
import EditContactPersonModal from "../../modals/customer/edit_contact_person_modal";
import {withRouter} from "react-router-dom";
import RemoveContactPersonAlert from "../../alerts/remove_contact_person_alert";
import {apiFetchContactPeople} from "../../../api/fetch";
import Paginator from "../../helper/paginator";
import {CAN_EDIT_CUSTOMERS} from "../../helper/role_permissions";
import {CTable} from "../../helper/ctable";

class CustomerContactPeopleContainer extends CoreComponent {
  state = {
    contactPeople: [],
  };

  fetchContactPeople = (filtersData) => {
    return this.cancelable(apiFetchContactPeople(this.props.customerID, filtersData, (data) => {
      this.setState({
        contactPeople: data.list,
      });
    }));
  };

  render() {
    const {customerID, user} = this.props;
    const {contactPeople} = this.state;

    return (
      <>
        {user.hasPermission(CAN_EDIT_CUSTOMERS) && (
          <NewContactPersonModal
            customerID={customerID}
            contactPeople={contactPeople}
          />
        )}
        <Paginator
          next={this.fetchContactPeople}
          pageLimit={10}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("name")}</th>
                <th>{this.trans("email")}</th>
                <th>{this.trans("phone")}</th>
                {user.hasPermission(CAN_EDIT_CUSTOMERS) && (
                  <th colSpan={2}/>
                )}
              </tr>
            </thead>
            <tbody>
              {contactPeople.map((val, i) => {
                return (
                  <tr key={`cPeople-${i}`}>
                    <td>{val.contactName}</td>
                    <td>{val.contactEmail || "-"}</td>
                    <td>{val.contactPhone || "-"}</td>
                    {user.hasPermission(CAN_EDIT_CUSTOMERS) && (
                      <>
                        <EditContactPersonModal
                          customerID={customerID}
                          contactPeople={contactPeople}
                          val={val}
                        />
                        <RemoveContactPersonAlert
                          setErrors={this.props.setErrors}
                          val={val}
                          contactPeople={contactPeople}
                        />
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </>
    );
  }
}

export default withRouter(withTranslation()(CustomerContactPeopleContainer));
