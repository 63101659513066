import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Button, Label, FormGroup, Input} from "reactstrap";
import {putRequest} from "../../../api/request";
import LabelWithError from "../../helper/label_with_error";
import {centsToEuros, eurosToCents} from "../../helper/utils";
import CoreComponent from "../../core_component";
import ModalWindow from "../../helper/modal_window";
import {withAlert} from "../../helper/alert";
import AInput from "../../helper/apple_input";


class EditCardLimitsModal extends CoreComponent {
  state = {};

  editLimits = (data) => {
    const requestData = {
      amountLimit: data.amountUnlimited ? "" : eurosToCents(data.amountLimit),
      countLimit: data.countUnlimited ? "" : data.countLimit,
      comment: data.comment
    };

    return new Promise((resolve, reject) => {
      this.cancelable(putRequest(`/api/cards/${this.props.cardID}/limits`, requestData)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  body = (data, errors, onChange, disabled) => {
    return (
      <>
        {this.props.renderAlert()}

        <FormGroup row>
          <Col md={3}>
            <LabelWithError wrap for="amountLimit" required>
              {this.trans("amountLimit")}
            </LabelWithError>
          </Col>
          <Col md={5}>
            <AInput
              type="number" id="amountLimit" name="amountLimit"
              step="0.01" min={0} onChange={onChange}
              value={data.amountUnlimited ? "" : data.amountLimit}
              disabled={data.amountUnlimited || disabled}
              required
            />
          </Col>
          <Col md={4}>
            <Label check>
              <Input
                type="checkbox" id="amountUnlimited"
                checked={data.amountUnlimited} name="amountUnlimited"
                onChange={onChange}
                disabled={disabled}
              />
              {this.trans("unlimited")}
            </Label>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md={3}>
            <LabelWithError wrap for="countLimit" required>
              {this.trans("countLimit")}
            </LabelWithError>
          </Col>
          <Col md={5}>
            <AInput
              type="number" id="countLimit" name="countLimit"
              min={0} onChange={onChange}
              value={data.countUnlimited ? "" : data.countLimit}
              disabled={data.countUnlimited || disabled}
              required
            />
          </Col>
          <Col md={4}>
            <Label check>
              <Input
                type="checkbox" id="countUnlimited"
                checked={data.countUnlimited} name="countUnlimited"
                onChange={onChange}
                disabled={disabled}
              />
              {this.trans("unlimited")}
            </Label>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md={3}>
            <LabelWithError wrap for="comment" required>
              {this.trans("comment")}
            </LabelWithError>
          </Col>
          <Col md={9}>
            <AInput
              maxLength={500}
              type="text" id="comment"
              name="comment" onChange={onChange}
              value={data.comment || ""}
              disabled={disabled}
              required
            />
          </Col>
        </FormGroup>
      </>
    );
  };

  opener = (toggleOpen) => {
    const openModal = (ev) => {
      const {amountLimit, countLimit} = this.props.card;

      toggleOpen(ev, {
        amountLimit: amountLimit !== null ? centsToEuros(amountLimit) : "",
        countLimit: countLimit !== null ? countLimit : "",
        amountUnlimited: amountLimit === null,
        countUnlimited: countLimit === null,
      });
    };

    return (
      <Button onClick={openModal} className="btn-edit sm-btn">
        {this.trans("edit")}
      </Button>
    );
  };

  render() {
    const {setErrors} = this.props;

    return (
      <ModalWindow
        headerText={this.trans("editLimits")}
        caller={this.opener}
        body={this.body}
        onSubmit={this.editLimits}
        doubleConfirm={true}
        setErrors={setErrors}
      />
    );
  }
}

export default withAlert(withTranslation()(EditCardLimitsModal));
