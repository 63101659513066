import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {NavLink} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import {ic_delete, ic_star} from "react-icons-kit/md";
import Icon from "react-icons-kit";
import {delRequest} from "../../../api/request";
import NewCustomerUnitModal from "../../modals/customer/new_customer_unit_modal";
import EditCustomerUnitModal from "../../modals/customer/edit_customer_unit_modal";
import {apiFetchCustomerUnits} from "../../../api/fetch";
import {ConfirmationModal} from "../../helper/modal_window";
import Tooltip from "../../helper/tooltip";
import Paginator from "../../helper/paginator";
import {CAN_EDIT_CUSTOMERS} from "../../helper/role_permissions";
import {CTable} from "../../helper/ctable";

class CustomerUnitsContainer extends CoreComponent {
  state = {
    customerUnits: [],
  };

  fetchCustomerUnits = (urlQuery) => {
    return this.cancelable(apiFetchCustomerUnits(this.props.customerID, urlQuery, (data) => {
      this.setState({
        customerUnits: data.list,
      });
    }));
  };

  deleteCustomerUnitRequest = (customerUnitID) => {
    this.cancelable(delRequest("/api/delete-customer-unit",{customerUnitID})).then(() => {
      this.navReload("closed");
    }).catch((error) => {
      if (error.cancelled) {
        return;
      }
      this.props.setErrors(error.errors);
    });
  };

  render() {
    const {customerID, user} = this.props;
    const {customerUnits} = this.state;

    return (
      <>
        {user.hasPermission(CAN_EDIT_CUSTOMERS) && (
          <NewCustomerUnitModal
            customerID={customerID}
            user={user}
          />
        )}
        <Paginator
          next={this.fetchCustomerUnits}
          pageLimit={10}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("name")}</th>
                <th>{this.trans("comment")}</th>
                {user.hasPermission(CAN_EDIT_CUSTOMERS) && (
                  <th colSpan={2}/>
                )}
              </tr>
            </thead>
            <tbody>
              {customerUnits.map((v, i) => {
                return (
                  <tr key={`cUnits-${i}`}>

                    <td>
                      <Link to={`/customers/${customerID}/unit-history/${v.customerUnitID}`}>
                        {v.name}
                      </Link>
                      {v.defaultCustomerUnit && (
                        <Tooltip id={"default-customer-unit-tooltip"} trans={"defaultCustomerUnitIsUniqueMsg"} showif={true}>
                          <Icon icon={ic_star} size={18}/>
                        </Tooltip>
                      )}
                    </td>
                    <td>{v.comment}</td>
                    {user.hasPermission(CAN_EDIT_CUSTOMERS) && (
                      <>
                        <EditCustomerUnitModal
                          val={v}
                          customerID={customerID}
                          user={user}
                        />
                        <td className="icon-wrapper-width">
                          <ConfirmationModal
                            title={v.name}
                            subtitle={this.trans("areYouSureToCloseThis.customerUnit")}
                            onSubmit={() => this.deleteCustomerUnitRequest(v.customerUnitID)}
                            opener={(open) => (
                              <Tooltip trans={"toCloseThisUnitFirstCloseAllUnitsCards"} showif={v.hasCards}>
                                <Tooltip trans={"toCloseThisUnitFirstUncheckDefaultCustomerUnit"} showif={!v.hasCards && v.defaultCustomerUnit}>
                                  <NavLink className="p-0 d-inline-block" href="#" onMouseUp={open} disabled={v.hasCards || v.defaultCustomerUnit}>
                                    <Icon icon={ic_delete}/>
                                  </NavLink>
                                </Tooltip>
                              </Tooltip>
                            )}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </>
    );
  }
}

export default withRouter(withTranslation()(CustomerUnitsContainer));
