import React from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {TabContent} from "reactstrap";
import {PageTitle} from "../helper/page_title";
import {CustomerUnitTabConsumer} from "../helper/context";
import OverviewRow from "../helper/overview_row";
import {withAlert} from "../helper/alert";
import CoreComponent from "../core_component";
import {apiFetchCustomerUnit, apiFetchCustomerUnitChanges} from "../../api/fetch";
import CustomerUnitChangesContainer from "../../component/containers/customer_unit/customer_unit_changes_container";
import CustomerUnitCardsContainer from "../containers/customer_unit/customer_unit_cards_container";
import NavTabs, {TabPaneOnOpen} from "../helper/nav_tabs";
import {CAN_GET_CARDS} from "../helper/role_permissions";
import {yesNo} from "../helper/utils";

class CustomerUnit extends CoreComponent {

  state = {
    unitInfo: {},
    changes: [],
  };

  customerID = this.props.match.params.id;
  customerUnitID = this.props.match.params.uid;

  /*** server communication ***/

  componentDidMount() {
    this.fetchInitial(this.fetchCustomerUnit());
    this.props.setInfoMsg(this.props.location.notification);
  }

  fetchCustomerUnit = () => {
    return this.cancelable(apiFetchCustomerUnit(this.customerID, this.customerUnitID, (data) => {
      this.setState({unitInfo: data});
    }));
  };

  fetchCustomerUnitChanges = (urlQuery) => {
    return this.cancelable(apiFetchCustomerUnitChanges(this.customerID, this.customerUnitID, urlQuery, (data) => {
      this.setState({changes: data.list});
    }));
  };


  /*** info about customer unit ***/

  renderCustomerUnitInfo = () => {
    const {unitInfo} = this.state;

    return (
      <>
        <OverviewRow name={"name"} value={unitInfo.name || "-"} nWidth={3} vWidth={9}/>
        <OverviewRow name={"comment"} value={unitInfo.comment || "-"} nWidth={3} vWidth={9}/>
        <OverviewRow name={"defaultCustomerUnit"} value={yesNo(unitInfo.defaultCustomerUnit)} nWidth={3} vWidth={9}/>
      </>
    );
  };

  tabs = {
    CARDS: "cards",
    CHANGES: "changes",
  };

  render() {
    const {changes} = this.state;
    const {setErrors, user} = this.props;

    const navTabs = [];

    if (user.hasPermission(CAN_GET_CARDS)) {
      navTabs.push({id: this.tabs.CARDS, title: "cards"});
    }

    navTabs.push({id: this.tabs.CHANGES, title: "changes"});

    return (
      <div>
        <PageTitle title={this.props.title}/>
        {this.props.renderAlert()}
        {this.renderCustomerUnitInfo()}

        <CustomerUnitTabConsumer>
          {({activeTab, toggleTab}) => (
            <div className={"mt-2"}>
              <NavTabs
                tabs={navTabs}
                activeTab={activeTab}
                toggleTab={toggleTab}
              />
              <TabContent activeTab={activeTab}>
                {user.hasPermission(CAN_GET_CARDS) && (
                  <TabPaneOnOpen tabId={this.tabs.CARDS}>
                    <CustomerUnitCardsContainer
                      setErrors={setErrors}
                      customerID={this.customerID}
                      customerUnitID={this.customerUnitID}
                      user={user}
                    />
                  </TabPaneOnOpen>
                )}
                <TabPaneOnOpen tabId={this.tabs.CHANGES}>
                  <CustomerUnitChangesContainer
                    changes={changes}
                    fetchUnitChanges={this.fetchCustomerUnitChanges}
                  />
                </TabPaneOnOpen>
              </TabContent>
            </div>
          )}
        </CustomerUnitTabConsumer>
      </div>
    );
  }
}

export default withAlert(withTranslation()(withRouter(CustomerUnit)));
