import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {Button} from "reactstrap";
import {withRouter} from "react-router-dom";
import {apiDeleteCardsWoClient} from "../../../api/fetch";
import {ConfirmationModal} from "../../helper/modal_window";
import Tooltip from "../../helper/tooltip";

class DeleteCardsWoClientAlert extends CoreComponent {

  state = {
    cardIDsDict: {},
    count: 0,
  };

  deleteCardsWoCliRequest = () => {
    const {setErrors} = this.props;

    const cardIDs = [];
    Object.entries(this.state.cardIDsDict).forEach(([key]) => {
      cardIDs.push(parseInt(key));
    });

    const reqData = {
      cardIDs: cardIDs,
    };

    this.cancelable(apiDeleteCardsWoClient(reqData, () => {
      this.navReload("closed");
    }, (error) => {
      setErrors(error.errors);
    }));
  };

  setAllCardIDs = (dict) => {
    this.setStateWithObject({
      cardIDsDict: dict,
      count: Object.entries(dict).length,
    });
  }

  render() {
    const {count} = this.state;
    const {disabled, getAllCardIDsDict} = this.props;

    const trns = this.trans("areYouSureToCloseThis.selectedCards");
    const cnt = this.trans("totalOfCountCards", undefined, {count: count});
    const subtitle = `${trns} (${cnt})`;

    return (
      <ConfirmationModal
        subtitle={subtitle}
        onSubmit={this.deleteCardsWoCliRequest}
        opener={(open) => {
          const classes = ["mr-2", "btn-del", "sm-btn"];

          if (disabled) {
            classes.push("btn-disabled");
          }

          return (
            <Tooltip id={"selectCardsWoCliForCloseCards"} trans={"selectCards"} showif={disabled}>
              <Button onMouseUp={open} className={classes.join(" ")} onClick={() => getAllCardIDsDict(this.setAllCardIDs)}>
                {this.trans("closeCards")}
              </Button>
            </Tooltip>
          );
        }}
      />
    );
  }
}

export default withRouter(withTranslation()(DeleteCardsWoClientAlert));
