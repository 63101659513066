import React from "react";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button, Col} from "reactstrap";
import {getRequest} from "../../api/request";
import {ADMIN, DISTRIBUTOR} from "../helper/user_roles";
import {PageTitle} from "../helper/page_title";
import FormGroupWErr from "../helper/form_group_w_err";
import FiltersContainer from "../helper/filters_container";
import ModalWindow from "../helper/modal_window";
import LoadingPage from "./loading_page";
import {withAlert} from "../helper/alert";
import CoreComponent from "../core_component";
import {apiCreateOperator, apiGetAdminOperators, apiFetchAvailableDistributors, apiGetOperatorUserOptions, apiGetRoles} from "../../api/fetch";
import TLink from "../helper/table_link";
import SelectInput from "../helper/select_input";
import Paginator from "../helper/paginator";
import {CTable} from "../helper/ctable";

class _Operators extends CoreComponent {
  state = {
    operators: [],
    roles: [],
    languages: [],
  };

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);
    this.fetchInitial(this.fetchRoles(), this.fetchLanguages());
  }

  fetchOperators = (filtersData) => {
    return this.cancelable(apiGetAdminOperators(filtersData, (data) => {
      this.setState({
        operators: data.list,
      });
    }));
  };

  fetchRoles = () => {
    return this.cancelable(apiGetRoles(null, resp => {
      this.setState({roles: resp});
    }));
  };

  fetchLanguages = () => {
    return new Promise((resolve, reject) => {
      this.cancelable(getRequest("/api/languages")).then((resp) => {
        if (Array.isArray(resp.data)) {
          this.setStateValue("languages", resp.data);
        }

        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  /*** filter component ***/

  filterRole = (filters, onChangeInt) => {
    return (
      <Col md={3}>
        <FormGroupWErr
          str="roleNr"
          label="role"
          type="select"
          options={this.state.roles
            .filter((role) => role.id >= DISTRIBUTOR && role.id <= ADMIN)
            .map((role) => ({value: role.id, name: role.name}))}
          onChange={onChangeInt}
          val={filters.roleNr}
          required={false}
        />
      </Col>
    );
  };

  filterLanguage = (filters, onChangeInt) => {
    return (
      <Col md={3}>
        <FormGroupWErr
          str="lang"
          label="language"
          type="select"
          options={this.state.languages.map((lang) => ({value: lang.id, name: this.trans(`languages.${lang.id}`)}))}
          onChange={onChangeInt}
          val={filters.lang}
          required={false}
        />
      </Col>
    );
  };

  filtersComponent = (filtersData, onChange, onChangeInt) => {
    const basicInput = (str, val) => {
      return (
        <Col md={3}>
          <FormGroupWErr str={str} val={val} onChange={onChange} required={false}/>
        </Col>
      );
    };

    return (
      <>
        {basicInput("name", filtersData.name)}
        {basicInput("email", filtersData.email)}
        {this.filterRole(filtersData, onChangeInt)}
        {this.filterLanguage(filtersData, onChangeInt)}
      </>
    );
  };

  /*** new user modal ***/

  newUserCaller = (toggleOpen) => {
    return (
      <Button className={"ml-3 btn-add sm-btn"} onClick={toggleOpen}>
        {this.trans("addNew")}
      </Button>
    );
  };

  newUserBody = (data, errors, onChange) => {
    const {roles} = this.state;

    return (
      <>
        <FormGroupWErr maxLength={254} str="email" type="email" err={errors.email} val={data.email} onChange={onChange}/>
        <FormGroupWErr
          str="roleNr"
          label="role"
          type="select"
          err={errors.roleNr}
          options={roles
            .filter((role) => role.id >= DISTRIBUTOR && role.id <= ADMIN)
            .map((role) => ({value: role.id, name: role.name}))
          }
          onChange={onChange}
          val={data.roleNr}
        />
        {this.props.user.isAdmin() && data.roleNr === DISTRIBUTOR && (
          <SelectInput
            str="distributorID"
            label="distributor"
            err={errors.distributorID}
            val={data.distributorID}
            onChange={onChange}
            onInputChange={apiFetchAvailableDistributors}
          />
        )}
      </>
    );
  };

  newUserOnSubmit = (data) => {
    return this.cancelable(apiCreateOperator(data, () => {
      if (this.props.user.name === data.email) {
        apiGetOperatorUserOptions(this.props.user.id, (data) => {
          this.props.user.setAccountOptions(data);
        }, err => {
          this.props.setErrors(err.errors);
        });
      }
    }));
  };

  /*** ***/

  render() {
    const {operators} = this.state;

    if (!this.state.isPageReady) {
      return <LoadingPage/>;
    }

    return (
      <div>
        {this.props.renderAlert()}
        <div className={"my-3 d-flex flex-row justify-content-start"}>
          <PageTitle isGrouped={true} title={this.props.title}/>
          <ModalWindow
            headerText={this.trans("newUser")}
            caller={this.newUserCaller}
            body={this.newUserBody}
            onSubmit={this.newUserOnSubmit}
          />
        </div>
        <Paginator
          next={this.fetchOperators}
          additionalComponent={(onFilter) => (<FiltersContainer renderFilters={this.filtersComponent} onFilter={onFilter}/>)}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("name")}</th>
                <th>{this.trans("email")}</th>
                <th>{this.trans("role")}</th>
                <th>{this.trans("language")}</th>
              </tr>
            </thead>
            <tbody>
              {operators.map((val, i) => {
                let roleStr = val.role;

                if (val.roleNr === DISTRIBUTOR) {
                  roleStr = `${roleStr} (${val.distributorName})`;
                }

                return (
                  <tr key={`operators-${i}`}>
                    <TLink to={`/operators/${val.accountID}`}>{val.name ? val.name : <i>{this.trans("registeringUser")}</i>}</TLink>
                    <td>{val.email}</td>
                    <td>{roleStr}</td>
                    <td>{val.lang ? this.trans(`languages.${val.lang}`) : "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export const Operators = withAlert(withRouter(withTranslation()(_Operators)));
