import React from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {getRequest, postRequest} from "../../api/request";
import FormGroupWErr from "../helper/form_group_w_err";
import LabelWithError from "../helper/label_with_error";
import CoreForm from ".././helper/core_form";
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import Select from "react-select";
import {centsToEuros, customerType, eurosToCents, ignoreTimezoneAndToISOString, minSearchStrLength,} from "../helper/utils";
import {withAlert} from "../helper/alert";
import {PageTitle} from "../helper/page_title";
import CoreComponent from "../core_component";
import DatePicker from "../helper/date_picker";
import AInput from "../helper/apple_input";
import {apiFetchCustomerUnits, apiGetBatchExpiringCards} from "../../api/fetch";
import Paginator from "../helper/paginator";
import {saveAs} from "file-saver";
import Icon from "react-icons-kit";
import {ic_info_outline} from "react-icons-kit/md";
import Tooltip from "../helper/tooltip";
import FileName from "../helper/fileutil";
import {CTable} from "../helper/ctable";

class NewCardPage extends CoreComponent {
  state = {
    cardProducts: [],
    customers: [],
    data: {},
    units: [],
    initCustomer: {},
    endTime: null,
    expiryDateOnCard: null,
    expiringCardsCount: null,
    expiringCards: [],
    web: false,
  };

  maxBatchSize = 1000; // Duplicated to backend

  componentDidMount() {
    const obj = {};

    if (typeof this.props.location.state !== "undefined" && this.props.location.state !== null) {
      let {customerID} = this.props.location.state;
      if (customerID !== "undefined" && customerID !== null) {
        obj.customerID = customerID;
        this.loadCustomer(customerID);
        this.loadUnits(customerID);
      }
    } else if (this.props.location.navData !== undefined) {
      const d = this.props.location.navData;

      this.loadCustomer(d.customerID);
      this.loadUnits(d.customerID);

      obj.name = d.name;
      obj.customerUnitID = d.customerUnitID;
      obj.cardProductID = d.cardProductID;
      obj.info = d.info;

      if (d.amountLimit !== null) {
        obj.amountLimited = true;
        obj.amountLimitEur = centsToEuros(d.amountLimit);
      }
      if (d.countLimit !== null) {
        obj.countLimited = true;
        obj.countLimit = d.countLimit;
      }

    }
    this.getcardProducts();
    obj.validFrom = new Date();
    this.setStateWithObject({data: obj}, this.calculateCardEndtime);
  }

  getExpiringCards = (query) => {
    return this.cancelable(apiGetBatchExpiringCards(query, this.props.location.navData.id, (resp) => {
      this.setState({
        expiringCardsCount: resp.count,
        expiringCards: resp.list,
      });
    }));
  };

  getcardProducts = () => {
    this.cancelable(getRequest("/api/card-products")).then((resp) => {
      if (Array.isArray(resp.data)) {
        this.setStateValue("cardProducts", resp.data);
      }
    }).catch((error) => {
      if (error.cancelled) {
        return;
      }
      this.props.setErrors(error.errors);
    });
  };

  loadUnits = (customerID, callOnSuccess) => {
    this.cancelOnUnmount(apiFetchCustomerUnits(customerID, {}, (respData) => {
      this.setState({
        units: respData.list,
      }, callOnSuccess);
    }, (error) => {
      if (error.cancelled) {
        return;
      }
      this.props.setErrors(error.errors);
    }));
  };

  loadCustomer = (customerID) => {
    this.cancelable(getRequest(`/api/customers/${customerID}`)).then((resp) => {
      this.setStateWithObject({
        initCustomer: {label: resp.data.name, value: resp.data.customerID},
        data: Object.assign({}, this.state.data, {name: resp.data.name}),
      });
    }).catch((error) => {
      if (error.cancelled) {
        return;
      }
      this.props.setErrors(error.errors);
    });
  };

  fetchCustomers = (filtersData) => {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (filtersData.length >= minSearchStrLength) {
        this.cancelable(getRequest("/api/customers", {name: filtersData})).then((resp) => {
          const list = resp.data.list;
          if (Array.isArray(list)) {
            this.setState({
              customers: list.map((v) => ({
                label: v.name,
                value: v.customerID,
                customerTypeID: v.customerTypeID
              })).filter(v => v.customerTypeID !== customerType.Self)
            });
          }
        }).catch((error) => {
          if (error.cancelled) {
            return;
          }
          this.props.setErrors(error.errors);
        });
      }
    }, 500);
  };

  calculateCardEndtime = () => {
    if (this.state.data.validFrom === undefined || this.state.data.cardProductID === undefined ||
      this.state.data.cardProductID === 0) {
      return;
    }
    const data = {
      "startTime": ignoreTimezoneAndToISOString(this.state.data.validFrom),
      "cardProductID": this.state.data.cardProductID
    };

    this.cancelable(postRequest("/api/cards/calc-endtime", data)).then((resp) => {
      this.setState({
        endTime: new Date(resp.data.endTime),
        expiryDateOnCard: new Date(resp.data.endTime),
      });
    }).catch((error) => {
      if (error.cancelled) {
        return;
      }
      this.props.setErrors(error.errors);
    });
  };

  onChangeCardProduct = (ev) => {
    this.setStateValue("data", Object.assign({}, this.state.data, {
      [ev.target.name]: parseInt(ev.target.value, 10)
    }), () => {
      this.calculateCardEndtime();
      for (const cardProductsKey of this.state.cardProducts) {
        if (cardProductsKey.id === this.state.data.cardProductID) {
          if (cardProductsKey.webEnabled) {
            this.setState({web: true});
          } else {
            this.setState({web: false});
          }
        }
      }
    });
  };
  onChangeInt = (ev) => {
    this.setStateValue("data", Object.assign({}, this.state.data, {
      [ev.target.name]: parseInt(ev.target.value, 10)
    }));
  };

  onChange = (ev, callBack) => {
    this.setStateValue("data", Object.assign({}, this.state.data, {
      [ev.target.name]: ev.target.type === "checkbox" ? ev.target.checked : ev.target.value
    }), callBack ? () => callBack(this.state.data) : null);
  };

  onChangeDate(date) {
    this.setStateValue("data", Object.assign({}, this.state.data, {
      validFrom: date,
    }), () => {
      this.calculateCardEndtime();
    });
  }

  onChangeExpiryDateOnCard = (e) => {
    this.setState({
      expiryDateOnCard: e
    });
  };

  onChangeValidTo = (e) => {
    this.setStateWithObject({
      endTime: e
    });
    if (e > this.state.expiryDateOnCard) {
      this.onChangeExpiryDateOnCard(e);
    }
  };
  onChangeCustomerID = (customer) => {
    this.setStateValue("data", Object.assign({}, this.state.data, {
      customerID: customer.value,
      name: customer.label,
    }));
    this.setState({initCustomer: customer});

    // kui peale loadUnitsi leitakse vaid 1 üksus, siis seatakse see automaatselt
    const autoSetSingleUnit = () => {
      if (this.state.units.length === 1) {
        this.setStateValue("data", Object.assign({}, this.state.data, {
          customerUnitID: this.state.units[0].customerUnitID,
        }));
      }
    };

    this.loadUnits(customer.value, autoSetSingleUnit);
  };

  newCards = () => {
    const {data, endTime, expiryDateOnCard} = this.state;

    data.validTo = ignoreTimezoneAndToISOString(endTime);
    data.expiryDateOnCard = ignoreTimezoneAndToISOString(expiryDateOnCard);
    data.amountLimit = data.amountLimited ? eurosToCents(data.amountLimitEur) : "";
    data.countLimit = data.countLimited ? data.countLimit : "";

    return new Promise((resolve, reject) => {
      const reqData = {card: data, noOfCards: data.noOfCards};
      if (this.props.location.navData !== undefined) {
        reqData.cardID = this.props.location.navData.id;
      }
      if (this.state.web) {
        reqData.pwd = data.pwd;
      }

      this.cancelable(postRequest("/api/cards/create", reqData, {responseType: "blob"})).then((rdata) => {
        let extension = "csv";
        let type = "text/csv; charset=utf-8;";
        if (this.state.web) {
          extension = "zip";
          type = "application/zip";
        }
        const blob = new Blob([rdata.data], {type: type});
        const fileName = new FileName(data.name, extension).withTimestamp(new Date());
        saveAs(blob, fileName.getFullName());
        if (this.state.expiringCardsCount === null) {
          this.props.setInfoMsg("saved");
        } else {
          this.navTo("/", "saved");
        }
        resolve();
      }).catch((error) => {
        if (error.cancelled) {
          reject(error);
          return;
        }
        this.props.setErrors(error.errors);
        reject(error);
      });
    });
  };

  resolveNrOfCardsPlaceholder = () => {
    if (this.state.expiringCardsCount !== null) {
      return this.trans("thereAreCountCardsToBeRenewedInThisBatch", undefined, {count: this.state.expiringCardsCount});
    }
    return "";
  };

  resolveTitle = () => {
    if (this.state.expiringCardsCount === null) {
      return this.props.title;
    }
    return this.props.title + " - " + this.trans("notCapitalized.forRenewingExpiringCardBatch");
  };

  render() {
    const {data, customers, initCustomer, endTime, expiryDateOnCard, expiringCards, expiringCardsCount} = this.state;
    return (
      <>
        {this.props.renderAlert()}
        <PageTitle title={this.resolveTitle()}/>
        <Row>
          <Col md={6}>
            <CoreForm onSubmit={this.newCards}>
              <Row>
                <Col>
                  <FormGroup>
                    <LabelWithError wrap for="customerID" required={true}>
                      {this.trans("customer")}
                    </LabelWithError>
                    <Select
                      name="customerID"
                      options={customers}
                      onChange={this.onChangeCustomerID}
                      onInputChange={this.fetchCustomers}
                      value={initCustomer}
                      required={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroupWErr
                    maxLength={64}
                    label="nameOnCard"
                    str="name"
                    onChange={this.onChange}
                    val={data.name}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroupWErr
                    label="customerUnit"
                    str="customerUnitID"
                    type="select"
                    options={this.state.units.map((cprod) => ({value: cprod.customerUnitID, name: cprod.name}))}
                    onChange={this.onChangeInt}
                    val={data.customerUnitID}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroupWErr
                    label="cardProduct"
                    str="cardProductID"
                    type="select"
                    options={this.state.cardProducts.map((cprod) => ({value: cprod.id, name: cprod.name}))}
                    onChange={this.onChangeCardProduct}
                    val={data.cardProductID}
                    required={true}
                  />
                </Col>
              </Row>

              {this.state.web &&
              <Row>
                <Col>
                  <FormGroup>
                    <LabelWithError for="pwd" required={true}>
                      {this.trans("password")}
                      <Tooltip trans={"zipPasswordUse"} showif={true}>
                        <Icon icon={ic_info_outline} size={20}/>
                      </Tooltip>
                    </LabelWithError>
                    <AInput
                      id="pwd"
                      type="password"
                      maxLength={200}
                      name="pwd"
                      onChange={this.onChange}
                      value={data.pwd}
                      required={true}
                      autocomplete="new-password"
                    />
                  </FormGroup>
                </Col>
              </Row>
              }

              <Row>
                <Col>
                  <FormGroupWErr
                    maxLength={200}
                    label="infoFieldOnCard"
                    str="info"
                    onChange={this.onChange}
                    val={data.info}
                    required={false}
                  />
                </Col>
              </Row>
              <FormGroup row>
                <Col xs={5} className="text-right pr-0 mt-2">
                  <Label className="dark-col">
                    <Input
                      type="checkbox" id="amountLimited"
                      name="amountLimited"
                      checked={data.amountLimited}
                      onChange={this.onChange}
                    />
                    {this.trans("amountLimit")}
                  </Label>
                </Col>
                <Col xs={7}>
                  <AInput
                    type="number"
                    id="amountLimitEur" name="amountLimitEur"
                    step="1" min={1}
                    onChange={this.onChangeInt}
                    value={data.amountLimited ? data.amountLimitEur : ""}
                    disabled={!data.amountLimited}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={5} className="text-right pr-0 mt-2">
                  <Label className="dark-col">
                    <Input
                      type="checkbox" id="countLimited"
                      name="countLimited"
                      checked={data.countLimited}
                      onChange={this.onChange}
                    />
                    {this.trans("countLimit")}
                  </Label>
                </Col>
                <Col xs={7}>
                  <AInput
                    type="number"
                    id="countLimit" name="countLimit"
                    min={0}
                    step="1"
                    onChange={this.onChangeInt}
                    value={data.countLimited ? data.countLimit : ""}
                    disabled={!data.countLimited}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={5} className="pr-0 mt-2">
                  <LabelWithError wrap contentEnd for="noOfCards">
                    {this.trans("noOfCards")}
                  </LabelWithError>
                </Col>
                <Col xs={7}>
                  <AInput
                    type="number"
                    id="noOfCards" name="noOfCards"
                    min={1} max={this.maxBatchSize}
                    step="1"
                    onChange={this.onChangeInt}
                    value={data.noOfCards || ""}
                    placeholder={this.resolveNrOfCardsPlaceholder()}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={5} className="pr-0 mt-2">
                  <LabelWithError contentEnd>
                    {this.trans("validFrom")}
                  </LabelWithError>
                </Col>
                <Col xs={7}>
                  <DatePicker
                    onChange={(e) => this.onChangeDate(e)}
                    minDate={new Date()}
                    value={data.validFrom}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={5} className="pr-0 mt-2">
                  <LabelWithError contentEnd>
                    {this.trans("validTo")}
                  </LabelWithError>
                </Col>
                <Col xs={7}>
                  <DatePicker
                    onChange={this.onChangeValidTo}
                    minDate={data.validFrom}
                    value={endTime}
                    disabled={data.cardProductID === undefined || data.cardProductID < 1}
                    format={"MM.yy"}
                    maxDetail={"year"}
                    minDetail={"year"}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={5} className="pr-0 mt-2">
                  <LabelWithError contentEnd>
                    {this.trans("expiryDateOnCard")}
                  </LabelWithError>
                </Col>
                <Col xs={7}>
                  <DatePicker
                    onChange={this.onChangeExpiryDateOnCard}
                    minDate={endTime}
                    value={expiryDateOnCard}
                    disabled={data.cardProductID === undefined || data.cardProductID < 1}
                    format={"MM.yy"}
                    maxDetail={"year"}
                    minDetail={"year"}
                  />
                </Col>
              </FormGroup>
              <Button className="btn-add sm-btn float-right">
                {this.trans("saveCardsAndDownloadCSV")}
              </Button>
            </CoreForm>
          </Col>
          {this.props.location.navData !== undefined &&
          <Col md={6}>
            <Paginator
              next={this.getExpiringCards}
              pageLimit={20}
            >
              <CTable>
                <thead>
                  <tr>
                    <th>{`${this.trans("expiringCardNumber")} (${expiringCardsCount})`}</th>
                  </tr>
                </thead>
                <tbody>
                  {expiringCards.map((card, i) => {
                    return (
                      <tr key={`exp-cards-${i}`}>
                        <td>{card.pan}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </CTable>
            </Paginator>
          </Col>
          }
        </Row>
      </>
    );
  }
}

export const NewCardPageExtension = NewCardPage;
export default withAlert(withRouter(withTranslation()(NewCardPage)));
