import React from "react";
import CoreComponent from "./../core_component";
import {Input, FormGroup} from "reactstrap";
import AInput from "./apple_input";
import LabelWithError from "./label_with_error";
import {withTranslation} from "react-i18next";

class TextInputWithCheckbox extends CoreComponent {

  state = {
    valCopy: this.props.value || "",
    checked: this.props.checked || false,
  };

  onChange = (ev) => {
    this.props.onChange(ev);
    this.setStateValue("valCopy", ev.target.value);
  };

  onChangeCheckbox = (ev) => {
    if (ev.target.checked) {
      this.props.onChange({
        target: {
          name: this.props.str,
          value: this.state.valCopy,
        }
      });
    } else {
      this.props.onChange({
        target: {
          name: this.props.str,
          value: "",
        }
      });
    }
    this.setStateValue("checked", ev.target.checked);
  };

  render() {
    const {str, label, value, type, maxLength, error, checkboxID} = this.props;
    const {checked} = this.state;

    return (
      <div>
        <FormGroup check>
          <Input type="checkbox" checked={checked} id={checkboxID} onChange={this.onChangeCheckbox}/>
          <LabelWithError error={error} for={checkboxID}>{this.trans(label || str)}</LabelWithError>
        </FormGroup>
        {this.state.checked &&
        <FormGroup>
          <AInput
            type={type || "text"}
            id={str}
            name={str}
            value={value}
            onChange={this.onChange}
            maxLength={maxLength}
            required
          />
        </FormGroup>
        }
      </div>
    );
  }
}

export default withTranslation()(TextInputWithCheckbox);
