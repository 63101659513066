import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {Button} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import {apiEditDistributor} from "../../../api/fetch";
import ModalWindow from "../../helper/modal_window";
import PropTypes from "prop-types";

class EditDistributorModal extends CoreComponent {
  renderCaller = (toggleOpen) => {
    return (
      <Button onMouseUp={(ev) => toggleOpen(ev, this.props.initialData)} className="btn-edit sm-btn">
        {this.trans("edit")}
      </Button>
    );
  };

  renderBody = (data, errors, onChange) => {
    return (
      <>
        <FormGroupWErr maxLength={128} str="name" err={errors.name} val={data.name} onChange={onChange}/>
      </>
    );
  };

  onSubmit = (data) => {
    return new Promise((resolve, reject) => {
      this.cancelable(apiEditDistributor(this.props.distributorID, data, () => {
        resolve();
      }, (err) => {
        reject(err);
      }));
    });
  };

  render() {
    return (
      <ModalWindow
        headerText={this.trans("editDistributor")}
        caller={this.renderCaller}
        body={this.renderBody}
        onSubmit={this.onSubmit}
      />
    );
  }
}

EditDistributorModal.propTypes = {
  initialData: PropTypes.object,
  distributorID: PropTypes.number.isRequired,
};

export default withTranslation()(EditDistributorModal);
