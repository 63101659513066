import React from "react";
import {Col, FormGroup} from "reactstrap";
import {withTranslation} from "react-i18next";
import FormGroupWErr from "../helper/form_group_w_err";
import FiltersContainer from "../helper/filters_container";
import CoreComponent from "../core_component";
import {DateRangePick} from "../helper/date_range_picker";
import LabelWithError from "../helper/label_with_error";
import FormGroupCheckbox from "../helper/form_group_checkbox";

class TransactionFilters extends CoreComponent {
  renderFilters = (filtersData, onChange) => {
    return (
      <>
        <Col md={3}>
          <FormGroup>
            <LabelWithError>
              {this.trans("date")}
            </LabelWithError>
            <DateRangePick
              name={"dateRange"}
              onChange={onChange}
              value={filtersData.dateRange}
            />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroupWErr
            str={"pan"} val={filtersData.pan}
            onChange={onChange} required={false}
          />
        </Col>
        <Col md={2}>
          <FormGroupWErr
            str={"customerName"} val={filtersData.customerName}
            onChange={onChange} required={false}
          />
        </Col>
        <Col md={2}>
          <FormGroupWErr
            str={"customerUnitName"} label={"customerUnit"} val={filtersData.customerUnitName}
            onChange={onChange} required={false}
          />
        </Col>
        <Col md={2}>
          <FormGroupCheckbox
            str={"excludeCancelledTransactions"} val={filtersData.excludeCancelledTransactions || false}
            onChange={onChange} required={false} vertical={true}
          />
        </Col>
      </>
    );
  };

  render() {
    return (
      <FiltersContainer
        renderFilters={this.renderFilters}
        onFilter={this.props.onFilter}
        extraButtons={this.props.extraButtons}
        initData={this.props.initialPageFilters}
      />
    );
  }
}

export default withTranslation()(TransactionFilters);
