import React from "react";
import {withTranslation} from "react-i18next";
import {centsToEurosToStr, mapChangedColumns} from "../../helper/utils";
import CoreComponent from "../../core_component";
import TLink from "../../helper/table_link";
import {apiFetchCardChanges} from "../../../api/fetch";
import Paginator from "../../helper/paginator";
import {CAN_GET_CUSTOMERS} from "../../helper/role_permissions";
import {CTable} from "../../helper/ctable";

class CardChangesContainer extends CoreComponent {
  state = {
    cardChanges: [],
  };

  fetchCardChanges = (urlQuery) => {
    return this.cancelable(apiFetchCardChanges(this.props.cardID, urlQuery, (respData) => {
      this.setState({cardChanges: respData.list});
    }));
  };

  render() {
    const {user} = this.props;

    return (
      <div>
        <Paginator
          next={this.fetchCardChanges}
          pageLimit={10}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("cardNo")}</th>
                <th>{this.trans("hiddenName")}</th>
                <th>{this.trans("unit")}</th>
                <th className="currency-col">{this.trans("amountLimit")}</th>
                <th>{this.trans("countLimit")}</th>
                <th>{this.trans("dateOfChange")}</th>
                <th>{this.trans("validTo")}</th>
                <th>{this.trans("comment")}</th>
                <th>{this.trans("operator")}</th>
              </tr>
            </thead>
            <tbody>
              {mapChangedColumns(this.state.cardChanges, (v, i, renderCol) => {
                return (
                  <tr key={`card-ch-${i}`}>
                    <td>{renderCol("pan")}</td>
                    <td>{renderCol("hiddenName", {defaultValue: "-"})}</td>
                    <TLink
                      to={`/customers/${v.customerID}/unit-history/${v.customerUnitID}`}
                      disableLink={v.customerUnitClosed || !user.hasPermission(CAN_GET_CUSTOMERS)}
                    >
                      {renderCol("customerUnitName")}
                    </TLink>
                    <td className="currency-col">
                      {renderCol("amountLimit", {formatter: centsToEurosToStr, defaultValue: this.trans("unlimited")})}
                    </td>
                    <td>{renderCol("countLimit", {defaultValue: this.trans("unlimited")})}</td>
                    <td>{v.dateOfChange}</td>
                    <td>{renderCol("validTo")}</td>
                    <td>{renderCol("comment", {defaultValue: "-"})}</td>
                    <td>{v.authorEmail}</td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withTranslation()(CardChangesContainer);
