import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";
import {NavLink} from "reactstrap";
import {ic_add_circle} from "react-icons-kit/md";
import Icon from "react-icons-kit";
import {withRouter} from "react-router-dom";
import {apiAddCompanyWebApiPermission, apiAvailableWebApiPermissions} from "../../../api/fetch";
import MultiSelectWrap from "../../helper/multi_select_wrap";

class AddWebApiPermissionModal extends CoreComponent {
  state = {
    avalilablePermissions: [],
    permission: [],
  };

  fetchAvailablePermissions = () => {
    return new Promise((resolve, reject) => {
      this.cancelOnUnmount(apiAvailableWebApiPermissions(this.props.webApiId, (respData) => {
        this.setState({avalilablePermissions: respData});
        resolve();
      }, (err) => {
        reject(err);
      }));
    });
  }

  addWebApiPermissionCaller = (toggleOpen, data) => {
    return (
      <NavLink className="p-0 d-inline-block" href="#" onClick={(ev) => toggleOpen(ev, data)}>
        <Icon icon={ic_add_circle}/>
      </NavLink>
    );
  };

  handleChange(e) {
    const ids = [];
    if (e !== null) {
      for (const perm of e) {
        ids.push(perm.value);
      }
    }
    this.setState({permission: ids});
  }

  addWebApiPermission = () => {
    if (this.state.avalilablePermissions.length === undefined) {
      return this.trans("nothingFound");
    }
    return (
      <>
        <MultiSelectWrap
          options={this.state.avalilablePermissions.map((ct) => (
            {value: ct.id, label: this.trans(`webApiPermission.${ct.name}`)}
          ))}
          placeholder={this.trans("selectAndDots")}
          onChange={this.handleChange.bind(this)}
          currentOption={this.state.permission}
          required={true}
          label={this.trans("permissions")}
        />
      </>
    );
  };

  addWebApiPermissionOnSubmit = () => {
    const postData = {};
    postData.permissions = this.state.permission;
    postData.webApiId = this.props.webApiId;

    this.cancelable(apiAddCompanyWebApiPermission(postData, () => {
      this.navReload("saved");
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };


  render() {
    return (
      <>
        <ModalWindow
          headerText={this.trans("addNewPermission")}
          caller={(toggleOpen) => this.addWebApiPermissionCaller(toggleOpen, this.props)}
          body={this.addWebApiPermission}
          onSubmit={this.addWebApiPermissionOnSubmit}
          onOpen={this.fetchAvailablePermissions}
          forbidSubmit={this.state.avalilablePermissions.length === undefined}
        />
      </>
    );
  }
}

export default withRouter(withTranslation()(AddWebApiPermissionModal));
