import React from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import FormGroupWErr from "../helper/form_group_w_err";
import LabelWithError from "../helper/label_with_error";
import CoreForm from ".././helper/core_form";
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {eurosToCents, ignoreTimezoneAndToISOString,} from "../helper/utils";
import {withAlert} from "../helper/alert";
import {PageTitle} from "../helper/page_title";
import DatePicker from "../helper/date_picker";
import AInput from "../helper/apple_input";
import FileName from "../helper/fileutil";
import {NewCardPageExtension} from "./new_card_page";
import {apiCreateCardsWithoutClient} from "../../api/fetch";
import {saveAs} from "file-saver";
import Tooltip from "../helper/tooltip";
import Icon from "react-icons-kit";
import {ic_info_outline} from "react-icons-kit/md";

class NewCardWoClientPage extends NewCardPageExtension {

  componentDidMount() {
    const obj = {};
    this.getcardProducts();
    obj.validFrom = new Date();
    this.setStateWithObject({data: obj}, this.calculateCardEndtime);
  }

  newCardsWoClient = () => {
    const {data, endTime} = this.state;

    data.validTo = ignoreTimezoneAndToISOString(endTime);
    data.amountLimit = data.amountLimited ? eurosToCents(data.amountLimitEur) : "";
    data.countLimit = data.countLimited ? data.countLimit : "";

    const reqData = {card: data, noOfCards: data.noOfCards};

    if (this.state.web) {
      reqData.pwd = data.pwd;
    }

    return this.cancelable(apiCreateCardsWithoutClient(reqData, (resp) => {
      if (!(typeof data.name === "string" && data.name !== "")) {
        data.name = "unnamed";
      }
      let extension = "csv";
      let type = "text/csv; charset=utf-8;";
      if (this.state.web) {
        extension = "zip";
        type = "application/zip";
      }
      const blob = new Blob([resp], {type: type});
      const fileName = new FileName(data.name, extension).withTimestamp(new Date());
      saveAs(blob, fileName.getFullName());
      this.navTo("/cards/without-client", "saved");
    }, (err) => {
      this.props.setErrors(err.errors);
    }));
  };

  render() {
    const {endTime, data} = this.state;

    return (
      <>
        {this.props.renderAlert()}
        <PageTitle title={this.props.title}/>
        <Row>
          <Col md={6}>
            <CoreForm onSubmit={this.newCardsWoClient}>
              <Row>
                <Col>
                  <FormGroupWErr
                    maxLength={64}
                    label="nameOnCard"
                    str="name"
                    onChange={this.onChange}
                    val={data.name}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroupWErr
                    label="cardProduct"
                    str="cardProductID"
                    type="select"
                    options={this.state.cardProducts.map((cprod) => ({value: cprod.id, name: cprod.name}))}
                    onChange={this.onChangeCardProduct}
                    val={data.cardProductID}
                    required={true}
                  />
                </Col>
              </Row>
              {this.state.web &&
              <Row>
                <Col>
                  <FormGroup>
                    <LabelWithError for="pwd" required={true}>
                      {this.trans("password")}
                      <Tooltip trans={"zipPasswordUse"} showif={true}>
                        <Icon icon={ic_info_outline} size={20}/>
                      </Tooltip>
                    </LabelWithError>
                    <AInput
                      id="pwd"
                      type="password"
                      maxLength={200}
                      name="pwd"
                      onChange={this.onChange}
                      value={data.pwd}
                      required={true}
                      autocomplete="new-password"
                    />
                  </FormGroup>
                </Col>
              </Row>
              }
              <Row>
                <Col>
                  <FormGroupWErr
                    maxLength={200}
                    label="infoFieldOnCard"
                    str="info"
                    onChange={this.onChange}
                    val={data.info}
                    required={false}
                  />
                </Col>
              </Row>
              <FormGroup row>
                <Col xs={5} className="text-right pr-0 mt-2">
                  <Label className="dark-col">
                    <Input
                      type="checkbox" id="amountLimited"
                      name="amountLimited"
                      checked={data.amountLimited}
                      onChange={this.onChange}
                    />
                    {this.trans("amountLimit")}
                  </Label>
                </Col>
                <Col xs={7}>
                  <AInput
                    type="number"
                    id="amountLimitEur" name="amountLimitEur"
                    step="1" min={1}
                    onChange={this.onChangeInt}
                    value={data.amountLimited ? data.amountLimitEur : ""}
                    disabled={!data.amountLimited}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={5} className="text-right pr-0 mt-2">
                  <Label className="dark-col">
                    <Input
                      type="checkbox" id="countLimited"
                      name="countLimited"
                      checked={data.countLimited}
                      onChange={this.onChange}
                    />
                    {this.trans("countLimit")}
                  </Label>
                </Col>
                <Col xs={7}>
                  <AInput
                    type="number"
                    id="countLimit" name="countLimit"
                    min={0}
                    step="1"
                    onChange={this.onChangeInt}
                    value={data.countLimited ? data.countLimit : ""}
                    disabled={!data.countLimited}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={5} className="pr-0 mt-2">
                  <LabelWithError wrap contentEnd for="noOfCards">
                    {this.trans("noOfCards")}
                  </LabelWithError>
                </Col>
                <Col xs={7}>
                  <AInput
                    type="number"
                    id="noOfCards" name="noOfCards"
                    min={1} max={this.maxBatchSize}
                    step="1"
                    onChange={this.onChangeInt}
                    value={data.noOfCards || ""}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={5} className="pr-0 mt-2">
                  <LabelWithError contentEnd>
                    {this.trans("validFrom")}
                  </LabelWithError>
                </Col>
                <Col xs={7}>
                  <DatePicker
                    onChange={(e) => this.onChangeDate(e)}
                    minDate={new Date()}
                    value={data.validFrom}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={5} className="pr-0 mt-2">
                  <LabelWithError contentEnd>
                    {this.trans("validTo")}
                  </LabelWithError>
                </Col>
                <Col xs={7}>
                  <DatePicker
                    onChange={this.onChangeValidTo}
                    minDate={data.validFrom}
                    value={endTime}
                    disabled={data.cardProductID === undefined || data.cardProductID < 1}
                    format={"MM.yy"}
                    maxDetail={"year"}
                    minDetail={"year"}
                  />
                </Col>
              </FormGroup>
              <Button className="btn-add sm-btn float-right">
                {this.trans("saveCardsAndDownloadCSV")}
              </Button>
            </CoreForm>
          </Col>
        </Row>
      </>
    );
  }
}

export default withAlert(withRouter(withTranslation()(NewCardWoClientPage)));
