import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {Col, FormGroup} from "reactstrap";
import DatePicker from "../../helper/date_picker";
import {ignoreTimezoneAndToISOString} from "../../helper/utils";
import CoreForm from "../../helper/core_form";
import {apiCreateReportsForAllCompanies} from "../../../api/fetch";
import {saveAs} from "file-saver";
import moment from "moment";
import {FetchButton} from "../../helper/fetch_button";

class AllReportsContainer extends CoreComponent {

  state = {
    date: new Date(),
  };

  onChange = (e) => {
    this.setState({
      date: e
    });
  };

  bytesToZipFile = (data) => {
    const blob = new Blob([data], {type: "application/zip"});
    const fileName = this.trans("notCapitalized.statement") + moment(this.state.date).format("_MM_YYYY") + ".zip";
    saveAs(blob, fileName);
  };

  createReports = () => {
    const query = {
      date: ignoreTimezoneAndToISOString(this.state.date),
      xlsLanguage: this.props.i18n.language
    };
    return this.cancelable(apiCreateReportsForAllCompanies(query, (data) => {
      this.bytesToZipFile(data);
    }, (err) => {
      this.props.setErrors(err.errors);
    }));
  };

  render() {
    const {date} = this.state;

    return (
      <>
        <CoreForm onSubmit={this.createReports}>
          <FormGroup row>
            <Col xs={5} className="text-right mt-3 d-flex">
              <DatePicker
                onChange={this.onChange}
                value={date}
                format={"MM.yy"}
                maxDetail={"year"}
                minDetail={"year"}
                maxDate={new Date()}
              />
              <FetchButton className="btn-add sm-btn ml-3">
                {this.trans("createReports")}
              </FetchButton>
            </Col>
            <Col xs={7}>
            </Col>
          </FormGroup>
        </CoreForm>
      </>
    );
  }

}

export default withTranslation()(AllReportsContainer);
