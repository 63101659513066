import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import Tooltip from "../../helper/tooltip";
import {Button} from "reactstrap";
import {putRequest} from "../../../api/request";
import ModalWindow from "../../helper/modal_window";
import {apiFetchCustomerUnits} from "../../../api/fetch";
import SelectInput from "../../helper/select_input";

class EditCustomerUnitModal extends CoreComponent {
  changeCustomerUnitCaller = (toggleOpen) => {
    const hasChecked = this.props.selectedCardIDs.length > 0;

    const classes = ["btn-edit", "sm-btn"];
    if (!hasChecked) {
      classes.push("btn-disabled");
    }

    return (
      <Tooltip id={"selectCardsForChangeCustUnit"} trans={"selectCards"} showif={!hasChecked}>
        <Button className={classes.join(" ")} onClick={toggleOpen}>{this.trans("changeCustomerUnit")}</Button>
      </Tooltip>
    );
  };

  changeCustomerUnitBody = (data, errors, onChange) => {
    return (
      <SelectInput
        str="customerUnitID"
        label="customerUnit"
        err={errors.customerUnitID}
        val={data.customerUnitID}
        onChange={onChange}
        onInputChange={(...args) => apiFetchCustomerUnits(this.props.customerID, ...args)}
        mapper={(rows) => rows.map((row) => ({value: row.customerUnitID, name: row.name}))}
      />
    );
  };

  changeCustomerUnitOnSubmit = (data) => {
    const reqData = {
      targetCustomerUnitID: data.customerUnitID,
      selectedCardIDs: this.props.selectedCardIDs,
    };

    return new Promise((resolve, reject) => {
      this.cancelable(putRequest("/api/companies/cards/edit-customer-unit", reqData)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  render() {
    return (
      <div className="mr-2">
        <ModalWindow
          headerText={this.trans("changeCustomerUnitForChosenCards")}
          caller={this.changeCustomerUnitCaller}
          body={this.changeCustomerUnitBody}
          onSubmit={this.changeCustomerUnitOnSubmit}
        />
      </div>
    );
  }

}

export default withTranslation()(EditCustomerUnitModal);
