import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {PageTitle} from "../../helper/page_title";
import NewTerminalModal from "../../modals/terminal/new_terminal_modal";
import {NavLink} from "reactstrap";
import {ic_delete} from "react-icons-kit/md";
import Icon from "react-icons-kit";
import {apiFetchTerminals} from "../../../api/fetch";
import EditTerminalModal from "../../modals/terminal/edit_terminal_modal";
import {delRequest} from "../../../api/request";
import {withRouter} from "react-router-dom";
import {ConfirmationModal} from "../../helper/modal_window";
import TLink from "../../helper/table_link";
import Paginator from "../../helper/paginator";
import {CAN_EDIT_TERMINALS} from "../../helper/role_permissions";
import {CTable} from "../../helper/ctable";

class TerminalContainer extends CoreComponent {
  state = {
    terminals: [],
  };

  fetchTerminals = (filtersData) => {
    return this.cancelable(apiFetchTerminals(this.props.terminalOwnerID, filtersData, (data) => {
      this.setState({
        terminals: data.list,
      });
    }));
  };

  removeTerminal = (terminalID) => {
    this.cancelable(delRequest(`/api/terminal-owners/${this.props.terminalOwnerID}/terminals/${terminalID}`)).then(() => {
      this.navReload("closed");
    }).catch((error) => {
      if (error.cancelled) {
        return;
      }

      this.props.setErrors(error.errors);
    });
  };

  render() {
    const {terminals} = this.state;
    const {terminalOwnerID, user} = this.props;

    return (
      <>
        <div className="mt-3">
          <div className="my-3 d-flex flex-row justify-content-start">
            <PageTitle isGrouped={true} title={this.trans("terminals")}/>
            {user.hasPermission(CAN_EDIT_TERMINALS) && (
              <NewTerminalModal
                terminalOwnerID={terminalOwnerID}
              />
            )}
          </div>
          <Paginator
            next={this.fetchTerminals}
            pageLimit={10}
          >
            <CTable>
              <thead>
                <tr>
                  <th>{this.trans("terminalID")}</th>
                  <th>{this.trans("merchantID")}</th>
                  <th>{this.trans("payDesk")}</th>
                  <th>{this.trans("merchantRegNo")}</th>
                  {user.hasPermission(CAN_EDIT_TERMINALS) && (
                    <th colSpan={2}/>
                  )}
                </tr>
              </thead>
              <tbody>
                {terminals.map((terminal, i) => {
                  return (
                    <tr key={`terminal-${i}`}>
                      <TLink to={`/terminal-owners/${terminalOwnerID}/terminals/${terminal.id}`}>
                        {terminal.terminalID}
                      </TLink>
                      <td>{terminal.merchantID}</td>
                      <td>{terminal.payDesk || "-"}</td>
                      <td>{terminal.merchantRegNo || "-"}</td>
                      {user.hasPermission(CAN_EDIT_TERMINALS) && (
                        <>
                          <td className="icon-wrapper-width">
                            <EditTerminalModal
                              terminal={terminal}
                              terminalOwnerID={terminalOwnerID}
                            />
                          </td>
                          <td className="icon-wrapper-width">
                            <ConfirmationModal
                              title={`${terminal.terminalID} / ${terminal.merchantID}`}
                              subtitle={this.trans("areYouSureToCloseThis.terminal")}
                              onSubmit={() => this.removeTerminal(terminal.id)}
                              opener={(open) => (
                                <NavLink className="p-0 d-inline-block" href="#" onMouseUp={open}>
                                  <Icon icon={ic_delete}/>
                                </NavLink>
                              )}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </CTable>
          </Paginator>
        </div>
      </>
    );
  }
}

export default withRouter(withTranslation()(TerminalContainer));
