import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {Button, Col, Row} from "reactstrap";
import OverviewRow from "../../helper/overview_row";
import EditTerminalOwnerModal from "../../modals/terminal/edit_terminal_owner_modal";
import {delRequest} from "../../../api/request";
import {withRouter} from "react-router-dom";
import {ConfirmationModal} from "../../helper/modal_window";
import {CAN_EDIT_TERMINALS} from "../../helper/role_permissions";

class TerminalOwnerInfoContainer extends CoreComponent {
  removeTerminalOwner = () => {
    this.cancelable(delRequest(`/api/terminal-owners/${this.props.terminalOwnerID}`)).then(() => {
      this.navTo("/terminal-owners", "closed");
    }).catch((error) => {
      if (error.cancelled) {
        return;
      }

      this.props.setErrors(error.errors);
    });
  };

  render() {
    const {terminalOwner, terminalOwnerID, user} = this.props;

    return (
      <>
        <Row>
          <Col md={10}>
            <OverviewRow name={"name"} value={terminalOwner.name} nWidth={4} vWidth={8}/>
            <OverviewRow name={"identifier"} value={terminalOwner.identifier || "-"} nWidth={4} vWidth={8}/>
          </Col>
          <Col md={2}>
            {user.hasPermission(CAN_EDIT_TERMINALS) && (
              <>
                <div className="mb-2 text-right">
                  <EditTerminalOwnerModal
                    terminalOwnerID={terminalOwnerID}
                    terminalOwner={terminalOwner}
                  />
                </div>
                <div className="mb-2 text-right">
                  <ConfirmationModal
                    title={terminalOwner.name}
                    subtitle={this.trans("areYouSureToCloseThis.terminalOwner")}
                    onSubmit={this.removeTerminalOwner}
                    opener={(open) => (
                      <Button onMouseUp={open} className="btn-del sm-btn">
                        {this.trans("close")}
                      </Button>
                    )}
                  />
                </div>
              </>
            )}
          </Col>
        </Row>
      </>
    );
  }

}

export default withRouter(withTranslation()(TerminalOwnerInfoContainer));
