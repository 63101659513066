import moment from "moment";
import {timestampFmt} from "./utils";
import {saveAs} from "file-saver";

export const saveXLSX = (data, namePrefix = "") => {
  const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
  const fileName = namePrefix.replace(" ", "_") + moment(new Date()).format("_DD_MM_YYYY") + ".xlsx";
  saveAs(blob, fileName);
};

// FileName defines a utility class for working with file names
class FileName {
  constructor(name, extension) {
    this.name = name.replace(/\s+/g, "_");
    this.ext = extension;
  }

  // Default output timestamp format
  timestampOutputFmt = "YYYY_MM_DD_HH_mm_ss";

  // withTimestampFormat overwrites default output timestamp format
  withTimestampFormat = (fmt) => {
    this.timestampOutputFmt = fmt;
    return this;
  };

  // withTimestamp applies single timestamp (or date-range) to a file name
  withTimestamp = (dateStart = null, dateEnd = null) => {
    const inputDateFmt = timestampFmt;
    const outputDateFmt = this.timestampOutputFmt;
    let dateValue = moment().format(outputDateFmt);

    if (dateStart !== null) {
      dateValue = moment(dateStart, inputDateFmt).format(outputDateFmt);
    }
    if (dateEnd !== null) {
      dateValue = `${dateValue}-${moment(dateEnd, inputDateFmt).format(outputDateFmt)}`;
    }

    this.name = `${this.name}__${dateValue}`;
    return this;
  };

  getFullName = () => {
    return `${this.name}.${this.ext}`;
  };
}

export default FileName;
