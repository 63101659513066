import React from "react";
import {withTranslation} from "react-i18next";
import {withAlert} from "../../helper/alert";
import CoreComponent from "../../core_component";
import {centsToEuros, centsToEurosToStr, eurosToCents} from "../../helper/utils";
import {postRequest} from "../../../api/request";
import FormGroupWErr from "../../helper/form_group_w_err";
import {Button, FormGroup, Input} from "reactstrap";
import ModalWindow from "../../helper/modal_window";
import {apiSearchTerminals} from "../../../api/fetch";
import LabelWithError from "../../helper/label_with_error";
import {DatetimePick} from "../../helper/date_range_picker";
import moment from "moment";

class NewCardTransactionModal extends CoreComponent {
  state = {
    terminals: [],
    manualDateIn: false,
  };

  terminalSearchTimeout = null;
  maxTransactionAmount = 1000000;

  componentWillUnmount() {
    clearTimeout(this.terminalSearchTimeout);
  }

  createNewTransaction = (trans) => {
    const reqData = Object.assign({}, trans, {
      amount: eurosToCents(trans.amount),
      terminalID: parseInt(trans.terminalID),
      dateIn: this.state.manualDateIn ? trans.dateIn : null,
    });

    return new Promise((resolve, reject) => {
      this.cancelable(postRequest(`/api/cards/${this.props.cardID}/transactions`, reqData)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  toggleManualDateIn = (ev) => {
    this.setStateValue("manualDateIn", ev.target.checked);
  };

  searchTerminals = (searchVal) => {
    clearTimeout(this.terminalSearchTimeout);

    // Ignore empty search value (but first let's clear timeout)
    if (!searchVal) {
      return;
    }

    const queryData = {
      search: searchVal,
      limit: 5
    };

    this.terminalSearchTimeout = setTimeout(() => {
      this.cancelable(apiSearchTerminals(queryData, (respData) => {
        this.setStateValue("terminals", respData.list.map((term) => {
          return {
            value: term.id,
            name: term.formattedParams,
          };
        }));
      }, (error) => {
        this.props.setErrors(error.errors);
      }));
    }, 500);
  };

  opener = (toggleOpen) => {
    const {maxAmount} = this.props;
    const canCreateNewTransaction = maxAmount === null || maxAmount > 0;

    return (
      <Button className="btn-add sm-btn" disabled={!canCreateNewTransaction} onClick={toggleOpen}>
        {this.trans("addNewTransaction")}
      </Button>
    );
  };

  body = (data, _, onChange) => {
    const {maxAmount, errors} = this.props;
    const {manualDateIn} = this.state;

    let amtMaxValue = this.maxTransactionAmount;
    let amtPlaceholder = "";

    if (maxAmount !== null) {
      amtMaxValue = centsToEuros(maxAmount);
      amtPlaceholder = `${this.trans("maxAmount")}: ${centsToEurosToStr(maxAmount)}`;
    }

    return (
      <>
        <FormGroupWErr
          str={"amount"} err={errors.amount}
          val={data.amount || ""} onChange={onChange}
          type="number" min="0.01" max={amtMaxValue} step="0.01"
          placeholder={amtPlaceholder}
        />

        <FormGroupWErr
          str={"terminalID"}
          label={"terminal"}
          type={"select"}
          err={errors.terminalID}
          val={data.terminalID}
          options={this.state.terminals}
          onChange={onChange}
          onInputChange={this.searchTerminals}
          placeholder={`${this.trans("search")}...`}
        />

        <FormGroup check>
          <Input type={"checkbox"} id={"manualDateIn"} checked={manualDateIn} onChange={this.toggleManualDateIn}/>
          <LabelWithError for={"manualDateIn"} required={manualDateIn}>
            {this.trans("setTimeOfTransaction")}
          </LabelWithError>
        </FormGroup>

        {manualDateIn &&
        <FormGroup>
          <DatetimePick
            name="dateIn"
            onChange={onChange}
            value={data.dateIn}
            minDate={moment().subtract(60, "days").toDate()}
            maxDate={new Date()}
            required={manualDateIn}
          />
        </FormGroup>
        }

        <FormGroupWErr
          str={"comment"} type="textarea" err={errors.comment} maxLength={500}
          val={data.comment || ""} onChange={onChange}
          className="required-textarea"
        />

        <FormGroup check>
          <Input
            type={"checkbox"} id={"showCommentInReports"} name={"showCommentInReports"}
            checked={data.showCommentInReports} onChange={onChange}
          />
          <LabelWithError for={"showCommentInReports"}>
            {this.trans("showCommentInReports")}
          </LabelWithError>
        </FormGroup>
      </>
    );
  };

  render() {
    const {setErrors, clearAlert, renderAlert} = this.props;

    return (
      <ModalWindow
        headerText={this.trans("newTransaction")}
        body={this.body}
        caller={this.opener}
        onSubmit={this.createNewTransaction}
        /* Overwrite alert handling methods */
        setErrors={setErrors}
        clearAlert={clearAlert}
        renderAlert={renderAlert}
      />
    );
  }
}

export default withAlert(withTranslation()(NewCardTransactionModal));
