import React from "react";
import {Col} from "reactstrap";
import {withTranslation} from "react-i18next";
import FormGroupWErr from "../helper/form_group_w_err";
import FiltersContainer from "../helper/filters_container";
import CoreComponent from "../core_component";
import {apiFetchCardProducts} from "../../api/fetch";

class CardsWithoutClientFilters extends CoreComponent {

  state = {
    cardProducts: [],
  };

  componentDidMount() {
    this.getCardProducts();
  }

  getCardProducts = () => {
    return this.cancelable(apiFetchCardProducts((data) => {
      this.setState({cardProducts: data});
    }, (err) => {
      this.props.setErrors(err.errors);
    }));
  };

  renderFilters = (filtersData, onChange) => {
    return (
      <>
        <Col md={3}>
          <FormGroupWErr
            str={"pan"} val={filtersData.pan}
            onChange={onChange} required={false}
          />
        </Col>
        <Col md={3}>
          <FormGroupWErr
            label={"nameOnCard"} str={"name"} val={filtersData.name}
            onChange={onChange} required={false}
          />
        </Col>
        <Col md={3}>
          <FormGroupWErr
            label={"batch"} str={"cardBatchID"} val={filtersData.cardBatchID}
            onChange={onChange} required={false} type={"number"}
          />
        </Col>
        <Col md={3}>
          <FormGroupWErr
            label="cardProduct"
            str="cardProductID"
            type="select"
            options={this.state.cardProducts.map((cprod) => ({value: cprod.id, name: cprod.name}))}
            onChange={onChange}
            val={filtersData.cardProductID}
            required={false}
          />
        </Col>
        <Col md={3}>
          <FormGroupWErr
            str="activated"
            label="cardState"
            toolTip={"activatedCardIsAddedToPrimaryCardList"}
            type="select"
            options={[{value: false, name: this.trans("notActivated")},
              {value: true, name: this.trans("activated")},
            ]}
            onChange={onChange}
            val={filtersData.activated}
            required={false}
          />
        </Col>
      </>
    );
  };

  render() {
    return (
      <FiltersContainer
        renderFilters={this.renderFilters}
        onFilter={this.props.onFilter}
        initData={this.props.initData}
      />
    );
  }
}

export default withTranslation()(CardsWithoutClientFilters);
