import React from "react";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {getRequest, postRequest, putRequest} from "../../api/request";
import {Button, Col, Row} from "reactstrap";
import {DISTRIBUTOR} from "../helper/user_roles";
import OverviewRow from "../helper/overview_row";
import {PageTitle} from "../helper/page_title";
import FormGroupWErr from "../helper/form_group_w_err";
import ModalWindow, {ConfirmationModal} from "../helper/modal_window";
import LoadingPage from "./loading_page";
import {withAlert} from "../helper/alert";
import CoreComponent from "../core_component";
import Icon from "react-icons-kit";
import {ic_info_outline} from "react-icons-kit/md";
import Tooltip from "../helper/tooltip";
import {
  apiCloseAccount, apiCloseUser,
  apiFetchAvailableDistributors,
  apiGetOperator, apiGetRoles,
  apiGetSelfOperator,
  apiReallowLogin,
  apiUpdateOperator, apiUpdateOperatorSelf
} from "../../api/fetch";
import SelectInput from "../helper/select_input";

class OperatorViewPage extends CoreComponent {
  state = {
    operator: {},
    /** new user modal state **/
    roles: [],
    languages: [],
  };

  // ID kattub -        kasutaja toimetab iseenda alt
  // ID puudub üldse -  kasutaja toimetab iseenda alt
  // Kõik muud juhud -  kasutaja toimetab teise kasutaja kallal

  // mitte range kontroll, lubame kontrolli stringi ja numbri vahel
  accountID = (this.props.user.selectedAccountID == this.props.match.params.aid ? "self" : this.props.match.params.aid) || "self";
  companyID = this.props.match.params.cid;

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);
    this.fetchInitial(this.fetchOperator());
  }

  fetchOperator = () => {
    const onResolve = resp => this.setStateValue("operator", resp);
    if (this.accountID === "self") {
      return this.cancelable(apiGetSelfOperator(onResolve));
    }
    return this.cancelable(apiGetOperator(this.accountID, onResolve));
  };

  isSelfUser = () => {
    return this.props.user.name === this.state.operator.email || this.accountID === "self";
  };

  reAllowLogin = () => {
    return this.cancelable(apiReallowLogin(this.accountID, () => {
      this.navReload("saved");
    }, (err) => {
      this.props.setErrors(err.errors);
    }));
  };

  backNavigation = (notification) => {
    const {user} = this.props;

    if (user.isAdminLike()) {
      if (!this.companyID) {
        // admin/distributor vaatab adminni
        this.navTo("/operators", notification);
      } else {
        // admin/distributor vaatab firma kasutajat
        this.navTo(`/companies/${this.companyID}`, notification);
      }
    } else {
      // firma vaatab kasutajat
      this.navTo("/companies/company", notification);
    }
  };

  /*** edit user modal ***/

  editUserCaller = (toggleOpen) => {
    return (
      <Button className={"btn-edit sm-btn"} onClick={(ev) => toggleOpen(ev, this.state.operator)}>
        {this.trans("edit")}
      </Button>
    );
  };

  editUserBody = (data, errors, onChange) => {
    const {roles, languages} = this.state;

    return (
      <>
        {this.isSelfUser() && (
          <>
            <FormGroupWErr maxLength={90} str="name" err={errors.name} val={data.name} onChange={onChange}/>
            <FormGroupWErr maxLength={254} str="email" type="email" err={errors.email} val={data.email} onChange={onChange}/>
          </>
        )}
        {roles.length > 0 && (
          <FormGroupWErr
            str="roleNr"
            label="role"
            type="select"
            err={errors.roleNr}
            options={
              roles.map((role) => (
                {value: role.id, name: role.name}
              ))
            }
            onChange={onChange}
            val={data.roleNr}
          />
        )}
        {this.props.user.isAdmin() && data.roleNr === DISTRIBUTOR && (
          <SelectInput
            str="distributorID"
            label="distributor"
            err={errors.distributorID}
            val={data.distributorID}
            onChange={onChange}
            onInputChange={apiFetchAvailableDistributors}
            fetchOnMount={true}
          />
        )}
        {this.isSelfUser() && (
          <FormGroupWErr
            str="lang"
            label="language"
            type="select"
            err={errors.lang}
            options={languages.map((lang) => ({value: lang.id, name: this.trans(`languages.${lang.id}`)}))}
            onChange={onChange}
            val={data.lang}
          />
        )}
      </>
    );
  };

  editUserOnSubmit = (data) => {
    const setNameResolve = resp => {
      if (resp.data && resp.data.isSelf) {
        this.props.user.setName(data.name);
      }
    };

    if (this.accountID === "self") {
      return this.cancelable(apiUpdateOperatorSelf(data, setNameResolve));
    } else {
      return this.cancelable(apiUpdateOperator(this.accountID, data, setNameResolve));
    }
  };

  editUserOnOpen = () => {
    return new Promise((resolve, reject) => {
      const {roles, languages} = this.state;
      let promises = [];

      if (this.accountID !== "self" && roles.length === 0 && this.props.user.isAdminLikeOrSuper()) {
        promises.push(new Promise((presolve, preject) => {
          const reqData = {
            companyID: this.companyID,
          };
          return apiGetRoles(reqData, resp => {
            if (Array.isArray(resp)) {
              this.setStateValue("roles", resp);
            }

            presolve();
          }, error => {
            preject(error);
          });
        }));
      }

      if (this.isSelfUser() && languages.length <= 0) {
        promises.push(new Promise((presolve, preject) => {
          this.cancelable(getRequest("/api/languages")).then((resp) => {
            if (Array.isArray(resp.data)) {
              this.setStateValue("languages", resp.data);
            }

            presolve();
          }).catch((error) => {
            preject(error);
          });
        }));
      }

      return Promise.all(promises).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  closeAccount = () => {
    return this.cancelable(apiCloseAccount(this.accountID,
      () => this.backNavigation("closed"),
      err => this.props.setErrors(err.errors)
    ));
  };

  closeUser = () => {
    return this.cancelable(apiCloseUser(this.accountID,
      () => this.backNavigation("closed"),
      err => this.props.setErrors(err.errors)
    ));
  };

  /*** change password modal ***/

  changePasswordCaller = (toggleOpen) => {
    return (
      <div className="my-2">
        <Button className={"btn-save sm-btn"} onClick={toggleOpen}>
          {this.trans("changePassword")}
        </Button>
      </div>
    );
  };

  changePasswordBody = (data, errors, onChange) => {
    return (
      <>
        <FormGroupWErr maxLength={256} str={"currentPassword"} type={"password"} err={errors.currentPassword} val={data.currentPassword} onChange={onChange}/>
        <FormGroupWErr maxLength={256} str={"password"} type={"password"} err={errors.password} val={data.password} onChange={onChange}/>
        <FormGroupWErr
          maxLength={256} label={"repeatPassword"} str={"repPassword"} type={"password"}
          err={errors.repPassword} val={data.repPassword} onChange={onChange}/>
      </>
    );
  };

  changePasswordOnSubmit = (data) => {
    return new Promise((resolve, reject) => {
      this.cancelable(putRequest("/api/pwchange", data)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  /*** ***/

  renderButtons = () => {
    const {operator} = this.state;
    const {user} = this.props;

    if (this.defined(operator.roleNr)) {
      if (operator.isSelf || operator.canEdit) {
        return (
          <div className={"float-right"}>
            {!(!operator.isSelf && operator.roleNr === DISTRIBUTOR && user.isDistributor()) && (
              <div className={"pb-3"}>
                <ModalWindow
                  headerText={this.trans("editUser")}
                  caller={this.editUserCaller}
                  body={this.editUserBody}
                  onSubmit={this.editUserOnSubmit}
                  onOpen={this.editUserOnOpen}
                />
              </div>
            )}
            {(!operator.isSelf || (user.isAdmin() && this.accountID !== "self")) && (
              <div className="pb-3">
                <ConfirmationModal
                  title={`${operator.name} (${operator.role})`}
                  subtitle={this.trans("areYouSureToCloseThis.account")}
                  onSubmit={this.closeAccount}
                  opener={(open) => (
                    <Button className={"btn-del sm-btn"} onClick={open}>
                      {this.trans(user.isAdmin() ? "closeAccount" : "close")}
                    </Button>
                  )}
                />
              </div>
            )}
            {!operator.isSelf && user.isAdmin() && (
              <div className="pb-3">
                <ConfirmationModal
                  title={operator.name}
                  subtitle={this.trans("areYouSureToCloseThis.user")}
                  onSubmit={this.closeUser}
                  opener={(open) => (
                    <Tooltip trans={"closingUserClosesAllRelatedAccountsToThisEmail"} showif={true}>
                      <Button className={"btn-del sm-btn"} onClick={open}>
                        {this.trans("closeUser")}
                      </Button>
                    </Tooltip>
                  )}
                />
              </div>
            )}
            {operator.isSelf && (
              <ModalWindow
                headerText={this.trans("changePassword")}
                caller={this.changePasswordCaller}
                body={this.changePasswordBody}
                onSubmit={this.changePasswordOnSubmit}
                clearOnError={true}
              />
            )}

            {((process.env.REACT_APP_MODE === "dev") && !operator.name && operator.debug_registerUuid) && (
              <div className="my-3">
                <Button className={"btn-save sm-btn"} onClick={() => {
                  window.localStorage.clear();
                  postRequest("/api/logout").promise.finally(() => {
                    const uuid = operator.debug_registerUuid;
                    window.location.pathname = `register/${uuid}`;
                  });
                }}>
                  DEBUG: Logout and register
                </Button>
              </div>
            )}
          </div>
        );
      }
    }

    return null;
  };

  render() {
    const {operator} = this.state;
    let roleStr = operator.role;

    if (operator.roleNr === DISTRIBUTOR) {
      roleStr = `${roleStr} (${operator.distributorName})`;
    }

    if (!this.state.isPageReady) {
      return <LoadingPage/>;
    }

    return (
      <div>
        {this.props.renderAlert()}
        <PageTitle title={this.props.title}/>
        {this.renderButtons()}
        <Row>
          <Col md={10}>
            <OverviewRow name={"name"} value={operator.name ? operator.name : <i>{this.trans("registeringUser")}</i>} nWidth={3} vWidth={9}/>
            <OverviewRow name={"email"} value={operator.email} nWidth={3} vWidth={9}/>
            <OverviewRow name={"role"} value={roleStr} nWidth={3} vWidth={9}/>
            <OverviewRow name={"language"} value={operator.lang ? this.trans(`languages.${operator.lang}`) : "-"} nWidth={3} vWidth={9}/>
            {!operator.loginAllowed && (
              <Row>
                <Col md={3} className="text-right">
                  <Tooltip trans={"loginTemporarilyNotAllowedMsg"} showif={true}>
                    <Icon icon={ic_info_outline} size={20}/>
                  </Tooltip>
                </Col>
                {this.props.user.isSuperuser() && !operator.isSelf && (
                  <Col md={9}>
                    <Button className="btn-edit sm-btn" onClick={() => this.reAllowLogin()}>
                      {this.trans("allowLogin")}
                    </Button>
                  </Col>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default withAlert(withTranslation()(withRouter(OperatorViewPage)));
