import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

class TLink extends CoreComponent {

  //roles ehk lubatud kasutaja rollid. Vaikimisi lubab kõiki
  //rolesi defineerides tuleb passida sisse ka user prop
  render() {
    const {roles, user, to, disableLink, children} = this.props;

    let link = false;
    if(!disableLink) {
      if(roles !== undefined) {
        link = user.isRole(roles);
      } else {
        link = true;
      }
    }

    return (
      <td>
        {link ?
          <Link to={to}>
            {children}
          </Link> : children
        }
      </td>
    );
  }

}

export default withTranslation()(TLink);
