import React from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import LabelWithError from "../helper/label_with_error";
import CoreForm from ".././helper/core_form";
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {withAlert} from "../helper/alert";
import {PageTitle} from "../helper/page_title";
import DatePicker from "../helper/date_picker";
import AInput from "../helper/apple_input";
import {apiActivateCard, apiGetActivatableCards} from "../../api/fetch";
import CoreComponent from "../core_component";
import FormGroupWErr from "../helper/form_group_w_err";
import {centsToEuros, eurosToCents, ignoreTimezoneAndToISOString} from "../helper/utils";


class ActivateCards extends CoreComponent {

  state = {
    card: {},
    searched: false,
  }

  noPan = () => {
    const card = this.state.card;
    return Object.keys(card).length === 0 || card.pan === undefined || card.pan.length < 5;
  };

  localActivateCard = () => {
    const reqObj = Object.assign({}, this.state.card);
    reqObj.amountLimitEur = eurosToCents(reqObj.amountLimitEur);
    reqObj.validTo = ignoreTimezoneAndToISOString(reqObj.validTo);

    return this.cancelable(apiActivateCard(reqObj, (resp) => {
      if (resp.notFound) {
        this.props.setWarningMsg("noCardFound");
        return;
      }

      this.props.setInfoMsg("activated");
      this.setStateWithObject({
        card: {},
      });
    }, (err) => {
      this.props.setErrors(err.errors);
    }));
  };

  onChangeCard = (e) => {
    const automaticallySearch = (noPan, valLen) => {
      if (noPan && valLen > 9) {
        this.setState({
          searched: true,
        });
        this.localGetActivatableCard();
      }
    };
    const noPan = this.noPan();
    const valLen = e.target.value.length;
    this.setStateWithObject({
      card: {
        ...this.state.card,
        pan: e.target.value,
      },
      searched: false,
    }, () => automaticallySearch(noPan, valLen));
  };

  localGetActivatableCard = () => {
    const query = {pan: this.state.card.pan};
    this.cancelable(apiGetActivatableCards(query, (respData) => {
      if (Object.entries(respData).length !== 0) {
        this.setStateWithObject({
          card: {
            cardID: respData.cardID,
            pan: respData.pan,
            amountLimit: respData.amountLimit,
            amountLimitEur: centsToEuros(respData.amountLimit),
            validTo: new Date(respData.validTo),
            validToMax: new Date(respData.validToMax), // see peab olema const
            amountLimitChecked: !!respData.amountLimit,
            validFrom: new Date(respData.validFrom),
          },
          searched: true,
        });
      } else {
        this.setStateWithObject({
          card: {
            pan: this.state.card.pan,
          },
          searched: false,
        });
        this.props.setWarningMsg("noCardFound");
      }
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  onChangeValidTo = (e) => {
    this.setStateWithObject({
      card: {
        ...this.state.card,
        validTo: e,
      }
    });
  };

  onChangeAmountLimit = () => {
    this.setStateWithObject({
      card: {
        ...this.state.card,
        amountLimitChecked: !this.state.card.amountLimitChecked,
      }
    });
  };

  onChangeAmountLimitEur = (e) => {
    this.setStateWithObject({
      card: {
        ...this.state.card,
        amountLimitEur: e.target.value,
      }
    });
  };

  render() {
    const {card, searched} = this.state;

    return (
      <>
        {this.props.renderAlert()}
        <PageTitle title={this.props.title}/>
        <Row>
          <Col md={6}>
            <CoreForm onSubmit={this.localActivateCard}>
              <FormGroupWErr
                label={"card"}
                str={"pan"}
                val={card.pan}
                onChange={this.onChangeCard}
              />
              <FormGroup row>
                <Col xs={5} className="text-right pr-0 mt-2">
                  <Label className="dark-col">
                    <Input
                      type="checkbox" id="amountLimitChecked"
                      name="amountLimitChecked"
                      label="amountLimit"
                      checked={card.amountLimitChecked}
                      onChange={this.onChangeAmountLimit}
                    />
                    {this.trans("amountLimit")}
                  </Label>
                </Col>
                <Col xs={7}>
                  <AInput
                    type="number"
                    id="amountLimitEur" name="amountLimitEur"
                    step="1" min={1}
                    onChange={this.onChangeAmountLimitEur}
                    value={card.amountLimitChecked ? card.amountLimitEur : ""}
                    disabled={!card.amountLimitChecked}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={5} className="text-right pr-0 mt-2">
                  <LabelWithError>
                    {this.trans("validTo")}
                  </LabelWithError>
                </Col>
                <Col xs={7}>
                  <DatePicker
                    onChange={this.onChangeValidTo}
                    minDate={card.validFrom}
                    maxDate={card.validToMax}
                    value={card.validTo}
                    format={"MM.yy"}
                    maxDetail={"year"}
                    minDetail={"year"}
                  />
                </Col>
              </FormGroup>
              <div className="float-right">
                <Button className="btn-filtr sm-btn mr-2" onClick={this.localGetActivatableCard} disabled={searched}>
                  {this.trans("search")}
                </Button>
                <Button type="submit" className="btn-filtr sm-btn" disabled={!searched}>
                  {this.trans("activateCard")}
                </Button>
              </div>
            </CoreForm>
          </Col>
        </Row>
      </>
    );
  }
}

export default withAlert(withRouter(withTranslation()(ActivateCards)));
