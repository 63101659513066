import React from "react";
import {PageTitle} from "./helper/page_title";
import CoreComponent from "./core_component";
import CompanyTotalsContainer from "./containers/company/company_totals_container";
import HomeRecentTransactions from "./containers/home/home_recent_transactions";
import {withAlert} from "./helper/alert";
import HomeExpiringCards from "./containers/home/home_expiring_cards";
import {Alert, Button, Col, Row} from "reactstrap";
import {CAN_ACTIVATE_CARDS, CAN_GET_CARDS, CAN_GET_TRANSACTIONS} from "./helper/role_permissions";
import {withTranslation} from "react-i18next";

class Home extends CoreComponent {
  componentDidMount() {
    const {user} = this.props;
    const perms = user.permissions;
    // kui väga spetsiifiliselt vaid 1 'CAN_ACTIVATE_CARDS' permission olemas, siis suuname kohe "/cards/activate"
    if (perms.length === 1 && perms[0] === CAN_ACTIVATE_CARDS && user.companyAsCustomer) {
      this.navTo("/cards/activate");
    }
    this.props.setInfoMsg(this.props.location.notification);
  }

  render() {
    const {user} = this.props;
    const perms = user.permissions;

    return (
      <div>
        {this.props.renderAlert()}
        <div className={"my-3 d-flex flex-row justify-content-start"}>
          <PageTitle title={this.props.title} isGrouped={true}/>
          {/*
          Mega ericase, aga handled: kui CAN_ACTIVATE_CARDS aga pole CAN_GET_CARDS, kuid samas mingid muud permissionid ka.
          Kuna ilma CAN_GET_CARDS ei näeks nuppu ja automaatset liikumist ei toimu, siis tekib nupp siia
          */}
          {user.hasPermission(CAN_ACTIVATE_CARDS) && !user.hasPermission(CAN_GET_CARDS) && user.companyAsCustomer && (
            <Button className="ml-2 btn-add sm-btn" onClick={() => this.navTo("/cards/activate")}>
              {this.trans("activateCard")}
            </Button>
          )}
        </div>
        {(perms.length === 1 && perms[0] === CAN_ACTIVATE_CARDS && !user.companyAsCustomer) && (
          <Alert color={"warning"}>
            {this.trans("youCantActivateCardsBecauseCompanyDoesNotMsg")}
          </Alert>
        )}
        {user.hasAnyPermission(CAN_GET_TRANSACTIONS, CAN_GET_CARDS) && (
          <div>
            <Row>
              {user.hasPermission(CAN_GET_TRANSACTIONS) && (
                <Col className="sec-panel m-2">
                  <CompanyTotalsContainer
                    setErrors={this.props.setErrors}
                  />
                </Col>
              )}
              {user.hasPermission(CAN_GET_CARDS) && (
                <Col xl={7} className="sec-panel m-2">
                  <HomeExpiringCards
                    setErrors={this.props.setErrors}
                    user={user}
                  />
                </Col>
              )}
            </Row>

            {user.hasPermission(CAN_GET_TRANSACTIONS) && (
              <Row>
                <Col className="sec-panel m-2">
                  <HomeRecentTransactions
                    setErrors={this.props.setErrors}
                    user={user}
                  />
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withAlert(withTranslation()(Home));
