import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import CoreForm from "../../helper/core_form";
import {Button, FormGroup} from "reactstrap";
import {getRequest, postRequest} from "../../../api/request";
import {PaginationLimit} from "../../helper/utils";
import {CAN_EDIT_CUSTOMERS} from "../../helper/role_permissions";

class CustomerMemo extends CoreComponent {

  state = {
    memos: [],
    memo: "",
    hasMore: true,
    page: 1,
  };

  componentDidMount() {
    this.fetchMemos();
  }

  fetchMemos = () => {
    return new Promise((resolve, reject) => {
      this.cancelable(getRequest(`/api/customers/${this.props.customerID}/memos?page=${this.state.page}`)).then((resp) => {
        if (Array.isArray(resp.data)) {
          this.setState({
            memos: this.state.memos.concat(resp.data),
            page: this.state.page + 1
          });
        }
        if (resp.data.length === 0 || resp.data.length < PaginationLimit ) {
          this.setState({hasMore: false});
        }

        resolve();
      }).catch((error) => {
        if (error.cancelled) {
          reject(error);
          return;
        }
        this.props.setErrors(error.errors);
        reject(error);
      });
    });
  };

  addMemo = () => {
    return new Promise((resolve, reject) => {
      this.cancelable(postRequest(`/api/customers/${this.props.customerID}/memos`, {memo: this.state.memo})).then((resp) => {
        this.setState({
          memo: "",
          memos: [resp.data, ...this.state.memos],
        });
        resolve();
      }).catch((error) => {
        if (error.cancelled) {
          reject(error);
          return;
        }
        this.props.setErrors(error.errors);
        reject(error);
      });
    });
  };

  onMemoChange = (ev) => {
    this.setState({memo: ev.target.value});
  };

  render() {
    const {memos, hasMore, memo} = this.state;
    const {user} = this.props;

    return (
      <>
        <div className="sec-panel px-3 h-100 flexbox-column">
          <h3 style={{flex:"initial"}} className="dark-col mt-3">{this.trans("memo")}</h3>
          <div style={{flex:"auto"}}> </div>
          <div style={{flex:"initial"}}>
            <div id="scrollableDiv" className="scroll-div">
              <InfiniteScroll
                dataLength={memos.length}
                next={this.fetchMemos}
                hasMore={hasMore}
                loader={<div className="loader" key={0}>{this.trans("loading")} ...</div>}
                scrollableTarget="scrollableDiv">
                {memos.map((m, i) => (
                  <div key={`memo-${i}`}>
                    <div className={"memo-title"}>{m.time} - {m.user}</div>
                    <p className={"memo-text"}>{m.memo}</p>
                  </div>)
                )}
              </InfiniteScroll>
            </div>
            {user.hasPermission(CAN_EDIT_CUSTOMERS) && (
              <CoreForm onSubmit={this.addMemo} className="brdr-top">
                <FormGroup className={"mt-1"}>
                  <textarea maxLength={2000} onChange={this.onMemoChange} rows={4} value={memo || ""} required className="required-textarea apple-input"/>
                  <div className={"d-flex justify-content-end"}>
                    <Button className={"btn-add sm-btn"}>{this.trans("add")}</Button>
                  </div>
                </FormGroup>
              </CoreForm>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(CustomerMemo);
