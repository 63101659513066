import React from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {withAlert} from "../helper/alert";
import CoreComponent from "../core_component";
import {apiDeleteDistributor, apiGetAvailableDistributor} from "../../api/fetch";
import {Button, Col, Row, TabContent} from "reactstrap";
import OverviewRow from "../helper/overview_row";
import {PageTitle} from "../helper/page_title";
import EditDistributorModal from "../modals/distributor/edit_distributor_modal";
import {ConfirmationModal} from "../helper/modal_window";
import LoadingPage from "./loading_page";
import TLink from "../helper/table_link";
import NavTabs, {TabPaneOnOpen} from "../helper/nav_tabs";
import {DistributorTabConsumer} from "../helper/context";
import DistributorChangesContainer from "../containers/distributor/distributor_changes_container";
import {CTable} from "../helper/ctable";

class DistributorPage extends CoreComponent {
  state = {
    distributor: {},
  };

  tabs = {
    CHANGES: "changes",
  };

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);
    this.fetchInitial(this.fetchDistributor());
  }

  fetchDistributor = () => {
    return this.cancelable(apiGetAvailableDistributor(this.props.match.params.id, (respData) => {
      this.setState({
        distributor: respData,
      });
    }));
  };

  deleteDistributor = () => {
    this.cancelable(apiDeleteDistributor(this.props.match.params.id, () => {
      this.navTo("/distributors", "closed");
    }, (err) => {
      if (err.cancelled) {
        return;
      }

      this.props.setErrors(err.errors);
    }));
  };

  render() {
    const {distributor} = this.state;

    if (!this.state.isPageReady) {
      return <LoadingPage/>;
    }

    const navTabs = [
      {id: this.tabs.CHANGES, title: "changes"},
    ];

    return (
      <div>
        {this.props.renderAlert()}

        <PageTitle title={`${this.trans("distributor")} ${distributor.name}`}/>

        <Row>
          <Col lg={7}>
            <Row>
              <Col md={9}>
                <OverviewRow name={"name"} value={distributor.name} nWidth={4} vWidth={8}/>
              </Col>
              <Col md={3} className="text-right">
                <div className="my-1">
                  <EditDistributorModal
                    distributorID={distributor.id}
                    initialData={{
                      name: distributor.name,
                    }}
                  />
                </div>

                <div className="my-1">
                  <ConfirmationModal
                    title={this.trans("deleteDistributor")}
                    onSubmit={() => this.deleteDistributor(distributor.id)}
                    opener={(open) => (
                      <Button onMouseUp={open} className="btn-del sm-btn">
                        {this.trans("close")}
                      </Button>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg={5}>
            <Row>
              <Col xs={12} className="my-1">
                <div className="sec-panel p-3">
                  <h5>{this.trans("relatedCompanies")}</h5>

                  <CTable>
                    <thead>
                      <tr>
                        <th>{this.trans("name")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {distributor.companies && distributor.companies.map((comp, i) => {
                        return (
                          <tr key={`comp-${i}`}>
                            <TLink to={`/companies/${comp.id}`}>
                              {comp.name}
                            </TLink>
                          </tr>
                        );
                      })}
                    </tbody>
                  </CTable>
                </div>
              </Col>

              <Col xs={12} className="my-1">
                <div className="sec-panel p-3">
                  <h5>{this.trans("relatedOperators")}</h5>

                  <CTable>
                    <thead>
                      <tr>
                        <th>{this.trans("name")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {distributor.operators && distributor.operators.map((oper, i) => {
                        return (
                          <tr key={`oper-${i}`}>
                            <TLink to={`/operators/${oper.id}`}>
                              {oper.name || <i>{this.trans("registeringUser")}</i>}
                            </TLink>
                          </tr>
                        );
                      })}
                    </tbody>
                  </CTable>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <DistributorTabConsumer>
              {({activeTab, toggleTab}) => (
                <div className={"mt-2"}>
                  <NavTabs
                    tabs={navTabs}
                    activeTab={activeTab}
                    toggleTab={toggleTab}
                  />
                  <TabContent activeTab={activeTab}>
                    <TabPaneOnOpen tabId={this.tabs.CHANGES}>
                      <DistributorChangesContainer
                        distributorID={this.props.match.params.id}
                        setErrors={this.props.setErrors}
                      />
                    </TabPaneOnOpen>
                  </TabContent>
                </div>
              )}
            </DistributorTabConsumer>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withAlert(withTranslation()(withRouter(DistributorPage)));
