import React from "react";
import {withRouter} from "react-router-dom";
import {Button, Col, FormGroup, Row} from "reactstrap";
import LabelWithError from "../helper/label_with_error";
import CoreForm from "../helper/core_form";
import {withTranslation} from "react-i18next";
import {PageTitle} from "../helper/page_title";
import {withAlert} from "../helper/alert";
import CoreComponent from "../core_component";
import AInput from "../helper/apple_input";
import {apiPwChangeUuid} from "../../api/fetch";
import PropTypes from "prop-types";

class _PWChange extends CoreComponent {
  state = {
    password: "",
    repPassword: "",
  };

  uuid = this.props.match.params.id;

  onChange = (e) => {
    this.setStateValue(e.target.name, e.target.value);
  };

  onSubmit = () => {
    const {repPassword, password} = this.state;

    const reqData = {repPassword, password};
    return this.cancelable(apiPwChangeUuid(this.uuid, reqData, resp => {
      this.props.login(resp);
      this.navReplace("/");
    }, error => {
      this.props.setErrors(error.errors);
      this.setState(Object.assign({}, this.state, {
        password: "",
        repPassword: ""
      }));
    }));
  };

  render() {
    const {errors, renderAlert} = this.props;

    return (
      <Col className={"panel"}>
        <Row className={"justify-content-center"}>
          <Col md={5}>
            <PageTitle title={this.trans("changePassword")}/>
            {renderAlert()}
            <CoreForm onSubmit={this.onSubmit}>
              <FormGroup>
                <LabelWithError error={errors.password} for="password">{this.trans("password")}</LabelWithError>
                <AInput maxLength={256} type="password" name="password" id="password" required onChange={this.onChange}/>
              </FormGroup>
              <FormGroup>
                <LabelWithError error={errors.repPassword} for="repPassword">{this.trans("repeatPassword")}</LabelWithError>
                <AInput maxLength={256} type="password" name="repPassword" id="repPassword" required onChange={this.onChange}/>
              </FormGroup>
              <FormGroup className={"d-flex justify-content-end"}>
                <Button className="sm-btn btn-save">{this.trans("changePassword")}</Button>
              </FormGroup>
            </CoreForm>
          </Col>
        </Row>
      </Col>
    );
  }
}

export const PWChange = withAlert(withTranslation()(withRouter(_PWChange)));

PWChange.propTypes = {
  login: PropTypes.func.isRequired,
};
