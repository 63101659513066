import React from "react";

class OverviewContainer extends React.Component {
  render() {
    return (
      <div className="overview-container">
        {
          (this.props.columns || []).map((col, i) => {
            let content = col.value;
            if (col.title) {
              content = (
                <>
                  <span className="overview-title">{col.title}:</span>&nbsp;{col.value}
                </>
              );
            } else if (col.value) { // Väärtus ilma title'ita
              content = col.value;
            }

            return (
              <div className="overview-column" key={`overview-col-${i}`}>
                {content}
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default OverviewContainer;
