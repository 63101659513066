import React from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import FormGroupWErr from "../helper/form_group_w_err";
import LabelWithError from "../helper/label_with_error";
import CoreForm from ".././helper/core_form";
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import Select from "react-select";
import {centsToEuros, eurosToCents, ignoreTimezoneAndToISOString,} from "../helper/utils";
import {withAlert} from "../helper/alert";
import {PageTitle} from "../helper/page_title";
import AInput from "../helper/apple_input";
import {apiAddClientToCard, apiFetchCardWoClient} from "../../api/fetch";
import {NewCardPageExtension} from "./new_card_page";
import DatePicker from "../helper/date_picker";

class CardWithoutClientAddClient extends NewCardPageExtension {

  componentDidMount() {
    const {navData} = this.props.location;
    if (navData !== undefined) {
      localStorage.setItem("cardsData", JSON.stringify(navData));
      this.setStateWithObject({cardsData: navData}, this.fetchData);
    } else {
      const cardsDataFromStore = JSON.parse(localStorage.getItem("cardsData"));
      if(cardsDataFromStore === null) { // kui andmed puuduvad, siis tagasi üldlehele
        this.navTo("/cards/without-client");
      }
      this.setStateWithObject({cardsData: cardsDataFromStore}, this.fetchData);
    }
  }

  fetchData = () => {
    this.getcardProducts();
    this.fetchCard();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    // muidu keelevahetusega crashib kuna see kutsub componentWillUnmount
    if (window.location.pathname !== "/cards/without-client/add-client") {
      localStorage.removeItem("cardsData");
    }
  }

  setInitState = (d) => {
    const obj = {};
    obj.cardID = d.cardID;
    obj.pan = d.pan;
    obj.name = d.name;
    obj.cardProductID = d.cardProductID;
    obj.info = d.info;
    obj.validTo = new Date(d.validTo);
    obj.amountLimit = d.amountLimit;
    if (this.defined(d.amountLimit)) {
      obj.amountLimitEur = centsToEuros(d.amountLimit);
      obj.amountLimited = true;
    }
    if (this.defined(d.countLimit)) {
      obj.countLimit = d.countLimit;
      obj.countLimited = true;
    }

    obj.validFrom = new Date(d.validFrom);
    this.setStateWithObject({data: obj, endTime: new Date(d.validTo)});
  }

  fetchCard = () => {
    let cardID = null;

    Object.entries(this.state.cardsData).forEach(([key]) => {
      if (cardID === null) {
        cardID = key;
      }
    });

    return this.cancelable(apiFetchCardWoClient(cardID, (data) => {
      this.setInitState(data);
    }));
  }

  onChangeCustomerID = (customer) => {
    if (this.state.data.name === undefined || this.state.data.name === "") {
      this.setStateValue("data", Object.assign({}, this.state.data, {
        customerID: customer.value,
        name: customer.label,
      }));
    } else {
      this.setStateValue("data", Object.assign({}, this.state.data, {
        customerID: customer.value,
      }));
    }

    this.setState({initCustomer: customer});
    this.loadUnits(customer.value);
  }

  addClientToCard = () => {
    const {data, endTime, cardsData} = this.state;

    data.validTo = ignoreTimezoneAndToISOString(endTime);
    data.amountLimit = data.amountLimited ? eurosToCents(data.amountLimitEur) : "";
    data.countLimit = data.countLimited ? data.countLimit : "";

    const cardIDs = [];
    Object.entries(cardsData).forEach(([key]) => {
      cardIDs.push(parseInt(key));
    });

    const reqData = {
      data: data,
      cardIDs: cardIDs,
    };
    return this.cancelable(apiAddClientToCard(reqData, () => {
      this.navTo("/cards/without-client", "saved");
    }, (err) => {
      this.props.setErrors(err.errors);
    }));
  }

  getCardProductName = (cardProductID) => {
    for (let i = 0; i < this.state.cardProducts.length; i++) {
      const v = this.state.cardProducts[i];
      if (v.id === cardProductID) {
        return v.name;
      }
    }
  }

  renderPanColumn = () => {
    const {cardsData} = this.state;
    let textStr = "";
    let count = 0;
    if (cardsData !== undefined) {
      const cardPans = Object.keys(cardsData).map((id) => cardsData[id].pan);
      textStr = cardPans.join(", ");
      count = Object.entries(cardsData).length;
    }

    let textVal = "";
    if (count > 4) {
      const countPreText = this.trans("totalOfCountCards", undefined, {count: count});
      textVal = `(${countPreText}) `;
    }
    textVal += textStr;

    return (
      <Col>
        <FormGroupWErr
          str={count > 1 ? "pans" : "pan"}
          val={textVal}
          disabled={true}
        />
      </Col>
    );
  };

  render() {
    const {endTime, data, customers, initCustomer} = this.state;

    return (
      <>
        {this.props.renderAlert()}
        <PageTitle title={this.props.title}/>
        <Row>
          <Col md={6}>
            <CoreForm onSubmit={this.addClientToCard}>
              <Row>
                {this.renderPanColumn()}
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <LabelWithError wrap for="customerID" required={true}>
                      {this.trans("customer")}
                    </LabelWithError>
                    <Select
                      name="customerID"
                      options={customers}
                      onChange={this.onChangeCustomerID}
                      onInputChange={this.fetchCustomers}
                      value={initCustomer}
                      required={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroupWErr
                    label="nameOnCard"
                    str="name"
                    val={data.name}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroupWErr
                    label="customerUnit"
                    str="customerUnitID"
                    type="select"
                    options={this.state.units.map((cprod) => ({value: cprod.customerUnitID, name: cprod.name}))}
                    onChange={this.onChangeInt}
                    val={data.customerUnitID}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroupWErr
                    label="cardProduct"
                    str="cardProductID"
                    val={this.getCardProductName(data.cardProductID)}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroupWErr
                    label="infoFieldOnCard"
                    str="info"
                    val={data.info}
                    disabled={true}
                  />
                </Col>
              </Row>
              <FormGroup row>
                <Col xs={5} className="text-right pr-0 mt-2">
                  <Label className="dark-col">
                    <Input
                      type="checkbox" id="amountLimited"
                      name="amountLimited"
                      checked={data.amountLimited}
                      onChange={this.onChange}
                    />
                    {this.trans("amountLimit")}
                  </Label>
                </Col>
                <Col xs={7}>
                  <AInput
                    type="number"
                    id="amountLimitEur" name="amountLimitEur"
                    step="1" min={1}
                    onChange={this.onChangeInt}
                    value={data.amountLimited ? data.amountLimitEur : ""}
                    disabled={!data.amountLimited}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={5} className="text-right pr-0 mt-2">
                  <Label className="dark-col">
                    <Input
                      type="checkbox" id="countLimited"
                      name="countLimited"
                      checked={data.countLimited}
                      onChange={this.onChange}
                    />
                    {this.trans("countLimit")}
                  </Label>
                </Col>
                <Col xs={7}>
                  <AInput
                    type="number"
                    id="countLimit" name="countLimit"
                    min={0}
                    step="1"
                    onChange={this.onChangeInt}
                    value={data.countLimited ? data.countLimit : ""}
                    disabled={!data.countLimited}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={5} className="text-right pr-0 mt-2">
                  <LabelWithError>
                    {this.trans("validFrom")}
                  </LabelWithError>
                </Col>
                <Col xs={7}>
                  <DatePicker
                    onChange={(e) => this.onChangeDate(e)}
                    minDate={new Date()}
                    maxDate={data.validTo}
                    value={data.validFrom}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={5} className="text-right pr-0 mt-2">
                  <LabelWithError>
                    {this.trans("validTo")}
                  </LabelWithError>
                </Col>
                <Col xs={7}>
                  <DatePicker
                    onChange={this.onChangeValidTo}
                    minDate={data.validFrom}
                    maxDate={data.validTo}
                    value={endTime}
                    format={"MM.yy"}
                    maxDetail={"year"}
                    minDetail={"year"}
                  />
                </Col>
              </FormGroup>
              <Button className="btn-add sm-btn float-right">
                {this.trans("addClientAndEditData")}
              </Button>
            </CoreForm>
          </Col>
        </Row>
      </>
    );
  }
}

export default withAlert(withRouter(withTranslation()(CardWithoutClientAddClient)));
