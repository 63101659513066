import React from "react";
import CoreComponent from "../core_component";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {DateRangePicker, Calendar} from "react-date-range";
import AInput from "../helper/apple_input";
import moment from "moment";
import {timestampFmt, dateFmt} from "./utils";
import {Col, Row} from "reactstrap";

export class DatetimePick extends CoreComponent {
  state = {
    showCalendar: false,
  };

  calendarRef = React.createRef();

  componentDidMount() {
    window.addEventListener("mouseup", this.handleClickOutsideCalendar);

    if (this.props.defaultValue && !this.props.value) {
      this.onChangeInternal(moment(this.props.defaultValue));
    }
  }

  componentWillUnmount() {
    window.removeEventListener("mouseup", this.handleClickOutsideCalendar);
    super.componentWillUnmount();
  }

  handleClickOutsideCalendar = (ev) => {
    if (this.state.showCalendar && this.calendarRef && !this.calendarRef.contains(ev.target)) {
      this.setState({
        showCalendar: false,
      });
    }
  };

  calculateMaxValues = () => {
    if (!this.props.maxDate) {
      return {};
    }
    if (!this.props.value) {
      return {date: this.props.maxDate};
    }

    const momtCurrent = moment(this.props.value, timestampFmt);
    const momtMax = moment(this.props.maxDate);

    let retVal = {date: this.props.maxDate};

    // Currently selected date is same as max date
    if (momtCurrent.dayOfYear() === momtMax.dayOfYear() && momtCurrent.year() === momtMax.year()) {
      retVal["hours"] = momtMax.hours();

      // Currently selected hour is same as max hour
      if (momtCurrent.hours() === momtMax.hours()) {
        retVal["mins"] = momtMax.minutes();
      }
    }

    return retVal;
  };

  invertCalendar = () => {
    this.setState({
      showCalendar: !this.state.showCalendar,
    });
  };

  onChangeInternal = (datetime) => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: datetime.format(timestampFmt),
      },
    });
  };

  onChange = (date, hours, mins) => {
    let datetime;

    hours = parseInt(hours) || 0;
    mins = parseInt(mins) || 0;

    // Assign current date if unassigned
    if (!date) {
      datetime = moment();
    } else {
      datetime = moment(date, dateFmt);
    }

    this.onChangeInternal(datetime.hour(hours).minute(mins).second(0));
  };

  onChangeDate = (date) => {
    const {hours, mins} = this.splitDatetime();

    this.onChange(date, hours, mins);
  };

  onChangeHours = (ev) => {
    const {date, mins} = this.splitDatetime();
    let hours = ev.target.value;
    if (hours > 23) { // To avoid bubbling up to next day
      hours = 23;
    } else if (hours < 0) {
      hours = 0;
    }

    this.onChange(date, hours, mins);
  };

  onChangeMins = (ev) => {
    const {date, hours} = this.splitDatetime();
    let mins = ev.target.value;
    if (mins > 59) { // To avoid bubbling up to next hour
      mins = 59;
    } else if (mins < 0) {
      mins = 0;
    }

    this.onChange(date, hours, mins);
  };

  splitDatetime = () => {
    let datetime = this.props.value;
    if (!datetime) {
      return {date: "", hours: "", mins: ""};
    }

    const momt = moment(datetime, timestampFmt);

    return {
      date: momt.format(dateFmt),
      hours: momt.hours(),
      mins: momt.minutes(),
    };
  };

  renderCalendar = (minDate, maxDate) => {
    let classes = [];

    if (this.props.right) {
      classes.push("pos-right");
    } else {
      classes.push("pos-left");
    }

    return (
      <div className={"date-picker-container"}>
        <Calendar
          className={classes.join(", ")}
          onChange={this.onChangeDate}
          date={this.props.value ? moment(this.props.value, timestampFmt).toDate() : ""}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    );
  };

  render() {
    const {showCalendar} = this.state;
    const {id, placeholder, required, minDate} = this.props;
    const {date, hours, mins} = this.splitDatetime();

    const maxValues = this.calculateMaxValues();

    /* FIXME Number inputs doesn't seem to be displayed properly in Gnome WEB browser */
    /* TODO proper min-datetime calculation - currently only min-date is applied */
    /* TODO minDate should be also applied on hours and minutes input fields */

    return (
      <div>
        <Row>
          <Col xs={6}>
            {/* Date selection */}
            <div ref={(ref) => this.calendarRef = ref}>
              <AInput
                id={id}
                type="text"
                onClick={this.invertCalendar}
                value={date}
                placeholder={placeholder || dateFmt}
                required={required}
              />
              {showCalendar && this.renderCalendar(minDate, maxValues.date)}
            </div>
          </Col>
          <Col xs={3} className={"px-1"}>
            {/* Hour selection */}
            <AInput
              type="number"
              onChange={this.onChangeHours}
              value={hours}
              placeholder={"HH"}
              required={required}
              min={0}
              max={maxValues.hours || 23}
            />
          </Col>
          <Col xs={3} className={"pl-1"}>
            {/* Minute selection */}
            <AInput
              type="number"
              onChange={this.onChangeMins}
              value={mins}
              placeholder={"mm"}
              required={required}
              min={0}
              max={maxValues.mins || 59}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export class DateRangePick extends CoreComponent {
  state = {
    showPicker: false,
  };

  dateRangeRef = React.createRef();


  componentDidMount() {
    window.addEventListener("mouseup", this.handleClickOutsideDatepicker);
  }

  componentWillUnmount() {
    window.removeEventListener("mouseup", this.handleClickOutsideDatepicker);
    super.componentWillUnmount();
  }

  handleClickOutsideDatepicker = (ev) => {
    if (this.state.showPicker && this.dateRangeRef && !this.dateRangeRef.contains(ev.target)) {
      this.setState({
        showPicker: false,
      });
    }
  };

  onChange = (ranges) => {
    const {startDate, endDate} = ranges.selection;
    this.props.onChange({
      target: {
        name: this.props.name,
        value: {
          startDate: moment(startDate).startOf("day").format(timestampFmt),
          endDate: moment(endDate).endOf("day").format(timestampFmt),
        },
      },
    });
  };

  clear = () => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: {},
      },
    });
  };

  invertPicker = () => {
    this.setState({
      showPicker: !this.state.showPicker
    });
  };

  formatDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return "";
    }

    return `${moment(startDate).format(dateFmt)} - ${moment(endDate).format(dateFmt)}`;
  };

  renderPicker = (startDate, endDate) => {
    const ranges = {
      startDate: startDate ? startDate.toDate() : null,
      endDate: endDate ? endDate.toDate() : null,
      key: this.props.key || "selection",
    };

    let classNames = [];
    if (this.props.right) {
      classNames.push("pos-right");
    } else {
      classNames.push("pos-left");
    }

    return (
      <DateRangePicker
        className={classNames.join(" ")}
        ranges={[ranges]}
        onChange={this.onChange}
        weekStartsOn={1}
      />
    );
  };

  render() {
    const {showPicker} = this.state;
    const {value} = this.props;

    let startDate = null;
    let endDate = null;
    let showClearButton = false;

    if (typeof value !== "undefined" && value !== null) {
      if (Object.prototype.hasOwnProperty.call(value, "startDate") && Object.prototype.hasOwnProperty.call(value, "endDate")) {
        startDate = moment(value.startDate, timestampFmt);
        endDate = moment(value.endDate, timestampFmt);
        showClearButton = true;
      }
    }

    return (
      <div ref={(ref) => this.dateRangeRef = ref} className="date-picker-container">
        {showClearButton &&
          <span className="clear-button" onClick={this.clear}>X</span>
        }
        <AInput
          readOnly
          onClick={this.invertPicker}
          value={this.formatDateRange(startDate, endDate)}
          placeholder={this.props.placeholder}
        />
        {showPicker && this.renderPicker(startDate, endDate)}
      </div>
    );
  }
}

export const parseDateRange = (obj) => {
  if (typeof obj !== "undefined" && obj !== null) {
    return [obj.startDate, obj.endDate];
  }

  return [null, null];
};
