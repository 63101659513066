import React from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Button, Col} from "reactstrap";
import {postRequest} from "../../api/request";
import {PageTitle} from "../helper/page_title";
import FormGroupWErr from "../helper/form_group_w_err";
import FiltersContainer from "../helper/filters_container";
import ModalWindow from "../helper/modal_window";
import {withAlert} from "../helper/alert";
import CoreComponent from "../core_component";
import TLink from "../helper/table_link";
import TextInputWithCheckbox from "../helper/text_input_w_checkbox";
import * as utils from "../helper/utils";
import {cardCreationType} from "../helper/utils";
import FormGroupCheckbox from "../helper/form_group_checkbox";
import Paginator from "../helper/paginator";
import {apiFetchCompanies} from "../../api/fetch";
import {CTable} from "../helper/ctable";

class CompaniesPage extends CoreComponent{
  state = {
    companies: [],
  };

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);
  }

  fetchCompanies = (filtersData) => {
    return this.cancelable(apiFetchCompanies(filtersData, (respData) => {
      this.setState({companies: respData.list});
    }));
  };

  filtersComponent = (filtersData, onChange) => {
    let basicInput = (str, val) => {
      return(
        <Col md={3}>
          <FormGroupWErr str={str} val={val} onChange={onChange} required={false}/>
        </Col>
      );
    };

    return (
      <>
        {basicInput("name", filtersData.name)}
        {basicInput("phone", filtersData.phone)}
        {basicInput("email", filtersData.email)}
        {basicInput("identification", filtersData.identification)}
      </>
    );
  };

  /*** new company modal ***/

  newCompanyCaller = (toggleOpen) => {
    const initData = {
      "cardCreationTypeID": cardCreationType.name.generate,
    };

    return (
      <Button className={"ml-3 btn-add sm-btn"} onClick={(ev) => toggleOpen(ev, initData)}>
        {this.trans("addNew")}
      </Button>
    );
  };

  newCompanyBody = (data, errors, onChange) => {
    return (
      <>
        <FormGroupWErr maxLength={64} str={"name"} err={errors.name} val={data.name} onChange={onChange} />
        <FormGroupWErr maxLength={256} str={"identification"} err={errors.identification} val={data.identification} onChange={onChange} />
        <FormGroupWErr maxLength={512} str={"address"} err={errors.address} val={data.address} onChange={onChange} />
        <FormGroupWErr maxLength={64} str={"phone"} err={errors.phone} val={data.phone} onChange={onChange} />
        <FormGroupWErr maxLength={254} str={"email"} type={"email"} err={errors.email} val={data.email} onChange={onChange} />
        <TextInputWithCheckbox
          str={"billingEmail"}
          type="email"
          checkboxID={"billingEmailCB"}
          value={data.billingEmail || ""}
          onChange={onChange}
          maxLength={254}
          error={errors.billingEmail}
        />
        <FormGroupWErr
          label="cardCreationType"
          str="cardCreationTypeID"
          type="select"
          options={utils.getTypesOptions(cardCreationType, "cardCreationTypeOptions", this.trans)}
          onChange={onChange}
          val={data.cardCreationTypeID}
          required={true}
        />
        <FormGroupCheckbox label={"allowPartialPayments"} str={"allowPartialPayments"} val={data.allowPartialPayments} onChange={onChange}/>
      </>
    );
  };

  newCompanyOnSubmit = (data) => {
    const reqData = Object.assign({}, data);

    return new Promise((resolve, reject) => {
      this.cancelable(postRequest("/api/companies/create", reqData)).then((resp) => {
        this.navTo(`/companies/${resp.data.ID}`, "saved");
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  /*** ***/

  render() {
    const {companies} = this.state;
    return (
      <div>
        {this.props.renderAlert()}
        <div className="my-3 d-flex flex-row justify-content-start">
          <PageTitle isGrouped={true} title={this.props.title}/>
          <ModalWindow
            headerText={this.trans("newCompany")}
            caller={this.newCompanyCaller}
            body={this.newCompanyBody}
            onSubmit={this.newCompanyOnSubmit}
          />
        </div>
        <Paginator
          next={this.fetchCompanies}
          additionalComponent={(onFilter) => (<FiltersContainer renderFilters={this.filtersComponent} onFilter={onFilter}/>)}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("name")}</th>
                <th>{this.trans("phone")}</th>
                <th>{this.trans("email")}</th>
                <th>{this.trans("identification")}</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((cst, i) => {
                return (
                  <tr key={`companies-${i}`}>
                    <TLink to={`/companies/${cst.companyID}`}>
                      {cst.name}
                    </TLink>
                    <td>{cst.phone}</td>
                    <td>{cst.email}</td>
                    <td>{cst.identification}</td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withAlert(withTranslation()(withRouter(CompaniesPage)));
