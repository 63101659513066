import React from "react";

class LoadingPage extends React.Component {
  render() {
    return (
      <div className="page-loader-container">
        <div className="page-loader"/>
      </div>
    );
  }
}

export default LoadingPage;
