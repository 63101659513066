import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {apiFetchCompanyOperators} from "../../../api/fetch";
import {PageTitle} from "../../helper/page_title";
import {NewUserModal} from "../../modals/company/new_user_modal";
import TLink from "../../helper/table_link";
import Paginator from "../../helper/paginator";
import {CTable} from "../../helper/ctable";

class _CompanyUsersContainer extends CoreComponent {
  state = {
    companyOperators: [],
  };

  fetchCompanyOperators = (filtersData) => {
    return this.cancelable(apiFetchCompanyOperators(this.props.companyID, filtersData, (data) => {
      this.setState({
        companyOperators: data.list,
      });
    }));
  };

  resolveOperatorLink = (val) => {
    const {companyID, company} = this.props;
    if (companyID !== "company") {
      return `/company/${companyID}/operators/${val.accountID}`;
    } else if (company.companyID !== undefined) {
      return `/company/${company.companyID}/operators/${val.accountID}`;
    } else {//in case company.companyID is undefined, stay on the same page
      return `/companies/${companyID}`;
    }
  };

  render() {
    const {companyOperators} = this.state;

    return (
      <>
        <div className="my-3 d-flex flex-row justify-content-start">
          <PageTitle isGrouped={true} title={this.trans("operators")}/>
          <NewUserModal
            user={this.props.user}
            companyID={this.props.companyID}
            setErrors={this.props.setErrors}
          />
        </div>
        <Paginator
          next={this.fetchCompanyOperators}
          pageLimit={10}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("name")}</th>
                <th>{this.trans("email")}</th>
                <th>{this.trans("role")}</th>
                <th>{this.trans("language")}</th>
              </tr>
            </thead>
            <tbody>
              {companyOperators.map((val, i) => {
                return (
                  <tr key={`companies-${i}`}>
                    <TLink to={this.resolveOperatorLink(val)}>{val.name ? val.name : <i>{this.trans("registeringUser")}</i>}</TLink>
                    <td>{val.email}</td>
                    <td>{val.role}</td>
                    <td>{val.lang ? this.trans(`languages.${val.lang}`) : "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </>
    );
  }
}

export const CompanyUsersContainer = withTranslation()(_CompanyUsersContainer);
