import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {apiFetchDistributorChanges} from "../../../api/fetch";
import Paginator from "../../helper/paginator";
import {mapChangedColumns} from "../../helper/utils";
import {CTable} from "../../helper/ctable";

class DistributorChangesContainer extends CoreComponent {
  state = {
    changes: [],
  };

  fetchChanges = (urlQuery) => {
    return this.cancelable(apiFetchDistributorChanges(this.props.distributorID, urlQuery, (respData) => {
      this.setState({
        changes: respData.list,
      });
    }, (err) => {
      if (err.cancelled) {
        return;
      }

      this.props.setErrors(err.errors);
    }));
  };

  render() {
    return (
      <div>
        <Paginator
          next={this.fetchChanges}
          pageLimit={10}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("name")}</th>
                <th>{this.trans("date")}</th>
                <th>{this.trans("author")}</th>
              </tr>
            </thead>
            <tbody>
              {mapChangedColumns(this.state.changes, (val, i, renderCol) => {
                return (
                  <tr key={`dist-change-${i}`}>
                    <td>{renderCol("name")}</td>
                    <td>{val.validFrom}</td>
                    <td>{val.authorEmail}</td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withTranslation()(DistributorChangesContainer);
