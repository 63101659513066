import React from "react";
import {Col} from "reactstrap";
import {withTranslation} from "react-i18next";
import FormGroupWErr from "../helper/form_group_w_err";
import FiltersContainer from "../helper/filters_container";
import CoreComponent from "../core_component";

class TerminalOwnerFilters extends CoreComponent {
  renderFilters = (filtersData, onChange) => {
    return (
      <>
        <Col md={3}>
          <FormGroupWErr
            str={"name"}
            val={filtersData.name}
            onChange={onChange}
            required={false}
          />
        </Col>
        <Col md={3}>
          <FormGroupWErr
            str={"identifier"}
            val={filtersData.identifier}
            onChange={onChange}
            required={false}
          />
        </Col>
      </>
    );
  };

  render() {
    return (
      <FiltersContainer
        renderFilters={this.renderFilters}
        onFilter={this.props.onFilter}
      />
    );
  }
}

export default withTranslation()(TerminalOwnerFilters);
