import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import FormGroupWErr from "../../helper/form_group_w_err";
import {putRequest} from "../../../api/request";
import {Button} from "reactstrap";
import ModalWindow from "../../helper/modal_window";

class EditTerminalOwnerModal extends CoreComponent {

  editTerminalOwnerBody = (data, errors, onChange) => {
    return (
      <>
        <FormGroupWErr maxLength={90} str="name" err={errors.name} val={data.name} onChange={onChange}/>
        <FormGroupWErr maxLength={16} str="identifier" err={errors.identifier} val={data.identifier} onChange={onChange} required={false}/>
      </>
    );
  };

  editTerminalOwnerOnSubmit = (data) => {
    return new Promise((resolve, reject) => {
      this.cancelable(putRequest(`/api/terminal-owners/${this.props.terminalOwnerID}`, data)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  editTerminalOwnerCaller = (toggleOpen) => {
    return(
      <Button className={"btn-edit sm-btn"} onClick={(ev) => toggleOpen(ev, this.props.terminalOwner)}>
        {this.trans("edit")}
      </Button>
    );
  };

  render() {
    return (
      <>
        <ModalWindow
          headerText={this.trans("editTerminalOwner")}
          body={this.editTerminalOwnerBody}
          caller={this.editTerminalOwnerCaller}
          onSubmit={(data) => this.editTerminalOwnerOnSubmit(data)}
        />
      </>
    );
  }

}

export default withTranslation()(EditTerminalOwnerModal);
