import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import FiltersContainer from "../../helper/filters_container";
import OverviewContainer from "../../helper/overview_container";
import {Col} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import {Orderable} from "../../helper/orderable";
import Paginator from "../../helper/paginator";
import {apiFetchCustomers} from "../../../api/fetch";
import TLink from "../../helper/table_link";
import {CTable} from "../../helper/ctable";

class CustomerCustomersContainer extends CoreComponent {
  state = {
    customers: [],
    customerCount: 0,

    /** init filters **/
    filterInitName: this.props.navData ? this.props.navData.searchString : null,
  };

  fetchCustomers = (filtersData) => {
    return this.cancelable(apiFetchCustomers(filtersData, (respData) => {
      this.setState({
        customers: respData.list,
        customerCount: respData.count,
      });
    }));
  };

  renderCustomerType = (filtersData, onChangeInt) => {
    return (
      <Col md={3}>
        <FormGroupWErr
          str="customerTypeID"
          label="customerType"
          type="select"
          options={this.props.customerTypes.map((ct) => ({value: ct.id, name: this.trans(`customerTypes.${ct.id}`)}))}
          onChange={onChangeInt}
          val={filtersData.customerTypeID}
          required={false}
        />
      </Col>
    );
  };

  filtersComponent = (filtersData, onChange, onChangeInt) => {
    let basicInput = (str, val) => {
      return (
        <Col md={3}>
          <FormGroupWErr str={str} val={val} onChange={onChange} required={false}/>
        </Col>
      );
    };
    return (
      <>
        {basicInput("name", filtersData.name)}
        {basicInput("identification", filtersData.identification)}
        {basicInput("email", filtersData.email)}
        {this.renderCustomerType(filtersData, onChangeInt)}
        {basicInput("customerNo", filtersData.customerNo)}
      </>
    );
  };

  render() {
    const {customers, customerCount, filterInitName} = this.state;

    return (
      <>
        <Paginator
          next={this.fetchCustomers}
          initialPageFilters={{name: filterInitName}}
          additionalComponent={(onFilter, initialPageFilters) => (
            <>
              <FiltersContainer
                renderFilters={this.filtersComponent}
                onFilter={onFilter}
                initData={initialPageFilters}
              />
              <OverviewContainer
                columns={[
                  {title: this.trans("customerCount"), value: customerCount},
                ]}
              />
            </>
          )}
        >
          {({onOrderByChange}) => {
            return (
              <CTable>
                <thead>
                  <Orderable
                    setOrderCallback={onOrderByChange}
                  >
                    {({renderOrderCol}) => (
                      <tr>
                        <th className="text-nowrap">
                          {renderOrderCol(this.trans("name"), "name")}
                        </th>
                        <th className="text-nowrap">
                          {renderOrderCol(this.trans("identification"), "identification")}
                        </th>
                        <th>{this.trans("email")}</th>
                        <th>{this.trans("billingEmail")}</th>
                        <th>{this.trans("customerNo")}</th>
                        <th>{this.trans("activeDiscount")}</th>
                      </tr>
                    )}
                  </Orderable>
                </thead>
                <tbody>
                  {customers.map((v, i) => {
                    return (
                      <tr key={`customer-${i}`}>
                        <TLink to={`/customers/${v.customerID}`}>
                          {v.name}
                        </TLink>
                        <td>{v.identification}</td>
                        <td>{v.email}</td>
                        <td>{v.billingEmail || "-"}</td>
                        <td>{v.customerNo || "-"}</td>
                        <td>{v.discountPercentage ? `${v.discountPercentage}%` : "-"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </CTable>
            );
          }}
        </Paginator>
      </>
    );
  }
}

export default withTranslation()(CustomerCustomersContainer);
