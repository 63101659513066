import React from "react";
import {withTranslation} from "react-i18next";
import {getRequest} from "../../api/request";
import {withRouter} from "react-router-dom";
import {ADMIN, DISTRIBUTOR} from "../helper/user_roles";
import OverviewRow from "../helper/overview_row";
import {PageTitle} from "../helper/page_title";
import LoadingPage from "./loading_page";
import {withAlert} from "../helper/alert";
import CoreComponent from "../core_component";
import {CompanyUsersContainer} from "../containers/company/company_users_container";
import EditCompanyModal from "../modals/company/edit_company_modal";
import {CompanyTabConsumer} from "../helper/context";
import NavTabs, {TabPaneOnOpen} from "../helper/nav_tabs";
import {TabContent} from "reactstrap";
import CompanyChangesContainer from "../containers/company/company_changes_container";
import CardProductsOverview from "../containers/card/card_products_overview";
import {cardCreationType, yesNo} from "../helper/utils";
import {CAN_GET_CARDS, CAN_GET_WEB_API} from "../helper/role_permissions";
import CompanyRolesContainer from "../containers/company/company_roles_container";
import CompanyWebApisContainer from "../containers/company/company_web_apis_container";

class CompanyViewPage extends CoreComponent {

  state = {
    company: {},
  };

  tabs = {
    USERS: "users",
    USER_ROLES: "userRoles",
    CARD_PRODUCTS: "cardProducts",
    CHANGES: "changes",
    WEB_API: "webApis",
  };

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);

    if (this.props.user.role === ADMIN || this.props.user.role === DISTRIBUTOR) {
      this.companyID = this.props.match.params.id;
    } else {
      this.companyID = "company";
    }
    this.fetchInitial(this.fetchCompany());
  }

  fetchCompany = () => {
    return new Promise((resolve, reject) => {
      this.cancelable(getRequest(`/api/companies/${this.companyID}`)).then((resp) => {
        resp.data.companyAsCustomer = resp.data.defaultCustomerUnitID > 1;
        this.setStateValue("company", resp.data);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  render() {
    const {company} = this.state;
    const {user} = this.props;

    if (!this.state.isPageReady) {
      return <LoadingPage/>;
    }

    const navTabs = [
      {id: this.tabs.USERS, title: "operators"},
    ];

    if (user.isSuperuser()) {
      navTabs.push({id: this.tabs.USER_ROLES, title: "userRoles"});
    }

    navTabs.push({id: this.tabs.CHANGES, title: "changes"});

    if (user.isAdmin() || user.isDistributor() || user.hasPermission(CAN_GET_CARDS)) {
      navTabs.push({id: this.tabs.CARD_PRODUCTS, title: "cardProducts"});
    }

    if (user.hasPermission(CAN_GET_WEB_API)) {
      navTabs.push({id: this.tabs.WEB_API, title: "web_apis"});
    }

    return (
      <div>
        {this.props.renderAlert()}
        <div className={"my-3 d-flex flex-row justify-content-start"}>
          <PageTitle isGrouped={true} title={company.name}/>
          <EditCompanyModal
            company={company}
            companyID={this.companyID}
            user={this.props.user}
          />
        </div>
        <OverviewRow name={"name"} value={company.name} nWidth={4} vWidth={8}/>
        <OverviewRow name={"identification"} value={company.identification} nWidth={4} vWidth={8}/>
        <OverviewRow name={"address"} value={company.address} nWidth={4} vWidth={8}/>
        <OverviewRow name={"phone"} value={company.phone} nWidth={4} vWidth={8}/>
        <OverviewRow name={"email"} value={company.email} nWidth={4} vWidth={8}/>
        <OverviewRow name={"billingEmail"} value={company.billingEmail} nWidth={4} vWidth={8} hidden={!company.billingEmail}/>
        <OverviewRow name={"allowPartialPayments"} value={yesNo(company.allowPartialPayments)} nWidth={4} vWidth={8}/>
        <OverviewRow name={"cardCreationType"} nWidth={4} vWidth={8} value={
          this.trans(`cardCreationTypeOptions.${cardCreationType.value[company.cardCreationTypeID]}`)}/>
        {user.isAdmin() && (
          <OverviewRow name={"distributor"} value={company.distributorName || "-"} nWidth={4} vWidth={8}/>
        )}
        {company.defaultCustomerID > 0 && (
          <OverviewRow name={"defaultCustomerUnit"} value={company.defaultCustomerUnitName} nWidth={4} vWidth={8}
            link={`/customers/${company.defaultCustomerID}`}
          />
        )}
        <CompanyTabConsumer>
          {({activeTab, toggleTab}) => (
            <div className={"mt-2"}>
              <NavTabs
                tabs={navTabs}
                activeTab={activeTab}
                toggleTab={toggleTab}
              />
              <TabContent activeTab={activeTab}>
                <TabPaneOnOpen tabId={this.tabs.USERS}>
                  <CompanyUsersContainer
                    company={company}
                    companyID={this.companyID}
                    user={this.props.user}
                  />
                </TabPaneOnOpen>
                {user.isSuperuser() && (
                  <TabPaneOnOpen tabId={this.tabs.USER_ROLES}>
                    <CompanyRolesContainer/>
                  </TabPaneOnOpen>
                )}
                <TabPaneOnOpen tabId={this.tabs.CHANGES}>
                  <CompanyChangesContainer
                    setErrors={this.props.setErrors}
                    companyID={this.companyID}
                  />
                </TabPaneOnOpen>
                {(user.isAdmin() || user.isDistributor() || user.hasPermission(CAN_GET_CARDS)) && (
                  <TabPaneOnOpen tabId={this.tabs.CARD_PRODUCTS}>
                    <CardProductsOverview
                      setErrors={this.props.setErrors}
                      companyID={this.companyID}
                      user={user}
                    />
                  </TabPaneOnOpen>
                )}
                {(user.hasPermission(CAN_GET_WEB_API)) && (
                  <TabPaneOnOpen tabId={this.tabs.WEB_API}>
                    <CompanyWebApisContainer
                      setErrors={this.props.setErrors}
                      setInfoMsg={this.props.setInfoMsg}
                      companyID={this.companyID}
                      user={user}
                    />
                  </TabPaneOnOpen>
                )}
              </TabContent>
            </div>
          )}
        </CompanyTabConsumer>
      </div>
    );
  }
}

export default withAlert(withTranslation()(withRouter(CompanyViewPage)));
