import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import ModalWindow from "../../helper/modal_window";
import {postRequest} from "../../../api/request";
import {Button, Input} from "reactstrap";
import LabelWithError from "../../helper/label_with_error";

class UploadFileModal extends CoreComponent {
  state = {
    selectedFile: null,
    err: null,
  };

  uploadBody = (data, errors) => {
    const allowedExts = this.props.allowedExtensions.map(v => "." + v).join(", ");

    return (
      <>
        <LabelWithError error={this.state.err || errors.file} errVar={errors.var} for="file">
          {this.trans("file")} {allowedExts && `(${allowedExts})`}
        </LabelWithError>
        <Input
          type={"file"} name={"file"} id={"file"} className={"mb-1"}
          onChange={this.onChangeHandler} accept={allowedExts}/>
      </>
    );
  };

  onChangeHandler = (event) => {
    const file = event.target.files[0];

    if (file) {
      const {allowedExtensions} = this.props;
      const fileExtMatch = file.name.match(/\.([^.]+)$/);
      let fileExt = "";

      if (fileExtMatch && fileExtMatch.length > 1) {
        fileExt = fileExtMatch[1];
      }

      // Check if file has allowed extension
      if (allowedExtensions.length > 0 && allowedExtensions.indexOf(fileExt) === -1) {
        this.setState({
          selectedFile: null,
          err: "ERROR_INVALID_FILE_EXT",
        });
        return;
      }
    }

    this.setState({
      selectedFile: file,
      err: null,
    });
  };

  uploadOnSubmit = () => {
    const data = new FormData();
    data.append("file", this.state.selectedFile);
    return new Promise((resolve, reject) => {
      this.cancelable(postRequest(this.props.url, data)).then(() => {
        resolve();
      }).catch((error) => {
        if (error.cancelled) {
          reject(error);
          return;
        }
        this.props.setErrors(error.errors);
        reject(error);
      });
    });
  };

  uploadCaller = (toggleOpen) => {
    return (
      <Button className={"ml-2 btn-add sm-btn"} onClick={toggleOpen}>
        {this.props.btnName}
      </Button>
    );
  };

  render() {
    return (
      <>
        <ModalWindow
          forbidSubmit={!this.state.selectedFile} // File must be selected, otherwise form submission is forbidden
          headerText={this.trans("uploadFile")}
          body={this.uploadBody}
          caller={(toggleOpen) => this.uploadCaller(toggleOpen)}
          onSubmit={this.uploadOnSubmit}
        />
      </>
    );
  }
}

UploadFileModal.defaultProps = {
  allowedExtensions: [],
};

export default withTranslation()(UploadFileModal);
