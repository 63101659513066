import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import {PaginationLimit} from "./utils";

class InfiniteScroller extends CoreComponent {
  state = {
    page: 1,
    hasMore: true,
    dataLength: 0,
    scrollId: this.props.id, //id peab olema alati defineeritud
    filtersData: this.props.initialPageFilters || {},
    orderBy: "",
  };

  scrollRef = React.createRef();

  componentDidMount() {
    if(this.props.id === undefined) {
      throw new Error("InfiniteScroller id must be defined!");// TODO REMOVE THIS CHECK BEFORE PRODUCTION
    }
    if(this.state.filtersData === {}) {
      this.onPageChange();
    } else {
      this.onPageChange(true);
    }
  }

  getNextPage = (doFilter = false) => {
    return new Promise((resolve, reject) => {
      const {page, dataLength} = this.state;
      const data = Object.assign({}, this.getFiltersDataState(), {
        page: page,
      });

      if (this.state.orderBy) {
        data["orderBy"] = this.state.orderBy;
      }

      this.props.next(data, doFilter).then((respData) => {
        let respDataLength;
        //kui vaja kasutada teist datat infinite scrolliga listi (arrayga), siis põhiline scrollitav data tuleb panna "list" sisse
        if(Array.isArray(respData)) {
          respDataLength = respData.length;
        } else if(respData !== undefined) {
          if(Array.isArray(respData.list)) {
            respDataLength = respData.list.length;
          } else if(Array.isArray(respData.data)) { //TODO see ei ole enam vajalik kui kõik server requestid tulevad fetch.jsx kaudu
            respDataLength = respData.data.length;
          }
        }

        this.setState(Object.assign({}, this.state, {
          dataLength: dataLength + respDataLength,
          hasMore: respDataLength >= PaginationLimit,
          page: page + 1
        }));

        resolve();
      }).catch((error) => {
        if (error.cancelled) {
          reject(error);
          return;
        }

        this.setStateValue("hasMore", false);
        reject(error);
      });
    });
  };

  onFilter = (filtersData) => {
    /** onFilter updates filters data in state and loads a first page of results **/
    return new Promise((resolve, reject) => {
      this.setStateValue("filtersData", filtersData, () => {
        this.onPageChange(true).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    });
  };
  
  getFiltersDataState = () => {
    if(this.props.getState !== undefined) {
      this.props.getState(this.state.filtersData);
    }
    return this.state.filtersData;
  };

  onOrderByChange = (orderBy) => {
    return new Promise((resolve, reject) => {
      this.setState({orderBy: orderBy}, () => {
        this.onFilter(this.getFiltersDataState()).then(resolve).catch(reject);
      });
    });
  };

  onPageChange = (doFilter = false) => {
    // In case of filtering, scroller state must be always reseted
    if (doFilter) {
      this.scrollRef.scrollTop = 0;

      return new Promise((resolve) => {
        this.setState(Object.assign({}, this.state, {
          page: 1,
          hasMore: true,
          dataLength: 0
        }), () => resolve(this.getNextPage(true)));
      });
    }

    return this.getNextPage(false).catch(() => {
      // Ignore error in case of simple fetch by InfiniteScroll
      // In case of filtering, error must be catched
    });
  };

  renderAdditionalComponent = () => {
    // renderAdditionalComponent can be any function that accepts function as a parameter
    // and returns renderable content
    const {additionalComponent} = this.props;
    return this.defined(additionalComponent) ? additionalComponent(this.onFilter, this.props.initialPageFilters) : null;
  };

  render() {
    const {dataLength, hasMore, scrollId} = this.state;

    let classes = "vertical-scroller";
    if (this.defined(this.props.size)) {
      classes = classes.concat(` vs-${this.props.size}`);
    }

    return (
      <div className="my-2">
        {this.renderAdditionalComponent()}
        <div className="horizontal-scroller mt-2">
          <div id={`scrollable-target-${scrollId}`} className={classes} ref={(ref) => this.scrollRef = ref}>
            <InfiniteScroll
              style={{overflow: "unset"}}
              dataLength={dataLength}
              next={this.onPageChange}
              hasMore={hasMore}
              loader={<div className="scroll-loader">{this.trans("loading")}...</div>}
              scrollableTarget={`scrollable-target-${scrollId}`}
            >
              {typeof this.props.children === "function"
                ? this.props.children({onOrderByChange: this.onOrderByChange})
                : this.props.children
              }
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(InfiniteScroller);
