import React from "react";
import {withTranslation} from "react-i18next";
import {Button} from "reactstrap";
import {putRequest} from "../../../api/request";
import FormGroupWErr from "../../helper/form_group_w_err";
import ModalWindow from "../../helper/modal_window";
import CoreComponent from "../../core_component";
import {withAlert} from "../../helper/alert";
import {cardState} from "../../helper/utils";
import {apiFetchCustomerUnits} from "../../../api/fetch";


class EditCardInfoModal extends CoreComponent {
  state = {
    customerUnits: [],
  };

  fetchCustomerUnits = (filtersData) => {
    return new Promise((resolve, reject) => {
      const {customerUnits} = this.state;

      if (customerUnits.length <= 0) {
        this.cancelOnUnmount(apiFetchCustomerUnits(this.props.card.customerID, filtersData, (respData) => {
          this.setState({
            customerUnits: respData.list,
          });

          resolve();
        }, (err) => {
          reject(err);
        }));

        return;
      }

      resolve();
    });
  };

  editCardInfo = (reqData) => {
    return new Promise((resolve, reject) => {
      this.cancelable(putRequest(`/api/companies/cards/${this.props.cardID}/edit`, reqData)).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  opener = (toggleOpen) => {
    return (
      <Button className="btn-edit sm-btn" onClick={(ev) => toggleOpen(ev, this.props.card)}>
        {this.trans("edit")}
      </Button>
    );
  };

  body = (data, errors, onChange) => {
    const {customerUnits} = this.state;

    return (
      <>
        {this.props.renderAlert()}

        <FormGroupWErr
          str={"pan"} err={errors.pan}
          val={data.pan} disabled
        />
        <FormGroupWErr
          str={"name"} err={errors.name}
          val={data.name} disabled
        />
        <FormGroupWErr
          str={"info"} label={"infoFieldOnCard"} err={errors.info}
          val={data.info || "-"} disabled
        />
        <FormGroupWErr
          str={"hiddenName"} err={errors.hiddenName} maxLength={64}
          val={data.hiddenName} required={false} onChange={onChange}
        />
        <FormGroupWErr
          str="customerUnitID"
          label="customerUnit"
          type="select"
          err={errors.customerUnitID}
          options={customerUnits.map((unit) => ({value: unit.customerUnitID, name: unit.name}))}
          onChange={onChange}
          val={data.customerUnitID}
        />
        <FormGroupWErr
          str={"customerName"} err={errors.customerName}
          val={data.customerName} disabled
        />
        <FormGroupWErr
          str={"cardProductName"} err={errors.cardProductName}
          val={data.cardProductName} disabled
        />
        <FormGroupWErr
          str={"validTo"} err={errors.validTo}
          val={data.validTo} disabled
        />
        <FormGroupWErr
          str={"cardState"} err={errors.cardState}
          val={this.trans(`cardStateTypes.${cardState.value[this.props.card.cardState]}`)} disabled
        />
      </>
    );
  };

  render() {
    const {setErrors} = this.props;

    return (
      <ModalWindow
        headerText={this.trans("editCard")}
        caller={this.opener}
        body={this.body}
        onSubmit={this.editCardInfo}
        onOpen={this.fetchCustomerUnits}
        setErrors={setErrors}
      />
    );
  }
}

export default withAlert(withTranslation()(EditCardInfoModal));
