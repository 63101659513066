import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {PageTitle} from "../../helper/page_title";
import TLink from "../../helper/table_link";
import {apiFetchCards} from "../../../api/fetch";
import {Link, withRouter} from "react-router-dom";
import Paginator from "../../helper/paginator";
import {CAN_GET_CUSTOMERS} from "../../helper/role_permissions";
import {CTable} from "../../helper/ctable";

class HomeExpiringCards extends CoreComponent {
  state = {
    expiringCards: [],
  };

  fetchExpiringCards = (query) => {
    return this.cancelable(apiFetchCards({...query, expiringSoon: true}, (data) => {
      this.setState({expiringCards: data.list});
    }));
  };

  render() {
    const {user} = this.props;

    return (
      <div className="p-3">
        <div className={"my-3 d-flex flex-row justify-content-start"}>
          <PageTitle isGrouped={true} title={this.trans("expiringCards")}/>
        </div>
        <Paginator
          next={this.fetchExpiringCards}
          pageLimit={5}
          paginationDisabled
          light
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("cardNo")}</th>
                <th>{this.trans("customer")}</th>
                <th>{this.trans("validTo")}</th>
                <th>{this.trans("customerUnit")}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.expiringCards.map((v, i) => {
                return (
                  <tr key={`expiring-cards-${i}`}>
                    <TLink to={`/cards/${v.id}`}>
                      {v.pan}
                    </TLink>
                    <TLink
                      to={`/customers/${v.customerID}`}
                      disableLink={v.customerClosed || !user.hasPermission(CAN_GET_CUSTOMERS)}
                    >
                      {v.customerName}
                    </TLink>
                    <td>{v.validTo}</td>
                    <TLink
                      to={`/customers/${v.customerID}/unit-history/${v.customerUnitID}`}
                      disableLink={v.customerUnitClosed || !user.hasPermission(CAN_GET_CUSTOMERS)}
                    >
                      {v.customerUnitName}
                    </TLink>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>

        <div className="text-center">
          {this.state.expiringCards.length > 0 && (
            <Link
              to={{
                pathname: "/cards",
                state: {expiringSoonOnly: true},
              }}
            >
              {this.trans("showMore")}
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(HomeExpiringCards));
