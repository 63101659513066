import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {ButtonGroup, DropdownMenu, DropdownToggle, Input, Label, UncontrolledButtonDropdown} from "reactstrap";
import TLink from "../../helper/table_link";
import {apiFetchCustomersActivityReport, apiFetchCustomersActivityReportDownload,} from "../../../api/fetch";
import CustomerReportFilters from "../../filters/customer_report_filters";
import {customerState} from "../../helper/utils";
import {FetchButton} from "../../helper/fetch_button";
import FileSaver from "file-saver";
import FileName from "../../helper/fileutil";
import {parseDateRange} from "../../helper/date_range_picker";
import Paginator from "../../helper/paginator";
import {CAN_GET_CUSTOMERS} from "../../helper/role_permissions";
import Icon from "react-icons-kit";
import {ic_settings} from "react-icons-kit/md";
import {CTable} from "../../helper/ctable";

const xlsxFieldsStorageKey = "customerReportXLSXFields";

const getInitialXLSXSettings = () => {
  let fields = window.localStorage.getItem(xlsxFieldsStorageKey);
  if (fields || fields === "") {
    fields = fields.split(",");
  }

  const settings = [
    {field: "name", selected: true},
    {field: "identification", selected: true},
    {field: "address", selected: true},
    {field: "email", selected: true},
    {field: "billingEmail", selected: true},
    {field: "phone", selected: true},
    {field: "language", selected: true},
    {field: "customerNo", selected: true},
    {field: "customerType", selected: true},
    {field: "customerState", selected: true},
    {field: "contactPerson", selected: true},
    {field: "memos", selected: true},
  ];

  if (fields) {
    return settings.map((sett) => {
      return {
        ...sett,
        selected: fields.indexOf(sett.field) !== -1,
      };
    });
  }

  return settings;
};

class CustomersReport extends CoreComponent {
  state = {
    customers: [],
    xlsxSettings: getInitialXLSXSettings(),
  };

  fetchReport = (query) => {
    return this.cancelable(apiFetchCustomersActivityReport(query, (data) => {
      this.setState({
        customers: data.list,
      });
    }));
  };

  initXLSXReport = (data) => {
    const xlsxFields = this.state.xlsxSettings
      .filter((sett) => sett.selected)
      .map((sett) => sett.field)
      .join(",");

    const xlsQuery = Object.assign({
      xlsLanguage: this.props.i18n.language,
      fields: xlsxFields,
    }, data);

    return this.cancelable(apiFetchCustomersActivityReportDownload(xlsQuery, (xlsData) => {
      const fileName = new FileName(this.trans("customerState"), "xlsx").withTimestamp(...parseDateRange(xlsQuery.dateRange));
      const opts = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      };

      FileSaver.saveAs(new Blob([xlsData], opts), fileName.getFullName());

      // Kui kasutaja võtab igakord konkreetsete valitud väljadega aruande,
      // siis salvestame valitud väljad local storage'isse maha,
      // et ei peaks väljasid igakord uuesti valima
      window.localStorage.setItem(xlsxFieldsStorageKey, xlsxFields);
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  onChangeXLSXSetting = (ev) => {
    this.setState({
      xlsxSettings: this.state.xlsxSettings.map((v) => ({
        ...v,
        selected: ev.currentTarget.name === v.field ? !v.selected : v.selected,
      })),
    });
  };

  render() {
    const {user} = this.props;

    return (
      <>
        <div className={"mt-2"}>
          <Paginator
            next={this.fetchReport}
            initialPageFilters={{customerState: customerState.name.all}}
            additionalComponent={(onFilter, initialPageFilters) => (
              <>
                <CustomerReportFilters
                  initialPageFilters={initialPageFilters}
                  onFilter={onFilter}
                  extraButtons={(data) => (
                    <ButtonGroup>
                      <UncontrolledButtonDropdown direction="left">
                        <DropdownToggle size="sm" className="d-inline-flex align-items-center">
                          <Icon icon={ic_settings} className="d-inline-flex"/>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.xlsxSettings.map((set) => {
                            const settingID = `xlsx-setting-${set.selected}-${set.field}`;

                            return (
                              <div key={settingID} className="px-4">
                                <Input
                                  type="checkbox"
                                  id={settingID} name={set.field}
                                  checked={set.selected}
                                  onChange={this.onChangeXLSXSetting}
                                />
                                <Label for={settingID}>{this.trans(set.field)}</Label>
                              </div>
                            );
                          })}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                      <FetchButton className={"sm-btn"} onClick={() => this.initXLSXReport(data)}>
                        XLSX
                      </FetchButton>
                    </ButtonGroup>
                  )}
                />
              </>
            )}
          >
            <CTable>
              <thead>
                <tr>
                  <th>{this.trans("name")}</th>
                  <th>{this.trans("identification")}</th>
                  <th>{this.trans("address")}</th>
                  <th>{this.trans("email")}</th>
                  <th>{this.trans("phone")}</th>
                  <th>{this.trans("customerState")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.customers.map((v, i) => (
                  <tr key={`report-customers-${i}`}>
                    <TLink
                      to={`/customers/${v.customerID}`}
                      disableLink={v.customerState === customerState.name.closed || !user.hasPermission(CAN_GET_CUSTOMERS)}
                    >
                      {v.name}
                    </TLink>
                    <td>{v.identification}</td>
                    <td>{v.address}</td>
                    <td>{v.email}</td>
                    <td>{v.phone || "-"}</td>
                    <td>{this.trans(`customerStateTypes.${customerState.value[v.customerState]}`)}</td>
                  </tr>
                ))}
              </tbody>
            </CTable>
          </Paginator>
        </div>
      </>
    );
  }
}

export default withTranslation()(CustomersReport);
