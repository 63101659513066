import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import i18n from "./core/i18n";
import {I18nextProvider} from "react-i18next";

// ignoreerime teatud hoiatusi mida pole väga mõtet fixida
const clgErr = console.error;
console.error = function ignoreErr(msg) {
  const supressedErrs = [
    "A component is changing a", // https://fb.me/react-controlled-components
    "This synthetic event is reused for performance reasons", // https://reactjs.org/docs/legacy-event-pooling.html - kaob React 17-ga
  ];

  const isSurpressed = supressedErrs.some(v => msg.includes(v));
  if (!isSurpressed) {
    clgErr.apply(console, arguments);
  }
};

ReactDOM.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <App/>
    </I18nextProvider>
  </BrowserRouter>, document.getElementById("root"));
registerServiceWorker();
