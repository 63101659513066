import React from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {PageTitle} from "../helper/page_title";
import LoadingPage from "./loading_page";
import {withAlert} from "../helper/alert";
import CoreComponent from "../core_component";
import NewCustomerModal from "../modals/customer/new_customer_modal";
import CustomerCustomersContainer from "../containers/customer/customer_customers_container";
import {apiFetchCustomerTypes} from "../../api/fetch";
import {CAN_EDIT_CUSTOMERS} from "../helper/role_permissions";

class CustomersPage extends CoreComponent {

  state = {
    customerTypes: [],
  };

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);
    this.fetchInitial(this.fetchCustomerTypes());
  }

  fetchCustomerTypes = () => {
    return this.cancelable(apiFetchCustomerTypes((data) => {
      this.setState({customerTypes: data});
    }));
  };

  render() {
    const {customerTypes} = this.state;
    const {user} = this.props;

    if (!this.state.isPageReady) {
      return <LoadingPage/>;
    }

    return (
      <div>
        {this.props.renderAlert()}
        <div className={"my-3 d-flex flex-row justify-content-start"}>
          <PageTitle isGrouped={true} title={this.props.title}/>
          {user.hasPermission(CAN_EDIT_CUSTOMERS) && (
            <NewCustomerModal
              customerTypes={customerTypes}
              user={user}
            />
          )}
        </div>
        <CustomerCustomersContainer
          setErrors={this.props.setErrors}
          customerTypes={customerTypes}
          navData={this.props.location.navData}
        />
      </div>
    );
  }
}

export default withAlert(withRouter(withTranslation()(CustomersPage)));
