import React from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Col} from "reactstrap";
import FormGroupWErr from "../helper/form_group_w_err";
import FiltersContainer from "../helper/filters_container";
import {withAlert} from "../helper/alert";
import CoreComponent from "../core_component";
import {apiFetchAvailableDistributors} from "../../api/fetch";
import {PageTitle} from "../helper/page_title";
import NewDistributorModal from "../modals/distributor/new_distributor_modal";
import TLink from "../helper/table_link";
import Paginator from "../helper/paginator";
import {CTable} from "../helper/ctable";

class DistributorsPage extends CoreComponent {
  state = {
    distributors: [],
  };

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);
  }

  fetchDistributors = (filtersData) => {
    return this.cancelable(apiFetchAvailableDistributors(filtersData, (respData) => {
      this.setState({
        distributors: respData.list,
      });
    }));
  };

  filtersComponent = (filtersData, onChange) => {
    let basicInput = (str, val, label = null) => {
      label = label || str;

      return (
        <Col md={3}>
          <FormGroupWErr str={str} val={val} label={label} onChange={onChange} required={false}/>
        </Col>
      );
    };

    return (
      <>
        {basicInput("search", filtersData.search, "name")}
      </>
    );
  };

  render() {
    const {distributors} = this.state;

    return (
      <div>
        {this.props.renderAlert()}

        <div className="my-3 d-flex flex-row justify-content-start">
          <PageTitle isGrouped={true} title={this.props.title}/>
          <NewDistributorModal/>
        </div>

        <Paginator
          next={this.fetchDistributors}
          additionalComponent={(onFilter) => (
            <FiltersContainer
              renderFilters={this.filtersComponent}
              onFilter={onFilter}
            />
          )}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("name")}</th>
              </tr>
            </thead>
            <tbody>
              {distributors.map((dist, i) => {
                return (
                  <tr key={`distributor-${i}`}>
                    <TLink to={`/distributors/${dist.id}`}>
                      {dist.name}
                    </TLink>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withAlert(withTranslation()(withRouter(DistributorsPage)));
