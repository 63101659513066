import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import ReactDatePicker from "react-date-picker";
import AInput from "../helper/apple_input";

class DatePicker extends CoreComponent {

  render() {
    const {onChange, value, disabled, lng, format, required, minDate, maxDate, maxDetail, minDetail} = this.props;

    return (
      <>
        {!disabled ?
          <div onClick={this.handleClick}>
            <ReactDatePicker
              locale={lng}
              format={format || "dd.MM.yyyy"}
              clearIcon={required === false ? <span>X</span> : null}
              minDate={minDate}
              maxDate={maxDate}
              maxDetail={maxDetail}
              minDetail={minDetail}
              onChange={onChange}
              value={value}
              required={this.defined(required) ? required : true}
            />
          </div>
          :
          <AInput disabled/>
        }
      </>
    );
  }
}

export default withTranslation()(DatePicker);
