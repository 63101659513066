import React from "react";

const CustomerTabContext = React.createContext({});
const CardTransactionsTabContext = React.createContext({});
const CustomerUnitTabContext = React.createContext({});
const ReportsTabContext = React.createContext({});
const AdminReportsTabContext = React.createContext({});
const TerminalOwnerTabContext = React.createContext({});
const TerminalTabContext = React.createContext({});
const CompanyTabContext = React.createContext({});
const DistributorTabContext = React.createContext({});

export const CustomerTabConsumer = CustomerTabContext.Consumer;
export const CardTransactionsTabConsumer = CardTransactionsTabContext.Consumer;
export const CustomerUnitTabConsumer = CustomerUnitTabContext.Consumer;
export const ReportsTabConsumer = ReportsTabContext.Consumer;
export const AdminReportsTabConsumer = AdminReportsTabContext.Consumer;
export const TerminalOwnerTabConsumer = TerminalOwnerTabContext.Consumer;
export const TerminalTabConsumer = TerminalTabContext.Consumer;
export const CompanyTabConsumer = CompanyTabContext.Consumer;
export const DistributorTabConsumer = DistributorTabContext.Consumer;

let tabIndex = 0;

function NewTabProvider(Context, activeTab) {
  const tabIdentifier = `react_tab_i${tabIndex}`;
  tabIndex = tabIndex + 1;

  return class extends React.Component {
    state = {
      activeTab: window.sessionStorage.getItem(tabIdentifier) || activeTab,
    };

    componentDidUpdate(prevProps, prevState) {
      if (this.state.activeTab !== prevState.activeTab) {
        window.sessionStorage.setItem(tabIdentifier, this.state.activeTab);
      }
    }

    toggle = (tab) => {
      this.setState({activeTab: tab});
    };

    render() {
      return (
        <Context.Provider value={{activeTab: this.state.activeTab, toggleTab: this.toggle}}>
          {this.props.children}
        </Context.Provider>
      );
    }
  };
}

export const CustomerTabProvider = NewTabProvider(CustomerTabContext, "cards");
export const CardTransactionsTabProvider = NewTabProvider(CardTransactionsTabContext, "transactions");
export const CustomerUnitTabProvider = NewTabProvider(CustomerUnitTabContext, "cards");
export const ReportsTabProvider = NewTabProvider(ReportsTabContext, "cards");
export const AdminReportsTabProvider = NewTabProvider(AdminReportsTabContext, "companies");
export const TerminalOwnerTabProvider = NewTabProvider(TerminalOwnerTabContext, "terminals");
export const TerminalTabProvider = NewTabProvider(TerminalTabContext, "changes");
export const CompanyTabProvider = NewTabProvider(CompanyTabContext, "users");
export const DistributorTabProvider = NewTabProvider(DistributorTabContext, "changes");

// WithProvider wraps BaseComponent inside as many context providers as provided
export function WithProviders(BaseComponent, providers = []) {
  return providers.reduce((wrapped, Provider) => (
    <Provider>
      {wrapped}
    </Provider>
  ), <BaseComponent/>);
}
