import React from "react";
import CoreComponent from "../../core_component";
import {Button} from "reactstrap";
import FormGroupWErr from "../../helper/form_group_w_err";
import {apiCreateNewDistributor} from "../../../api/fetch";
import ModalWindow from "../../helper/modal_window";
import {withTranslation} from "react-i18next";

class NewDistributorModal extends CoreComponent {
  renderCaller = (toggleOpen) => {
    return (
      <Button className={"ml-3 btn-add sm-btn"} onClick={toggleOpen}>
        {this.trans("addNew")}
      </Button>
    );
  };

  renderBody = (data, errors, onChange) => {
    return (
      <>
        <FormGroupWErr maxLength={128} str="name" err={errors.name} val={data.name} onChange={onChange}/>
      </>
    );
  };

  onSubmit = (data) => {
    return new Promise((resolve, reject) => {
      this.cancelable(apiCreateNewDistributor(data, () => {
        resolve();
      }, (err) => {
        reject(err);
      }));
    });
  };

  render() {
    return (
      <ModalWindow
        headerText={this.trans("newDistributor")}
        caller={this.renderCaller}
        body={this.renderBody}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default withTranslation()(NewDistributorModal);
