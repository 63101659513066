import React from "react";
import {Badge, Label} from "reactstrap";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

class LabelWithError extends CoreComponent {
  render() {
    let errField = "";
    let requiredField = "";

    if (this.props.error) {
      errField = (
        <Badge color="danger" className="ml-2 multiline-badge">
          {this.trans(`errors.${this.props.error}`, true, {VAR: `${this.props.errVar}`})}
        </Badge>
      );
    }

    if (this.props.required) {
      requiredField = (
        <span className="px-2 font-weight-bold" title={this.trans("requiredField")}>*</span>
      );
    }

    const classNames = ["d-flex", "mb-2"];

    if (this.props.contentEnd) {
      classNames.push("justify-content-end");
    } else {
      classNames.push("align-items-center");
    }

    if (!this.props.wrap) {
      classNames.push("text-nowrap");
    }

    return (
      <div className={classNames.join(" ")}>
        <Label for={this.props.for} className={`mb-0 dark-col ${this.props.error ? "text-danger" : ""}`}>
          {this.props.children}{requiredField}
        </Label>
        {errField}
      </div>
    );
  }
}

export default withTranslation()(LabelWithError);

LabelWithError.propTypes = {
  error: PropTypes.string,
  errVar: PropTypes.string,
  required: PropTypes.bool,
  contentEnd: PropTypes.bool,
  wrap: PropTypes.bool,
  for: PropTypes.string,
};
