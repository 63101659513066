import React from "react";
import CoreComponent from "../../core_component";
import {withTranslation} from "react-i18next";
import {apiFetchCompanyChanges} from "../../../api/fetch";
import {cardCreationType, mapChangedColumns, yesNo} from "../../helper/utils";
import Paginator from "../../helper/paginator";
import {CTable} from "../../helper/ctable";

class CompanyChangesContainer extends CoreComponent {
  state = {
    companyChanges: [],
  };

  fetchCompanyChanges = (urlQuery) => {
    return this.cancelable(apiFetchCompanyChanges(this.props.companyID, urlQuery, (data) => {
      this.setState({companyChanges: data.list});
    }));
  };

  render() {
    return (
      <div>
        <Paginator
          next={this.fetchCompanyChanges}
          pageLimit={10}
        >
          <CTable>
            <thead>
              <tr>
                <th>{this.trans("name")}</th>
                <th>{this.trans("identification")}</th>
                <th>{this.trans("address")}</th>
                <th>{this.trans("phone")}</th>
                <th>{this.trans("email")}</th>
                <th>{this.trans("billingEmail")}</th>
                <th>{this.trans("allowPartialPayments")}</th>
                <th>{this.trans("cardCreationType")}</th>
                <th>{this.trans("dateOfChange")}</th>
                <th>{this.trans("author")}</th>
              </tr>
            </thead>
            <tbody>
              {mapChangedColumns(this.state.companyChanges, (val, i, renderCol) => {
                return (
                  <tr key={`company-change-${i}`}>
                    <td>{renderCol("name")}</td>
                    <td>{renderCol("identification")}</td>
                    <td>{renderCol("address")}</td>
                    <td>{renderCol("phone")}</td>
                    <td>{renderCol("email")}</td>
                    <td>{renderCol("billingEmail", {defaultValue: "-"})}</td>
                    <td>{renderCol("allowPartialPayments", {formatter: yesNo})}</td>
                    <td>{renderCol("cardCreationTypeID", {formatter: (v) => this.trans(`cardCreationTypeOptions.${cardCreationType.value[v]}`)})}</td>
                    <td>{val.validFrom}</td>
                    <td>{val.authorEmail}</td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withTranslation()(CompanyChangesContainer);
