import React from "react";
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";
import Icon from "react-icons-kit";
import {ic_file_download} from "react-icons-kit/md";

class FButton extends React.Component {
  wrapOnClick = (onClickFn) => {
    this.props.onClickInternal(onClickFn);
  };

  render() {
    const {children, ...restProps} = this.props;
    delete restProps.onClickInternal;

    return (
      <Button {...restProps} onClick={this.props.onClick && (() => this.wrapOnClick(this.props.onClick))}>
        {children}
        <Icon icon={ic_file_download} className="ml-1"/>
      </Button>
    );
  }
}

class FButtonDropdown extends React.Component {
  wrapOnClick = (onClickFn) => {
    this.props.onClickInternal(onClickFn);
  };

  render() {
    return (
      <UncontrolledButtonDropdown>
        <DropdownToggle className={this.props.className} disabled={this.props.disabled} caret>
          {this.props.title}
          <Icon icon={ic_file_download} className="ml-1"/>
        </DropdownToggle>
        <DropdownMenu>
          {this.props.options.map((opt, i) => (
            <DropdownItem key={`dropdown-option-${i}`} onClick={opt.onClick && (() => this.wrapOnClick(opt.onClick))}>
              {opt.title}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }
}

// withMomentaryDisableOnClick defines HOC
// that disables wrapped component momentarily after onClick is being called
const withMomentaryDisableOnClick = (WrappedComponent) => {
  return class extends React.Component {
    state = {
      disabled: false,
    };

    timeoutID = null;

    componentWillUnmount() {
      clearTimeout(this.timeoutID);
    }

    afterClick = () => {
      this.timeoutID = setTimeout(() => {
        this.setState({disabled: false});
      }, 1000);
    };

    onClick = (onClickFn) => {
      // No clicking allowed if component is disabled
      if (this.state.disabled) {
        return;
      }

      this.setState({disabled: true}, () => {
        onClickFn().then(() => {
          this.afterClick();
        }).catch((error) => {
          if (error.cancelled) {
            return;
          }

          this.afterClick();
        });
      });
    };

    render() {
      return (
        <WrappedComponent {...this.props} onClickInternal={this.onClick} disabled={this.state.disabled}/>
      );
    }
  };
};

export const FetchButtonDropdown = withMomentaryDisableOnClick(FButtonDropdown);
export const FetchButton = withMomentaryDisableOnClick(FButton);
