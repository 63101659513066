import React from "react";
import {Link, withRouter} from "react-router-dom";
import {Nav, Navbar, NavItem, NavLink} from "reactstrap";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import Icon from "react-icons-kit";
import {ic_clear_all, ic_dehaze} from "react-icons-kit/md";

class Menu extends CoreComponent {
  state = {
    activePath: "",
    menus: [],
    showMobileMenu: false,
  };

  componentDidMount() {
    this.setState(Object.assign({}, this.state, {
      activePath: window.location.pathname,
      menus: this.props.menus
    }));
  }

  componentDidUpdate() {
    if (this.state.activePath !== window.location.pathname) {
      this.setStateValue("activePath", window.location.pathname);
    }
  }

  isActive = (path) => {
    if (path === "/") {
      return this.state.activePath === "/";
    }

    return this.state.activePath.indexOf(path) === 0;
  };

  showMenu = (menu, user) => {
    return user.isAllowedToAccess(menu.roles, menu.permission);
  };

  render() {
    const {user} = this.props;
    const {showMobileMenu} = this.state;

    let mainMenuClasses = ["main-menu"];

    if (!showMobileMenu) {
      mainMenuClasses.push("hidden");
    }

    return (
      <div className="menu-panel" style={{height: "100%"}}>
        <div className={"title-bar"}>
          <div className={"menu-toggler"} onClick={() => this.setState({showMobileMenu: !showMobileMenu})}>
            <Icon size={40} icon={ic_dehaze} style={{"color": "var(--col-lime-1)"}}/>
          </div>
          <h1>
            <Link to={"/"}>
              <div className="kkis-logo"/>
            </Link>
          </h1>
          <div className={"space-filler"}/>
        </div>
        <div className="py-1 text-center break-word" style={{borderBottom: "1px solid #dae67c", borderTop: "1px solid #dae67c"}}>
          <div className="user-name" onClick={() => this.navTo("/operators/self")}>
            {user.name}
          </div>
        </div>
        {user.previousLogin &&
        <div className="previous-login-container">
          <span>{this.trans("previousLogin")}:</span>
          <span>{user.previousLogin}</span>
        </div>
        }
        <Navbar className={mainMenuClasses.join(" ")}>
          <Nav vertical pills>
            {this.state.menus.filter((menu) => this.showMenu(menu, user)).map((menu, i) => {
              return (
                <NavItem key={`menu-${i}`}>
                  <NavLink tag={Link} to={menu.path} active={this.isActive(menu.path)} className="menu-text">
                    <Icon icon={menu.icon || ic_clear_all} className="ml-3 icon"/>&nbsp;{this.trans(menu.title)}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(withTranslation()(Menu));
