import React from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Col, Row, TabContent} from "reactstrap";
import {getRequest} from "../../api/request";
import {CustomerTabConsumer} from "../helper/context";
import LoadingPage from "./loading_page";
import {withAlert} from "../helper/alert";
import CoreComponent from "../core_component";
import CustomerInfoContainer from "../containers/customer/customer_info_container";
import CustomerMemo from "../containers/customer/customer_memo";
import NavTabs, {TabPaneOnOpen} from "../helper/nav_tabs";
import CustomerCardsContainer from "../containers/customer/customer_cards_container";
import CustomerUnitsContainer from "../containers/customer/customer_units_container";
import CustomerContactPeopleContainer from "../containers/customer/customer_contact_people_container";
import CustomerChangesContainer from "../containers/customer/customer_changes_container";
import {CAN_EDIT_CUSTOMERS, CAN_GET_CARDS} from "../helper/role_permissions";
import PrinterFileContainer from "../containers/customer/printer_file_container";
import CustomerDiscountContainer from "../containers/customer/customer_discount_container";

class CustomerViewPage extends CoreComponent {
  state = {
    customer: {},
  };

  customerID = this.props.match.params.id;

  tabs = {
    CARDS: "cards",
    CHANGES: "changes",
    UNITS: "units",
    CONTACTPEOPLE: "contactPeople",
    PRINTERFILES: "printerFiles",
    DISCOUNT: "discount"
  };

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);
    this.fetchInitial(
      this.fetchCustomer(),
    );
  }

  fetchCustomer = () => {
    return new Promise((resolve, reject) => {
      this.cancelable(getRequest(`/api/customers/${this.customerID}`)).then((resp) => {
        this.setStateValue("customer", resp.data);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  };

  render() {
    const {customer} = this.state;
    const {setErrors, user} = this.props;

    if (!this.state.isPageReady) {
      return <LoadingPage/>;
    }

    const navTabs = [];

    if (user.hasPermission(CAN_GET_CARDS)) {
      navTabs.push({id: this.tabs.CARDS, title: "cards"});
    }

    navTabs.push(
      {id: this.tabs.CHANGES, title: "changes"},
      {id: this.tabs.UNITS, title: "units"},
      {id: this.tabs.CONTACTPEOPLE, title: "contactPeople"},
    );

    if (user.hasPermission(CAN_GET_CARDS)) {
      navTabs.push({id: this.tabs.PRINTERFILES, title: "printerFiles"});
      navTabs.push({id: this.tabs.DISCOUNT, title: "discount"});
    }

    return (
      <div>
        {this.props.renderAlert()}
        <Row>
          <Col md={7}>
            <CustomerInfoContainer
              setErrors={setErrors}
              customer={customer}
              customerID={this.customerID}
              user={user}
            />
          </Col>
          <Col md={5}>
            <CustomerMemo
              setErrors={setErrors}
              customerID={this.customerID}
              user={user}
            />
          </Col>
        </Row>
        <CustomerTabConsumer>
          {({activeTab, toggleTab}) => (
            <div className={"mt-2"}>
              <NavTabs
                tabs={navTabs}
                activeTab={activeTab}
                toggleTab={toggleTab}
              />
              <TabContent activeTab={activeTab}>
                {user.hasPermission(CAN_GET_CARDS) && (
                  <TabPaneOnOpen tabId={this.tabs.CARDS}>
                    <CustomerCardsContainer
                      user={user}
                      setErrors={setErrors}
                      customerID={this.customerID}
                      customer={customer}
                    />
                  </TabPaneOnOpen>
                )}
                <TabPaneOnOpen tabId={this.tabs.CHANGES}>
                  <CustomerChangesContainer
                    setErrors={setErrors}
                    customerID={this.customerID}
                  />
                </TabPaneOnOpen>
                <TabPaneOnOpen tabId={this.tabs.UNITS}>
                  <CustomerUnitsContainer
                    setErrors={setErrors}
                    customerID={this.customerID}
                    user={user}
                  />
                </TabPaneOnOpen>
                <TabPaneOnOpen tabId={this.tabs.CONTACTPEOPLE}>
                  <CustomerContactPeopleContainer
                    setErrors={setErrors}
                    customerID={this.customerID}
                    user={user}
                  />
                </TabPaneOnOpen>
                {user.hasPermission(CAN_GET_CARDS) && (
                  <TabPaneOnOpen tabId={this.tabs.PRINTERFILES}>
                    <PrinterFileContainer
                      setErrors={setErrors}
                      customerID={this.customerID}
                    />
                  </TabPaneOnOpen>
                )}
                {user.hasPermission(CAN_EDIT_CUSTOMERS) && (
                  <TabPaneOnOpen tabId={this.tabs.DISCOUNT}>
                    <CustomerDiscountContainer
                      setErrors={setErrors}
                      customerID={this.customerID}
                    />
                  </TabPaneOnOpen>
                )}
              </TabContent>
            </div>
          )}
        </CustomerTabConsumer>
      </div>
    );
  }
}

export default withAlert(withRouter(withTranslation()(CustomerViewPage)));
