import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import {Col} from "reactstrap";
import FormGroupWErr from "../helper/form_group_w_err";
import FiltersContainer from "../helper/filters_container";
import {customerState} from "../helper/utils";

class CustomerReportFilters extends CoreComponent {

  renderFilters = (filtersData, onChange, onChangeInt) => {

    return (
      <>
        <Col md={3}>
          <FormGroupWErr
            str="customerState"
            type="select"
            options={
              Object.keys(customerState.name).map((v) => ({
                value: customerState.name[v],
                name: this.trans(`customerStateTypes.${v}`),
              }))
            }
            onChange={onChangeInt}
            val={filtersData.customerState}
            required={false}
          />
        </Col>
      </>
    );
  };

  render() {
    return (
      <FiltersContainer
        renderFilters={this.renderFilters}
        onFilter={this.props.onFilter}
        extraButtons={this.props.extraButtons}
        initData={this.props.initialPageFilters}
      />
    );
  }
}

export default withTranslation()(CustomerReportFilters);
