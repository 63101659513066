import React from "react";
import {Redirect, Route, withRouter} from "react-router-dom";
import {Container, Row} from "reactstrap";
import "./css/App.css";
import "./css/colors.css";
import {Login} from "./component/login";
import {PWChange} from "./component/pages/pw_change";
import Home from "./component/home";
import {Header} from "./component/header";
import {
  AdminReportsTabProvider,
  CardTransactionsTabProvider,
  CompanyTabProvider,
  CustomerTabProvider,
  CustomerUnitTabProvider,
  DistributorTabProvider,
  ReportsTabProvider,
  TerminalOwnerTabProvider,
  TerminalTabProvider,
} from "./component/helper/context";
import {ADMIN, DISTRIBUTOR, SUSER} from "./component/helper/user_roles";
import {PrivateRoute} from "./component/helper/private_route";
import CompaniesPage from "./component/pages/companies_page";
import CompanyViewPage from "./component/pages/company_overview_page";
import CoreComponent from "./component/core_component";
import CustomersPage from "./component/pages/customers_page";
import CustomerViewPage from "./component/pages/customer_overview_page";
import CustomerUnit from "./component/pages/customer_unit";
import {Operators} from "./component/pages/operators_page";
import OperatorViewPage from "./component/pages/operator_view_page";
import TerminalOwnersPage from "./component/pages/terminal_owners_page";
import TerminalOwnerOverviewPage from "./component/pages/terminal_owner_overview_page";
import TerminalOverviewPage from "./component/pages/terminal_overview_page";
import CardsPage from "./component/pages/cards_page";
import CardOverviewPage from "./component/pages/card_overview_page";
import ReportsPage from "./component/pages/reports_page";
import AdminReportsPage from "./component/pages/admin_reports_page";
import NewCardPage from "./component/pages/new_card_page";
import DistributorPage from "./component/pages/distributor_page";
import DistributorsPage from "./component/pages/distributors_page";
import CardsWithoutClient from "./component/pages/cards_without_client";
import ActivateCards from "./component/pages/activate_cards";
import ErrorBoundary from "./component/helper/error_boundary";
import ForgotPasswordPage from "./component/pages/forgot_password_page";
import {ic_contacts, ic_description, ic_devices_other, ic_group, ic_payment, ic_settings_applications} from "react-icons-kit/md";
import {statsBars} from "react-icons-kit/icomoon/statsBars";
import NewCardWoClientPage from "./component/pages/new_card_without_client_page";
import CardWithoutClientAddClient from "./component/pages/card_without_client_add_client";
import {
  CAN_ACTIVATE_CARDS,
  CAN_GENERATE_CARDS,
  CAN_GET_CARDS,
  CAN_GET_CUSTOMERS,
  CAN_GET_REPORTS,
  CAN_GET_STATISTICS,
  CAN_GET_TERMINALS
} from "./component/helper/role_permissions";
import {withUserData} from "./User";
import {StatsPage} from "./component/pages/stats_page";
import {Register} from "./component/pages/register";

const uuidRegex = "([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})";

class App extends CoreComponent {
  menus = [
    {
      path: "/",
      title: "home",
      component: Home
    },
    {
      path: "/customers",
      title: "customers",
      icon: ic_contacts,
      permission: CAN_GET_CUSTOMERS,
      component: CustomersPage
    },
    {
      path: "/cards",
      title: "cards",
      icon: ic_payment,
      permission: CAN_GET_CARDS,
      component: CardsPage
    },
    {
      path: "/companies",
      title: "companies",
      icon: ic_contacts,
      roles: [DISTRIBUTOR, ADMIN],
      component: CompaniesPage
    },
    {
      path: "/terminal-owners",
      title: "terminals",
      icon: ic_devices_other,
      permission: CAN_GET_TERMINALS,
      component: TerminalOwnersPage
    },
    {
      path: "/reports",
      title: "reports",
      icon: ic_description,
      permission: CAN_GET_REPORTS,
      component: ReportsPage,
      providers: [ReportsTabProvider]
    },
    {
      path: "/stats",
      title: "statistics",
      icon: statsBars,
      permission: CAN_GET_STATISTICS,
      component: StatsPage,
    },
    {
      path: "/admin-reports",
      title: "reports",
      icon: ic_description,
      roles: [DISTRIBUTOR, ADMIN],
      component: AdminReportsPage,
      providers: [AdminReportsTabProvider]
    },
    {
      path: "/distributors",
      title: "distributors",
      icon: ic_group,
      roles: [ADMIN],
      component: DistributorsPage,
    },
    {
      path: "/operators",
      title: "operators",
      icon: ic_settings_applications,
      roles: [DISTRIBUTOR, ADMIN],
      component: Operators
    },
    {
      path: "/companies/company",
      title: "account",
      icon: ic_settings_applications,
      roles: [SUSER],
      providers: [CompanyTabProvider],
      component: CompanyViewPage
    },
  ];

  routes = [
    {
      path: "/cards/:id([1-9][0-9]*)",
      title: "card",
      permission: CAN_GET_CARDS,
      component: CardOverviewPage,
      providers: [CardTransactionsTabProvider]
    },
    {
      path: "/cards/new",
      title: "addCard",
      permission: CAN_GENERATE_CARDS,
      component: NewCardPage
    },
    {
      path: "/cards/without-client/new",
      title: "addCardWithoutClient",
      permission: CAN_GENERATE_CARDS,
      component: NewCardWoClientPage,
    },
    {
      path: "/cards/without-client",
      title: "cardsWithoutClient",
      permission: CAN_GET_CARDS,
      component: CardsWithoutClient,
    },
    {
      path: "/cards/activate",
      title: "activateCard",
      permission: CAN_ACTIVATE_CARDS,
      component: ActivateCards,
    },
    {
      path: "/cards/without-client/add-client",
      title: "addClientToCard",
      permission: CAN_GENERATE_CARDS,
      component: CardWithoutClientAddClient,
    },
    {
      path: "/customers/:id([1-9][0-9]*)",
      title: "customer",
      permission: CAN_GET_CUSTOMERS,
      component: CustomerViewPage,
      providers: [CustomerTabProvider]
    },
    {
      path: "/customers/:id([1-9][0-9]*)/unit-history/:uid([1-9][0-9]*)",
      title: "customerUnit",
      permission: CAN_GET_CUSTOMERS,
      component: CustomerUnit,
      providers: [CustomerUnitTabProvider]
    },
    {
      path: "/companies/:id([1-9][0-9]*)",
      title: "company",
      roles: [DISTRIBUTOR, ADMIN],
      providers: [CompanyTabProvider],
      component: CompanyViewPage
    },
    {
      path: "/terminal-owners/:id([1-9][0-9]*)",
      title: "terminalOwner",
      permission: CAN_GET_TERMINALS,
      component: TerminalOwnerOverviewPage,
      providers: [TerminalOwnerTabProvider],
    },
    {
      path: "/terminal-owners/:id([1-9][0-9]*)/terminals/:tid([1-9][0-9]*)",
      title: "terminal",
      permission: CAN_GET_TERMINALS,
      component: TerminalOverviewPage,
      providers: [TerminalTabProvider],
    },
    {
      path: "/operators/:aid([1-9][0-9]*)",
      title: "operator",
      roles: [DISTRIBUTOR, ADMIN],
      component: OperatorViewPage,
    },
    {
      path: "/company/:cid([1-9][0-9]*)/operators/:aid([1-9][0-9]*)",
      title: "operator",
      roles: [SUSER, DISTRIBUTOR, ADMIN],
      component: OperatorViewPage
    },
    {
      path: "/operators/self",
      title: "operator",
      component: OperatorViewPage
    },
    {
      path: "/distributors/:id([1-9][0-9]*)",
      title: "distributor",
      roles: [ADMIN],
      component: DistributorPage,
      providers: [DistributorTabProvider],
    },
  ];

  render() {
    let classNames = ["mt-3 mb-4 main-wrapper"];
    const {user, changeLanguage, logout, login} = this.props;

    if (["/login", "/forgot-password"].includes(window.location.pathname)) {
      classNames.push("login-page-wrapper");
    }

    return (
      <Container className={classNames.join(" ")}>
        <Header user={user} changeLanguage={changeLanguage} logout={logout}/>
        <Row className="mb-2">
          {this.menus.concat(this.routes).map((route, i) => (
            <PrivateRoute key={`route-${i}`} user={user} logout={logout} menus={this.menus} exact {...route}/>
          ))}
          <ErrorBoundary>
            <Route exact path="/login" render={() => {
              return user.isLoggedIn() ? <Redirect to="/"/> : <Login login={login}/>;
            }}/>
            <Route exact path={`/pwchange/:id${uuidRegex}`} render={() => {
              return user.isLoggedIn() ? <Redirect to="/"/> : <PWChange login={login}/>;
            }}/>
            <Route exact path={`/register/:id${uuidRegex}`} render={() => {
              return user.isLoggedIn() ? <Redirect to="/"/> : <Register login={login}/>;
            }}/>
            <Route exact path="/forgot-password" render={() => {
              return user.isLoggedIn() ? <Redirect to="/"/> : <ForgotPasswordPage/>;
            }}/>

          </ErrorBoundary>
          {/* TODO figure out how to show NotFoundPage without breaking React.Context */}
        </Row>
      </Container>
    );
  }
}

export default withUserData(withRouter(App));
