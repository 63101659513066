import React from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import CoreComponent from "./core_component";
import i18next from "i18next";
import {Button, ButtonGroup, Col, Row} from "reactstrap";
import Icon from "react-icons-kit";
import {exit} from "react-icons-kit/icomoon/exit";
import {apiChangeSelectedAccount, apiSearch} from "../api/fetch";
import SelectInput from "./helper/select_input";
import SelectWrap from "./helper/select_wrap";

class _Header extends CoreComponent {

  localChangeSelectedAccount = (ev) => {
    this.cancelable(apiChangeSelectedAccount(ev.target.value, () => {
      this.navTo("/", "accountChanged");
    }));
  };

  render() {
    const {user, logout} = this.props;

    return (
      <Row className="header-row">
        {user.isLoggedIn() && (
          <>
            {user.hasMultipleAccounts() && (
              <Col className="search-col">
                <SelectWrap
                  str="selectedAccountID"
                  options={user.getOperatorAccountOptions()}
                  onChange={this.localChangeSelectedAccount}
                  val={user.selectedAccountID}
                  required={false}
                  isClearable={false}
                />
              </Col>
            )}
            <Col className="search-col">
              <SelectInput
                onInputChange={apiSearch}
                onChange={(v) => {
                  if (v.target && v.target.value) {
                    this.navTo(v.target.value);
                  }
                }}
                standalone={true}
                required={false}
                minInputLength={2}
                mapper={(rows) => rows.map((row) => {
                  return {
                    name: row.value,
                    value: row.urlPath,
                  };
                })}
              />
            </Col>
          </>
        )}

        <Col className="language-choice-col">
          <ButtonGroup size="sm">
            {(i18next.languages.sort() || []).map((lang) => {
              const cl = i18next.language === lang ? "btn-active" : "btn-inactive";
              return (
                <Button key={`language-${lang}`} onClick={() => this.props.changeLanguage(lang)} className={cl}>
                  {String(lang).toUpperCase()}
                </Button>
              );
            })}
          </ButtonGroup>
        </Col>

        {user.isLoggedIn() && (
          <Col className="logout-col">
            <Button color="link" onClick={logout}>
              <Icon icon={exit}/> {this.trans("logout")}
            </Button>
          </Col>
        )}
      </Row>
    );
  }
}

export const Header = withTranslation()(withRouter(_Header));
