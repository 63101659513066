import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import Select from "react-select";


class SelectWrap extends CoreComponent {

  onChangeConversion = (data) => {
    let {str, onChange, initData} = this.props;

    if (data !== null) {
      let _data = {
        target: {
          name: str,
          value: data.value
        }
      };
      onChange(_data);
    } else {
      let _data = {
        target: {
          name: str,
          value: initData || 0
        }
      };
      onChange(_data);
    }
  };

  render() {
    let {val, options, required, onInputChange, placeholder, onClick} = this.props;

    let currentOption = null;
    //antud mappimine on vajalik kuna Select library kasutab value - labeli, aga meil on value - name key'd
    let _options = options.map(v => {

      if (val === v.value) {
        currentOption = {
          value: v.value,
          label: v.name
        };
      }

      return {
        value: v.value,
        label: v.name
      };
    });

    return (
      <>
        <span onClick={onClick}>
          <Select
            isClearable={this.props.isClearable !== false}
            value={currentOption}
            onChange={this.onChangeConversion}
            onInputChange={onInputChange}
            options={_options}
            placeholder={placeholder || this.trans("selectAndDots")}
            noOptionsMessage={() => this.trans("noResultsFound")}
          >
          </Select>
        </span>
        {/*input field on ainult et simuleerida required välja kuna Select ei tundu seda toetavat*/}
        <input
          tabIndex={-1}
          value={this.defined(currentOption) ? currentOption.value : ""}
          required={required}
          className="select-wrap-required-invisible-input"
          onChange={() => {}} // vältimaks warningu
        >
        </input>
      </>
    );
  }
}

export default withTranslation()(SelectWrap);
