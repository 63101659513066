import React from "react";
import {Link, withRouter} from "react-router-dom";
import CoreComponent from "../core_component";
import {Button, Col, FormGroup, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import {withAlert} from "../helper/alert";
import CoreForm from "../helper/core_form";
import LabelWithError from "../helper/label_with_error";
import AInput from "../helper/apple_input";
import {apiForgotPassword} from "../../api/fetch";

class ForgotPasswordPage extends CoreComponent {
  state = {
    "email": "",
  };

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);
  }

  onChange = (e) => {
    this.setStateValue(e.target.name, e.target.value);
  };

  onSubmit = () => {
    return this.cancelable(apiForgotPassword(this.state.email, () => {
      this.navReload("forgotPasswordSuccess", true);
    }, (err) => {
      this.props.setErrors(err.errors);
    }));
  };

  render() {
    const {errors, renderAlert} = this.props;
    const {email} = this.state;

    return (
      <Col className="panel">
        <Row className="justify-content-center my-3">
          <Col xs={9}>
            <Link to="/">
              <div className="kkis-logo lg"/>
            </Link>

            {renderAlert()}

            <CoreForm onSubmit={this.onSubmit}>
              <FormGroup>
                <LabelWithError error={errors.email} for={"email"}>
                  {this.trans("email")}
                </LabelWithError>
                <AInput maxLength={254} type="email" id="email" name="email" value={email} required onChange={this.onChange}/>
              </FormGroup>
              <FormGroup className="d-flex justify-content-end">
                <Button className="sm-btn btn-save">
                  {this.trans("forgotPassword")}
                </Button>
              </FormGroup>
            </CoreForm>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default withAlert(withTranslation()(withRouter(ForgotPasswordPage)));
