import React from "react";
import CoreComponent from "../../core_component";
import {Col, NavLink, Row} from "reactstrap";
import {apiDeleteCompanyWebApi, apiDeleteCompanyWebApiIP, apiDeleteCompanyWebApiPermission, apiGetCompanyWebApis} from "../../../api/fetch";
import {PageTitle} from "../../helper/page_title";
import NewWebApiModal from "../../modals/company/new_web_api_modal";
import {CAN_EDIT_WEB_API} from "../../helper/role_permissions";
import {ConfirmationModal} from "../../helper/modal_window";
import Icon from "react-icons-kit";
import {ic_content_copy, ic_delete, ic_format_list_numbered} from "react-icons-kit/md";
import ShowMoreText from "react-show-more-text";
import EditWebApiModal from "../../modals/company/edit_web_api_modal";
import AddWebApiIpModal from "../../modals/company/add_web_api_ip_modal";
import {getRequest} from "../../../api/request";
import CollapsibleTableRow from "../../helper/collapsible_table_row";
import EditWebApiIpModal from "../../modals/company/edit_web_api_ip_modal";
import Tooltip from "../../helper/tooltip";
import AddWebApiPermissionModal from "../../modals/company/add_web_api_permission_modal";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {CTable} from "../../helper/ctable";


class CompanyWebApisContainer extends CoreComponent {
  state = {
    webApis: [],
  };

  componentDidMount() {
    this.fetchCompanyWebApis();
  }

  fetchCompanyWebApis = () => {
    return this.cancelable(apiGetCompanyWebApis(this.props.companyID, (data) => {
      this.setState({webApis: data});
    }, (err) => {
      this.props.setErrors(err.errors);
    }));
  }

  removeWebApi = (webApiID) => {
    this.cancelable(apiDeleteCompanyWebApi(webApiID, () => {
      this.navReload("closed");
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  removeWebApiIP = (webApiIpID) => {
    this.cancelable(apiDeleteCompanyWebApiIP(webApiIpID, () => {
      this.navReload("closed");
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  removeWebApiPermission = (webApiPermissionId) => {
    this.cancelable(apiDeleteCompanyWebApiPermission(webApiPermissionId, () => {
      this.navReload("closed");
    }, (error) => {
      this.props.setErrors(error.errors);
    }));
  };

  copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      this.props.setInfoMsg(this.trans("copied"));
    } catch (err) {
      this.props.setErrors(this.trans("failedToCopy"));
    }
  };

  fetchWebApiDetails = (webApiID) => {
    return new Promise((resolve, reject) => {
      this.cancelable(getRequest(`/api/companies/company/web-apis/${webApiID}/detail`)).then((resp) => {
        resolve(resp.data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  renderWebApiDetails = (data) => {
    return (
      <Row className={"mb-3"}>
        <Col md={8}>
          <div className="d-flex flex-row justify-content-start">
            <h5 className={"mr-3"}>{this.trans("ipAddress")}</h5>
            {this.props.user.hasPermission(CAN_EDIT_WEB_API) && (
              <AddWebApiIpModal
                webApiId={data.webApiId}
                setErrors={this.props.setErrors}
              />
            )}
          </div>
          {(data.webApiIPs) && data.webApiIPs.map((ip, j) => {
            return (
              <Row className={"p-0 m-0"} key={`web-api-ip-${j}`}>
                <Col>{ip.ipAddress}</Col>
                <Col>{ip.comment}</Col>
                <Col md={3}>{ip.author.Email}</Col>
                {this.props.user.hasPermission(CAN_EDIT_WEB_API) && (
                  <Col md={1}>
                    <EditWebApiIpModal
                      webApiIpID={ip.id}
                      webApiIpAddress={ip.ipAddress}
                      comment={ip.comment}
                      setErrors={this.props.setErrors}
                    />
                    {data.webApiIPs.length > 1 &&
                      <Tooltip title={this.trans("deleteIP")} showif={true}>
                        <ConfirmationModal
                          title={ip.ipAddress}
                          subtitle={this.trans("areYouSureToCloseThis.webApiIP")}
                          onSubmit={() => this.removeWebApiIP(ip.id)}
                          opener={(open) => (
                            <NavLink className="p-0 d-inline-block" href="#" onMouseUp={open}>
                              <Icon icon={ic_delete}/>
                            </NavLink>
                          )}
                        />
                      </Tooltip>
                    }
                  </Col>
                )}
                <hr className={"thin-line"}/>
              </Row>
            )
            ;
          })
          }</Col>
        <Col md={4}>
          <div className="d-flex flex-row justify-content-start">
            <h5 className={"mr-3"}>{this.trans("permissions")}</h5>
            {this.props.user.hasPermission(CAN_EDIT_WEB_API) && (
              <AddWebApiPermissionModal
                webApiId={data.webApiId}
                setErrors={this.props.setErrors}
              />
            )}
          </div>
          {(data.webApiPerms) && data.webApiPerms.map((perm, i) => {
            return (
              <Row className={"p-0 m-0"} key={`web-api-permission-${i}`}>
                <Col>{this.trans(`webApiPermission.${perm.permission.name}`)}</Col>
                <Col>{perm.author.Email}</Col>
                {this.props.user.hasPermission(CAN_EDIT_WEB_API) && (
                  <Col md={1}>
                    <ConfirmationModal
                      title={this.trans(`webApiPermission.${perm.permission.name}`)}
                      subtitle={this.trans("areYouSureToCloseThis.webApiPermission")}
                      onSubmit={() => this.removeWebApiPermission(perm.id)}
                      opener={(open) => (
                        <NavLink className="p-0 d-inline-block" href="#" onMouseUp={open}>
                          <Icon icon={ic_delete}/>
                        </NavLink>
                      )}
                    />
                  </Col>
                )}
                <hr className={"thin-line"}/>
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <div>
        {(this.props.user.hasPermission(CAN_EDIT_WEB_API)) && (
          <div className="my-3 d-flex flex-row justify-content-start">
            <PageTitle isGrouped={true} title={this.trans("web_apis")}/>
            <NewWebApiModal
              companyID={this.props.companyID}
              setErrors={this.props.setErrors}
            />
          </div>
        )}
        <CTable>
          <thead>
            <tr>
              <th>{this.trans("name")}</th>
              <th className={"text-center"}>{this.trans("apiKey")}</th>
              <th>{this.trans("dateOfChange")}</th>
              <th className={"text-center"}>{this.trans("author")}</th>
              <th/>
              {this.props.user.hasPermission(CAN_EDIT_WEB_API) && (<th colSpan={2}/>)}
            </tr>
          </thead>
          <tbody>
            {this.state.webApis.map((val, i) => {
              const targetRef = React.createRef();
              return (
                <CollapsibleTableRow
                  isOpenable={true}
                  key={`api-change-${i}`}
                  colSpan={7}
                  displayData={this.renderWebApiDetails}
                  loadData={() => this.fetchWebApiDetails(val.webApiId)}
                  setErrors={this.props.setErrors}
                  targetRef={targetRef}
                >
                  <td>{val.name}</td>
                  <td>
                    <div className={"d-flex flex-row"}>
                      <ShowMoreText
                        lines={3}
                        more={this.trans("showMore")}
                        less={this.trans("showLess")}
                        expanded={false}
                        width={580}
                        truncatedEndingComponent={"... "}
                      >
                        <span style={{wordBreak: "break-all"}}>{val.apiKey}</span>
                      </ShowMoreText>
                      <div className={"ml-3 cursor-pointer"}>
                        <Tooltip title={this.trans("copyApiKey")} showif={true}>
                          <Icon icon={ic_content_copy} onClick={() => this.copyToClipBoard(`${val.apiKey}`)}/>
                        </Tooltip>
                      </div>
                    </div>
                  </td>
                  <td>{val.validFrom}</td>
                  <td>{val.authorEmail}</td>
                  <td className="icon-wrapper-width">
                    <div ref={targetRef} className={"d-inline-block cursor-pointer"}>
                      <Tooltip title={`${this.trans("show")} ${this.trans("details").toLowerCase()}`} showif={true}>
                        <Icon icon={ic_format_list_numbered}/>
                      </Tooltip>
                    </div>
                  </td>
                  {this.props.user.hasPermission(CAN_EDIT_WEB_API) && (
                    <>
                      <td className="icon-wrapper-width">
                        <EditWebApiModal
                          webApiId={val.webApiId}
                          name={val.name}
                          setErrors={this.props.setErrors}
                        />
                      </td>
                      <td className="icon-wrapper-width">
                        <ConfirmationModal
                          title={val.name}
                          subtitle={this.trans("areYouSureToCloseThis.webApi")}
                          onSubmit={() => this.removeWebApi(val.webApiId)}
                          opener={(open) => (
                            <NavLink className="p-0 d-inline-block" href="#" onMouseUp={open}>
                              <Icon icon={ic_delete}/>
                            </NavLink>
                          )}
                        />
                      </td>
                    </>
                  )
                  }
                </CollapsibleTableRow>
              )
              ;
            })}
          </tbody>
        </CTable>
      </div>
    )
    ;
  }
}

export default withRouter(withTranslation()(CompanyWebApisContainer));
