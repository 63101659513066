import React from "react";
import {Button, Input, NavLink} from "reactstrap";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {PageTitle} from "../helper/page_title";
import LoadingPage from "./loading_page";
import {withAlert} from "../helper/alert";
import CardsFilters from "../filters/cards_filters";
import {apiFetchCard, apiFetchCardProducts, apiFetchCards, apiFetchCardsXLSX} from "../../api/fetch";
import {cardState, isCardUsed} from "../helper/utils";
import CoreComponent from "../core_component";
import Icon from "react-icons-kit";
import {ic_autorenew, ic_radio_button_checked, ic_radio_button_unchecked, ic_update} from "react-icons-kit/md";
import OverviewContainer from "../helper/overview_container";
import TLink from "../helper/table_link";
import {SUSER} from "../helper/user_roles";
import Paginator from "../helper/paginator";
import ExtendCardExpiryTimeModal from "../modals/card/extend_card_expiry_time_modal";
import Tooltip from "../helper/tooltip";
import {FetchButton} from "../helper/fetch_button";
import {saveXLSX} from "../helper/fileutil";
import CardBalancesContainer, {CardBalancesTableColumn} from "../containers/card/card_balances_container";
import {CAN_ACTIVATE_CARDS, CAN_GENERATE_CARDS, CAN_GET_CUSTOMERS, CAN_GET_REPORTS} from "../helper/role_permissions";
import {CTable} from "../helper/ctable";

class CardsPage extends CoreComponent {
  state = {
    cards: [],
    cardProducts: [],
    cardCount: 0,
    initialPageFilters: {
      cardState: cardState.name.active,
      pan: "",
    },
    filterInitCardState: null,
  };

  componentDidMount() {
    this.props.setInfoMsg(this.props.location.notification);
    this.fetchInitial(this.fetchCardProducts());
  }

  downloadXLSX = (filtersData) => {
    return this.cancelable(apiFetchCardsXLSX(filtersData, (data) => {
      saveXLSX(data, this.trans("cards"));
    }, (error) => {
      if (error.cancelled) {
        return;
      }

      this.props.setErrors(error.errors);
    }));
  };

  fetchCards = (filtersData) => {
    return this.cancelable(apiFetchCards(filtersData, (data) => {
      this.setState({
        cards: data.list,
        cardCount: data.count,
      });
    }));
  };

  fetchCardProducts = () => {
    return this.cancelable(apiFetchCardProducts((data) => {
      this.setStateValue("cardProducts", data);
    }));
  };

  navigateToRenewCards = (cardID) => {
    this.cancelable(apiFetchCard(cardID, (resp) => {
      this.navTo("/cards/new", null, resp);
    }, (error) => {
      if (error.cancelled) {
        return;
      }

      this.props.setErrors(error.errors);
    }));
  };

  selectOrUnselectCard = (event, id = null) => {
    let cards = this.state.cards.map((card) => {
      if (id === null || card.id === id) {
        card.selected = event.target.checked;
      }

      return card;
    });

    this.setState({
      cards: cards,
    });
  };

  render() {
    const {cards, cardProducts, cardCount, initialPageFilters} = this.state;
    const {renderAlert, user, title} = this.props;

    const selectedCards = cards.filter(card => card.selected);
    const selectedExtendableCards = selectedCards.filter(card => card.canExtendExpiryDate);
    const selectedExtendableCardIDs = selectedExtendableCards.map(card => card.id);

    let maxExtendByMonths;

    if (selectedExtendableCards.length > 0) {
      maxExtendByMonths = Math.min(...selectedExtendableCards.map(card => card.maxMonthsToExtend));
    }

    if (this.props.location.state && this.props.location.state.expiringSoonOnly) {
      initialPageFilters["expiringSoon"] = true;
    }

    if (!this.state.isPageReady) {
      return <LoadingPage/>;
    }

    return (
      <div>
        {renderAlert()}

        <div className={"my-3 d-flex flex-row justify-content-start"}>
          <PageTitle title={title} isGrouped={true}/>
          {user.hasPermission(CAN_GENERATE_CARDS) && user.isGenerateCards() && (
            <Button className="ml-2 btn-add sm-btn" onClick={() => this.navTo("/cards/new")}>
              {this.trans("addNew")}
            </Button>
          )}
          <Button className="ml-2 btn-add sm-btn" onClick={() => this.navTo("/cards/without-client")}>
            {this.trans("cardsWithoutClient")}
          </Button>
          {user.hasPermission(CAN_ACTIVATE_CARDS) && user.companyAsCustomer &&
            <Button className="ml-2 btn-add sm-btn" onClick={() => this.navTo("/cards/activate")}>
              {this.trans("activateCard")}
            </Button>
          }
        </div>

        <Paginator
          next={this.fetchCards}
          initialPageFilters={initialPageFilters}
          additionalComponent={(onFilter, initPageFilters) => (
            <>
              <CardsFilters
                onFilter={onFilter}
                cardProducts={cardProducts}
                initData={initPageFilters}
                extraButtons={(data) => (
                  user.hasPermission(CAN_GET_REPORTS) && (
                    <FetchButton className={"sm-btn"} onClick={() => this.downloadXLSX(data)}>
                      XLSX
                    </FetchButton>
                  )
                )}
              />
              <OverviewContainer
                columns={[
                  {title: this.trans("cardCount"), value: cardCount}, user.hasPermission(CAN_GENERATE_CARDS) && {
                    value: (
                      <ExtendCardExpiryTimeModal
                        cardIDs={selectedExtendableCardIDs}
                        disabled={selectedExtendableCardIDs.length < 1 || selectedExtendableCardIDs.length !== selectedCards.length}
                        maxExtendByMonths={maxExtendByMonths}
                      />
                    )
                  }
                ]}
              />
            </>
          )}
        >
          <CTable>
            <thead>
              <tr>
                <th>
                  <Input
                    type={"checkbox"}
                    className={"table-checkbox-margin-left"}
                    onChange={(ev) => this.selectOrUnselectCard(ev)}
                    checked={cards.filter((v) => v.selected).length === cards.length && cards.length > 0}
                  />
                </th>
                <th>{`${this.trans("pan")}`}</th>
                <th className="text-center">{this.trans("balanceSlashLimit")}</th>
                <th>{this.trans("customerName")}</th>
                <th>{this.trans("nameOnCard")}</th>
                <th>{this.trans("infoFieldOnCard")}</th>
                <th>{this.trans("cardProductName")}</th>
                <th>{this.trans("validTo")}</th>
                <th>{this.trans("expiryDateOnCard")}</th>
                <th>{this.trans("cardState")}</th>
                <th>{this.trans("usedUp")}</th>
                <th/>
              </tr>
            </thead>
            <tbody>
              {cards.map((card, i) => {
                return (
                  <tr key={`cards-${card.id}-${i}`}>
                    <td>
                      <Input
                        type={"checkbox"}
                        className={"table-checkbox-margin-left"}
                        onChange={(ev) => this.selectOrUnselectCard(ev, card.id)}
                        checked={card.selected}
                      />
                    </td>
                    <TLink to={`/cards/${card.id}`}>
                      <Tooltip
                        showif={true}
                        className={"card-balance-tooltip-content"}
                        getContent={() => <CardBalancesContainer cardID={card.id}/>}
                      >
                        {card.pan}
                      </Tooltip>
                    </TLink>
                    <CardBalancesTableColumn totals={card.balanceAndTotals}/>
                    <TLink
                      user={user} roles={[SUSER]}
                      to={`/customers/${card.customerID}`}
                      disableLink={card.customerClosed || !user.hasPermission(CAN_GET_CUSTOMERS)}
                    >
                      {card.customerName}
                    </TLink>
                    <td>{card.name || "-"}</td>
                    <td>{card.info || "-"}</td>
                    <td>{card.cardProductName}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span>{card.validTo}</span>
                        {card.canExtendExpiryDate && (
                          <Tooltip trans={"cardExpiryDateCanBeExtended"} showif={true}>
                            <Icon icon={ic_update} className="d-flex" style={{"color": "green"}}/>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                    <td>{card.expiryDateOnCard}</td>
                    <td>{this.trans(`cardStateTypes.${cardState.value[card.cardState]}`)}</td>
                    <td><Icon icon={isCardUsed(card.balanceAndTotals) ? ic_radio_button_checked : ic_radio_button_unchecked}/></td>
                    <td>
                      {card.expiringSoon && user.hasPermission(CAN_GENERATE_CARDS) && (
                        <Tooltip title={`${this.trans("renewBatch")} (${this.trans("batch").toLowerCase()} ${card.batchID})`} showif={true}>
                          <NavLink className="p-0 d-inline-block" href="#" onClick={() => this.navigateToRenewCards(card.id)}>
                            <Icon icon={ic_autorenew}/>
                          </NavLink>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </CTable>
        </Paginator>
      </div>
    );
  }
}

export default withAlert(withRouter(withTranslation()(CardsPage)));
