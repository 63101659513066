import React from "react";
import CoreComponent from "../core_component";
import {withTranslation} from "react-i18next";
import {FormGroup, Input, Label} from "reactstrap";

class FormGroupCheckbox extends CoreComponent {

  render() {
    const {str, val, onChange, disabled, vertical, className} = this.props;
    let {label} = this.props;

    if (!label) {
      label = str;
    }

    label = typeof label === "object" ? label : this.trans(label);

    const input = (
      <Input
        type="checkbox"
        id={str}
        name={str}
        checked={val}
        onChange={onChange}
        disabled={disabled}
        className="label-group-checkbox mr-2"
      />
    );

    if (vertical) {
      return (
        <FormGroup className="checkbox-form-group">
          <Label className="dark-col" for={str}>
            {label}
          </Label>
          <div>
            {input}
          </div>
        </FormGroup>
      );
    }

    return (
      <FormGroup className={`checkbox-form-group ${className}`}>
        <Label className="dark-col" for={str}>
          {input}
          {label}
        </Label>
      </FormGroup>
    );
  }
}

export default withTranslation()(FormGroupCheckbox);
